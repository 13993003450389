<script>
import _ from 'lodash';

import color from '@shared/lib/color';

import WaterfallChart from '@console/components/charts/WaterfallChart.vue';
import Currency from '@shared/components/Currency.vue';
import Tooltip from '@shared/design/Tooltip.vue';

export default {
  components: {
    Currency,
    Tooltip,
    WaterfallChart,
  },
  props: {
    amortizationSummary: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tooltip() {
      return {
        enabled: false,
      };
    },
    yAxis() {
      return {
        title: 'USD',
        labels: {
          format: '${value:,f}', // eslint-disable-line no-template-curly-in-string
        },
        floor: 0,
      };
    },
    series() {
      const hasPrepaymentAdjustment = _.get(this.amortizationSummary, 'prepayment_adjustment', 0) !== 0;
      const chartColors = {
        beginning_prepayment_balance: color.getColor('gray'),
        new_prepayment_deployed: color.getColor('success'),
        prepayment_adjustment:
          hasPrepaymentAdjustment && this.amortizationSummary.prepayment_adjustment > 0
            ? color.getColor('success')
            : color.getColor('danger'),
        prepayment_consumed: color.getColor('danger'),
        ending_prepayment_balance: color.getColor('gray'),
      };

      return [
        {
          borderWidth: 0,
          pointPadding: 0,
          states: {
            hover: {
              enabled: false,
            },
          },
          minPointLength: 3,
          data: [
            {
              name: 'Beginning prepayment balance',
              y: this.amortizationSummary.beginning_prepayment_balance,
              color: chartColors.beginning_prepayment_balance,
            },
            {
              name: 'New prepayment deployed',
              y: this.amortizationSummary.new_prepayment_deployed,
              color: chartColors.new_prepayment_deployed,
            },
            hasPrepaymentAdjustment
              ? {
                  name: 'Prepayment adjustment',
                  y: this.amortizationSummary.prepayment_adjustment,
                  color: chartColors.prepayment_adjustment,
                }
              : null,
            {
              name: 'Prepayment consumed',
              y: this.amortizationSummary.prepayment_consumed,
              color: chartColors.prepayment_consumed,
            },
            {
              // rather than using isSum:true,
              // force this value to be negative, to render a bar moving down
              name: 'Ending prepayment balance',
              y: this.amortizationSummary.ending_prepayment_balance * -1,
              color: chartColors.ending_prepayment_balance,
            },
          ].filter(x => x !== null), // remove hasPrepaymentAdjustment if not used
        },
      ];
    },
  },
};
</script>

<template>
  <div class="pl-1 pt-1 pb-1">
    <div class="row">
      <div class="col col-lg-5">
        <table class="table dataLegend">
          <tr class="noTopBorder">
            <td>
              <span id="beginning_prepayment_balance" class="tooltip-target">Beginning prepayment balance</span>
              <Tooltip target="beginning_prepayment_balance">
                The total of all compute prepayments made in prior months that have not yet been consumed. This value is
                carried forward from the ending balance of the prior month and is typically reflected on the balance
                sheet.
              </Tooltip>
            </td>
            <td class="colAmount font-weight-bold">
              <Currency :value="amortizationSummary.beginning_prepayment_balance" />
            </td>
          </tr>
          <tr>
            <td>
              <span id="new_prepayment_deployed" class="tooltip-target">New prepayment deployed</span>
              <Tooltip target="new_prepayment_deployed">
                New Compute Savings Plan and EC2 Reserved Instance prepayment our service has deployed this month. This
                value is typically reflected as an outflow on the cash flow statement and adds to the prepayment
                balance.
              </Tooltip>
            </td>
            <td class="colAmount font-weight-bold">
              <Currency
                :value="amortizationSummary.new_prepayment_deployed"
                signed
                :class="amortizationSummary.new_prepayment_deployed >= 0 ? 'text-success' : 'text-danger'"
              />
            </td>
          </tr>
          <tr v-if="amortizationSummary.prepayment_adjustment">
            <td>
              <span id="prepayment_adjustment" class="tooltip-target">Prepayment adjustment</span>
              <Tooltip target="prepayment_adjustment">
                <div>
                  A change in prepayment value has been detected. This can happen when AWS accounts containing prepaid
                  SPs and/or EC2 RIs are moved into or out of the Organization, when upfront SPs or EC2 RIs are
                  purchased outside of our platform, etc.
                </div>
              </Tooltip>
            </td>
            <td class="colAmount font-weight-bold">
              <Currency
                :value="amortizationSummary.prepayment_adjustment"
                signed
                :class="amortizationSummary.prepayment_adjustment >= 0 ? 'text-success' : 'text-danger'"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span id="prepayment_consumed" class="tooltip-target">Prepayment consumed</span>
              <Tooltip target="prepayment_consumed">
                <div>
                  Prepayment that was used this month based on the commitment term. This value is typically reflected on
                  the income statement and reduces the prepayment balance.
                </div>
              </Tooltip>
            </td>
            <td class="colAmount font-weight-bold">
              <Currency :value="amortizationSummary.prepayment_consumed" signed class="text-danger" />
            </td>
          </tr>
          <tr>
            <td>
              <div class="base"></div>
              <span id="ending_prepayment_balance" class="tooltip-target">Ending prepayment balance</span>
              <Tooltip target="ending_prepayment_balance">
                <div>
                  Remaining compute prepayments that will be consumed in future periods. This value is typically
                  reflected on the balance sheet.
                </div>
              </Tooltip>
            </td>
            <td class="colAmount font-weight-bold">
              <Currency :value="amortizationSummary.ending_prepayment_balance" />
            </td>
          </tr>
        </table>
      </div>
      <div class="col col-lg-7 secondColumn">
        <WaterfallChart :series="series" :tooltip="tooltip" :y-axis="yAxis" zoomable />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.callout {
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 1.2;
}

.total {
  font-weight: bold;
}
tr.noTopBorder th,
tr.noTopBorder td {
  border-top: 0;
}
.colAmount {
  text-align: right;
}
.dataLegend {
  height: 100%;
  td {
    vertical-align: middle;
  }
}

.secondColumn {
  @include media-breakpoint-down(lg) {
    padding-top: 2rem;
  }
}
</style>
