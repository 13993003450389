<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="37.103" height="37.103" viewBox="0 0 37.103 37.103">
    <path
      d="M18.551 1A17.551 17.551 0 1 1 1 18.551 17.551 17.551 0 0 1 18.551 1z"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M10.351 16.954h10.775a.7.7 0 0 0 0-1.4H10.351l3.593-3.641a.7.7 0 0 0 0-.987.685.685 0 0 0-.977 0l-4.768 4.837a.708.708 0 0 0 0 .987l4.768 4.836a.686.686 0 0 0 .977 0 .7.7 0 0 0 0-.987zm19.559 4.324l-4.768-4.836a.686.686 0 0 0-.977 0 .7.7 0 0 0 0 .987l3.593 3.645H16.984a.7.7 0 0 0 0 1.4h10.774l-3.593 3.645a.7.7 0 0 0 0 .987.685.685 0 0 0 .977 0l4.767-4.836a.706.706 0 0 0 .001-.992z"
      fill="currentColor"
    />
  </svg>
</template>
