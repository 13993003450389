<script setup>
import { validateOrganizationAccess } from '@gcp/services/accessValidation';

import ValidationStepperItem from './ValidationStepperItem.vue';
import CopyButton from '@shared/design/CopyButtonV2.vue';

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  organizationId: {
    type: String,
    required: true,
  },
  serviceAccount: {
    type: Object,
    required: true,
  },
  role: {
    type: Object,
    required: true,
  },
  permissions: {
    type: String,
    required: true,
  },
  onValidated: {
    type: Function,
    required: true,
  },
});

const organizationIamUrl = `https://console.cloud.google.com/iam-admin/iam?organizationId=${props.organizationId}`;

const validate = async () => {
  await validateOrganizationAccess(props.organizationId, props.permissions);
  await props.onValidated();
};
</script>

<template>
  <ValidationStepperItem title="Organization Access" :open="open" :validate="validate">
    <template #default>
      <ul class="pl-3">
        <li>
          Navigate to <a target="_blank" rel="noopener noreferrer" :href="organizationIamUrl">Google Cloud IAM</a>
        </li>
        <li>
          Click <b>Grant Access</b> and add the following new principal:<br />
          <span class="text-monospace bg-light p-1">{{ props.serviceAccount?.email }}</span>
          <CopyButton :key="props.serviceAccount?.email" class="ml-2" :text="serviceAccount?.email" />
        </li>
        <li>
          Assign the <b>{{ props.role.title }}</b> role
        </li>
      </ul>
    </template>
    <template #error>
      Hmm, we weren't able to validate access. Please ensure the principal name is correct and the specified roles are
      assigned. Also confirm that the <b>{{ props.role.title }}</b> role has the permissions shown above. If the issue
      persists, please chat with us for help.
    </template>
  </ValidationStepperItem>
</template>
