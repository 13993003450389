<script setup lang="ts">
import type { FeatureFlag } from '@shared/state/feature.store';

import { useFeatureFlag } from '@shared/state/feature.store';

const props = defineProps<{
  featureFlag: FeatureFlag;
}>();

const isEnabled = useFeatureFlag(props.featureFlag);
</script>

<template>
  <slot v-if="isEnabled" />
  <slot v-else name="disabled" />
</template>
