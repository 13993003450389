<script>
export default {};
</script>

<template>
  <div class="setting">
    <div>
      <span class="label">
        <slot name="label" />
      </span>
      <p class="mb-0 mt-1 text-muted">
        <slot name="description" />
      </p>
    </div>
    <div>
      <slot name="value" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.setting {
  display: flex;
  flex-direction: column;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  > div {
    width: 100%;
    padding-right: 1rem;
    margin-top: 1rem;
  }

  p {
    font-size: 0.85rem;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;

    > div {
      width: 50%;
      padding-right: 3rem;
      margin-top: 0;
    }
  }
}

.label {
  font-weight: 400;
}
</style>
