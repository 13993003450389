<script>
import _ from 'lodash';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    activeKey: {
      type: String,
      required: true,
    },
  },
  methods: {
    clicked(item) {
      const tab = _.find(this.items, i => i.key === item.key);
      if (!tab.disabled) {
        this.$emit('changed', item.key);
      }
    },
  },
};
</script>

<template>
  <ul class="tabList">
    <li
      v-for="item in items"
      :key="item.key"
      class="tabListItem"
      :class="{ active: item.key === activeKey, disabled: !!item.disabled }"
      @click="clicked(item)"
    >
      {{ item.label }}
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.tabList {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.tabListItem {
  position: relative;
  display: inline-block;
  padding: 1rem 1.5rem 0.5rem 0;
  font-weight: 400;
}

.tabListItem:not(.disabled):hover {
  cursor: pointer;
}

.active {
  font-weight: 500;
}

.active:not(.disabled)::after,
.tabListItem:not(.disabled):hover::after {
  display: block;
  height: 4px;
  margin-top: 4px;
  content: '';
  background-color: #5c54ff;
  border-radius: 2px;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
