<script>
import { dateFormat, dateUtc } from '@shared/utilities/filters';

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    formatDate(date, format = 'MMMM D, YYYY') {
      return dateFormat(dateUtc(date), format);
    },
  },
};
</script>

<template>
  <div v-if="value" class="lastUpdated small">Last Updated: {{ formatDate(value) }}</div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.lastUpdated {
  font-size: 75%;
  font-weight: 300;
  color: $gray-500;
  vertical-align: top;

  @include media-breakpoint-up(md) {
    font-size: 100%;
  }
}

.lastUpdated.small {
  font-size: 0.85rem;
}
</style>
