<script>
import _ from 'lodash';
import { mapActions, mapState } from 'pinia';
import vuex from 'vuex';

import { useAuthStore } from '@shared/state/auth.store';

import ProfileDropdownItem from '@console/components/navigation/ProfileDropdownItem.vue';
import ProfileImage from '@console/components/navigation/ProfileImage.vue';
import BillingIcon from '@shared/design/icons/BillingIcon.vue';
import UserIcon from '@shared/design/icons/UserIcon.vue';

export default {
  components: {
    BillingIcon,
    ProfileImage,
    ProfileDropdownItem,
    UserIcon,
  },
  props: {
    blockNavModal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      otherCompaniesExpanded: false,
      suppressHideOnCompanySwitch: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['profile']),
    ...mapState(useAuthStore, ['isFederated', 'isEmployee', 'role']),
    ...vuex.mapState('customer', ['selectedCompanyId', 'customers']),
    ...vuex.mapGetters('customer', ['companyName', 'isSubscribed', 'isDemo']),
    customerArray() {
      return _.toArray(this.customers);
    },
    canSwitchCustomers() {
      return this.customerArray.length > 1;
    },
    hasCustomer() {
      return this.customerArray.length > 0;
    },
    otherCompanies() {
      return _.filter(_.values(this.customers), customer => customer.id !== this.selectedCompanyId);
    },
    userFullName() {
      return _.get(this.profile, 'name');
    },
    userCreatedAt() {
      return _.get(this.profile, 'claims.createdAt');
    },
    showCustomerDropdownItems() {
      return this.hasCustomer && !this.isDemo;
    },
    isOwner() {
      return this.role(this.selectedCompanyId) === 'Owner';
    },
    menuClasses() {
      return {
        'mt-2': true,
        'pt-0': true,
        'pb-0': true,
        'rounded-sm': true,
      };
    },
  },
  mounted() {
    this.$root.$on('bv::dropdown::hide', this.onDropdownHide);
  },
  unmounted() {
    this.$root.$off('bv::dropdown::hide', this.onDropdownHide);
  },
  methods: {
    ...mapActions(useAuthStore, ['logOut']),
    async onSwitchCompanyClicked() {
      this.suppressHideOnCompanySwitch = true;
      this.otherCompaniesExpanded = !this.otherCompaniesExpanded;
    },
    async onCompanyChanged(companyId) {
      const query = { switchToCompanyId: companyId };
      await this.$router.push({ name: 'root', query });
    },
    onDropdownHide(bvEvent) {
      if (this.suppressHideOnCompanySwitch) {
        bvEvent.preventDefault();
        this.suppressHideOnCompanySwitch = false;
      }
    },
  },
};
</script>

<template>
  <div v-if="profile">
    <b-dropdown
      ref="dropdown"
      variant="link"
      toggle-class="text-decoration-none pr-0 pl-0 btn-no-focus-box-shadow"
      :menu-class="menuClasses"
      offset="25"
      right
      no-caret
    >
      <template v-slot:button-content>
        <div class="profile">
          <div class="pl-4">
            <div v-if="companyName" class="text-truncate text-nowrap text-right">
              {{ companyName }}
            </div>
            <div class="small font-weight-light text-truncate text-nowrap text-right">
              {{ userFullName }}
            </div>
          </div>
          <div class="d-flex justify-content-center pl-3 text-muted">
            <BaseIcon name="angle-down" />
          </div>
        </div>
      </template>
      <div id="profile-button-menu" class="bg-white">
        <ProfileDropdownItem
          v-if="hasCustomer && !isDemo && !isFederated && !isEmployee"
          :to="{ name: 'user_profile' }"
        >
          <template v-slot:left>
            <ProfileImage :profile="profile" small />
          </template>
          <template v-slot:right>
            <span>Profile</span>
          </template>
        </ProfileDropdownItem>
        <ProfileDropdownItem v-if="showCustomerDropdownItems && isOwner && isSubscribed" :to="{ name: 'billing' }">
          <template v-slot:left>
            <BillingIcon />
          </template>
          <template v-slot:right>
            <span>Billing</span>
          </template>
        </ProfileDropdownItem>
        <ProfileDropdownItem v-if="showCustomerDropdownItems && isOwner" :to="{ name: 'user_management' }">
          <template v-slot:left>
            <UserIcon />
          </template>
          <template v-slot:right>
            <span>User Management</span>
          </template>
        </ProfileDropdownItem>
        <ProfileDropdownItem
          v-if="showCustomerDropdownItems && canSwitchCustomers && !isFederated"
          @click="onSwitchCompanyClicked"
        >
          <template v-slot:left>
            <BaseIcon name="building" variant="far" />
          </template>
          <template v-slot:right>
            <span class="mr-2">Switch Company</span>
            <BaseIcon v-if="!otherCompaniesExpanded" name="angle-down" />
            <BaseIcon v-if="otherCompaniesExpanded" name="angle-up" />
          </template>
        </ProfileDropdownItem>
        <template v-if="otherCompaniesExpanded">
          <ProfileDropdownItem
            v-for="company in otherCompanies"
            :key="company.id"
            class="withBorderTop"
            @click="onCompanyChanged(company.id)"
          >
            <template v-slot:right> {{ company.company_name }} </template>
          </ProfileDropdownItem>
        </template>
        <ProfileDropdownItem class="withBorderTop" @click="logOut">
          <template v-slot:left>
            <BaseIcon name="sign-out-alt" />
          </template>
          <template v-slot:right>
            <span>Log Out</span>
          </template>
        </ProfileDropdownItem>
      </div>
    </b-dropdown>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-700;
  border-left: none;

  > div:first-child {
    font-weight: 500;
  }

  @include media-breakpoint-up(md) {
    border-left: 1px solid $gray-400;
  }
}

.withBorderTop {
  border-top: 1px solid $gray-200;
}

.navigationPopover {
  font-size: 0.95rem;
}
</style>
