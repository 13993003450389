<script setup>
const props = defineProps({
  enabled: {
    type: Boolean,
    required: true,
  },
  off: {
    type: String,
    required: true,
  },
  on: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['change']);
const turnOff = () => emit('change', false);
const turnOn = () => emit('change', true);
</script>

<template>
  <div class="toggleContainer">
    <button
      class="btn btn-sm"
      :class="{ 'btn-primary': !enabled, 'btn-link': enabled }"
      :disabled="disabled"
      type="button"
      @click="turnOff()"
    >
      {{ props.off }}
    </button>
    <button
      class="btn btn-sm"
      :class="{ 'btn-primary': enabled, 'btn-link': !enabled }"
      :disabled="disabled"
      type="button"
      @click="turnOn()"
    >
      {{ props.on }}
    </button>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.toggleContainer {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  padding: 2px;
  background-color: #f5f6fa;
  border: 1px solid $gray-500;
  border-radius: 18px;

  &:focus {
    border-color: white;
  }

  .btn {
    padding: 0.125rem 0.6rem;
    border-radius: 15px;
  }

  /* stylelint-disable selector-class-pattern */
  .btn-primary:focus {
    background-color: map-get($theme-colors, 'primary');
    border-color: map-get($theme-colors, 'primary');
    box-shadow: none;
  }

  .btn-link:hover {
    text-decoration: none;
  }
  /* stylelint-enable selector-class-pattern */
}
</style>
