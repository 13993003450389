import type { App } from 'vue';

import FormSubmitButton from '@shared/design/FormSubmitButton.vue';
import BaseIcon from '@shared/design/icons/BaseIcon.vue';

export const useSharedDesign = (app: App) => {
  app.component('BaseIcon', BaseIcon);
  app.component('FormSubmitButton', FormSubmitButton);
};

declare module 'vue' {
  export interface GlobalComponents {
    BaseIcon: typeof BaseIcon;
    FormSubmitButton: typeof FormSubmitButton;
  }
}
