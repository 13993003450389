<script>
import _ from 'lodash';

import ReallocationMethod from '@aws/components/showback/ReallocationMethod.vue';
import SavingsShare from '@aws/components/showback/SavingsShare.vue';
import DownloadCsvIcon from '@shared/design/icons/DownloadCsvIcon.vue';

export default {
  components: {
    ReallocationMethod,
    SavingsShare,
    DownloadCsvIcon,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    demo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onFilterChange(event) {
      const { name, selected } = event;
      if (this.demo && name === 'savings_share') {
        throw new Error('Changing filter for savings_share is not allowed');
      }
      const newState = _.assign({}, this.value, { [name]: selected });
      this.$emit('input', newState);
    },
    downloadCSV() {
      this.$emit('download-csv');
    },
  },
};
</script>

<template>
  <div class="pb-1 d-flex justify-content-between">
    <div class="actions">
      <div>
        <strong>Savings Reallocation Method</strong>
        <ReallocationMethod name="reallocation_method" :value="value.reallocation_method" @change="onFilterChange" />
      </div>
      <div>
        <strong>ProsperOps Savings Share</strong>
        <SavingsShare name="savings_share" :disabled="demo" :value="value.savings_share" @change="onFilterChange" />
      </div>
    </div>
    <div>
      <b-button
        title="Download in CSV format"
        variant="transparent"
        class="csvIcon btn-no-focus-box-shadow"
        @click.prevent="downloadCSV"
      >
        <DownloadCsvIcon />
      </b-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.actions {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  > div:nth-child(1) {
    margin-right: 0;

    @include media-breakpoint-up(lg) {
      margin-right: 2rem;
    }
  }
}

.csvIcon > svg {
  width: 24px;
  height: 24px;
}
</style>
