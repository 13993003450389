<script>
import { mapActions } from 'pinia';
import { mapActions as vuexMapActions } from 'vuex';

import { useAuthStore } from '@shared/state/auth.store';

import LoadingPage from '@shared/components/LoadingPage.vue';

export default {
  components: {
    LoadingPage,
  },
  async mounted() {
    const target = await this.authenticateFromCallback();
    // we reset the console state, specifically so the
    // navigation context will be cleared from local storage.
    // for onboarding, this state is set to a placeholder. just
    // because this user had the placeholder in their local storage,
    // does not mean that they should automatically go to the onboarding
    // after login. Another user may have created an AWS organization already.
    // once we clear, the router logic will pick the appropriate landing
    // for this user.
    await this.resetAwsOrganization();

    try {
      await this.$router.replace(target);
    } catch (error) {
      const ignorableRedirectWarning = /Redirected when going from "\/callback.+ via a navigation guard./g;
      const shouldSuppress = ignorableRedirectWarning.test(error.message);
      if (!shouldSuppress) {
        throw error;
      }
    }
  },
  methods: {
    ...mapActions(useAuthStore, ['authenticateFromCallback']),
    ...vuexMapActions('aws', ['resetAwsOrganization']),
  },
};
</script>

<template>
  <LoadingPage />
</template>
