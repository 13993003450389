<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AccordionListItem',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  mounted() {
    this.expanded = this.active;
  },
  updated() {
    this.expanded = this.active;
  },
});
</script>

<template>
  <div class="p-3 border-bottom">
    <div class="d-flex align-items-center">
      <div class="pr-2 text-muted">
        <BaseIcon v-if="expanded" name="angle-up" />
        <BaseIcon v-else name="angle-down" />
      </div>
      <div class="d-flex align-items-center w-100">
        <slot />
      </div>
    </div>
    <div v-if="expanded" class="mx-3 my-3">
      <slot name="expanded" />
    </div>
  </div>
</template>
