<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapActions } from 'pinia';
import { mapActions as vuexMapActions } from 'vuex';

import { useAuthStore } from '@shared/state/auth.store';

import FocusContent from '@console/components/FocusContent.vue';
import Layout from '@console/Layout.vue';
import TextInput from '@shared/design/TextInput.vue';

export default {
  components: {
    Layout,
    TextInput,
    FocusContent,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      submitting: false,
      companyName: '',
    };
  },
  head: {
    title: 'Add Company',
  },
  validations: {
    companyName: {
      required,
    },
  },
  methods: {
    ...mapActions(useAuthStore, ['refreshRoles']),
    ...vuexMapActions('customer', ['addCompany']),
    async submit() {
      this.submitting = true;
      await this.addCompany(this.companyName);
      await this.refreshRoles();
      await this.$router.push({ name: 'root' });
    },
  },
};
</script>

<template>
  <Layout no-nav with-footer wide>
    <template #default>
      <FocusContent>
        <form @submit.prevent="submit">
          <div class="row">
            <div class="col">
              <h1 class="display-4">Getting started is easy!</h1>
            </div>
          </div>
          <div class="row">
            <div class="col pt-3 pb-3">
              <ul class="setupList">
                <li>
                  <div class="number">1</div>
                  <div>
                    After creating your company, you'll be directed to our Onboarding Wizard where you can configure our
                    least privilege access to your cloud environment (takes minutes)
                  </div>
                </li>
                <li>
                  <div class="number">2</div>
                  <div>
                    With that access, we'll produce a
                    <strong>free, no commitment</strong> Savings Analysis which we'll personally review with you
                  </div>
                </li>
                <li>
                  <div class="number">3</div>
                  <div>
                    If you subscribe, we'll begin actively managing your commitment portfolio and you'll have full
                    access to our Console to see your savings performance
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col pt-3 pb-3">
              <div class="inputWrapper">
                <TextInput
                  id="companyName"
                  ref="input"
                  v-model.trim="v$.companyName.$model"
                  type="text"
                  class="w-100"
                  placeholder="Your Company's Name"
                  autocomplete="off"
                  with-focus
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex flex-row-reverse">
              <form-submit-button
                type="submit"
                variant="primary"
                class="rounded-sm"
                :loading="submitting"
                :disabled="v$.$invalid"
              >
                Add Company
                <template v-slot:loading> Adding... </template>
              </form-submit-button>
            </div>
          </div>
        </form>
      </FocusContent>
    </template>
    <template v-slot:footer>
      <div class="d-flex justify-content-center align-items-center">
        <div>
          By creating a company, you acknowledge that you have read and accept our
          <a href="https://www.prosperops.com/legal/savings-analysis-terms/" target="_blank">Savings Analysis Terms</a>.
        </div>
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.setupList {
  padding: 0;
  margin: 0;
  font-size: 1.2rem;
  list-style-type: none;
}

.setupList li {
  display: flex;
  max-width: 700px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.number {
  padding-right: 1rem;
  font-size: 1.6rem;
  font-weight: bolder;
  color: map-get($theme-colors, 'primary');
}

.number::after {
  content: '.';
}
</style>
