<script>
import _ from 'lodash';
import qs from 'qs';
import { mapGetters } from 'vuex';

import csv from '@console/lib/csvBuilder';
import customerService from '@console/services/customerService';

import ShowbackTableAccountRow from '@aws/components/showback/ShowbackTableAccountRow.vue';
import ShowbackTableFilter from '@aws/components/showback/ShowbackTableFilter.vue';
import ShowbackTableHeader from '@aws/components/showback/ShowbackTableHeader.vue';
import ShowbackTableRow from '@aws/components/showback/ShowbackTableRow.vue';
import ShowbackTableSummaryRow from '@aws/components/showback/ShowbackTableSummaryRow.vue';
import TimeframeSelector from '@aws/components/showback/TimeframeSelector.vue';
import BuildingState from '@console/components/BuildingState.vue';
import Layout from '@console/Layout.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import BoxMessageV2 from '@shared/design/BoxMessageV2.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import Panel from '@shared/design/panels/Panel.vue';

export default {
  components: {
    Layout,
    PageHeader,
    Panel,
    BuildingState,
    TimeframeSelector,
    BoxMessage,
    BoxMessageV2,
    ShowbackTableRow,
    ShowbackTableFilter,
    ShowbackTableHeader,
    ShowbackTableSummaryRow,
    ShowbackTableAccountRow,
  },
  data() {
    return {
      selectedTimeframe: null,
      showback: null,
      showbackNotFound: false,
      filters: {
        reallocation_method: 'shared',
        savings_share: this.$route.query.savings_share || 'include',
      },
    };
  },
  head: {
    title: 'Showback',
  },
  computed: {
    ...mapGetters('customer', ['isReseller', 'isDemo']),
    ...mapGetters('aws', [
      'selectedOrganization',
      'selectedOrganizationManagementAccount',
      'selectedOrganizationAccounts',
    ]),
    isLoading() {
      return !this.selectedTimeframe && !this.showbackNotFound;
    },
    awsAccountsByNumber() {
      if (this.isDemo) {
        return _.keyBy(this.selectedOrganizationAccounts, 'unmasked_aws_account_number');
      }
      return _.keyBy(this.selectedOrganizationAccounts, 'aws_account_number');
    },
    showbackRows() {
      const round = (row, key) => Number((_.get(row, key, 0) || 0).toFixed(2));
      const summaries = _.get(this.showback, ['dashboard', 'summaries'], []);
      const reallocationMethod = this.filters.reallocation_method;
      const savingsShare = this.filters.savings_share;
      const filter = {
        reallocation_method: _.capitalize(reallocationMethod),
        includes_savings_share: _.toLower(savingsShare) === 'include',
      };
      const summary = _.find(summaries, filter);
      const rows = _.get(summary, 'rows', []);
      return _.map(rows, row => {
        return _.assign({}, row, {
          original_cost_rounded: round(row, 'original_cost'),
          original_savings_rounded: round(row, 'original_savings'),
          undiscounted_usage_rounded: round(row, 'undiscounted_usage'),
          reallocated_savings_rounded: round(row, 'reallocated_savings'),
          adjusted_cost_rounded: round(row, 'adjusted_cost'),
          net_adjustment_rounded: round(row, 'net_adjustment'),
        });
      });
    },
    awsAccountRows() {
      const awsAccountRows = _.filter(this.showbackRows, row => row.aws_account_number);
      return this.sortShowbackRows(awsAccountRows);
    },
    serviceShareRow() {
      return _.find(this.showbackRows, row => !row.aws_account_number);
    },
    filterKey() {
      const reallocationMethod = this.filters.reallocation_method;
      const savingsShare = this.filters.savings_share;
      return `showback_filter_${reallocationMethod}_${savingsShare}`;
    },
  },
  watch: {
    async filters(newFilters) {
      const path = this.$route.path;
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery,
        ..._.pick(newFilters, ['savings_share']),
      };
      window.history.replaceState('', '', `${path}?${qs.stringify(newQuery)}`);
    },
  },
  async mounted() {
    if (this.isReseller) {
      await this.$router.push({ name: '404' });
      return;
    }

    if (this.isDemo) {
      this.filters.savings_share = 'exclude';
    }

    await this.load(this.$route.params.timeframe);
  },
  methods: {
    async load(timeframe) {
      try {
        const awsOrganizationId = this.selectedOrganization.id;
        const response = await customerService.getShowback(awsOrganizationId, timeframe);
        this.showback = response.data;
        this.selectedTimeframe = this.showback.selected_timeframe;
      } catch (e) {
        this.handleError(e);
      }
    },
    handleError(e) {
      const status = _.get(e, 'response.status', 500);
      if (status === 404) {
        this.showbackNotFound = true;
      } else {
        throw e;
      }
    },
    async onChange(nextTimeframe) {
      const timeframe = nextTimeframe.key;
      await this.$router.push({ name: 'aws_showback', params: { timeframe } });
    },
    downloadCSV() {
      const filename = csv.showbackFileName(
        this.selectedTimeframe,
        this.selectedOrganizationManagementAccount,
        this.filters
      );

      let sorted = _.orderBy(this.showbackRows, ['aws_account_number'], ['asc']);

      sorted = _.map(sorted, row =>
        _.assign({}, row, {
          aws_account_number: !row.aws_account_number ? '' : this.awsAccountNumberForRow(row.aws_account_number),
          friendly_name: !row.aws_account_number ? '' : this.awsAccountFriendlyNameForRow(row.aws_account_number),
        })
      );

      const rows = csv.showbackRows(sorted);
      csv.saveFile(filename, rows);
    },
    awsAccountNumberForRow(awsAccountNumber) {
      // Deleted accounts won't show up in the store, and will need censored manually
      const awsAccount = this.awsAccountsByNumber[awsAccountNumber];
      const fallback = this.isDemo ? 'XXXXXXXXXXXX' : awsAccountNumber;
      return _.get(awsAccount, 'aws_account_number', fallback);
    },
    awsAccountFriendlyNameForRow(awsAccountNumber) {
      // Deleted accounts won't show up in the store, and will need censored manually
      const awsAccount = this.awsAccountsByNumber[awsAccountNumber];
      return _.get(awsAccount, 'friendly_name', 'AWS Account');
    },
    sortShowbackRows(awsAccountRows) {
      // accounts with net_adjustment < 0 are sorted first (asc),
      // then, we add accounts with net_adjustment >= 0, which are sorted (asc)
      // by friendly name.
      const groups = _.groupBy(awsAccountRows, 'aws_account_number');
      const sortable = _.map(groups, (rows, key) => ({
        rows,
        aws_account_number: this.awsAccountNumberForRow(key),
        friendly_name: this.awsAccountFriendlyNameForRow(key),
        total_net_adjustment_rounded: _.sumBy(rows, 'net_adjustment_rounded'),
      }));
      const isNegativeNetAdjustment = a => a.total_net_adjustment_rounded < 0;
      const lowerCaseFriendlyName = a => a.friendly_name.toLowerCase();
      let [negative, nonnegative] = _.partition(sortable, isNegativeNetAdjustment);
      negative = _.orderBy(negative, ['total_net_adjustment_rounded'], ['asc']);
      nonnegative = _.orderBy(nonnegative, [lowerCaseFriendlyName], ['asc']);
      return _.concat(negative, nonnegative);
    },
  },
};
</script>

<template>
  <Layout :loading="isLoading">
    <template #default>
      <BuildingState v-if="showbackNotFound" variant="showback" />
      <div v-else>
        <div class="showbackViewDisabled">
          <p class="lead text-center mb-0 mt-5">Showback is not supported on your device's screen resolution.</p>
        </div>
        <div class="showback">
          <PageHeader wrap-utility>
            <h1>Showback</h1>
            <template v-slot:utility>
              <TimeframeSelector
                :selected="selectedTimeframe"
                :timeframes="showback.available_timeframes"
                @change="onChange"
              />
            </template>
          </PageHeader>
          <div class="row sectional">
            <div class="col">
              <BoxMessageV2>
                <div>
                  <p>
                    Native AWS billing does not properly allocate savings generated from a centralized portfolio of
                    Reserved Instances and Savings Plans out to an Organization. This creates transparency and
                    cost/savings attribution problems. This report addresses that by properly reallocating savings and
                    adjusting cost on a per resource basis.
                    <a href="https://help.prosperops.com/showback-intro" target="_blank">Learn More</a>
                  </p>
                  <p class="mb-0">
                    Note: Amounts shown only include AWS compute services and do not include post-purchase adjustments
                    such as AWS Private Pricing, EDPs, refunds, credits, taxes, etc.
                  </p>
                </div>
              </BoxMessageV2>
            </div>
          </div>
          <div v-if="!showback.customer_visible" class="row sectional">
            <div class="col">
              <BoxMessage type="error">
                <div>
                  This month is not currently customer visible as either: a) the net adjustment is not $0 or b) one or
                  more rows have reallocated savings that exceeds undiscounted usage.
                </div>
              </BoxMessage>
            </div>
          </div>
          <div class="row sectional">
            <div class="col">
              <Panel class="pt-0">
                <div class="pt-3 pb-3 showbackStickyHeader">
                  <ShowbackTableFilter v-model.lazy="filters" :demo="isDemo" @download-csv="downloadCSV" />
                  <div class="pt-2">
                    <ShowbackTableHeader />
                  </div>
                </div>
                <div :key="filterKey">
                  <div :key="filterKey + '_content'" class="fadeIn">
                    <ShowbackTableSummaryRow :rows="showbackRows" />
                    <ShowbackTableAccountRow
                      v-for="row in awsAccountRows"
                      :key="row.key"
                      class="resourceRow"
                      :row="row"
                    />
                    <ShowbackTableRow v-if="serviceShareRow" class="resourceRow" :row="serviceShareRow">
                      <template v-slot:label>
                        <div>
                          {{ serviceShareRow.service }}
                        </div>
                      </template>
                    </ShowbackTableRow>
                  </div>
                </div>
              </Panel>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
.showback {
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
}

.showbackViewDisabled {
  display: block;

  @media (min-width: 1200px) {
    display: none;
  }
}

.showbackStickyHeader {
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: #fff;
}

.resourceRow:not(:last-child) {
  border-bottom: 2px solid #f5f6fa;
}

.fadeIn {
  opacity: 1;
  animation: fade 0.25s forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
</style>
