<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<template>
  <button class="mobileButton pl-4" @click="$emit('click')">
    <BaseIcon v-if="open" name="times" />
    <BaseIcon v-else name="bars" />
  </button>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.mobileButton {
  font-size: 1.8rem;
  background-color: transparent;
  border: 0;
  border-left: 1px solid $gray-400;
  outline: 0;
}
</style>
