<script>
import Big from 'big.js';

import NumberHelpers from '@shared/utilities/number_helpers';

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    decimals: {
      type: Number,
      default: 2,
    },
    // There are a couple places where values are stored as cents (e.g. 1000 for $10.00). In those cases, use true
    fromCents: {
      type: Boolean,
      default: false,
    },
    // Indicates if the dollar amount should be formated with + or -, otherwise parenthesis are used for negatives
    signed: {
      type: Boolean,
      default: false,
    },
    // If specified and the value is 0, this will be displayed instead (common values are "$0" or "-")
    zero: {
      type: String,
      default: null,
    },
  },
  computed: {
    formattedValue() {
      const value = this.fromCents ? Number(Big(this.value).div(100)) : this.value;

      const result = this.signed
        ? NumberHelpers.formatSignedDollars(value, this.decimals)
        : NumberHelpers.formatDollars(value, this.decimals);

      // Check for zero after rounding, since a value of 0.004 will be rounded to 0.00 and should be treated as zero
      if (this.zero || this.zero === '') {
        // Check to see if the result is zero by removing all non-numeric characters (don't remove "." or "," either)
        // then check if the entire value is 0 followed by zero or many 0 decimals (use "." or "," as the decimal
        // separator to handle EU formatting)
        const isZero = result.replace(/[^0-9.,]/g, '').match(/^0((\.|,)0+)?$/);

        if (isZero) return this.zero;
      }

      return result;
    },
  },
};
</script>

<template>
  <span>{{ formattedValue }}</span>
</template>
