import type { RegionResponse } from '@console/services/api.models';

import { defineStore } from 'pinia';

import awsService from '@aws/services/awsService';

export const useRegionStore = defineStore('awsRegions', {
  state: () => ({
    all: [] as Array<RegionResponse>,
    displayNames: new Map<string, string>(),
  }),
  getters: {
    displayName() {
      return (systemName: string) => this.displayNames.get(systemName) ?? systemName;
    },
    mapSystemNames() {
      return (systemNames: Array<string>): RegionResponse[] =>
        systemNames.map<RegionResponse>(name => ({
          system_name: name,
          display_name: this.displayName(name),
        }));
    },
  },
  actions: {
    async load() {
      if (this.all.length) return;
      this.all = await awsService.getRegions();
      this.displayNames = new Map(this.all.map(region => [region.system_name, region.display_name]));
    },
  },
});
