<script>
import { mapActions } from 'vuex';

import Layout from '@console/Layout.vue';

export default {
  components: {
    Layout,
  },
  props: {
    customerId: {
      type: String,
      required: true,
    },
    awsOrganizationId: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    try {
      await this.switchCompany(this.customerId);
      await this.switchOrganization(this.awsOrganizationId);
    } catch (e) {
      await this.resetCustomer();
      await this.resetAwsOrganization();
      await this.initializeCustomer();
    }
    const url = this.$route.query.url || '/';
    await this.$router.replace(url);
  },
  methods: {
    ...mapActions('aws', {
      resetAwsOrganization: 'resetAwsOrganization',
      switchOrganization: 'switchOrganization',
    }),
    ...mapActions('customer', ['initializeCustomer', 'switchCompany', 'resetCustomer']),
  },
};
</script>

<template>
  <Layout no-nav>
    <template #default>
      <span class="d-none"></span>
    </template>
  </Layout>
</template>
