<script>
export default {
  props: {
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<template>
  <div class="loader" :class="{ spinForever: !error }">
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100">
      <g fill="none" fill-rule="evenodd">
        <circle cx="50" cy="50" r="50" />
        <g fill="#5C54FF" fill-rule="nonzero">
          <path
            d="M50 56.678A6.674 6.674 0 0 1 43.338 50 6.661 6.661 0 0 1 50 43.338c3.68 0 6.678 2.981 6.678 6.662A6.688 6.688 0 0 1 50 56.678M50 30c-11.039 0-20 8.961-20 20 0 11.055 8.961 20 20 20a19.988 19.988 0 0 0 20-20c0-11.039-8.945-20-20-20M33.791 68.5A6.791 6.791 0 0 0 27 75.306c0 3.749 3.04 6.787 6.791 6.787a6.791 6.791 0 0 0 6.804-6.787 6.794 6.794 0 0 0-6.804-6.806"
          />
        </g>
      </g>
    </svg>
    <div v-if="error" class="errorIcon">
      <BaseIcon name="exclamation" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  transition-delay: 0.3s;
}

.spinForever {
  animation-name: spin;
  animation-duration: 1200ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.errorIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-bottom: -20px;
  margin-left: -40px;
  font-size: normal;
  font-size: 1rem;
  color: $white;
  background-color: map-get($theme-colors, 'danger');
  border-radius: 50%;
}
</style>
