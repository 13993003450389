<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    classes() {
      return this.user.user_status === 'Active' ? 'badge-success' : 'badge-warning';
    },
    text() {
      return this.user.user_status === 'PendingEmailValidation' ? 'Pending Email Validation' : this.user.user_status;
    },
  },
};
</script>

<template>
  <div class="badge badge-pill p-2 pr-3 pl-3" :class="classes">
    {{ text }}
  </div>
</template>
