<script>
import _ from 'lodash';

const pageSize = 6;

export default {
  props: {
    selected: {
      type: Object,
      required: true,
    },
    timeframes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      current: null,
      startIndex: 0,
    };
  },
  computed: {
    currentPage() {
      return _.slice(this.timeframes, this.startIndex, this.startIndex + pageSize);
    },
    canNextPage() {
      return this.startIndex + pageSize < this.timeframes.length;
    },
    canPrevPage() {
      return this.startIndex - pageSize >= 0;
    },
    showPagination() {
      return this.timeframes.length > pageSize;
    },
    spacers() {
      return this.showPagination ? _.range(0, this.currentPage.length - pageSize) : [];
    },
  },
  mounted() {
    this.setStartIndex();
    this.$root.$on('bv::dropdown::hide', () => {
      this.setStartIndex();
    });
  },
  methods: {
    setStartIndex() {
      const index = _.findIndex(this.timeframes, { key: this.selected.key });
      this.current = this.timeframes[index];
      this.startIndex = Math.floor(index / pageSize) * pageSize;
    },
    change(newKey) {
      if (newKey !== this.current.key) {
        const found = _.find(this.timeframes, { key: newKey });
        this.$emit('change', found);
      }
    },
    prevPage() {
      this.startIndex = this.startIndex - pageSize;
    },
    nextPage() {
      this.startIndex = this.startIndex + pageSize;
    },
  },
};
</script>

<template>
  <b-dropdown
    variant="primary"
    menu-class="rounded-0 mt-1 timeframeMenu"
    toggle-class="btn-no-focus-box-shadow rounded-sm"
    right
    no-caret
    :offset="10"
  >
    <template #button-content>
      <div v-if="current" class="d-flex align-items-center justify-content-center">
        <div>
          {{ current.friendly_name }}
        </div>
        <div class="ml-2">
          <BaseIcon name="angle-down" />
        </div>
      </div>
    </template>
    <b-dropdown-item v-for="timeframe in currentPage" :key="timeframe.key" @click.prevent="change(timeframe.key)">
      <div class="d-flex align-items-center justify-content-end">
        <div>
          <div :class="{ timeframeActive: timeframe.key === selected.key }">
            {{ timeframe.friendly_name }}
          </div>
        </div>
      </div>
    </b-dropdown-item>
    <b-dropdown-text v-for="spacer in spacers" :key="'spacer-' + spacer"> </b-dropdown-text>
    <b-dropdown-form v-if="showPagination" class="border-top paginator mt-3 pt-2">
      <div class="d-flex align-items-center justify-content-between">
        <div class="timeframePaginator">
          <b-button
            v-show="canNextPage"
            variant="transparent"
            class="p-0 btn-no-focus-box-shadow timeframePaginatorButton"
            @click.prevent="nextPage"
          >
            <small class="text-muted"><BaseIcon name="arrow-left" /></small>
          </b-button>
        </div>
        <div class="timeframePaginator">
          <b-button
            v-show="canPrevPage"
            variant="transparent"
            class="p-0 btn-no-focus-box-shadow timeframePaginatorButton"
            @click.prevent="prevPage"
          >
            <small class="text-muted"><BaseIcon name="arrow-right" /></small>
          </b-button>
        </div>
      </div>
    </b-dropdown-form>
  </b-dropdown>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.timeframePaginator {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 25px;
}

.timeframePaginatorButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: $gray-600;
  background-color: $gray-300;
  border-radius: 50%;
}

.timeframeActive {
  border-bottom: 3px solid map-get($theme-colors, 'primary');
}
</style>
