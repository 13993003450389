<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';

import { PusherSetup } from '@console/mixins/pusher_setup';

import AwsAccountFilterList from '@aws/components/accounts/AwsAccountFilterList.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';

export default {
  components: {
    AwsAccountFilterList,
    BoxMessage,
  },
  mixins: [PusherSetup],
  computed: {
    ...mapGetters('customer', ['isSubscribed', 'isReseller']),
    ...mapGetters('aws', [
      'selectedOrganizationManagementAccount',
      'selectedOrganizationChildAccounts',
      'selectedOrganizationIsCurDataSource',
    ]),
    ...mapState('aws', ['awsAccounts']),
    showActiveManagementMessage() {
      return this.isSubscribed;
    },
    awsAccounts() {
      const linked = this.selectedOrganizationChildAccounts;
      const skip = a => _.includes(['Ignored', 'ResellerEndCustomer', 'NoAccessRequired'], a.status);
      return _.reject(linked, skip);
    },
  },
  methods: {
    buildIndex(awsAccount) {
      const parts = [awsAccount.friendly_name, awsAccount.aws_account_number, awsAccount.id, awsAccount.status];
      return _.join(parts, '|').toLowerCase();
    },
  },
};
</script>

<template>
  <div>
    <div class="row pt-4">
      <div class="col">
        <h2>Configure Additional AWS Account Access</h2>
      </div>
    </div>
    <BoxMessage type="info" class="pt-2 pb-2">
      <strong>Almost there!</strong>
      <p v-if="isReseller" class="mb-0">
        The last step is to configure access on arbitrage accounts in the Organization. For each account listed, click
        <strong>Setup</strong>
        and follow the steps to configure our IAM role. Once complete, click
        <strong>Validate Access</strong>
        to confirm.
      </p>
      <p v-else-if="selectedOrganizationIsCurDataSource" class="mb-0">
        The last step is to configure access on member accounts containing commitments in the Organization. For each
        account listed, click
        <strong>Setup</strong>
        and follow the steps to configure our IAM role. Once complete, click
        <strong>Validate Access</strong>
        to confirm.
      </p>
      <p v-else class="mb-0">
        The last step is to configure access on all member accounts in the Organization. For each account listed, click
        <strong>Setup</strong>
        and follow the steps to configure our IAM role. Once complete, click
        <strong>Validate Access</strong>
        to confirm. To automate creation of the ProsperOps role across all AWS accounts in your Organization, please see
        <a href="https://help.prosperops.com/automating-rollout-of-the-prosperops-iam-role" target="_blank"
          >this help article</a
        >.
      </p>
    </BoxMessage>
    <BoxMessage v-if="showActiveManagementMessage" type="success" class="pt-2 pb-2">
      <p class="mb-0">
        Once ProsperOps access to the accounts below has been confirmed, we will begin to actively manage your savings.
      </p>
    </BoxMessage>
    <div class="pt-4">
      <AwsAccountFilterList :aws-accounts="awsAccounts" />
    </div>
  </div>
</template>

<style scoped>
.emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  margin-top: 8px;
  font-size: 1.2rem;
  background-color: #fff;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}
</style>
