<script>
import { defineComponent } from 'vue';

import AccordionListItem from '@shared/design/AccordionListItem.vue';
import FormSubmitButton from '@shared/design/FormSubmitButton.vue';
import BaseIcon from '@shared/design/icons/BaseIcon.vue';
import Tooltip from '@shared/design/Tooltip.vue';

export default defineComponent({
  name: 'ValidationStepperItem',
  components: {
    AccordionListItem,
    BaseIcon,
    FormSubmitButton,
    Tooltip,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    validate: {
      type: Function,
      required: true,
    },
    buttonLabel: {
      type: String,
      default: 'Validate',
    },
    buttonLabelLoading: {
      type: String,
      default: 'Validating...',
    },
  },
  data() {
    return {
      isComplete: false,
      isError: false,
      isLoading: false,
      error: null, // Axios error object
    };
  },
  computed: {
    errorMessage() {
      // Show reponse body when 400
      if (this.error?.code === 'ERR_BAD_REQUEST') {
        return this.error.response.data;
      }

      // Default: show ApiError message
      return this.error?.message;
    },
  },
  methods: {
    async onClick() {
      this.reset();
      this.isLoading = true;

      try {
        await this.validate();
        this.isComplete = true;
        this.isError = false;
      } catch (err) {
        this.error = err;
        this.isComplete = false;
        this.isError = true;
      }

      this.isLoading = false;
    },
    reset() {
      this.isComplete = false;
      this.isError = false;
      this.isLoading = false;
      this.error = null;
    },
  },
});
</script>

<template>
  <AccordionListItem :active="open">
    <template #default>
      <div class="flex-grow-1">{{ title }}</div>
      <div>
        <FormSubmitButton v-show="open" type="button" variant="primary" :loading="isLoading" @click.prevent="onClick">
          {{ buttonLabel }}
          <template #loading>{{ buttonLabelLoading }}</template>
        </FormSubmitButton>
        <BaseIcon v-show="isComplete && !open" class="text-success validated" name="check" />
        <div v-if="isError" class="ml-3 d-inline-block">
          <BaseIcon id="validation_stepper_item_error" name="exclamation-triangle" class="text-danger" />
          <Tooltip target="validation_stepper_item_error">
            <slot name="error">
              {{ errorMessage }}
            </slot>
          </Tooltip>
        </div>
      </div>
    </template>
    <template v-if="open" #expanded>
      <slot />
    </template>
  </AccordionListItem>
</template>
