<script setup>
import { computed, onBeforeMount, ref } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';
import {
  PermissionSet,
  validateBillingAccountAccess,
  validateOrganizationAccess,
} from '@gcp/services/accessValidation';

import CreateIamRolePanel from '@gcp/components/onboarding/CreateIamRolePanel.vue';
import ValidateBillingAccountAccess from '@gcp/components/onboarding/ValidateBillingAccountAccess.vue';
import ValidateOrganizationAccess from '@gcp/components/onboarding/ValidateOrganizationAccess.vue';
import ValidationStepperItem from '@gcp/components/onboarding/ValidationStepperItem.vue';
import AccordionList from '@shared/design/AccordionList.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import CopyButton from '@shared/design/CopyButtonV2.vue';

const store = useVuexStore();
const context = computed(() => store.getters['nav/context']);
const billingAccount = computed(() => store.getters['gcp/getBillingAccountById'](context.value.id));
const serviceAccount = computed(() => store.state.gcp.serviceAccount);
const savingsAnalysisRole = computed(() => store.state.gcp.savingsAnalysisRole);
const managementRole = computed(() => store.state.gcp.managementRole);
const allPermissions = computed(() => [...savingsAnalysisRole.value.permissions, ...managementRole.value.permissions]);
const isSubscribed = computed(() => store.getters['customer/isSubscribed']);

const needsBillingAccountPermissionReconfiguration = ref();
const needsOrganizationPermissionReconfiguration = ref();
const showCreateRole = computed(
  () => needsBillingAccountPermissionReconfiguration.value || needsOrganizationPermissionReconfiguration.value
);
const isLoaded = computed(() => {
  return (
    needsBillingAccountPermissionReconfiguration.value !== undefined &&
    needsOrganizationPermissionReconfiguration.value !== undefined &&
    savingsAnalysisRole.value &&
    managementRole.value &&
    serviceAccount.value
  );
});

const step = ref();

const onRoleCreated = () => {
  step.value = 'validate-billing-account-access';
};

const onBillingAccountAccessValidated = () => {
  step.value = showCreateRole.value ? 'validate-organization-access' : 'validate-additional-role-permissions';
};

const onOrganizationAccessValidated = async () => {
  await store.dispatch('gcp/activateBillingAccount', { billingAccountId: billingAccount.value.id });
};

const validateManagementPermissions = async () => {
  await validateBillingAccountAccess(billingAccount.value.billing_account_id, PermissionSet.Management);
  await validateOrganizationAccess(billingAccount.value.organization_id, PermissionSet.Management);
  await store.dispatch('gcp/activateBillingAccount', { billingAccountId: billingAccount.value.id });
};

const testPermissions = async inner => {
  try {
    await inner();
    return true;
  } catch (e) {
    return false;
  }
};

onBeforeMount(async () => {
  const [hasBillingAccountAccess, hasOrganizationAccess] = await Promise.all([
    testPermissions(() =>
      validateBillingAccountAccess(billingAccount.value.billing_account_id, PermissionSet.SavingsAnalysis)
    ),
    testPermissions(() =>
      validateOrganizationAccess(billingAccount.value.organization_id, PermissionSet.SavingsAnalysis)
    ),
  ]);
  needsBillingAccountPermissionReconfiguration.value = !hasBillingAccountAccess;
  needsOrganizationPermissionReconfiguration.value = !hasOrganizationAccess;
  if (hasBillingAccountAccess && hasOrganizationAccess) {
    step.value = 'validate-billing-account-access';
  }
});

store.dispatch('gcp/loadCustomerServiceAccount');
store.dispatch('gcp/loadSavingsAnalysisRole');
store.dispatch('gcp/loadManagementRole');
</script>

<template>
  <div v-if="isLoaded" class="managementPermissions">
    <div class="row sectional">
      <div class="col">
        <h2>Additional Google Cloud Access</h2>
        <div class="pt-2">
          <BoxMessage type="info"
            >ProsperOps needs additional
            <a href="https://help.prosperops.com/limited-iam-permissions-for-discount-management">IAM permissions</a> to
            actively manage your commitment discounts. Configure the custom ProsperOps role access using the Google
            Cloud Console, confirming after adding all of the required permissions. If you need additional help, see
            <a href="https://help.prosperops.com/gcp-onboarding-guide-additional-gcp-access" target="_blank"
              >this article</a
            >
            for detailed step-by-step instructions.</BoxMessage
          >
        </div>
        <div class="pt-2">
          <BoxMessage v-if="isSubscribed" type="success" class="pt-2 pb-2" data-test-id="subscribed-box-message">
            Once additional Google Cloud access has been confirmed, we will begin to actively manage your savings.
          </BoxMessage>
        </div>
      </div>
    </div>
    <div class="row sectional">
      <div class="col">
        <div v-if="showCreateRole">
          <CreateIamRolePanel
            :iam-role="savingsAnalysisRole"
            :organization-id="organizationId"
            :permissions="allPermissions"
            :on-created="onRoleCreated"
          />
        </div>
        <AccordionList class="bg-white">
          <ValidateBillingAccountAccess
            :billing-account-id="billingAccount.billing_account_id"
            :role="managementRole"
            :service-account="serviceAccount"
            :open="step === 'validate-billing-account-access'"
            :on-validated="onBillingAccountAccessValidated"
            :permissions="PermissionSet.Management"
            :include-analysis-permissions="needsBillingAccountPermissionReconfiguration"
          />
          <template v-if="showCreateRole">
            <!-- Show Org validation whenever CreateIamRolePanel is used as CreateIamRolePanel doesn't have validation -->
            <ValidateOrganizationAccess
              :organization-id="billingAccount.organization_id"
              :role="managementRole"
              :service-account="serviceAccount"
              :open="step === 'validate-organization-access'"
              :on-validated="onOrganizationAccessValidated"
              :permissions="PermissionSet.Management"
            />
          </template>
          <ValidationStepperItem
            v-else
            title="Additional Role Permissions"
            :open="step === 'validate-additional-role-permissions'"
            :validate="validateManagementPermissions"
          >
            <template #default>
              <ul class="pl-3">
                <li>
                  Navigate to the
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    :href="`https://console.cloud.google.com/iam-admin/roles/details/organizations<${billingAccount.organization_id}<roles<prosperops?organizationId=${billingAccount.organization_id}`"
                    >ProsperOps IAM role</a
                  >
                </li>
                <li>Update the role configuration to include the following permissions:</li>
              </ul>
              <div class="mt-2">
                Permissions:<br />
                <div v-for="permission in managementRole.permissions.toSorted()" :key="permission" class="mt-1">
                  <span class="text-monospace bg-light p-1">{{ permission }}</span>
                  <CopyButton :key="permission" class="ml-2" :text="permission" /><br />
                </div>
              </div>
            </template>
            <template #error>
              Hmm, we weren't able to validate access. Please ensure the principal name is correct and the specified
              roles are assigned. Also confirm that the <b>{{ managementRole.title }}</b> role has the permissions shown
              above. If the issue persists, please chat with us for help.
            </template>
          </ValidationStepperItem>
        </AccordionList>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.panel {
  height: auto;
}

.managementPermissions li + li {
  margin-top: 0.25rem;
}
</style>
