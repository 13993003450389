<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    shown() {
      this.$emit('shown');
    },
  },
};
</script>

<template>
  <b-modal
    :id="id"
    :size="size"
    centered
    content-class="border-0 no-focus-modal-close-button"
    header-class="border-0 modalHeader flex-grow-1"
    hide-footer
    @shown="shown"
  >
    <slot />
    <template v-slot:modal-title>
      {{ title }}
    </template>
    <template #modal-header-close>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" height="24" width="24">
        <path
          d="m253.897 826.205-24.102-24.102L455.897 576 229.795 349.897l24.102-24.102L480 551.897l226.103-226.102 24.102 24.102L504.103 576l226.102 226.103-24.102 24.102L480 600.103 253.897 826.205Z"
        />
      </svg>
    </template>
  </b-modal>
</template>

<style lang="scss">
@import '@shared/scss/colors.scss';

.modalHeader {
  line-height: 1.2;
}

.modalHeader::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 5px;
  content: ' ';
  background-image: linear-gradient(to right, map-get($theme-colors, 'primary'), map-get($theme-colors, 'success'));
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
</style>
