<script>
export default {};
</script>

<template>
  <div class="panel rounded-sm">
    <slot />
  </div>
</template>

<style scoped>
.panel {
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}
</style>
