<script setup lang="ts">
import type { ComputeCommitmentsDashboardResponse } from '@console/services/gcp/api.models';

import { useHead } from '@unhead/vue';
import { isAxiosError } from 'axios';
import { computed, onMounted, ref } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';
import * as billingAccountService from '@gcp/services/billingAccountService';

import BuildingState from '@console/components/BuildingState.vue';
import Layout from '@console/Layout.vue';
import FeatureFlagSwitch from '@shared/components/FeatureFlagSwitch.vue';
import PageHeader from '@shared/design/PageHeader.vue';

useHead({
  title: 'Compute Commitments',
});

const commitmentsDashboardResponse = ref<ComputeCommitmentsDashboardResponse>();
const commitmentsNotFound = ref(false);

const store = useVuexStore();

const isLoading = computed(() => !commitmentsDashboardResponse.value && !commitmentsNotFound.value);

const dashboard = computed(() => commitmentsDashboardResponse.value?.dashboard);

onMounted(async () => {
  try {
    const gcpBillingAccountId = store.getters['gcp/selectedBillingAccountId'];
    const response = await billingAccountService.getCommitmentsDashboard(gcpBillingAccountId);
    commitmentsDashboardResponse.value = response as ComputeCommitmentsDashboardResponse;
  } catch (e) {
    handleError(e);
  }
});

function handleError(e: unknown) {
  if (isAxiosError(e) && e.status === 404) {
    commitmentsNotFound.value = true;
  } else {
    throw e;
  }
}
</script>

<template>
  <FeatureFlagSwitch feature-flag="gcpCommitments">
    <Layout :loading="isLoading">
      <template #default>
        <BuildingState v-if="false" />
        <div v-else>
          <PageHeader wrap-utility>
            <h1>Compute Commitments</h1>
          </PageHeader>
        </div>
        <div>
          Version: {{ dashboard?.version }}<br />
          Data Through: {{ dashboard?.data_through_date }}<br />
        </div>
      </template>
    </Layout>
  </FeatureFlagSwitch>
</template>
