<script setup lang="ts">
import type { CustomerDataExportIamValidationResponse } from '@console/services/api.models';

import { computed, ref } from 'vue';

import awsService from '@aws/services/awsService';
import { useAwsStore } from '@aws/stores/aws.store';
import { CustomerDataExportValidationStatus } from '@console/services/api.models';
import { useVuexStore } from '@console/state/vuex/store';

import SettingRow from '@console/components/settings/SettingRow.vue';
import Tooltip from '@shared/design/Tooltip.vue';

const vuexStore = useVuexStore();
const awsStore = useAwsStore();

interface Props {
  isLoading: boolean;
}
withDefaults(defineProps<Props>(), {
  isLoading: false,
});

const emits = defineEmits<{ (e: 'onToggleLoading', isLoading: boolean): void }>();

const isRunningValidation = ref(false);

const isCustomerDataExportEnabled = computed<boolean>(() => {
  const isEnabled = vuexStore.getters['aws/selectedOrganizationSettings']?.is_customer_data_export_enabled ?? false;
  const hasS3BucketNameForExport = !!vuexStore.getters['aws/selectedOrganizationSettings']
    ?.customer_data_export_s3_bucket_name;
  return isEnabled && hasS3BucketNameForExport;
});

const selectedOrganizationId = computed<string>(() => vuexStore.getters['aws/selectedOrganizationId']);
const selectedOrganizationAccountId = computed<string>(
  () => vuexStore.getters['aws/selectedOrganizationManagementAccount']?.id
);
const validationStatus = computed<CustomerDataExportIamValidationResponse>(() => {
  return awsStore.customerDataExportValidations[selectedOrganizationId.value];
});
const friendlyValidationStatusText = computed<string>(() => {
  switch (validationStatus.value?.status_code) {
    case CustomerDataExportValidationStatus.Enabled:
      return 'Enabled';
    case CustomerDataExportValidationStatus.Error:
      return 'Failed to validate IAM permissions for S3 bucket.';
    case CustomerDataExportValidationStatus.PendingIamValidation:
      return 'Pending IAM validation';
    default:
      return '';
  }
});

async function validate() {
  emits('onToggleLoading', true);
  isRunningValidation.value = true;

  const validation = await awsService.validateCustomerDataExportIamPermissions(
    vuexStore.getters['aws/selectedOrganizationId']
  );
  awsStore.setCustomerDataExportValidation(vuexStore.getters['aws/selectedOrganizationId'], validation);

  emits('onToggleLoading', false);
  isRunningValidation.value = false;
}
</script>

<template>
  <SettingRow>
    <template #label> Status </template>
    <template #description>
      <div>
        <span class="mr-2">
          {{ isRunningValidation ? 'Validating...' : friendlyValidationStatusText }}
        </span>
        <span v-if="validationStatus?.status_code === CustomerDataExportValidationStatus.Enabled">
          <BaseIcon name="check-circle" class="text-success" />
        </span>
        <span
          v-if="validationStatus?.status_code === CustomerDataExportValidationStatus.Error"
          id="customerDataExportStatus"
        >
          <BaseIcon name="exclamation-triangle" class="text-danger" />
        </span>
        <Tooltip v-if="validationStatus?.error_message" target="customerDataExportStatus" placement="bottom">
          {{ validationStatus.error_message }}
        </Tooltip>
      </div>
    </template>
    <template #value>
      <router-link
        :is="isLoading || !isCustomerDataExportEnabled ? 'span' : 'router-link'"
        type="button"
        class="btn btn-dark btn-sm mr-3 rounded-sm"
        :class="{ disabled: isLoading || !isCustomerDataExportEnabled }"
        :to="{
          name: 'aws_configure_account',
          params: { awsAccountId: selectedOrganizationAccountId },
        }"
      >
        Setup
      </router-link>
      <button
        type="button"
        class="btn btn-primary btn-sm rounded-sm validate"
        :disabled="isLoading || !isCustomerDataExportEnabled"
        @click="validate"
      >
        Validate Access
      </button>
    </template>
  </SettingRow>
</template>
