<script setup lang="ts">
import type { OptionsArray } from '@console/components/filters/types';
import type { RegionResponse } from '@console/services/api.models';

import { computed } from 'vue';

import RegionName from '@aws/components/RegionName.vue';
import Container from '@console/components/filters/FilterContainer.vue';

const props = defineProps<{
  modelValue?: RegionResponse | null;
  regions: OptionsArray<RegionResponse>;
}>();

const emit = defineEmits(['update:modelValue']);

const selectedRegion = computed(() => {
  return props.modelValue;
});

const onClick = (region?: RegionResponse) => {
  emit('update:modelValue', region);
};
</script>

<template>
  <Container label="Region" type="region">
    <template #selected-value>
      <span v-if="!selectedRegion">All Regions</span>
      <RegionName v-else :region="selectedRegion" />
    </template>

    <b-dropdown-item @click="onClick()">All Regions</b-dropdown-item>
    <b-dropdown-divider />
    <template v-for="region in props.regions" :key="region">
      <!-- if it's a RegionResponse object -->
      <b-dropdown-item v-if="'system_name' in region" :key="region" @click="onClick(region)">
        <RegionName :region="region" />
      </b-dropdown-item>
      <!-- if it's a group -->
      <template v-else>
        <b-dropdown-group :id="region.label" :header="region.label">
          <b-dropdown-item
            v-for="regionValue in region.values"
            :key="regionValue"
            class="grouped"
            @click="onClick(regionValue)"
          >
            <RegionName :region="regionValue" />
          </b-dropdown-item>
        </b-dropdown-group>
      </template>
    </template>
  </Container>
</template>
