<script>
export default {
  props: {
    initialAccess: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<template>
  <div class="d-flex justify-content-center align-items-center">
    <div class="securityBlurb">
      <BaseIcon name="lock" class="mr-1 text-primary" />
      <strong class="mr-1">Security is always our #1 priority.</strong>
      <span v-if="initialAccess">
        Permissions being granted are least privilege and only allow us to view cost and usage information, understand
        your AWS account structure, and validate our access.
      </span>
      <span v-else>
        Our permissions are least privilege and only grant the minimum set of actions required to monitor usage and
        actively manage your commitment portfolio.
      </span>
      We follow
      <a
        href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_create_for-user_externalid.html"
        target="_blank"
      >
        AWS best practices
      </a>
      for our IAM role configuration.
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.securityBlurb {
  width: 90%;
  margin-top: 1rem;
  font-size: 0.75rem;
  font-weight: 400;
  text-align: center;

  @include media-breakpoint-up(md) {
    width: 75%;
  }

  @include media-breakpoint-up(lg) {
    width: 60%;
  }
}
</style>
