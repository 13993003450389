<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: 'shared',
    },
  },
  data() {
    return {
      selected: this.value,
      labels: {
        shared: 'Shared Benefit',
        earned: 'Earned Benefit',
      },
    };
  },
  methods: {
    change(newSelected) {
      this.selected = newSelected;
      const event = { name: this.name, selected: newSelected };
      this.$emit('change', event);
    },
  },
};
</script>

<template>
  <b-dropdown
    variant="transparent"
    menu-class="rounded-0"
    toggle-class="btn-no-focus-box-shadow rounded-0"
    right
    no-caret
  >
    <template #button-content>
      <div v-if="selected" class="d-flex align-items-center justify-content-center">
        <div class="text-capitalize">
          {{ labels[selected] }}
        </div>
        <div class="ml-2">
          <BaseIcon name="angle-down" />
        </div>
      </div>
    </template>
    <b-dropdown-item @click.prevent="change('shared')">
      <div class="d-flex align-items-start justify-content-start">Shared Benefit</div>
    </b-dropdown-item>
    <b-dropdown-item disabled @click.prevent="change('earned')">
      <div class="d-flex align-items-start justify-content-start">
        Earned Benefit <span class="pl-1">(coming soon)</span>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>
