import {
  atLeastRole,
  billingAccountOnboarded,
  hasRoles,
  or,
  requires,
  selectedCompany,
  userIsEmployee,
} from '@console/router/requirements';

import ComputeCommitments from '@gcp/views/Commitments.vue';
import ComputeSavings from '@gcp/views/ComputeSavings.vue';
import ComputeSettings from '@gcp/views/ComputeSettings.vue';
import Onboarding from '@gcp/views/Onboarding.vue';
import Permissions from '@gcp/views/Permissions.vue';

export const routes = [
  {
    path: '/gcp/compute/savings/:timeframe?',
    name: 'gcp_compute_savings',
    component: ComputeSavings,
    beforeEnter: requires(
      hasRoles,
      selectedCompany,
      atLeastRole('Viewer'),
      or(userIsEmployee, billingAccountOnboarded)
    ),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/gcp/compute/commitments',
    name: 'gcp_compute_commitments',
    component: ComputeCommitments,
    props: true,
    beforeEnter: requires(hasRoles, selectedCompany, billingAccountOnboarded, atLeastRole('Viewer')),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/gcp/compute/settings',
    name: 'gcp_compute_settings',
    component: ComputeSettings,
    beforeEnter: requires(hasRoles, selectedCompany, billingAccountOnboarded, atLeastRole('Viewer')),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/gcp/permissions',
    name: 'gcp_permissions',
    component: Permissions,
    meta: {
      initModules: true,
    },
  },
  {
    path: '/gcp/onboarding',
    name: 'gcp_onboarding',
    component: Onboarding,
    meta: {
      initModules: true,
    },
  },
];
