<script setup lang="ts">
import type { CalendlyWidgetProps } from './CalendlyWidget.vue';

import { computed, ref } from 'vue';

import CalendlyWidget from './CalendlyWidget.vue';
import LoadingButton from '@shared/components/LoadingButton.vue';

type Props = CalendlyWidgetProps & {
  disabled?: boolean;
};

withDefaults(defineProps<Props>(), {
  disabled: false,
});

const emit = defineEmits<{
  (e: 'show'): void;
  (e: 'event-scheduled'): void;
}>();

const loading = ref(true);
const buttonClicked = ref(false);

const showCalendar = () => {
  buttonClicked.value = true;
  if (!loading.value) {
    emit('show');
  }
};

const calendarLoaded = () => {
  loading.value = false;
  if (buttonClicked.value) {
    emit('show');
  }
};

const calendarVisible = computed(() => !loading.value && buttonClicked.value);
const buttonVisible = computed(() => !calendarVisible.value);
</script>

<template>
  <div>
    <LoadingButton v-if="buttonVisible" :loading="buttonClicked" :disabled="disabled" @click="showCalendar">
      <slot />
    </LoadingButton>
    <CalendlyWidget
      :routing-form-id="routingFormId"
      load-silently
      :hide="!calendarVisible"
      @loaded="calendarLoaded"
      @event-scheduled="emit('event-scheduled')"
    />
  </div>
</template>
