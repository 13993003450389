<script>
import authService from '@console/services/authService';

export default {
  async created() {
    await authService.login();
  },
};
</script>

<template>
  <div style="display: none">
    <p>Loading...</p>
  </div>
</template>
