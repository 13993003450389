<script>
import { mapState } from 'pinia';
import { mapGetters as vuexMapGetters } from 'vuex';

import { useAuthStore } from '@shared/state/auth.store';

export default {
  props: {
    atleast: {
      type: String,
      required: true,
      validator(value) {
        return ['Viewer', 'Editor', 'Owner'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    ...mapState(useAuthStore, ['isAtLeastRole']),
    ...vuexMapGetters('customer', ['selectedCompany']),
    canSee() {
      return this.isAtLeastRole(this.selectedCompany.id, this.atleast);
    },
  },
};
</script>

<template>
  <div v-if="canSee">
    <slot />
  </div>
</template>
