<script>
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength, numeric } from '@vuelidate/validators';

import customerService from '@console/services/customerService';

export default {
  props: {
    iamRole: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonLocation: {
      type: String,
      default: 'bottom',
      validator(value) {
        return ['left', 'right'].indexOf(value) !== -1;
      },
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      awsAccountNumber: '',
      loading: false,
    };
  },
  validations: {
    awsAccountNumber: {
      required,
      minLength: minLength(12),
      maxLength: maxLength(12),
      numeric,
    },
  },
  computed: {
    canCancel() {
      return !!this.iamRole;
    },
    isButtonLocationLeft() {
      return this.buttonLocation === 'left';
    },
    isButtonLocationRight() {
      return this.buttonLocation === 'right';
    },
  },
  mounted() {
    if (this.iamRole && this.iamRole.aws_account_number) {
      this.awsAccountNumber = this.iamRole.aws_account_number;
    }
  },
  methods: {
    async addRole() {
      try {
        this.loading = true;
        const response = await customerService.addRole(this.awsAccountNumber);
        this.$emit('onSuccess', response.data);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        if (e.response.status === 400) {
          this.$emit('onError', 'Please check that the provided AWS Account Number is valid.');
        } else {
          throw e;
        }
      }
    },
    onInput() {
      this.awsAccountNumber = this.awsAccountNumber.replace(/-|[^0-9]/gi, '');
      if (this.awsAccountNumber.length === 0) {
        this.v$.awsAccountNumber.$reset();
      }
      if (this.awsAccountNumber.length >= 12) {
        this.v$.awsAccountNumber.$touch();
      }
    },
    cancel() {
      this.$emit('onCancel');
    },
  },
};
</script>

<template>
  <form @submit.prevent="addRole">
    <div class="row pt-4">
      <div class="col">
        <label for="awsAccountNumber" class="awsAccountNumberLabel"> AWS Management Account </label>
        <div :class="{ inputWrapper: true, inputError: v$.$errors.length }">
          <input
            id="awsAccountNumber"
            ref="input"
            v-model.trim="awsAccountNumber"
            type="text"
            class="input"
            placeholder="Your 12 Digit AWS Account Number"
            autocomplete="off"
            :disabled="disabled"
            @input="onInput"
          />
        </div>
        <div v-if="isButtonLocationRight" class="buttonWrapper">
          <form-submit-button
            type="submit"
            variant="primary"
            class="rounded-sm simpleButton"
            :loading="loading"
            :disabled="v$.$invalid"
          >
            Next
            <template #loading> Next... </template>
          </form-submit-button>
        </div>
        <div v-if="canCancel && isButtonLocationRight" class="cancelWrapper">
          <button class="btn btn-outline-info rounded-sm simpleButton" @click.prevent="cancel">Cancel</button>
        </div>
      </div>
    </div>
    <div class="row pt-4">
      <slot />
    </div>
    <div v-if="isButtonLocationLeft" class="row pt-4">
      <div class="col pt-4">
        <form-submit-button
          type="submit"
          variant="primary"
          class="rounded-sm simpleButton"
          :loading="loading"
          :disabled="v$.$invalid"
        >
          Next
          <template #loading> Next... </template>
        </form-submit-button>
      </div>
    </div>
    <div v-if="v$.awsAccountNumber.$errors.length" class="error">
      <div v-if="v$.awsAccountNumber.required.$invalid">AWS Account Number is required.</div>
      <div v-if="v$.awsAccountNumber.minLength.$invalid">AWS Account Number must be 12 digits.</div>
      <div v-if="v$.awsAccountNumber.maxLength.$invalid">AWS Account Number must be 12 digits.</div>
    </div>
  </form>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.inputWrapper {
  margin-bottom: 0.1rem;
}

.inputWrapper::after {
  display: block;
  height: 3px;
  content: '';
}

.inputWrapper:focus-within::after {
  margin-right: 1rem;
  background-color: map-get($theme-colors, 'success');
  transition: background-color 0.2s ease-in-out;
}

.inputWrapper.inputError:focus-within::after {
  margin-right: 1rem;
  background-color: map-get($theme-colors, 'danger');
  transition: background-color 0.2s ease-in-out;
}

.buttonWrapper,
.cancelWrapper,
.inputWrapper {
  display: inline-block;
}

.cancelWrapper {
  margin-left: 1rem;
}

.buttonWrapper,
.cancelWrapper {
  padding-bottom: 2px;
  vertical-align: middle;
}

.input {
  min-width: 380px;
  height: 40px;
  padding: 0;
  padding-left: 1rem;
  margin: 0;
  margin-right: 1rem;
  font-size: 1rem;
  background-color: #fff;
  border: 0;
  border-radius: 0;
}

.input:focus {
  outline: none;
}

.error {
  color: map-get($theme-colors, 'danger');
}

.awsAccountNumberLabel {
  display: block;
}

.simpleButton {
  height: 40px;
  margin-bottom: 2px;
}
</style>
