<script lang="ts">
import type { ComputeSpendCoverageTrend } from '@console/services/gcp/api.models';
import type Highcharts from 'highcharts';
import type { PropType } from 'vue';

import moment from 'moment';

import * as chartUtilities from '@console/components/charts/utility';
import NumberHelpers from '@shared/utilities/number_helpers';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const SERIES_NAME_BASE_TARGET = 'Base Target';

interface Series extends Highcharts.SeriesOptions {
  legendOrder: number;
  legendSelected: boolean;
}

export default {
  components: {
    ChartLegend,
    SeriesChart,
    PanelSection,
  },
  props: {
    spendCoverageTrend: {
      type: Array as PropType<ComputeSpendCoverageTrend[]>,
      required: true,
    },
  },
  data() {
    return {
      series: [] as Series[],
    };
  },
  computed: {
    xAxis() {
      return {
        categories: this.spendCoverageTrend.map(sc => moment.utc(sc.month_start).format('MMM YYYY')),
      };
    },
    yAxis() {
      return [
        {
          min: 0,
          title: {
            text: null,
          },
          labels: {
            format: chartUtilities.getCurrencyFormat(),
          },
        },
      ];
    },
    filteredSeries() {
      return this.series.filter(s => s.legendSelected);
    },
    tooltip() {
      return chartUtilities.tooltipWithTotal({
        totalLabel: 'Compute Usage',
        valueFormatter: v => NumberHelpers.formatDollars(v),
        seriesExcludedFromTotal: [SERIES_NAME_BASE_TARGET],
      });
    },
  },
  mounted() {
    const firstMonths = this.spendCoverageTrend.slice(0, -1);
    const lastMonth = this.spendCoverageTrend.slice(-1)[0] ?? {};
    // Don't include the last month if there aren't any fields other than month_start (i.e. no spend coverage data for the month)
    const includeLastMonth = Object.keys(lastMonth).some(key => key !== 'month_start');
    const months = includeLastMonth ? [...firstMonths, lastMonth] : firstMonths;

    interface SeriesDef {
      name: string;
      color: string;
      type: 'area' | 'line';
    }
    interface ComputeSeriesDef extends SeriesDef {
      field: keyof ComputeSpendCoverageTrend;
    }

    // This is the order the items will be stacked in the chart (the first will be on the top)
    const seriesDefs: ComputeSeriesDef[] = [
      { name: 'On-Demand', field: 'on_demand', color: '#fc5454', type: 'area' },
      { name: 'Other', field: 'other', color: '#feebbf', type: 'area' },
      { name: 'Unbilled CUDs', field: 'unbilled_cud', color: '#e6e6e6', type: 'area' },
      { name: 'Smart: Resource-based CUDs', field: 'smart_resource_cud', color: '#00c58c', type: 'area' },
      { name: 'Smart: Spend-based CUDs', field: 'smart_spend_cud', color: '#8fffdf', type: 'area' },
      { name: 'Base: Spend-based CUDs', field: 'base_spend_cud', color: '#5c54ff', type: 'area' },
      { name: 'Inherited: Resource-based CUDs', field: 'inherited_resource_cud', color: '#fcbe2c', type: 'area' },
      { name: 'Inherited: Spend-based CUDs', field: 'inherited_spend_cud', color: '#a7a3ff', type: 'area' },
      { name: SERIES_NAME_BASE_TARGET, field: 'base_spend_coverage_target', color: '#495057', type: 'line' },
    ];

    const areaOptions = {
      fillOpacity: '0.5',
      dashStyle: 'solid',
      stacking: 'normal',
    };
    const lineOptions = {
      dashStyle: 'dash',
    };

    this.series = seriesDefs
      .map((s, index) => ({
        label: s.name,
        // Use null as the default value to ensure that days without values are still shown on the x axis
        data: months.map(month => month[s.field] ?? null),
        // Reverse the order of the legend so that the last item (bottom) is the leftmost
        legendOrder: seriesDefs.length - index,
        legendSelected: true,
        tooltip: {
          valuePrefix: '$',
        },
        marker: {
          enabled: false,
        },
        // Apply the options specific to the series type
        ...(s.type === 'area' ? areaOptions : lineOptions),
        // Allow the series to override any of the above properties
        ...s,
      }))
      // Remove any series without any data points
      .filter(s => s.data.some(d => !!d));
  },
};
</script>

<template>
  <PanelSection header="Spend Coverage Trend">
    <div>
      <ChartLegend v-model="series" />
      <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" :tooltip="tooltip" />
      <div class="d-flex flex-row-reverse">
        <small>(normalized)</small>
      </div>
    </div>
  </PanelSection>
</template>
