<script>
export default {};
</script>

<template>
  <div class="recommendation">
    <div>
      <slot name="text" />
    </div>
    <div class="ml-3">
      <slot name="action" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.recommendation {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
  }
}

.recommendation > div:first-child {
  flex-grow: 1;
}
</style>
