<script>
export default {
  props: {
    message: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      currentMessage: null,
    };
  },
  watch: {
    message(newMessage) {
      this.show(newMessage);
    },
  },
  mounted() {
    this.show(this.message);
  },
  methods: {
    show(message) {
      if (message) {
        this.currentMessage = message;
        this.visible = true;
        setTimeout(() => this.fade(), 4000);
      }
    },
    fade() {
      this.visible = false;
    },
  },
};
</script>

<template>
  <transition name="fade">
    <div v-show="visible" class="flashContainer">
      <div class="flashSuccess bounce">
        <BaseIcon name="check-circle" class="mr-2" />
        <span>{{ currentMessage }}</span>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.flashContainer {
  position: absolute;
  top: 35px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1600px;
}

.flashSuccess {
  position: relative;
  z-index: 10;
  padding: 0.75rem;
  color: #fff;
  background-color: map-get($theme-colors, 'success');
  border-radius: 4px;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}

.flashSuccess > span {
  font-size: 0.9rem;
  font-weight: 500;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.bounce {
  animation-name: bounce;
  animation-duration: 0.4s;
  animation-play-state: running;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-direction: normal;
  animation-fill-mode: none;
}

@keyframes bounce {
  0% {
    top: -20px;
  }
  25% {
    top: -10px;
  }
  50% {
    top: -5px;
  }
  75% {
    top: -2px;
  }
  100% {
    top: 0;
  }
}
</style>
