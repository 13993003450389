<script setup lang="ts">
import { dateFormat, dateUtc } from '@shared/utilities/filters';

import StatusPill from '@shared/design/StatusPill.vue';

defineProps<{
  dataThroughDate: string;
  finalized: boolean;
}>();

const formatDate = (date: string, format = 'MMMM Do') => dateFormat(dateUtc(date), format);
</script>

<template>
  <StatusPill v-if="finalized">
    <BaseIcon name="calendar-check" class="mr-1" variant="far" />
    Finalized
  </StatusPill>
  <StatusPill v-else variant="warning">
    <BaseIcon name="calendar-alt" class="mr-1" variant="far" />
    As of {{ formatDate(dataThroughDate) }}
  </StatusPill>
</template>
