<script setup lang="ts">
import type { AmortizationChangeEvent } from './types';

import PanelDropdown from '@shared/design/panels/PanelDropdown.vue';

defineProps<{
  name: string;
}>();

const emit = defineEmits<{
  (event: 'change', payload: AmortizationChangeEvent): void;
}>();

const change = (event: AmortizationChangeEvent) => {
  emit('change', event);
};
</script>

<template>
  <PanelDropdown :name="name" :options="['amortized', 'actual']" @change="change" />
</template>
