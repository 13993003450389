import type { GranularRole } from './auth.store';
import type { GetUserResponse } from '@shared/services/customer.models';

import _ from 'lodash';
import { defineStore } from 'pinia';

import { useAuthStore } from './auth.store';
import {
  addGranularUser,
  addUser,
  deleteUser,
  getUser,
  getUsers,
  updateGranularRoles,
  updateUserRole,
} from '@shared/services/customer';

export type User = GetUserResponse & { searchIndex: string };

interface UserStore {
  users: User[];
}

export const useUsersStore = defineStore('user', {
  state: (): UserStore => ({
    users: [],
  }),
  getters: {
    getByEmail: state => (email: string) => {
      return state.users.find(u => u.email_address === email);
    },
  },
  actions: {
    // This function handles the logic of rebuilding the user list state
    // because it needs to be sorted and have a search index attribute for filtering
    async rebuildUserListState(usersToRebuild: GetUserResponse[]) {
      const authStore = useAuthStore();
      const buildSearchIndex = (user: GetUserResponse) => {
        const fullName = `${user.first_name} ${user.last_name}`;
        const parts = [fullName, user.user_status, user.email_address];
        return parts.join('|').toLowerCase();
      };
      const usersWithSearchIdx = usersToRebuild.map(user => {
        return { ...user, searchIndex: buildSearchIndex(user) };
      });

      const prioritizeCurrentUser = (u: User) => (u.email_address === authStore.email ? 1 : 2);
      const sortByLastName = (u: User) => u.last_name;
      const sortByCreatedAt = (u: User) => u.created_date;
      this.users = _.sortBy(usersWithSearchIdx, [prioritizeCurrentUser, sortByLastName, sortByCreatedAt]);
    },
    // A note when using refresh vs refreshByEmail
    // Because ultimately the data store for this is coming from Auth0
    // I have noticed it can be eventually consistent. Additionally when we query directly
    // for the email we don't seem to encounter this issue. So if you are updating data
    // for a record in a short timespan and expect it to be updated use refreshByEmail.
    async refresh() {
      const response = await getUsers();
      this.rebuildUserListState(response.data);
    },
    async refreshByEmail(email: string) {
      const user = await getUser(email);
      this.rebuildUserListState([...this.users.filter(u => u.email_address !== email), user.data]);
    },
    async delete(user: User) {
      await deleteUser(user.email_address);
      this.users = [...this.users.filter(u => u.email_address !== user.email_address)];
    },
    async addUser(firstName: string, lastName: string, emailAddress: string, userRole: string) {
      await addUser(firstName, lastName, emailAddress, userRole);
      await this.refreshByEmail(emailAddress);
    },
    async updateUserRole(emailAddress: string, userRole: string) {
      await updateUserRole(emailAddress, userRole);
      await this.refreshByEmail(emailAddress);
    },
    async addGranularUser(firstName: string, lastName: string, emailAddress: string, granularRoles: GranularRole[]) {
      await addGranularUser(firstName, lastName, emailAddress, granularRoles);
      await this.refreshByEmail(emailAddress);
    },
    async updateGranularRoles(emailAddress: string, granularRoles: GranularRole[]) {
      await updateGranularRoles(emailAddress, granularRoles);
      await this.refreshByEmail(emailAddress);
    },
    reset() {
      this.users = [];
    },
  },
});
