import type { PermissionLevel } from '@shared/state/auth.store';
import type { FeatureFlag } from '@shared/state/feature.store';
import type { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import _ from 'lodash';

import { store } from '@console/state/vuex/store';
import { useAuthStore } from '@shared/state/auth.store';
import { useFeatureStore } from '@shared/state/feature.store';
import env from '@shared/utilities/environment';

const skipRequirements = () => {
  const isLocal = env.get('VITE_ENV') === 'local';
  if (!isLocal) return false;

  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.has('skip_route_requirements');
};

// We have a special navigation guard function that can process multiple requirements
// together which doesn't always take a next parameter.
interface NavigationGuardRequirement {
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next?: NavigationGuardNext): ReturnType<NavigationGuard>;
}

const requires = (...requirements: NavigationGuardRequirement[]) => (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const results = _.map(requirements, requirement => requirement(to, from));
  const pass = _.every(results);
  if (skipRequirements()) {
    console.warn('Skipping requirements for local development. Result of requirements check:', pass);
    return next();
  }
  return pass ? next() : next({ name: 'root' });
};

const or = (...requirements: NavigationGuardRequirement[]) => (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  return requirements.some(req => req(to, from));
};

const isCustomerSettingEnabled = (setting: string) => () => store.getters['customer/isSettingEnabled'](setting);
const isFeatureEnabled = (flag: FeatureFlag) => () => {
  // https://pinia.vuejs.org/core-concepts/outside-component-usage.html
  // get store inside the function to ensure app.use(pinia) is called first
  const featureStore = useFeatureStore();
  return featureStore.featureFlags[flag];
};
const atLeastRole = (role: PermissionLevel) => () =>
  useAuthStore().isAtLeastRole(store.state.customer.selectedCompanyId, role);
const userIsEmployee = () => useAuthStore().isEmployee;
const selectedCompany = () => !!store.state.customer.selectedCompanyId;
const hasRoles = () => useAuthStore().hasRoles;
const notNewOrganization = () => !store.getters['aws/isNewOrganization'];
const activeSubscription = (to: RouteLocationNormalized) => {
  const isSubscribed = !!store.getters['customer/isSubscribed'];
  return userIsEmployee() ? isSubscribed || to.query.admin_override === 'true' : isSubscribed;
};

const notInDemo = () => !store.getters['customer/isDemo'];

const noActiveSubscriptionOrNotConfiguredOrg = () => {
  const noActiveSubscription = !store.getters['customer/isSubscribed'];
  const organizationNotConfigured = !store.getters['aws/selectedOrganizationIsConfigured'];

  return noActiveSubscription || organizationNotConfigured;
};

const billingAccountOnboarded = () => {
  const id = store.state.nav?.context?.id;
  if (!id) {
    return false;
  }
  return store.getters['gcp/isBillingAccountOnboarded'](id);
};

export {
  requires,
  notInDemo,
  isCustomerSettingEnabled,
  isFeatureEnabled,
  atLeastRole,
  selectedCompany,
  hasRoles,
  notNewOrganization,
  activeSubscription,
  noActiveSubscriptionOrNotConfiguredOrg,
  billingAccountOnboarded,
  userIsEmployee,
  or,
};
