const cache = {};

const getColor = variable => {
  if (!cache[variable]) {
    const style = window.getComputedStyle(document.body);
    const value = style.getPropertyValue(`--${variable}`);
    if (!value) {
      throw new Error(`Unable to find css value for ${variable}`);
    }
    cache[variable] = value.trim();
  }
  return cache[variable];
};

export default {
  getColor,
};
