import _ from 'lodash';
import NProgress from 'nprogress/nprogress';
import { createRouter, createWebHistory } from 'vue-router';

import { useAzureStore } from '@azure/state';
import errors from '@console/lib/errors';
import routes from '@console/router/routes';
import { store } from '@console/state/vuex/store';
import analytics from '@shared/lib/analytics';
import { useAuthStore } from '@shared/state/auth.store';
import env from '@shared/utilities/environment';

NProgress.configure({ showSpinner: false });

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

const showProgressBar = (to, from) => from.name && from.name !== to.name;
const isAuthenticated = to => _.get(to.matched[0], 'meta.authenticated', true);
const shouldInitModules = to => _.get(to.matched[0], 'meta.initModules', false);
const handleError = to => _.get(to.matched[0], 'meta.initHandleError', true);
const bootstrap = async to => {
  if (shouldInitModules(to)) {
    try {
      await store.dispatch('customer/initializeCustomer');
      await store.dispatch('aws/loadOrganizations');
      await store.dispatch('aws/loadAwsAccounts');
      await store.dispatch('gcp/loadAllBillingAccounts');
      await store.dispatch('nav/initialize');
      await store.dispatch('gcp/loadSelectedBillingAccount');

      const azureStore = useAzureStore();
      await azureStore.loadBillingScopes();
    } catch (e) {
      console.error(e);
    }
  }
};

const handleRefreshError = async (e, from, to, next) => {
  try {
    const redirect = await errors.handle(e, to.fullPath);
    if (redirect) {
      return next(redirect);
    }
  } catch (e) {
    return next({ name: 'error' });
  }
};

router.beforeEach(async (to, from, next) => {
  if (showProgressBar(to, from)) {
    NProgress.start();
  }

  if (!isAuthenticated(to)) {
    return next();
  }

  const authStore = useAuthStore();
  try {
    await authStore.refresh();
  } catch (e) {
    await handleRefreshError(e, from, to, next);
    return;
  }

  if (authStore.hasRoles) {
    try {
      // bootstrap the necessary modules required to render.
      // if the user has no roles, they can't make any api calls
      // so we skip bootstrapping modules.
      await bootstrap(to);
    } catch (e) {
      // if the route supports handling errors, send the user to the error page
      // otherwise, just continue by calling next().
      // this is useful in error pages - if an error occurs rendering the error
      // page, we continue routing despite the error to avoid an infinite loop.
      if (handleError(to)) {
        return next({ name: 'error' });
      }
    }
  }

  return next();
});

router.afterEach((to, from) => {
  const url = `${env.get('VITE_BASE_URL')}${to.path}`;
  analytics.page(to.name || 'console_unknown', {
    title: 'ProsperOps Console',
    path: to.path,
    url: url,
    referrer: from.path,
  });
  if (showProgressBar(to, from)) {
    NProgress.done();
  }
});

export default router;
