<script>
export default {
  async mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
    });
    window.dataLayer.push({
      event: 'gtm.js',
    });
    window.dataLayer.push({
      event: 'login',
    });
    try {
      await this.$loadScript('https://www.googletagmanager.com/gtm.js?id=GTM-NGB88HB');
    } catch (error) {
      // A failure to load the GTM script would throw an exception and route the user, in the iframe, to the '/error' page. This is undesirable since '/error' is an authenticated route and the user might already be authenticated. An example: a user running an AdBlocker (or the Brave browser) would cause this script to error which would cause the authenticated user to be redirected to the '/error' page
    }
  },
};
</script>

<template>
  <div></div>
</template>
