import type { OptionsArray } from './types';
import type { Ref, ComputedRef, MaybeRefOrGetter } from 'vue';

import { ref, computed, toValue } from 'vue';

type FilterFunction = (val: string) => string[];

type SearchResult<TValue> = {
  searchRef: Ref<HTMLElement | null>;
  searchText: Ref<string | null>;
  filteredOptions: ComputedRef<OptionsArray<TValue>>;
  reset: () => void;
};

export default function useSearch(
  options: MaybeRefOrGetter<OptionsArray<string>>,
  getSearchFields: FilterFunction = val => [val]
): SearchResult<string> {
  const searchRef = ref<HTMLElement | null>(null);
  const searchText = ref<string | null>(null);

  const filteredOptions = computed(() => {
    if (!searchText.value) {
      return toValue(options);
    }

    const searchTextLower = searchText.value.toLowerCase();
    return toValue(options).reduce((acc, value) => {
      // If it's a group, filter the groups values
      if (value != null && typeof value === 'object' && 'values' in value) {
        const rv = {
          ...value,
          values: value.values.filter(val => {
            const fieldsToSearch = getSearchFields(val);
            return fieldsToSearch.some(fieldValue =>
              fieldValue.toLowerCase().includes(searchText.value?.toLowerCase() ?? '')
            );
          }),
        };
        acc.push(rv);
      } else {
        // handle non group values
        const fieldsToSearch = getSearchFields(value);
        if (fieldsToSearch.some(fieldValue => fieldValue.toLowerCase().includes(searchTextLower))) {
          acc.push(value);
        }
      }

      return acc;
    }, [] as OptionsArray<string>);
  });

  const reset = () => {
    searchText.value = '';
    searchRef.value?.focus();
  };

  return {
    searchRef,
    searchText,
    filteredOptions,
    reset,
  };
}
