// Instances of Intl.NumberFormat are expensive to create, so cache them using the options as the key
const formatters: Record<string, Intl.NumberFormat> = {};

function getFormatter(options: Intl.NumberFormatOptions) {
  const language = navigator.language;
  const key = `${language}--${JSON.stringify(options)}`;

  const formatter = formatters[key];
  if (formatter) return formatter;

  return (formatters[key] = new Intl.NumberFormat(language, options));
}

function formatUnits(num: number, style?: 'currency' | 'percent') {
  if (style === 'percent') num *= 100;
  const lookup = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'K' },
    { value: 1, symbol: '' },
  ];

  const item = lookup.find(item => Math.abs(num) >= item.value);
  if (!item) {
    return style === 'currency' ? '$0' : style === 'percent' ? '0%' : '0';
  }

  const decimals = getDecimals();
  const untrimmed = (num / item.value).toFixed(decimals);

  // remove trailing zeros so 2.00K becomes 2K
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const trimmed = Number.parseFloat(untrimmed.replace(regexp, ''));
  let formatted: string;

  // We've adjusted where the decimal place is so we can't rely on the other
  // formatters to handle currency and percentage correctly. We'll add them manually here.
  if (style === 'currency') {
    if (num < 0) {
      formatted = `-$${trimmed * -1}${item.symbol}`;
    } else {
      formatted = `$${trimmed}${item.symbol}`;
    }
  } else if (style === 'percent') {
    formatted = trimmed + item.symbol + '%';
  } else {
    formatted = trimmed + item.symbol;
  }
  return formatted;

  function getDecimals() {
    const wholeDigits = wholeNumberDigits(num);
    const digitMod = wholeDigits % 3;
    return digitMod === 0 ? 0 : 3 - digitMod;
  }
}

const formatNumber = (value: number | undefined | null, decimals = 0, hideTrailingZeros = false) => {
  const formatter = getFormatter({
    minimumFractionDigits: hideTrailingZeros ? 0 : decimals,
    maximumFractionDigits: decimals,
  });

  return formatter.format(value ?? 0);
};

const formatPercent = (value: number | undefined | null, decimals = 0, hideTrailingZeros = false) => {
  const formatter = getFormatter({
    style: 'percent',
    minimumFractionDigits: hideTrailingZeros ? 0 : decimals,
    maximumFractionDigits: decimals,
  });

  return formatter.format(value ?? 0);
};

const formatDollarsWithOptionalDecimals = (
  value: number | undefined | null,
  minDecimals?: number,
  maxDecimals?: number
) => {
  if (maxDecimals != null && minDecimals != null && maxDecimals < minDecimals) maxDecimals = minDecimals;

  const formatter = getFormatter({
    style: 'currency',
    currency: 'USD',
    currencySign: 'accounting',
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  });

  return formatter.format(value ?? 0);
};

const formatDollars = (value: number | undefined | null, decimals = 2) => {
  return formatDollarsWithOptionalDecimals(value, decimals, decimals);
};

const formatSignedDollars = (amount: number | undefined | null, decimals = 2) => {
  const formatted = formatDollars(Math.abs(amount ?? 0), decimals);
  if (!amount) return formatted;

  return amount > 0 ? `+ ${formatted}` : `- ${formatted}`;
};

function wholeNumberDigits(num: number) {
  return (Math.log10((num ^ (num >> 31)) - (num >> 31)) | 0) + 1;
}

export default {
  formatNumber,
  formatPercent,
  formatUnits,

  formatDollars,
  formatDollarsWithOptionalDecimals,
  formatSignedDollars,
};
