<template>
  <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame" clip-path="url(#clip0_93_1849)">
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5005 39.0042C30.2703 39.0042 39.001 30.2741 39.001 19.5021C39.001 8.73005 30.2703 0 19.5005 0C8.73067 0 0 8.73005 0 19.5021C0 30.2741 8.73067 39.0042 19.5005 39.0042ZM19.5005 37.3084C29.3337 37.3084 37.3055 29.3376 37.3055 19.5042C37.3055 9.67074 29.3347 1.69991 19.5005 1.69991C9.66632 1.69991 1.69647 9.67074 1.69647 19.5042C1.69647 29.3376 9.66731 37.3043 19.5005 37.3043V37.3084ZM22.0114 23.6695L22.6461 10.5958L18.7724 23.099L19.6088 27.8296L22.0114 23.6695ZM7.63115 18.6573C7.63115 12.1017 12.945 6.78819 19.5005 6.78819C26.056 6.78819 31.3698 12.1069 31.3698 18.6573H33.0653C33.0653 11.1652 26.9916 5.09244 19.4995 5.09244C12.0074 5.09244 5.93467 11.1662 5.93467 18.6573H7.63115Z"
        fill="#5C54FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_93_1849">
        <rect width="39" height="39" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
