<script>
import { mapGetters } from 'vuex';

import ConsoleNavigation from '@console/components/navigation/ConsoleNavigation.vue';
import IncrementalLifetimeSavings from '@console/components/navigation/IncrementalLifetimeSavings.vue';
import LifetimeSavings from '@console/components/navigation/LifetimeSavings.vue';
import ProfileButton from '@console/components/navigation/ProfileButton.vue';

export default {
  components: {
    ConsoleNavigation,
    ProfileButton,
    LifetimeSavings,
    IncrementalLifetimeSavings,
  },
  props: {
    noNav: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('customer', ['lifetimeSavings', 'incrementalLifetimeSavings']),
    showLifetimeSavings() {
      return this.lifetimeSavings && this.lifetimeSavings > 0;
    },
    showIncrementalLifetimeSavings() {
      return this.incrementalLifetimeSavings && this.incrementalLifetimeSavings > 0;
    },
  },
  mounted() {
    const body = document.querySelector('body');
    body.classList.add('overflow-hidden');
  },
  unmounted() {
    const body = document.querySelector('body');
    body.classList.remove('overflow-hidden');
  },
};
</script>

<template>
  <div class="menu">
    <div class="menuNav">
      <div
        class="menuNavTop pt-2 pb-2 px-3 d-flex align-items-center"
        :class="{
          'justify-content-between': showLifetimeSavings,
          'justify-content-center': !showLifetimeSavings,
        }"
      >
        <div v-if="showLifetimeSavings">
          <LifetimeSavings />
        </div>
        <div v-if="showIncrementalLifetimeSavings">
          <IncrementalLifetimeSavings />
        </div>
        <div>
          <ProfileButton block-nav-modal />
        </div>
      </div>
      <ConsoleNavigation v-if="!noNav" mobile class="pb-3" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.menu {
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
  background-color: $prosperops-purple;
}

.menuNav {
  position: relative;
  width: 100%;
}

.menuNavTop {
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: map-get($theme-colors, 'light');
  opacity: 1;
}
</style>
