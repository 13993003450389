<script setup>
import moment from 'moment';
import { computed } from 'vue';

import NumberHelpers from '@shared/utilities/number_helpers';

import Panel from '@shared/design/panels/Panel.vue';

const props = defineProps({
  month: {
    type: String,
    required: true,
  },
  summary: {
    type: Object,
    required: true,
  },
  isFinalized: {
    type: Boolean,
    required: true,
  },
});

const totalSavings = computed(() => props.summary?.total_savings);
const formattedSavings = computed(() => NumberHelpers.formatDollars(Math.abs(totalSavings.value)));
const hasSavings = computed(() => totalSavings.value >= 0);
const highlightColor = computed(() => (hasSavings.value ? 'text-success' : 'text-danger'));
</script>

<template>
  <Panel>
    <div class="px-2">
      <span class="callout mr-2">
        <template v-if="props.isFinalized">
          In {{ moment.utc(props.month).format('MMMM') }}, you {{ hasSavings ? 'saved' : 'lost' }}
          <span :class="[highlightColor, 'font-weight-bold']">{{ formattedSavings }}</span> on your Compute spend.
        </template>
        <template v-else>
          So far in {{ moment.utc(props.month).format('MMMM') }}, you've {{ hasSavings ? 'saved' : 'lost' }}
          <span :class="[highlightColor, 'font-weight-bold']">{{ formattedSavings }}</span> on your Compute spend.
        </template>
      </span>
    </div>
  </Panel>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.callout {
  font-size: 1.9rem;
  line-height: 1.2em;
}

.costBreakdown {
  td {
    height: 4.5rem;
    vertical-align: middle;
  }

  td:nth-child(2) {
    text-align: right;
  }

  tfoot {
    font-weight: 500;
    background-color: $gray-50;
  }
}
</style>
