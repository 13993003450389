<script>
import { mapState } from 'pinia';
import { mapState as vuexMapState } from 'vuex';

import customerService from '@console/services/customerService';
import { useAuthStore } from '@shared/state/auth.store';
import { dateFromNow, dateLocalize } from '@shared/utilities/filters';

import ProfileImage from '@console/components/navigation/ProfileImage.vue';
import Layout from '@console/Layout.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import PageHeader from '@shared/design/PageHeader.vue';

export default {
  components: {
    Layout,
    PageHeader,
    BoxMessage,
    ProfileImage,
  },
  data() {
    return {
      successMessage: '',
      changePasswordLoading: false,
      changePasswordDisabled: false,
      user: null,
    };
  },
  head: {
    title: 'User Profile',
  },
  computed: {
    ...mapState(useAuthStore, ['profile', 'accessToken', 'isSocial', 'isFederated', 'role', 'isGranularUser']),
    ...vuexMapState('customer', ['customers']),
  },
  async mounted() {
    try {
      const response = await customerService.getSelfUser();
      this.user = response.data;
    } catch (error) {
      await this.$router.push({ name: 'error' });
      throw error;
    }
  },
  methods: {
    onSubmitChange() {
      this.changePasswordDisabled = true;
    },
    onChangedEmail(event) {
      this.successMessage = `Your email address has been successfully changed. Please check ${event.email} for further instructions.`;
    },
    async onChangePassword() {
      this.changePasswordDisabled = true;
      await customerService.changePassword();
      this.changePasswordDisabled = false;
      this.successMessage = `Your password change request has been successfully initiated. Please check ${this.profile.email} for further instructions.`;
    },
    dateFromNow(date) {
      return dateFromNow(dateLocalize(date));
    },
    displayRolePermission(customerId) {
      if (this.isGranularUser(customerId)) {
        return 'Custom';
      }
      return this.role(customerId);
    },
  },
};
</script>

<template>
  <Layout>
    <template #default>
      <BoxMessage v-if="successMessage" type="success" class="pb-4">
        <p class="mb-0">
          {{ successMessage }}
        </p>
      </BoxMessage>
      <PageHeader wrap-utility>
        <div class="d-flex">
          <ProfileImage :profile="profile" />
          <h1 class="pl-3 mb-0">
            {{ profile.name }}
          </h1>
        </div>
        <template v-slot:utility>
          <div>
            <button
              v-if="!isSocial && !isFederated"
              class="btn btn-primary rounded-sm text-nowrap changePassword"
              :disabled="changePasswordDisabled"
              @click.prevent="onChangePassword"
            >
              Change Password
            </button>
          </div>
        </template>
      </PageHeader>
      <div class="pt-3">
        <div class="row pt-2">
          <div class="col-sm-6 pb-2">
            <div class="text-muted">Email Address</div>
            <div
              :class="{
                'd-inline-block': isFederated,
                userAttribute: !isFederated,
              }"
            >
              {{ profile.email }}
            </div>
          </div>
          <div class="col-sm-6">
            <div class="text-muted">User Since</div>
            <div v-if="user" class="userAttribute" :title="new Date(user.created_date)">
              {{ dateFromNow(user.created_date) }}
            </div>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col pt-2 pb-2">
            <h2>Access</h2>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <table class="table companyList">
              <thead>
                <tr>
                  <th class="w-50" scope="col">Company</th>
                  <th scope="col">
                    <div>Role</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="customer in customers" :key="customer.id">
                  <td>
                    {{ customer.company_name }}
                  </td>
                  <td>
                    <div>
                      {{ displayRolePermission(customer.id) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<style scoped>
.userAttribute {
  font-size: 1.3rem;
  font-weight: 400;
}

.boxShadow {
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}

.changePassword:disabled {
  cursor: not-allowed;
}

.companyList {
  background: #fff;
}
</style>
