<script>
import _ from 'lodash';

export default {
  props: {
    organizationSummary: {
      type: Object,
      required: true,
    },
  },
  computed: {
    pendingOrganizations() {
      const pendingOrgs = this.organizationSummary.pending_organizations;
      return !!pendingOrgs || pendingOrgs === 0 ? pendingOrgs : '-';
    },
    countClass() {
      const activeCountColumns = _.get(this.organizationSummary, 'active_organizations').toString().length;
      const pendingCountColumns = _.get(this.organizationSummary, 'pending_organizations', 0).toString().length;
      if (activeCountColumns >= 4 || (activeCountColumns >= 3 && pendingCountColumns >= 3)) {
        return 'largeNumber';
      }
      return 'smallNumber';
    },
  },
};
</script>

<template>
  <div class="panel rounded-sm panelSection">
    <section class="centerContent">
      <div class="h-100">
        <div class="row">
          <div class="col-8 col-sm description">Active Organizations</div>
          <div class="col-4 col-sm activeOrgs" :class="countClass">
            {{ organizationSummary.active_organizations }}
          </div>
          <div class="col-8 col-sm description">Pending Organizations</div>
          <div class="col-4 col-sm pendingOrgs" :class="countClass">
            {{ pendingOrganizations }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped="true">
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.description {
  margin: auto;
  font-size: 0.9rem;
}

.activeOrgs,
.pendingOrgs {
  margin: auto;
  font-size: 2.5rem;
}

.activeOrgs.largeNumber,
.pendingOrgs.largeNumber {
  font-size: 2rem;
}

.pendingOrgs {
  color: #fcbe2c;
}

.panel {
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);

  @include media-breakpoint-up(lg) {
    // When displaying side by side with the savings breakdown, make sure there's enough horizontal space to prevent
    // the text from wrapping
    min-width: 428px;
  }
}

.panelSection {
  > section {
    height: calc(100% - 25px);
  }
}

.centerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
}

header.newHeader {
  padding-bottom: 8px;
  border-bottom: 1px solid $gray-200;
}
</style>
