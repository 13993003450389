<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'off',
    },
    withFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      currentValue: '',
    };
  },
  mounted() {
    this.currentValue = this.value;
    if (this.withFocus) {
      this.focus();
    }
  },
  methods: {
    onInput() {
      this.$emit('input', this.currentValue);
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<template>
  <div class="textInput">
    <span v-if="type === 'search'" class="searchIcon">
      <BaseIcon name="search" class="d-inline-block" />
    </span>
    <input
      :id="id"
      ref="input"
      v-model.trim="currentValue"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      type="text"
      class="input"
      :class="{ 'w-75': type === 'search', 'pl-2': type !== 'search' }"
      @input="onInput"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.input {
  width: 100%;
  height: 36px;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  background-color: #fff;
  border: 0;
  border-radius: 0;
}

.input:focus {
  outline: none;
}

.textInput {
  position: relative;
  display: inline-block;
  background-color: #fff;
}

.textInput::after {
  position: absolute;
  bottom: -2px;
  display: block;
  width: 100%;
  height: 3px;
  content: '';
}

.textInput:focus-within::after {
  background-color: map-get($theme-colors, 'success');
  transition: background-color 0.2s ease-in-out;
}

.textInput.inputError:focus-within::after {
  margin-right: 1rem;
  background-color: map-get($theme-colors, 'danger');
  transition: background-color 0.2s ease-in-out;
}

.searchIcon {
  height: 36px;
  padding-right: 0.5rem;
  padding-left: 0.75rem;
  color: map-get($theme-colors, 'secondary');
}
</style>
