<template>
  <svg
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Icon-Architecture/48/Arch_Amazon-OpenSearch-Service_48</title>
    <g
      id="Icon-Architecture/48/Arch_Amazon-OpenSearch-Service_48"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Icon-Architecture-BG/48/Analytics" fill="#8C4FFF">
        <rect id="Rectangle" x="0" y="0" width="64" height="64"></rect>
      </g>
      <path
        id="Amazon-OpenSearch-Service_Icon_48_Squid"
        d="M35,41 C32.243,41 30,38.757 30,36 C30,33.243 32.243,31 35,31 C37.757,31 40,33.243 40,36 C40,38.757 37.757,41 35,41 L35,41 Z M35,29 C31.141,29 28,32.14 28,36 C28,39.86 31.141,43 35,43 C38.859,43 42,39.86 42,36 C42,32.14 38.859,29 35,29 L35,29 Z M49.71,48.919 C49.291,49.384 48.569,49.42 48.106,49.004 L42.543,43.985 C43.093,43.466 43.591,42.894 44.024,42.271 L49.625,47.316 C50.089,47.736 50.127,48.457 49.71,48.919 L49.71,48.919 Z M26,36 C26,31.038 30.037,27 35,27 C39.963,27 44,31.038 44,36 C44,40.962 39.963,45 35,45 C30.037,45 26,40.962 26,36 L26,36 Z M45.033,40.488 C45.649,39.116 46,37.6 46,36 C46,29.935 41.065,25 35,25 C28.935,25 24,29.935 24,36 C24,42.065 28.935,47 35,47 C37.191,47 39.23,46.349 40.947,45.24 L46.769,50.491 C47.366,51.027 48.116,51.292 48.863,51.292 C49.722,51.292 50.577,50.943 51.196,50.259 C52.349,48.975 52.246,46.99 50.965,45.832 L45.033,40.488 Z M53,17 L53,43 L51,43 L51,18 L46,18 L46,26 L44,26 L44,17 C44,16.448 44.447,16 45,16 L52,16 C52.553,16 53,16.448 53,17 L53,17 Z M40,50 L42,50 L42,53 C42,53.552 41.553,54 41,54 L34,54 C33.447,54 33,53.552 33,53 L33,49 L35,49 L35,52 L40,52 L40,50 Z M35,22 L33,22 L33,12 C33,11.448 33.447,11 34,11 L41,11 C41.553,11 42,11.448 42,12 L42,23 L40,23 L40,13 L35,13 L35,22 Z M29,48 L31,48 L31,53 C31,53.552 30.553,54 30,54 L23,54 C22.447,54 22,53.552 22,53 L22,43 L24,43 L24,52 L29,52 L29,48 Z M24,28 L22,28 L22,19 C22,18.448 22.447,18 23,18 L30,18 C30.553,18 31,18.448 31,19 L31,23 L29,23 L29,20 L24,20 L24,28 Z M13,52 L18,52 L18,26 L13,26 L13,52 Z M19,24 L12,24 C11.447,24 11,24.448 11,25 L11,53 C11,53.552 11.447,54 12,54 L19,54 C19.553,54 20,53.552 20,53 L20,25 C20,24.448 19.553,24 19,24 L19,24 Z"
        fill="#FFFFFF"
      ></path>
    </g>
  </svg>
</template>
