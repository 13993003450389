<script>
import highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import highchartsSolidGauge from 'highcharts/modules/solid-gauge';
import _ from 'lodash';

import { esrMax, percentFormat } from './utility';
import NumberHelpers from '@shared/utilities/number_helpers';

highchartsMore(highcharts);
highchartsSolidGauge(highcharts);

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    defaultMax: {
      type: Number,
      required: false,
      default: 50,
    },
    width: {
      type: Number,
      required: false,
      default: 280,
    },
    height: {
      type: Number,
      required: false,
      default: 200,
    },
  },
  computed: {
    display() {
      return _.round(this.value, 1);
    },
    formatted() {
      return `${NumberHelpers.formatNumber(this.value, 1)}%`;
    },

    chartOptions() {
      return {
        chart: {
          type: 'solidgauge',
          spacingBottom: 0,
          spacingTop: 0,
          spacingLeft: 0,
          spacingRight: 0,
          height: this.height,
          width: this.width,
          backgroundColor: 'transparent',
        },
        title: null,
        pane: {
          startAngle: -90,
          endAngle: 90,
          background: {
            innerRadius: '80%',
            outerRadius: '100%',
            backgroundColor: '#f5f6fa',
            borderWidth: 0,
            shape: 'arc',
          },
          size: this.width,
          center: ['50%', '80%'],
        },
        tooltip: {
          enabled: false,
        },
        yAxis: {
          lineWidth: 0,
          tickPositioner: function () {
            return [this.min, this.max];
          },
          minorTickInterval: null,
          tickLength: 0,
          labels: {
            distance: -16,
            y: 16,
            format: percentFormat,
          },
          min: 0,
          max: esrMax(this.value, this.defaultMax),
          minColor: '#5c54ff',
          maxColor: '#5c54ff',
        },
        plotOptions: {
          solidgauge: {
            innerRadius: '80%',
            dataLabels: {
              y: -36,
              borderWidth: 0,
              useHTML: true,
            },
          },
        },
        series: [
          {
            name: this.title,
            data: [this.display],
            dataLabels: {
              enabled: false,
            },
          },
        ],
      };
    },
  },
};
</script>

<template>
  <div class="gauge">
    <chart :options="chartOptions" />
    <div class="gaugeValue">
      {{ formatted }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.gauge {
  position: relative;

  .gaugeValue {
    position: absolute;
    top: 50%;
    width: 100%;
    font-size: 52px;
    font-weight: 800;
    text-align: center;
  }
}
</style>
