<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <b-dropdown variant="transparent" menu-class="rounded-sm" toggle-class="btn-no-focus-box-shadow" right no-caret>
    <template #button-content>
      <div class="d-flex align-items-center justify-content-center text-white">
        <small v-if="value === 'LinuxMacUnix'"> Linux, macOS, or Unix </small>
        <small v-if="value === 'PowerShell'"> Powershell </small>
        <small v-if="value === 'WindowsCommandPrompt'"> Windows Command Prompt </small>
        <small class="ml-1">
          <BaseIcon name="angle-down" />
        </small>
      </div>
    </template>
    <b-dropdown-item @click.prevent="$emit('input', 'LinuxMacUnix')">Linux, macOS, or Unix</b-dropdown-item>
    <b-dropdown-item @click.prevent="$emit('input', 'PowerShell')">Powershell</b-dropdown-item>
    <b-dropdown-item @click.prevent="$emit('input', 'WindowsCommandPrompt')">Windows Command Prompt</b-dropdown-item>
  </b-dropdown>
</template>
