<script>
import _ from 'lodash';
import moment from 'moment';

import * as chartUtilities from '@console/components/charts/utility';
import NumberHelpers from '@shared/utilities/number_helpers';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';

export default {
  components: {
    ChartLegend,
    SeriesChart,
  },
  props: {
    arbitrageOpportunityTrend: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      series: [],
    };
  },
  computed: {
    xAxis() {
      return {
        categories: _.map(this.arbitrageOpportunityTrend, opportunity => {
          return moment.utc(opportunity.month_start).format('MMM YYYY');
        }),
      };
    },
    yAxis() {
      return [
        {
          min: 0,
          title: {
            text: null,
          },
          labels: {
            format: chartUtilities.getCurrencyFormat(),
          },
        },
      ];
    },
    filteredSeries() {
      return _.filter(this.series, s => s.legendSelected);
    },
    tooltip() {
      return chartUtilities.tooltipWithTotal({
        totalLabel: 'Total Compute Usage',
        valueFormatter: v => NumberHelpers.formatDollars(v),
      });
    },
  },
  mounted() {
    const safeMap = (arr, key) => _.map(arr, a => _.get(a, key, null));
    const endCustomerSpendCoverage = safeMap(this.arbitrageOpportunityTrend, 'end_customer_spend_coverage');
    const arbitrageSpendCoverage = safeMap(this.arbitrageOpportunityTrend, 'arbitrage_spend_coverage');
    const uncoveredUsage = safeMap(this.arbitrageOpportunityTrend, 'uncovered_usage');
    const series = [
      {
        label: 'Uncovered Usage',
        name: 'Uncovered Usage',
        type: 'column',
        stacking: 'normal',
        data: uncoveredUsage,
        color: '#fc5454',
        legendOrder: 2,
        legendSelected: true,
        tooltip: {
          valuePrefix: '$',
        },
      },
      {
        label: 'Arbitrage Spend Coverage',
        name: 'Arbitrage Spend Coverage',
        type: 'column',
        stacking: 'normal',
        data: arbitrageSpendCoverage,
        color: '#fcbe2c',
        legendOrder: 1,
        legendSelected: true,
        tooltip: {
          valuePrefix: '$',
        },
      },
      {
        label: 'End Customer Spend Coverage',
        name: 'End Customer Spend Coverage',
        type: 'column',
        stacking: 'normal',
        data: endCustomerSpendCoverage,
        color: '#5c54ff',
        legendOrder: 0,
        legendSelected: true,
        tooltip: {
          valuePrefix: '$',
        },
      },
    ];
    const isZeroOrNull = d => d === 0 || d === null;
    const containsNoData = el => _.every(el.data, isZeroOrNull);
    this.series = _.reject(series, containsNoData);
  },
};
</script>

<template>
  <div>
    <ChartLegend v-model="series" />
    <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" :tooltip="tooltip" />
    <div class="d-flex flex-row-reverse footnote">
      <small> (normalized) </small>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footnote {
  position: absolute;
  right: 5%;
  bottom: 15px;
}
</style>
