import { mapState } from 'pinia';
import Pusher from 'pusher-js';
import { mapState as vuexMapState, mapActions as vuexMapActions } from 'vuex';

import customerService from '@console/services/customerService';
import { useAuthStore } from '@shared/state/auth.store';
import env from '@shared/utilities/environment';

export const PusherSetup = {
  data() {
    return {
      pusher: null,
    };
  },
  async created() {
    const authEndpoint = await customerService.getPusherAuthEndpoint();
    this.pusher = new Pusher(env.get('VITE_PUSHER_KEY'), {
      cluster: 'us2',
      forceTLS: true,
      authEndpoint: authEndpoint,
      auth: {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      },
    });

    var channel = this.pusher.subscribe('private-customer-' + this.selectedCompanyId);

    channel.bind('AwsAccountStatusChange', data => {
      this.updateAccountStatus({
        awsAccountId: data.aws_account_id,
        status: data.new_status,
      });
    });

    channel.bind('AwsAccountOfferingStatusChange', data => {
      this.updateAccountOfferingStatus({
        awsAccountId: data.aws_account_id,
        offering: data.offering,
        status: data.new_status,
      });
    });

    channel.bind('AwsOrganizationStatusChange', data => {
      this.updateOrganizationStatus({
        awsOrganizationId: data.aws_organization_id,
        status: data.new_status,
      });
    });

    channel.bind('NewAwsAccount', () => {
      this.loadAwsAccounts();
    });
  },
  computed: {
    ...mapState(useAuthStore, ['accessToken']),
    ...vuexMapState('customer', ['selectedCompanyId']),
  },
  methods: {
    ...vuexMapActions('aws', [
      'updateAccountStatus',
      'updateAccountOfferingStatus',
      'updateOrganizationStatus',
      'loadAwsAccounts',
    ]),
    disconnectPusher() {
      if (this.pusher) {
        this.pusher.disconnect();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.disconnectPusher();
    next();
  },
};
