<script>
export default {
  props: {
    asLink: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<template>
  <div v-if="asLink" class="logoSection">
    <router-link to="/" class="text-decoration-none">
      <img src="@console/assets/images/logo.svg" class="logo" />
    </router-link>
  </div>
  <div v-else class="logoSection">
    <img src="@console/assets/images/logo.svg" class="logo" />
  </div>
</template>

<style lang="scss" scoped>
.logo {
  height: 30px;
}
</style>
