<script setup lang="ts">
import { computed } from 'vue';

import awsService, { EditableAwsOrgSettings } from '@aws/services/awsService';
import { useVuexStore } from '@console/state/vuex/store';

import SettingRow from '@console/components/settings/SettingRow.vue';
import Toggle from '@shared/components/Toggle.vue';

const vuexStore = useVuexStore();

interface Props {
  isLoading: boolean;
}
withDefaults(defineProps<Props>(), {
  isLoading: false,
});

const isCustomerDataExportEnabled = computed<boolean>(
  () => vuexStore.getters['aws/selectedOrganizationSettings']?.is_customer_data_export_enabled ?? false
);

const emits = defineEmits<{ (e: 'onToggleLoading', isLoading: boolean): void }>();

async function onToggleExportEnabled() {
  emits('onToggleLoading', true);

  isCustomerDataExportEnabled.value
    ? await awsService.disableSetting(
        vuexStore.getters['aws/selectedOrganizationId'],
        EditableAwsOrgSettings.IsCustomerDataExportEnabled
      )
    : await awsService.enableSetting(
        vuexStore.getters['aws/selectedOrganizationId'],
        EditableAwsOrgSettings.IsCustomerDataExportEnabled
      );

  await vuexStore.dispatch('aws/initializeAwsOrganizationSettings');

  emits('onToggleLoading', false);
}
</script>

<template>
  <SettingRow>
    <template #label> Enabled? </template>
    <template #description>
      Export data to the S3 bucket specified below. Allow up to 48 hours for historical data to be transferred.
    </template>
    <template #value>
      <Toggle
        :enabled="isCustomerDataExportEnabled ?? false"
        off="Disabled"
        on="Enabled"
        :disabled="isLoading"
        @change="onToggleExportEnabled"
      />
    </template>
  </SettingRow>
</template>
