import { azure } from '@shared/services/api';

/* eslint-disable camelcase */
export enum AgreementType {
  EnterpriseAgreement = 'EnterpriseAgreement',
  MicrosoftCustomerAgreement = 'MicrosoftCustomerAgreement',
}

export enum BillingScopeStatus {
  Provisioning = 'Provisioning',
}

export type CreateBillingScopeRequest = {
  agreement_type?: AgreementType;
  billing_account_id?: string;
  billing_profile_id?: string;
  service_principal_id?: string;
  storage_account_subscription_id?: string;
  storage_account_resource_group_name?: string;
  storage_account_name?: string;
};

export type BillingScopeResponse = {
  id: string;
  agreement_type: AgreementType;
  billing_account_id: string;
  billing_profile_id?: string | undefined;
  service_principal_id: string;
  storage_account_subscription_id: string;
  storage_account_resource_group_name: string;
  storage_account_name: string;
  status: BillingScopeStatus;
  display_name: string;
};
/* eslint-enable camelcase */

export type BillingScopeExistsResponse = {
  exists: boolean;
};

export async function getAll() {
  const res = await azure.get<BillingScopeResponse[]>(`/v1/billing_scopes`);
  return res.data;
}

export async function create(request: CreateBillingScopeRequest) {
  const res = await azure.post<BillingScopeResponse>(`/v1/billing_scopes`, request);
  return res.data;
}

export async function exists(billingAccountId?: string, billingProfileId?: string) {
  const res = await azure.get<BillingScopeExistsResponse>(`/v1/billing_scopes/exists`, {
    params: {
      billingAccountId,
      billingProfileId,
    },
  });
  return res.data;
}
