<script>
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import { mapGetters } from 'vuex';

import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';
import { dateFormat, dateUtc } from '@shared/utilities/filters';
import NumberHelpers from '@shared/utilities/number_helpers';

import Currency from '@shared/components/Currency.vue';
import Modal from '@shared/design/Modal.vue';

export default {
  components: {
    Currency,
    Modal,
  },
  props: {
    savings: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
    awsAccounts: {
      type: Array,
      required: true,
    },
    demo: {
      type: Boolean,
      required: false,
      default: false,
    },
    includeTotals: {
      type: Boolean,
      required: false,
      default: true,
    },
    service: {
      type: String,
      required: false,
      default: 'compute',
    },
  },
  computed: {
    ...mapGetters('aws', ['isMultiOrgSummarySelected']),
    ...mapGetters('customer', ['isReseller']),
    isFinalized() {
      return this.savings.dashboard.is_finalized;
    },
    dataThroughDate() {
      return this.savings.dashboard.data_through_date;
    },
    totalSavings() {
      return this.savings.dashboard.savings_summary.total_savings;
    },
    formattedSavings() {
      return NumberHelpers.formatDollars(Math.abs(this.totalSavings));
    },
    savingsPlanSavings() {
      return this.savings.dashboard.savings_summary.savings_plan_savings;
    },
    reservedInstanceSavings() {
      return this.savings.dashboard.savings_summary.ec2_reserved_instance_savings;
    },
    nonComputeReservationSavings() {
      return this.savings.dashboard.savings_summary.reservation_savings;
    },
    startDate() {
      const date = this.savings.month_start;
      return moment.utc(date).format('YYYY-MM-DD');
    },
    endDate() {
      const date = this.savings.dashboard.data_through_date;
      // cost explorer end date is exclusive, endDate=2023-11-30 needs to be endDate=2023-12-01
      return moment.utc(date).add(1, 'days').format('YYYY-MM-DD');
    },
    awsAccountNumber() {
      if (this.demo) {
        return 'xxxx xxxx xxxx';
      }
      return this.organization.management_aws_account_number;
    },
    reservedInstanceLink() {
      if (this.demo) {
        return '/';
      }
      const filters = this.isReseller ? this.filterByAwsAccounts(this.awsAccounts) : [];
      const queryString = this.riQueryString(filters);
      return `https://console.aws.amazon.com/cost-management/home?region=us-east-1#/ri/utilization?${queryString}`;
    },
    savingsPlanLink() {
      if (this.demo) {
        return '/';
      }
      const filters = this.isReseller ? this.filterByAwsAccounts(this.awsAccounts) : [];
      const queryString = this.spQueryString(filters);
      return `https://console.aws.amazon.com/cost-management/home?region=us-east-1#/savings-plans/utilization?${queryString}`;
    },
    nonComputeCostExplorerLink() {
      if (this.demo) {
        return '/';
      }
      const queryString = this.riQueryString([]);
      return `https://console.aws.amazon.com/cost-management/home?region=us-east-1#/ri/utilization?${queryString}`;
    },
    serviceDisplayName() {
      return AwsServiceHelpers.getDisplayName(this.service);
    },
    isProductCompute() {
      return this.service === 'compute';
    },
    riCostExplorerServiceName() {
      // Use the EC2 service name for Compute, since this is for RI utilization which doesn't apply to the other
      // Compute services (i.e. Lambda & Fargate)
      const service = this.isProductCompute ? 'ec2' : this.service;

      return AwsServiceHelpers.getCostExplorerReservedInstanceServiceName(service);
    },
    hasSavingsPlan() {
      return _.has(this.savings, ['dashboard', 'key_metrics', 'savings_plan_utilization_percentage']);
    },
  },
  methods: {
    serviceSpendLabel() {
      var label = `${this.serviceDisplayName} spend`;

      if (this.isProductCompute) {
        if (this.isReseller) {
          return `arbitrageable ${label}`;
        }
      }

      return label;
    },
    multiOrgText() {
      return this.isMultiOrgSummarySelected ? ' across all Organizations' : '';
    },
    filterByAwsAccounts(awsAccounts) {
      return [
        {
          dimension: 'LinkedAccount',
          values: _.map(awsAccounts, 'aws_account_number'),
          include: true,
          children: null,
        },
      ];
    },
    riQueryString(extraFilters = []) {
      return qs.stringify({
        groupBy: 'None',
        chartStyle: 'Line',
        timeRangeOption: 'Custom',
        granularity: 'Daily',
        reportName: 'RI Utilization',
        usageAs: 'usageQuantity',
        subscriptionIds: JSON.stringify([]),
        filter: JSON.stringify([
          ...extraFilters,
          {
            dimension: 'Service',
            values: [this.riCostExplorerServiceName],
            include: true,
            children: null,
          },
        ]),
        reportId: '',
        target: 100,
        startDate: this.startDate,
        endDate: this.endDate,
      });
    },
    spQueryString(extraFilters = []) {
      return qs.stringify({
        subscriptionIds: JSON.stringify([]),
        chartStyle: 'Line',
        timeRangeOption: 'Custom',
        granularity: 'Daily',
        reportName: 'Utilization report',
        reportType: 'SavingsPlansUtilization',
        isTemplate: true,
        filter: JSON.stringify([...extraFilters]),
        startDate: this.startDate,
        endDate: this.endDate,
      });
    },
    formatDate(date, format = 'MMMM') {
      return dateFormat(dateUtc(date), format);
    },
  },
};
</script>

<template>
  <div class="pl-2 pt-1 pb-1">
    <Modal id="cost-explorer" size="lg" title="AWS Cost Explorer">
      <p>
        ProsperOps considers AWS systems authoritative for savings data. If you&apos;d like to validate our numbers with
        AWS, open a new browser tab and login to the AWS Console on your
        <strong>management account ({{ awsAccountNumber }})</strong>
        with sufficient permissions to view AWS Cost Explorer, then click
        <span v-if="hasSavingsPlan">each</span><span v-else>the</span>
        button below.
      </p>
      <table class="table costExplorerBreakdown mt-4">
        <tr v-if="reservedInstanceSavings" class="subtotal">
          <td>
            <b-button
              target="_blank"
              :href="reservedInstanceLink"
              variant="primary"
              :disabled="demo"
              class="rounded-sm"
            >
              View Reserved Instance Savings
              <BaseIcon class="ml-2" name="external-link-alt" />
            </b-button>
          </td>
          <td>
            <BaseIcon name="arrow-right" />
          </td>
          <td>
            <div class="highlight">
              <Currency :value="reservedInstanceSavings" />
            </div>
            <strong>(Total Net Savings)</strong>
          </td>
        </tr>
        <tr v-if="hasSavingsPlan" class="subtotal">
          <td>
            <b-button target="_blank" :href="savingsPlanLink" variant="primary" :disabled="demo" class="rounded-sm">
              View Savings Plan Savings
              <BaseIcon class="ml-2" name="external-link-alt" />
            </b-button>
          </td>
          <td>
            <BaseIcon name="arrow-right" />
          </td>
          <td>
            <div class="highlight">
              <Currency :value="savingsPlanSavings" />
            </div>
            <strong>(Total Net Savings vs On-Demand)</strong>
          </td>
        </tr>
        <tr v-if="nonComputeReservationSavings" class="subtotal">
          <td>
            <b-button
              target="_blank"
              :href="nonComputeCostExplorerLink"
              variant="primary"
              :disabled="demo"
              class="rounded-sm"
            >
              View {{ nonComputeServiceName }} Savings
              <BaseIcon class="ml-2" name="external-link-alt" />
            </b-button>
          </td>
          <td>
            <BaseIcon name="arrow-right" />
          </td>
          <td>
            <div class="highlight">
              <Currency :value="nonComputeReservationSavings" />
            </div>
            <strong>(Total Net Savings)</strong>
          </td>
        </tr>
        <tr v-if="includeTotals" class="total">
          <td>Total Savings</td>
          <td>
            <BaseIcon name="arrow-right" />
          </td>
          <td>
            <Currency :value="totalSavings" />
          </td>
        </tr>
      </table>
    </Modal>
    <span class="callout mr-2">
      <span v-if="isFinalized">
        In {{ formatDate(dataThroughDate) }}, you
        {{ totalSavings >= 0 ? 'saved' : 'lost' }}
      </span>
      <span v-else>
        So far in
        {{ formatDate(dataThroughDate) }}, you've
        {{ totalSavings >= 0 ? 'saved' : 'lost' }}
      </span>
      <span
        class="total"
        :class="{
          'text-success': totalSavings >= 0,
          'text-danger': totalSavings < 0,
        }"
        >&nbsp;{{ formattedSavings }}</span
      >
      on your {{ serviceSpendLabel() }}{{ multiOrgText() }}.
    </span>
    <small v-if="!isMultiOrgSummarySelected" class="text-nowrap">
      <a v-b-modal.cost-explorer href="#" @click.prevent>Show me this in AWS Cost Explorer</a>
    </small>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.callout {
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 1.2;
}

.total {
  font-weight: bold;
}

table.costExplorerBreakdown {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  table-layout: fixed;

  td {
    padding-bottom: 1rem;
    text-align: center;
    vertical-align: middle;
  }

  a {
    width: 100%;
    border-radius: 0;
  }

  tr.subtotal td:last-child {
    .highlight {
      font-size: 2rem;
      font-weight: bold;
      color: map-get($theme-colors, 'primary');
    }
  }

  tr.total td:first-child,
  tr.total td:last-child {
    font-size: 2rem;
    font-weight: bold;
    color: map-get($theme-colors, 'success');
  }

  tr td:nth-child(2) {
    font-size: 2rem;
  }

  tr:not(:last-child) {
    td {
      border-top: 0;
    }
  }

  td:nth-child(1) {
    width: 45%;
  }

  td:nth-child(2) {
    width: 10%;
  }

  td:nth-child(3) {
    width: 45%;
  }
}
</style>
