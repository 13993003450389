<script lang="ts">
import type { PropType } from 'vue';

import _ from 'lodash';

export interface Section {
  /** Used as the tooltip label */
  key: string;
  color: string;
  value: number;
  /** Defaults to true if not provided (i.e. undefined) */
  showTooltip?: boolean;
}

export interface Thumb {
  visible: boolean;
  position?: number;
  description?: string;
}

export default {
  props: {
    sections: {
      type: Array as PropType<Section[]>,
      required: true,
    },
    thumb: {
      type: Object as PropType<Thumb>,
      required: true,
    },
  },
  computed: {
    startStyles() {
      const first = _.find(this.sections, s => s.value > 0);
      if (!first) return undefined;

      return `background-color: ${first.color}`;
    },
    sectionsStyles() {
      // Pixel % math could result in 1 pixel left over, set background to the last section
      // so that there isn't a visible gap. When the math works out this won't be visible.
      const last = _.findLast(this.sections, s => s.value > 0);
      if (!last) return undefined;

      return `background-color: ${last.color}`;
    },
    endStyles() {
      const last = _.findLast(this.sections, s => s.value > 0);
      if (!last) return undefined;

      return `background-color: ${last.color}`;
    },
  },
  methods: {
    tooltip(section: Section) {
      const show = _.get(section, 'showTooltip', true);
      if (!show) {
        return undefined;
      }
      return `${section.key}`;
    },
    thumbTextPos(thumb: Thumb) {
      if (!thumb.visible || !thumb.position) return {};

      let offset = '100px';
      if (this.adjustOffsetForSmallerScreen() && thumb.position < 20) {
        offset = '50px';
      }
      const leftCalc = `calc(${thumb.position}% - ${offset})`;
      return { left: leftCalc };
    },
    adjustOffsetForSmallerScreen() {
      if (!window.matchMedia) {
        return false;
      }
      // if the current viewport matches the md media query, there is enough
      // room and we don't need to adjust
      const match = window.matchMedia('(min-width: 768px)');
      if (match.matches) {
        return false;
      }
      return true;
    },
  },
};
</script>

<template>
  <div class="bar">
    <div class="start" :style="startStyles"></div>
    <div class="sections" :style="sectionsStyles">
      <div
        v-for="section in sections"
        :key="section.key"
        :title="tooltip(section)"
        :style="{
          'background-color': section.color,
          width: section.value + '%',
        }"
      ></div>
    </div>
    <div v-if="thumb.visible" class="thumb" :style="{ left: thumb.position + '%' }"></div>
    <div v-if="thumb.visible" class="thumbText" :style="thumbTextPos(thumb)">
      {{ thumb.description }}
    </div>
    <div class="end" :style="endStyles"></div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.bar {
  position: relative;
  width: 100%;
  height: 20px;
}

.start {
  display: inline-block;
  width: 10px;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.end {
  display: inline-block;
  width: 10px;
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.sections {
  display: inline-block;
  width: calc(100% - 20px); /* 20px = width of .start + .end */
  height: 100%;

  > div {
    display: inline-block;
    height: 100%;
  }
}

.thumb {
  position: absolute;
  top: -12px;
  display: inline-block;
  width: 4px;
  height: 44px;
  background-color: map-get($theme-colors, 'info');
  border-radius: 20px;
}

.thumbText {
  position: absolute;
  width: 200px;
  padding-top: 18px;
  overflow: visible;
  font-weight: 500;
  text-align: center;
}
</style>
