<template>
  <div class="panelLabel">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/bootstrap';

.panelLabel {
  padding: 1px 9px 5px;
  font-size: $font-size-sm;
  font-weight: 400;
  vertical-align: top;
}
</style>
