<script>
import _ from 'lodash';
import Gravatar from 'vue-gravatar';

export default {
  components: {
    'v-gravatar': Gravatar,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      required: false,
      default: 40,
    },
  },
  computed: {
    style() {
      const sizeInPixels = `${this.size}px`;
      return { width: sizeInPixels, height: sizeInPixels };
    },
    altText() {
      const user = this.user;
      const names = _.compact([user.first_name, user.last_name]);
      const name = _.join(names, ' ');
      return `${name}'s Profile Image`;
    },
  },
};
</script>

<template>
  <v-gravatar
    :email="user.email_address"
    :size="size"
    :default-img="user.picture_url"
    :style="style"
    :alt="altText"
    class="rounded-circle"
  />
</template>
