<script>
import _ from 'lodash';

import { dateFormat, dateUtc } from '@shared/utilities/filters';

import Currency from '@shared/components/Currency.vue';
import Tooltip from '@shared/design/Tooltip.vue';

export default {
  components: {
    Currency,
    Tooltip,
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    resourceType: {
      type: String,
      required: true,
    },
  },
  computed: {
    label() {
      if (this.resourceType === 'reserved_instance') {
        return 'Reserved Instance Exchange ID';
      }
      if (this.resourceType === 'savings_plan') {
        return 'Savings Plan ID';
      }
      throw new Error('Unexpected resource_type');
    },
    endDateLabel() {
      if (this.resourceType === 'reserved_instance') {
        return 'Original End Date';
      }
      if (this.resourceType === 'savings_plan') {
        return 'End Date';
      }
      throw new Error('Unexpected resource_type');
    },
    resources() {
      const details = _.get(this.invoice, 'invoice_details', []);
      const detailsByResourceType = _.groupBy(details, 'resource_type');
      return _.get(detailsByResourceType, this.resourceType, []);
    },
    isVisible() {
      return _.some(this.resources);
    },
    invoiceId() {
      return _.get(this.invoice, 'invoice_id');
    },
    showTooltip() {
      return this.resourceType === 'reserved_instance';
    },
    tooltipTarget() {
      return `${this.invoiceId}_resourceId_header`;
    },
  },
  methods: {
    formatDate(date, format = 'MMM DD, YYYY') {
      return dateFormat(dateUtc(date), format);
    },
  },
};
</script>

<template>
  <div v-if="isVisible">
    <Tooltip v-if="showTooltip" :target="tooltipTarget">
      <div>
        May appear on the AWS invoice as
        <span class="text-nowrap font-weight-bold">Lease ID</span>.
      </div>
    </Tooltip>
    <table class="invoiceDetailTable">
      <thead>
        <tr class="text-muted">
          <th class="font-weight-normal resourceId">
            <span :id="tooltipTarget" :class="{ 'tooltip-target': showTooltip }">
              {{ label }}
            </span>
          </th>
          <th class="font-weight-normal startDate">Start Date</th>
          <th class="font-weight-normal endDate">{{ endDateLabel }}</th>
          <th class="font-weight-normal prepaymentAmount">
            <div class="mr-2">Cost</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="resource in resources" :key="resource.resource_id">
          <td class="resourceId">
            {{ resource.resource_id }}
          </td>
          <td class="startDate">
            {{ formatDate(resource.start_date) }}
          </td>
          <td class="endDate">
            {{ formatDate(resource.end_date) }}
          </td>
          <td class="prepaymentAmount">
            <Currency :value="resource.prepayment_amount" class="mr-2" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.invoiceDetailTable {
  width: calc(100% - 42px);
  margin-left: 42px;

  th,
  td {
    padding: 12px;
  }
}

.invoiceDetailTable > thead > tr {
  text-align: left;
  background-color: $table-head-background;
}

.resourceId {
  width: 50%;
}

.startDate,
.endDate {
  width: 20%;
  text-align: left;
}

.prepaymentAmount {
  text-align: right;
}
</style>
