<script>
import _ from 'lodash';

import Currency from '@shared/components/Currency.vue';

export default {
  components: {
    Currency,
  },
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasTotalAllocation() {
      return (
        this.isNumber(this.settings.base_one_year_allocation) || this.isNumber(this.settings.base_three_year_allocation)
      );
    },
    totalAllocation() {
      return _.sum([this.settings.base_one_year_allocation, this.settings.base_three_year_allocation]);
    },
  },
  methods: {
    isNumber(n) {
      return _.isNumber(n);
    },
  },
};
</script>

<template>
  <div>
    <div>
      <span v-if="hasTotalAllocation">
        <Currency :value="totalAllocation" :decimals="0" class="font-weight-bold" />
        per month
      </span>
      <span v-else> - </span>
    </div>
    <div v-if="isNumber(settings.base_one_year_allocation) || isNumber(settings.base_three_year_allocation)">
      <div v-if="isNumber(settings.base_one_year_allocation)" class="pl-3 pt-2">
        <span class="mr-1">1 Year: </span>
        <Currency :value="settings.base_one_year_allocation" :decimals="0" class="font-weight-bold" />
      </div>
      <div v-else class="pl-3 pt-2"><span class="mr-1">1 Year: </span> -</div>
      <div v-if="isNumber(settings.base_three_year_allocation)" class="pl-3 pt-2">
        <span class="mr-1">3 Year: </span>
        <Currency :value="settings.base_three_year_allocation" :decimals="0" class="font-weight-bold" />
      </div>
      <div v-else class="pl-3 pt-2"><span class="mr-1">3 Year: </span> -</div>
    </div>
  </div>
</template>
