<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';

import * as chartUtility from '@console/components/charts/utility';
import * as mask from '@console/lib/mask';
import NumberHelpers from '@shared/utilities/number_helpers';

import ChartLegend from '@console/components/charts/ChartLegendV2.vue';
import ScatterChart from '@console/components/charts/ScatterChart.vue';
import TimelineCircle from '@shared/design/icons/TimelineCircle.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const priority = [
  'no_upfront_1_year',
  'partial_upfront_1_year',
  'all_upfront_1_year',
  'no_upfront_3_year',
  'partial_upfront_3_year',
  'all_upfront_3_year',
];

const colors = {
  no_upfront_1_year: '#a7a3ff',
  partial_upfront_1_year: '#00c58c',
  all_upfront_1_year: '#fcbe2c',
  no_upfront_3_year: '#fc5454',
  partial_upfront_3_year: '#5c54ff',
  all_upfront_3_year: '#8fffdf',
};

const labels = {
  no_upfront_1_year: '1 Year No Upfront',
  partial_upfront_1_year: '1 Year Partial Upfront',
  all_upfront_1_year: '1 Year All Upfront',
  no_upfront_3_year: '3 Year No Upfront',
  partial_upfront_3_year: '3 Year Partial Upfront',
  all_upfront_3_year: '3 Year All Upfront',
};

const fillColors = {
  true: '#ffffff',
  false: null,
};

const lineWidths = {
  true: 3,
  false: null,
};

export default {
  components: {
    ChartLegend,
    PanelSection,
    ScatterChart,
    TimelineCircle,
  },
  props: {
    timeline: {
      type: Object,
      required: true,
    },
    dataThroughDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      legend: [],
      series: [],
    };
  },
  computed: {
    ...mapGetters('customer', ['isDemo']),
    hasAwsAccountDetails() {
      return this.timeline.friendly_name && this.timeline.aws_account_number;
    },
    awsAccountFriendlyName() {
      return this.isDemo ? mask.generateAwsAccountName(this.timeline.aws_account_id) : this.timeline.friendly_name;
    },
    awsAccountNumber() {
      return this.isDemo ? mask.redact(this.timeline.aws_account_number) : this.timeline.aws_account_number;
    },
    tooltip() {
      return {
        enabled: true,
        borderRadius: 0,
        borderColor: '#adb5bd',
        shared: true,
        shadow: false,
        headerFormat: '',
        pointFormat:
          'Expiration: <b>{point.po_date}</b><br>' +
          'Commitment (amortized): <b>${point.po_commitment_amortized_per_hour}/hr</b><br>' + // eslint-disable-line
          'Percent of regional CRI commit: <b>{point.po_convertible_ri_regional_commit_percentage}%</b><br>',
      };
    },
    height() {
      const rows = _.size(this.timeline.details);
      return rows * 50;
    },
    xAxis() {
      const startDate = moment.utc(this.dataThroughDate).startOf('month').toDate();
      const endDate = moment.utc(this.dataThroughDate).endOf('month').add(3, 'years').toDate();
      return {
        type: 'datetime',
        min: +startDate,
        max: +endDate,
        tickLength: 0,
        labels: {
          formatter: function () {
            const value = this.value;
            return moment.utc(value).format('MMM YYYY');
          },
        },
        plotLines: chartUtility.monthlyPlotLines(startDate, endDate),
      };
    },
    yAxis() {
      const categories = _.map(this.timeline.details, this.timelineCategory);
      return [
        {
          visible: true,
          title: '',
          labels: {
            enabled: false,
            format: '',
          },
          categories: categories,
          min: 1,
          max: _.size(categories),
        },
      ];
    },
    filteredSeries() {
      const selected = _.filter(this.legend, 'selected');
      const labels = _.map(selected, 'label');
      const isLabelSelected = s => _.includes(labels, s.name);
      return _.map(this.series, s => {
        const visible = isLabelSelected(s);
        return { ...s, visible };
      });
    },
  },
  mounted() {
    const categories = _.map(this.timeline.details, this.timelineCategory);
    const categoryPriority = category => _.indexOf(priority, category);
    const orderedCategories = _.orderBy(categories, categoryPriority);
    this.series = _.map(orderedCategories, this.seriesElement);
    this.legend = _.map(orderedCategories, this.legendElement);
  },
  methods: {
    legendElement(key) {
      return {
        label: labels[key],
        color: colors[key],
        selected: true,
        hasData: true,
      };
    },
    seriesElement(key, index) {
      const timeline = this.timeline.details;
      const color = colors[key];
      const details = timeline[index].expiration_date_details;
      const toData = detail => {
        const expiration = moment.utc(detail.expiration_date);
        const hourly = detail.amortized_hourly_commitment;
        const percentage = detail.percentage_of_regional_convertible_reserved_instance_commitment;
        const seed = detail.is_seed;
        return {
          x: +expiration,
          y: index + 1,
          po_date: expiration.format('MMMM DD, YYYY'),
          po_commitment_amortized_per_hour: NumberHelpers.formatNumber(hourly, 3),
          po_convertible_ri_regional_commit_percentage: NumberHelpers.formatNumber(percentage, 3),
          marker: {
            radius: 5 + percentage / 10,
            symbol: 'circle',
            fillColor: fillColors[`${seed}`],
            lineWidth: lineWidths[`${seed}`],
            lineColor: color,
          },
        };
      };
      return {
        name: labels[key],
        color: color,
        data: _.map(details, toData),
      };
    },
    timelineCategory(t) {
      const offering = _.replace(_.lowerCase(t.offering_type), ' ', '_');
      return `${offering}_${t.years}_year`;
    },
  },
};
</script>

<template>
  <PanelSection :header="`${awsAccountFriendlyName} - ${awsAccountNumber}`">
    <template v-slot:header>
      <div class="flex-grow-1">
        {{ timeline.region_display_name }}<br />
        <small class="text-muted">{{ timeline.region }}</small>
      </div>
    </template>
    <template v-slot:utility>
      <div class="text-right">
        {{ awsAccountFriendlyName }}<br />
        <small class="text-muted">{{ awsAccountNumber }}</small>
      </div>
    </template>
    <div class="convertibleRiExpirationTimeline">
      <ChartLegend v-model:model-value="legend" />
      <ScatterChart :x-axis="xAxis" :y-axis="yAxis" :tooltip="tooltip" :series="filteredSeries" :height="height" />
      <div class="d-flex pt-2 pl-2">
        <div class="pr-4">
          <TimelineCircle class="text-muted mr-1" />
          <small class="text-muted">Commitment</small>
        </div>
        <div>
          <TimelineCircle class="text-muted mr-1" hollow />
          <small class="text-muted">Seed</small>
        </div>
      </div>
    </div>
  </PanelSection>
</template>

<style scoped>
.convertibleRiExpirationTimeline + .convertibleRiExpirationTimeline {
  margin-top: 1.5rem;
}
</style>
