<script>
import PrepaymentAsOfPill from '@aws/components/prepayments/PrepaymentAsOfPill.vue';
import TimeframeDropdown from '@shared/design/TimeframeDropdown.vue';

export default {
  components: {
    PrepaymentAsOfPill,
    TimeframeDropdown,
  },
  props: {
    selected: {
      type: Object,
      required: true,
    },
    timeframes: {
      type: Array,
      required: true,
    },
    finalized: {
      type: Boolean,
      required: true,
    },
    dataThroughDate: {
      type: String,
      required: true,
    },
  },
  methods: {
    change(newTimeframe) {
      this.$emit('change', newTimeframe);
    },
  },
};
</script>

<template>
  <div class="timeframeSelector">
    <div>
      <PrepaymentAsOfPill :finalized="finalized" :data-through-date="dataThroughDate" />
    </div>
    <div>
      <TimeframeDropdown :selected="selected" :timeframes="timeframes" @change="change" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.timeframeSelector {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
  }
}

.timeframeSelector > div:first-child {
  padding-top: 0.38rem;
  margin-right: 0;

  @include media-breakpoint-up(lg) {
    padding-top: 0;
    margin-right: 0.75rem;
  }
}
</style>
