<script setup lang="ts">
import type { ChartLegendOptions } from '@console/components/charts/ChartLegendV2.vue';
import type { AwsOrganizationComputeCommitmentBurndown } from '@console/services/api.models';
import type { SeriesAreaOptions, XAxisPlotLinesOptions } from 'highcharts';

import { computed, onMounted, ref } from 'vue';

import * as chartUtilities from '@console/components/charts/utility';
import NumberHelpers from '@shared/utilities/number_helpers';

import ChartLegend from '@console/components/charts/ChartLegendV2.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';

const MS_IN_DAY = 86400000;

const props = defineProps<{
  commitmentBurndown: AwsOrganizationComputeCommitmentBurndown;
  dataThroughDate: string;
}>();

// Provide overrides to specify the exact types that will be used, since Highcharts has more options/types than we
// need, making it difficult to consume
type PlotLineOptions = XAxisPlotLinesOptions & { name: string; color: string };
type SeriesPoint = [number, number | null]; // [X, Y]
// "data" is omitted to allow overriding with SeriesPoint[] (this wasn't working without Omit, probably because data is
// defined as a complex type in SeriesAreaOptions)
type SeriesOptions = Omit<SeriesAreaOptions, 'data'> & { name: string; color: string; data: SeriesPoint[] };

// All of the fields other than the plotlines represent series data (Array<number | null>)
type KeyOfBurndownPlotLines = 'enterprise_discount_program_expiration_date';
type KeyOfBurndownSeries = Exclude<keyof AwsOrganizationComputeCommitmentBurndown, KeyOfBurndownPlotLines>;

interface SeriesDefinition {
  field: KeyOfBurndownSeries;
  name: string;
  color: string;
}
const seriesDefs: SeriesDefinition[] = [
  { field: 'convertible_reserved_instances', name: 'Convertible RIs', color: '#00c58c' },
  { field: 'standard_reserved_instances', name: 'Standard RIs', color: '#fcbe2c' },
  { field: 'compute_savings_plans', name: 'Compute SPs', color: '#5c54ff' },
  { field: 'ec2_instance_savings_plans', name: 'EC2 Instance SPs', color: '#8fffdf' },
];

interface PlotLineDefinition {
  field: KeyOfBurndownPlotLines;
  name: string;
  color: string;
}
const plotLineDefs: PlotLineDefinition[] = [
  { field: 'enterprise_discount_program_expiration_date', name: 'EDP Expiration', color: '#fc5454' },
];

const legend = ref<ChartLegendOptions[]>([]);
const series = ref<SeriesOptions[]>([]);
const plotLines = ref<PlotLineOptions[]>([]);

onMounted(() => {
  const msStart = +new Date(props.dataThroughDate);
  const burndown = props.commitmentBurndown;

  series.value = seriesDefs
    .map(s => {
      const values = burndown?.[s.field] ?? [];
      return {
        ...s,
        type: 'area',
        stacking: 'normal',
        data: values.map((el, i) => [msStart + i * MS_IN_DAY, el]), // Map to [X, Y]
        marker: {
          enabled: false,
        },
        tooltip: {
          valuePrefix: '$',
        },
      } as SeriesOptions;
    })
    // Remove any series without data
    .filter(s => s.data.some(([, y]) => !!y));

  plotLines.value = plotLineDefs
    .map(p => {
      const value = burndown?.[p.field];
      return {
        ...p,
        value: value ? +new Date(value) : null,
        width: 3,
        zIndex: 3,
      };
    })
    // Remove any plotlines without data
    .filter(p => p.value) as PlotLineOptions[];

  // Define the legends based on the series and plotlines that have data
  legend.value = [...series.value, ...plotLines.value].map(x => ({
    label: x.name,
    color: x.color,
    selected: true,
  }));
});

const selectedLegends = computed(() => legend.value.filter(i => i.selected).map(i => i.label));
const filteredPlotlines = computed(() => plotLines.value.filter(p => selectedLegends.value.includes(p.name)));
const filteredSeries = computed(() => series.value.filter(s => selectedLegends.value.includes(s.name)));

const tooltip = chartUtilities.tooltipWithTotal({
  totalLabel: 'Commitment Total',
  valueFormatter: v => NumberHelpers.formatDollars(v, 0),
  xLabelFormatter: x => chartUtilities.dateFormat('%B %d, %Y', x as number),
});

const xAxis = computed(() => ({
  type: 'datetime',
  labels: {
    formatter: chartUtilities.datetimeZoomLabelFormatter,
  },
  tickLength: 0,
  plotLines: filteredPlotlines.value,
  units: chartUtilities.units,
}));

const yAxis = [
  {
    min: 0,
    title: {
      text: null,
    },
    labels: {
      format: chartUtilities.getCurrencyFormat(),
    },
  },
];
</script>

<template>
  <div>
    <ChartLegend v-model:model-value="legend" />
    <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :tooltip="tooltip" :series="filteredSeries" zoomable />
  </div>
</template>
