<script>
export default {
  props: {
    wrapUtility: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<template>
  <div class="d-flex pb-2" :class="{ 'flex-wrap': wrapUtility }">
    <div class="flex-grow-1">
      <slot />
      <div class="subheader">
        <slot name="subheader" />
      </div>
    </div>
    <div class="pt-1">
      <slot name="utility" />
    </div>
  </div>
</template>

<style scoped>
.subheader {
  font-size: 1.1rem;
  font-weight: 400;
}
</style>
