import type {
  CustomerDataExportIamValidationResponse,
  CustomerSettingResponse,
  RegionResponse,
} from '@console/services/api.models';

import { core } from '@shared/services/api';

export enum EditableAwsOrgSettings {
  IsCustomerDataExportEnabled = 'IsCustomerDataExportEnabled',
  CustomerDataExportS3BucketName = 'CustomerDataExportS3BucketName',
}

class AwsService {
  async getRegions() {
    const res = await core.get<Array<RegionResponse>>(`/v1/aws/regions`);
    return res.data;
  }

  async setSetting(awsOrgId: string, settingName: EditableAwsOrgSettings, settingValue: string | number) {
    const res = await core.put<CustomerSettingResponse>(
      `/v1/aws_organizations/${awsOrgId}/settings/${settingName}?value=${encodeURIComponent(settingValue)}`
    );
    return res.data;
  }

  async enableSetting(awsOrgId: string, settingName: EditableAwsOrgSettings) {
    const res = await core.put<CustomerSettingResponse>(`/v1/aws_organizations/${awsOrgId}/settings/${settingName}`);
    return res.data;
  }

  async disableSetting(awsOrgId: string, settingName: EditableAwsOrgSettings) {
    const res = await core.delete<CustomerSettingResponse>(`/v1/aws_organizations/${awsOrgId}/settings/${settingName}`);
    return res.data;
  }

  async getCustomerDataExportIamValidationStatus(awsOrgId: string) {
    const res = await core.get<CustomerDataExportIamValidationResponse>(
      `/v1/aws_organizations/${awsOrgId}/data-export/iam/validate`
    );
    return res.data;
  }

  async validateCustomerDataExportIamPermissions(awsOrgId: string) {
    const res = await core.post<CustomerDataExportIamValidationResponse>(
      `/v1/aws_organizations/${awsOrgId}/data-export/iam/validate`
    );
    return res.data;
  }
}

const awsService = new AwsService();
export default awsService;
