<script>
import { mapGetters } from 'vuex';

import { dateFormat, dateUtc } from '@shared/utilities/filters';
import NumberHelpers from '@shared/utilities/number_helpers';

import Tooltip from '@shared/design/Tooltip.vue';

export default {
  components: {
    Tooltip,
  },
  computed: {
    ...mapGetters('customer', ['incrementalLifetimeSavings', 'lifetimeSavingsDate']),
    hasIncrementalLifetimeSavings() {
      return this.incrementalLifetimeSavings && this.incrementalLifetimeSavings > 0;
    },
    showTooltip() {
      return this.lifetimeSavingsDate;
    },
    incrementalLifetimeSavingsDisplay() {
      return NumberHelpers.formatDollars(this.incrementalLifetimeSavings);
    },
  },
  methods: {
    formatDate(date, format = 'MMMM D, YYYY') {
      return dateFormat(dateUtc(date), format);
    },
  },
};
</script>

<template>
  <div v-if="hasIncrementalLifetimeSavings">
    <div>
      <span id="incrementalLifetimeSavingsTitle" :class="{ 'tooltip-target': showTooltip }"
        >Incremental Lifetime Savings:</span
      >
      <span class="savings">{{ incrementalLifetimeSavingsDisplay }}</span>
      <Tooltip v-if="showTooltip" target="incrementalLifetimeSavingsTitle">
        <div>
          <strong>Incremental Lifetime Savings</strong> includes all savings generated from commitments managed by
          ProsperOps, above and beyond what you would have otherwise saved (assuming you continued achieving your
          baseline Effective Savings Rate), less ProsperOps charges, as of
          {{ formatDate(lifetimeSavingsDate) }}
        </div>
      </Tooltip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.savings {
  margin-left: 0.8rem;
  font-weight: bold;
  color: map-get($theme-colors, 'success');
}
</style>
