<script>
import _ from 'lodash';

import ShowbackTableAggregateRow from '@aws/components/showback/ShowbackTableAggregateRow.vue';
import ShowbackTableResourceRow from '@aws/components/showback/ShowbackTableResourceRow.vue';

export default {
  components: {
    ShowbackTableAggregateRow,
    ShowbackTableResourceRow,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    selectedRows() {
      const rows = _.map(this.rows, r =>
        _.assign({}, r, {
          key: this.resourceKey(r),
          match_key: this.matchKey(r),
        })
      );
      return _.orderBy(rows, ['match_key'], ['asc']);
    },
  },
  methods: {
    onClick() {
      this.expanded = !this.expanded;
    },
    isExpandable(rows) {
      return (
        _.every(rows, r => r.service === 'EC2') ||
        _.every(rows, r => r.service === 'EC2 Unused Capacity Reservations') ||
        _.every(rows, r => r.service === 'EC2 Dedicated Hosts')
      );
    },
    resourceKey(row) {
      return _.join([row.service, row.region, row.platform, row.tenancy, row.instance_type], '|');
    },
    matchKey(row) {
      if (row.service === 'EC2 Dedicated Hosts') {
        return _.join([row.region, row.instance_type], ':');
      }

      return _.join([row.region, row.platform, row.tenancy, row.instance_type], ':');
    },
  },
};
</script>

<template>
  <div v-if="isExpandable(rows)">
    <b-button variant="transparent" class="btn-no-focus-box-shadow w-100 p-0" @click="onClick">
      <ShowbackTableAggregateRow :rows="rows" :level="1" :expanded="expanded">
        <template v-slot:label>
          <slot name="label" />
        </template>
      </ShowbackTableAggregateRow>
    </b-button>
    <b-collapse v-model="expanded">
      <ShowbackTableResourceRow v-for="row in selectedRows" :key="row.key" :row="row">
        <template v-slot:label>
          <div class="text-truncate">
            {{ row.match_key }}
          </div>
        </template>
      </ShowbackTableResourceRow>
    </b-collapse>
  </div>
  <div v-else>
    <ShowbackTableAggregateRow :rows="rows" :level="1">
      <template v-slot:label>
        <slot name="label" />
      </template>
    </ShowbackTableAggregateRow>
  </div>
</template>
