<script>
import { mapActions } from 'vuex';

import { PusherSetup } from '@console/mixins/pusher_setup';

import Poller from '@console/components/Poller.vue';
import SpinnerLogo from '@shared/design/spinners/SpinnerLogo.vue';

export default {
  page: {
    title: 'Getting things ready',
  },
  components: {
    Poller,
    SpinnerLogo,
  },
  mixins: [PusherSetup],
  data() {
    return {
      pollerHandle: null,
      error: false,
    };
  },
  unmounted() {
    this.stopPolling();
  },
  methods: {
    ...mapActions('aws', ['reload']),
    tick(tickCount) {
      if (tickCount === 1) {
        this.fallbackToPolling();
      } else {
        this.fallbackToError();
      }
    },
    fallbackToPolling() {
      this.disconnectPusher();
      this.reload();
      this.pollerHandle = setInterval(() => this.reload(), 25 * 1000);
    },
    fallbackToError() {
      this.disconnectPusher();
      this.stopPolling();
      this.error = true;
    },
    stopPolling() {
      if (this.pollerHandle) {
        clearInterval(this.pollerHandle);
        this.pollerHandle = null;
      }
    },
  },
};
</script>

<template>
  <div class="loadingState">
    <Poller :seconds-interval="300" @tick="tick" />
    <div class="spinner">
      <SpinnerLogo :error="error" />
      <div v-if="error" class="text">
        An unexpected error has occurred while provisioning your organization.
        <br />
        <small>Our team has been notified.</small>
      </div>
      <div v-else class="text">
        Getting things ready...
        <br />
        <small>This should take a minute or two</small>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.spinner {
  padding-bottom: 3rem;
  font-size: 2.5rem;
  font-weight: 600;
}

.text {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

.loadingState {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 60px);
}
</style>
