<script>
import _ from 'lodash';
import Gravatar from 'vue-gravatar';

export default {
  components: {
    'v-gravatar': Gravatar,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    size() {
      return this.small ? 25 : 40;
    },
    style() {
      const sizeInPixels = `${this.size}px`;
      return { width: sizeInPixels, height: sizeInPixels };
    },
    altText() {
      const profile = this.profile;
      const names = _.compact([profile.given_name, profile.family_name]);
      const name = _.join(names, ' ');
      return `${name}'s Profile Image`;
    },
  },
};
</script>

<template>
  <v-gravatar
    :email="profile.email"
    :size="size"
    :default-img="profile.picture"
    :style="style"
    :alt="altText"
    class="rounded-circle"
  />
</template>
