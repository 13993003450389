<script>
export default {
  props: {
    xAxis: {
      type: Object,
      required: true,
    },
    yAxis: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: undefined,
    },
    tooltip: {
      type: Object,
      required: false,
      default: () => ({
        enabled: true,
        borderRadius: 0,
        borderColor: '#adb5bd',
        shared: true,
        shadow: false,
        headerFormat: '<strong style="font-size: 12px">{point.key}</strong> <br />',
      }),
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'scatter',
          backgroundColor: 'transparent',
          height: this.height + 48, // the legend is 48px
        },
        title: null,
        plotOptions: {
          scatter: {
            stickyTracking: false,
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: this.tooltip,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        series: this.series,
      };
    },
  },
};
</script>

<template>
  <div>
    <chart :options="chartOptions" />
  </div>
</template>
