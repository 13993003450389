<script setup>
import { computed } from 'vue';

import ExchangeIcon from '@shared/design/icons/ExchangeIcon.vue';
import MetricPanel from '@shared/design/panels/MetricPanel.vue';

const props = defineProps({
  count: {
    type: Number,
    required: true,
  },
});

const rules = new Intl.PluralRules('en-US');
const names = new Map([
  ['one', 'ProsperOps Portfolio Action'],
  ['other', 'ProsperOps Portfolio Actions'],
]);

const name = computed(() => {
  const rule = rules.select(props.count);
  return names.get(rule);
});
const description = `The number of actions our service has taken to purchase, merge, split, etc. your commitment portfolio. This reflects the amount of work our service has performed to deliver the savings outcome received.`;
</script>

<template>
  <MetricPanel :name="name" :description="description" :icon="ExchangeIcon" color="success">
    <div>{{ props.count }}</div>
  </MetricPanel>
</template>
