<script>
import _ from 'lodash';

import customerService from '@console/services/customerService';
import { core as api } from '@shared/services/api';
import { dateFormat, dateUtc } from '@shared/utilities/filters';
import NumberHelpers from '@shared/utilities/number_helpers';

import Layout from '@console/Layout.vue';
import Currency from '@shared/components/Currency.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import BaseIcon from '@shared/design/icons/BaseIcon.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import Panel from '@shared/design/panels/Panel.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';
import TextInput from '@shared/design/TextInput.vue';

export default {
  components: {
    BoxMessage,
    Currency,
    TextInput,
    Layout,
    PageHeader,
    Panel,
    PanelSection,
    BaseIcon,
  },
  data() {
    return {
      submitError: false,
      submitSuccess: false,
      submitting: false,
      subscription: null,
      portal: null,
      settings: null,
      emails: null,
      invoices: null,
      isDownloadingInvoice: {},
    };
  },
  head: {
    title: 'Billing',
  },
  computed: {
    isLoading() {
      const data = [this.subscription, this.portal, this.settings];
      return _.some(data, _.isNull);
    },
    canUpdateSettings() {
      return !_.get(this.settings, 'block_settings_changes', false);
    },
    emailsUpdated() {
      return this.emails !== _.get(this.settings, 'additional_emails');
    },
    isAwsMarketplace() {
      return _.get(this.settings, 'aws_marketplace_customer', false);
    },
    hasOfferingDetails() {
      return _.get(this.subscription, 'offering_details', []).length > 0;
    },
  },
  async mounted() {
    try {
      const responses = await Promise.all([
        customerService.getSubscription(),
        customerService.getBillingPortal(),
        customerService.getBillingSettings(),
        customerService.getInvoices(),
      ]);
      const [subscription, portal, settings, invoicesResponse] = _.map(responses, _.property('data'));
      this.subscription = subscription;
      this.portal = portal;
      this.settings = settings;
      this.emails = settings.additional_emails || '';
      this.invoices = invoicesResponse.invoices;
    } catch (error) {
      await this.$router.push({ name: 'error' });
      throw error;
    }
  },
  methods: {
    formatDate(date, format = 'MMMM DD, YYYY') {
      return dateFormat(dateUtc(date), format);
    },
    formatDollars(amount) {
      return NumberHelpers.formatDollars(amount);
    },
    downloadPdf(pdfLink, invoiceNumber) {
      this.isDownloadingInvoice[invoiceNumber] = true;
      api
        .get(pdfLink, {
          responseType: 'blob',
          headers: { Accept: 'application/pdf', 'Content-Type': 'application/pdf' },
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `ProsperOps-Invoice-${invoiceNumber}.pdf`);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          this.isDownloadingInvoice[invoiceNumber] = false;
        });
    },
    openBillingPortal() {
      window.open(this.portal.url, '_blank');
    },
    async submit() {
      try {
        this.submitError = false;
        this.submitSuccess = false;
        this.submitting = true;
        const response = await customerService.updateBillingSettings(this.emails);
        this.settings = response.data;
        this.submitting = false;
        this.submitSuccess = true;
      } catch (e) {
        this.submitting = false;
        const statusCode = _.get(e, 'response.status', 500);
        if (statusCode === 400) {
          this.submitError = true;
        } else {
          throw e;
        }
      }
    },
  },
};
</script>

<template>
  <Layout :loading="isLoading">
    <template #default>
      <BoxMessage v-if="submitError" type="error" class="pb-4">
        One or more of the additional email addresses provided is not valid.
      </BoxMessage>
      <BoxMessage v-if="submitSuccess" type="success" class="pb-4">
        Your billing settings have been updated successfully.
      </BoxMessage>
      <PageHeader>
        <h1>Billing</h1>
      </PageHeader>
      <template v-if="isAwsMarketplace">
        <div class="row sectional">
          <div class="col">
            <PanelSection header="AWS Marketplace">
              <p class="text-center">
                Your ProsperOps subscription is billed via the AWS Marketplace. View your Billing information in the
                <a href="https://console.aws.amazon.com/billing/home?#/bills" target="_blank">AWS Console</a>.
              </p>
            </PanelSection>
          </div>
        </div>
      </template>
      <div class="row sectional">
        <div v-if="!isAwsMarketplace" class="col-xl-auto">
          <PanelSection header="Current Balance" class="currentBalance" center-content>
            <div>
              <Currency v-if="subscription" :value="subscription.balance_in_cents" from-cents />
            </div>
          </PanelSection>
        </div>
        <div class="col">
          <PanelSection header="Subscription Summary">
            <template v-if="hasOfferingDetails">
              <p>You currently have an active ProsperOps subscription and will be billed:</p>
              <ul>
                <li v-for="offeringDetail in subscription.offering_details" :key="offeringDetail.offering">
                  {{ offeringDetail.offering_friendly_name }}
                  <ul>
                    <li v-for="pricingDescription in offeringDetail.pricing_descriptions" :key="pricingDescription">
                      {{ pricingDescription }}
                    </li>
                  </ul>
                </li>
              </ul>
            </template>
            <template v-else>
              <p class="text-center">Your ProsperOps subscription details will be available shortly.</p>
            </template>
          </PanelSection>
        </div>
      </div>
      <template v-if="isAwsMarketplace">
        <div class="row sectional">
          <div class="col">
            <PanelSection header="Invoices">
              <BoxMessage type="warning" class="pt-2 pb-4" border-variant="outlined">
                <strong>Please do not pay these invoices directly.</strong>
                <p>
                  Your ProsperOps subscription is billed via the AWS Marketplace. The charges shown here will appear on
                  your AWS invoices.
                </p>
              </BoxMessage>
              <table class="table table-striped invoicesTable">
                <thead>
                  <tr class="invoicesHeader">
                    <th>Date Issued</th>
                    <th>Invoice Number</th>
                    <th>Invoice Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="invoice in invoices" :key="invoice.invoice_number">
                    <td>{{ formatDate(invoice.issue_date) }}</td>
                    <td>#{{ invoice.invoice_number }}</td>
                    <td>{{ formatDollars(invoice.total_amount) }}</td>
                    <!-- Specific width to prevent table from changing size when spinner shows up -->
                    <td style="width: 200px">
                      <div class="centerContent">
                        <button
                          class="btn btn-link shadow-none"
                          :disabled="isDownloadingInvoice[invoice.invoice_number]"
                          @click="downloadPdf(invoice.pdf_link, invoice.invoice_number)"
                        >
                          <BaseIcon name="download" class="mr-2" /> Download PDF
                        </button>
                        <div
                          v-if="isDownloadingInvoice[invoice.invoice_number]"
                          class="spinner-border spinner-border-sm text-secondary"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </PanelSection>
          </div>
        </div>
      </template>
      <template v-if="!isAwsMarketplace">
        <div v-if="portal && portal.url" class="row sectional">
          <div class="col">
            <PanelSection header="Invoices and Payment Details">
              <p class="text-center">
                Access our Billing Portal to view your invoices and update your payment information.
              </p>
              <p class="text-center">
                <form-submit-button variant="primary" class="rounded-sm" @click="openBillingPortal">
                  Open Billing Portal
                  <BaseIcon name="external-link-alt" class="ml-2" />
                </form-submit-button>
              </p>
            </PanelSection>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <div class="pt-1 pb-1">
              <h2>Settings</h2>
            </div>
            <form v-if="canUpdateSettings" @submit.prevent="submit">
              <label for="settings" class="d-block"> Send copies of billing emails to </label>
              <TextInput
                id="settings"
                v-model="emails"
                class="w-100"
                placeholder="Comma-separated list; all users in the Owner role receive billing emails automatically"
              />
              <div>
                <form-submit-button
                  variant="primary"
                  type="submit"
                  :disabled="!emailsUpdated"
                  :loading="submitting"
                  class="rounded-sm mt-2"
                >
                  Update Settings
                  <template v-slot:loading> Updating... </template>
                </form-submit-button>
              </div>
            </form>
            <Panel v-else>
              <p class="mb-0">
                Your account is currently configured with custom billing settings. If you would like to update the email
                address(es) that receive billing emails, please
                <router-link :to="{ name: 'help' }">contact us</router-link>.
              </p>
            </Panel>
          </div>
        </div>
      </template>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.currentBalance {
  min-width: 300px;
  min-height: 200px;
  font-size: 2rem;
}

.invoicesTable {
  tbody td {
    align-content: center;
  }
}

.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
