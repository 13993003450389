<script>
import { mapGetters } from 'vuex';

import { dateFormat, dateUtc } from '@shared/utilities/filters';
import NumberHelpers from '@shared/utilities/number_helpers';

import Tooltip from '@shared/design/Tooltip.vue';

export default {
  components: {
    Tooltip,
  },
  computed: {
    ...mapGetters('customer', ['lifetimeSavings', 'lifetimeSavingsDate']),
    hasLifetimeSavings() {
      return this.lifetimeSavings && this.lifetimeSavings > 0;
    },
    showTooltip() {
      return this.lifetimeSavingsDate;
    },
    lifetimeSavingsDisplay() {
      return NumberHelpers.formatDollars(this.lifetimeSavings);
    },
  },
  methods: {
    formatDate(date, format = 'MMMM D, YYYY') {
      return dateFormat(dateUtc(date), format);
    },
  },
};
</script>

<template>
  <div v-if="hasLifetimeSavings">
    <div>
      <span id="lifetimeSavingsTitle" :class="{ 'tooltip-target': showTooltip }">Lifetime Savings:</span>
      <span class="savings">{{ lifetimeSavingsDisplay }}</span>
      <Tooltip v-if="showTooltip" target="lifetimeSavingsTitle">
        <div>
          <strong>Lifetime Savings</strong> includes all savings generated from commitments managed by ProsperOps, less
          ProsperOps charges, as of
          {{ formatDate(lifetimeSavingsDate) }}
        </div>
      </Tooltip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.savings {
  margin-left: 0.8rem;
  font-weight: bold;
  color: map-get($theme-colors, 'success');
}
</style>
