<script>
import _ from 'lodash';

import SectionChart from '@console/components/charts/SectionChart.vue';
import SectionTable from '@console/components/charts/SectionTable.vue';

const sortOrder = [
  'convertible_reserved_instances',
  'compute_savings_plans',
  'standard_reserved_instances',
  'ec2_instance_savings_plans',
];

export default {
  components: {
    SectionChart,
    SectionTable,
  },
  props: {
    totalRemainingCommitment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      colors: {
        convertible_reserved_instances: '#00c58c',
        compute_savings_plans: '#5c54ff',
        standard_reserved_instances: '#fcbe2c',
        ec2_instance_savings_plans: '#8fffdf',
      },
      labels: {
        convertible_reserved_instances: 'Convertible RIs',
        compute_savings_plans: 'Compute SPs',
        standard_reserved_instances: 'Standard RIs',
        ec2_instance_savings_plans: 'EC2 Instance SPs',
      },
    };
  },
  computed: {
    sections() {
      const tofilter = ['overall_dollars'];
      const commitments = this.totalRemainingCommitment;
      const keys = _.map(commitments, (value, key) => key);
      const filtered = _.filter(keys, key => !_.includes(tofilter, key));
      const removedollar = key => _.replace(key, '_dollars', '');
      const removepercentage = key => _.replace(key, '_percentage', '');
      const trimmed = _.map(filtered, _.flow([removedollar, removepercentage]));
      const uniq = _.uniq(trimmed);
      const sorted = _.orderBy(uniq, u => _.indexOf(sortOrder, u));
      return _.map(sorted, key => this.sectionElement(key));
    },
    total() {
      const commitment = this.totalRemainingCommitment;
      const dollars = key => _.get(commitment, `${key}_dollars`);
      return {
        key: 'Total Commitment',
        value: 100,
        amount: dollars('overall'),
      };
    },
  },
  methods: {
    sectionElement(key) {
      const commitment = this.totalRemainingCommitment;
      const color = this.colors[key];
      const label = this.labels[key];
      const percentage = key => _.get(commitment, `${key}_percentage`);
      const dollars = key => _.get(commitment, `${key}_dollars`);
      return {
        key: label,
        color: color,
        value: percentage(key),
        amount: dollars(key),
      };
    },
  },
};
</script>

<template>
  <div class="totalRemainingCommitment">
    <div class="d-flex justify-content-center pt-5">
      <SectionChart :sections="sections" :thumb="{ visible: false }" />
    </div>
    <SectionTable class="mt-2" :sections="sections" :total="total" />
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.totalRemainingCommitment {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}

.totalRemainingCommitment > div:nth-child(1),
.totalRemainingCommitment > div:nth-child(2) {
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  @include media-breakpoint-up(lg) {
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
</style>
