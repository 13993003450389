<script>
import Currency from '@shared/components/Currency.vue';
import Dash from '@shared/design/Dash.vue';
import Tooltip from '@shared/design/Tooltip.vue';

export default {
  components: {
    Currency,
    Dash,
    Tooltip,
  },
  props: {
    budget: {
      type: Object,
      required: true,
    },
    wide: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    formatQuarter(quarter) {
      return `Q${quarter.quarter_number} ${quarter.year}`;
    },
  },
};
</script>

<template>
  <table class="table prepayment" :class="{ wide: wide }">
    <thead>
      <tr class="tableHeader">
        <th></th>
        <th v-for="(value, i) in budget" :key="'quarter_' + i" class="text-uppercase text-muted font-weight-normal">
          {{ formatQuarter(value) }}
        </th>
      </tr>
    </thead>
    <tbody class="tableBody">
      <tr>
        <th class="font-weight-bold">
          <div>Approved Budget</div>
        </th>
        <td v-for="(value, i) in budget" :key="'approved_' + i" class="font-weight-bold">
          <Dash :value="value.budget" on-zero>
            <Currency :value="value.budget" :decimals="0" />
          </Dash>
        </td>
      </tr>
      <tr>
        <th>
          <div>Deployed</div>
        </th>
        <td v-for="(value, i) in budget" :key="'deployed_' + i">
          <template v-if="value.spent_monthly_all_upfront_flex >= 0.5 || value.spent_optimization >= 0.5">
            <span :id="'deployedAmount_' + i" class="tooltip-target">
              <Dash :value="value.spent" on-zero>
                <Currency :value="value.spent" :decimals="0" />
              </Dash>
            </span>
            <Tooltip :target="'deployedAmount_' + i">
              Additional prepayment was deployed as follows:
              <br /><br />
              <template v-if="value.spent_monthly_all_upfront_flex >= 0.5">
                &nbsp;- Monthly All Upfront Flex:
                <Currency :value="value.spent_monthly_all_upfront_flex" :decimals="0" />
                <br />
              </template>
              <template v-if="value.spent_optimization >= 0.5">
                &nbsp;- Optimization:
                <Currency :value="value.spent_optimization" :decimals="0" />
                <br />
              </template>
              <br />
              This prepayment is not included in the Deployed amount because it is not governed by the prepayment
              budget.
            </Tooltip>
          </template>
          <template v-else>
            <Dash :value="value.spent" on-zero>
              <Currency :value="value.spent" :decimals="0" />
            </Dash>
          </template>
        </td>
      </tr>
      <tr>
        <th>
          <div>Remaining</div>
        </th>
        <td v-for="(value, i) in budget" :key="'remaining_' + i">
          <Dash :value="value.remaining" on-zero>
            <Currency :value="value.remaining" :decimals="0" />
          </Dash>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

table.prepayment {
  max-width: 750px;
  th,
  td {
    padding: 6px;
  }

  @include media-breakpoint-up(md) {
    th {
      padding: 12px;
    }
    td {
      padding: 18px;
    }
  }
}

.prepayment.wide {
  max-width: 100%;
}

.tableHeader th {
  text-align: right;
  background-color: $table-head-background;
  border-top: 0;
  border-bottom: 0;
}

.tableBody > tr > td,
.tableBody > tr > th {
  font-weight: 400;
}

.tableBody > tr:not(:last-child) > td,
.tableBody > tr:not(:last-child) > th {
  border-top: none;
}

.tableBody td {
  text-align: right;
}

.tableBody td > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
</style>
