<script setup lang="ts">
import { computed } from 'vue';

export type ChartLegendOptions = {
  label: string;
  color: string;
  selected: boolean;
};

export type ChartLegendProps = {
  // TODO: update any place that uses this component to use v-model instead of v-model:model-value once we are not longer in compatibility mode
  // see: https://v3-migration.vuejs.org/breaking-changes/v-model
  modelValue: Array<ChartLegendOptions>;
};

const props = defineProps<ChartLegendProps>();
const emit = defineEmits<{
  (event: 'update:modelValue', options: Array<ChartLegendOptions>): void;
}>();

const selected = computed(() => {
  return props.modelValue.filter(i => i.selected);
});

const click = (item: ChartLegendOptions) => {
  if (selected.value.length === 1 && item.selected) {
    return;
  }
  item.selected = !item.selected;
  emit('update:modelValue', props.modelValue);
};

const classes = (item: ChartLegendOptions) => {
  const base = ['legendItem'];
  if (!item.selected) {
    return [...base, 'clickable', 'notSelected'];
  } else if (selected.value.length === 1) {
    return [...base, 'notClickable'];
  } else {
    return [...base, 'clickable'];
  }
};
</script>

<template>
  <div class="legend">
    <div v-for="item in modelValue" :key="item.label" :class="classes(item)" @click="click(item)">
      <div :style="{ 'background-color': item.color }"></div>
      <div class="chartLegendText">{{ item.label }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.legend {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.legendItem {
  display: flex;
  align-items: center;
  margin-right: 1.2rem;
}

.legendItem div:first-child {
  width: 20px;
  height: 10px;
  margin-right: 0.5rem;
  border-radius: 8px;
}

.legendItem div:last-child {
  font-size: 0.8rem;
}

.clickable {
  cursor: pointer;
}

.notClickable {
  cursor: not-allowed;
}

.notSelected {
  opacity: 0.5;
}

.chartLegendText {
  max-width: 275px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include media-breakpoint-up(md) {
    max-width: 100%;
  }
}
</style>
