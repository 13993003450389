<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';

import customerService from '@console/services/customerService';

import ErrorMessageByCode from '@aws/components/accounts/ErrorMessageByCode.vue';
import SecurityFooter from '@aws/components/accounts/SecurityFooter.vue';
import SetupDirections from '@aws/components/accounts/SetupDirections.vue';
import Layout from '@console/Layout.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import PageHeader from '@shared/design/PageHeader.vue';

export default {
  components: {
    Layout,
    PageHeader,
    SetupDirections,
    SecurityFooter,
    BoxMessage,
    ErrorMessageByCode,
  },
  data() {
    return {
      iamDetails: null,
      validating: false,
      errorCode: null,
      errorCollection: null,
    };
  },
  head: {
    title: 'AWS Account',
  },
  computed: {
    ...mapState('aws', ['awsAccounts']),
    ...mapGetters('aws', ['selectedOrganizationHasOnboarded']),
    subheader() {
      const awsAccount = this.selectedAwsAccount;
      return `${awsAccount.friendly_name} (${awsAccount.aws_account_number})`;
    },
    selectedAwsAccount() {
      return _.find(this.awsAccounts, a => a.id === this.$route.params.awsAccountId);
    },
    selectedAwsAccountIsActive() {
      const status = _.get(this.selectedAwsAccount, 'status');
      const activeStatuses = ['Active', 'Ignored', 'ResellerEndCustomer', 'NoAccessRequired'];
      return _.includes(activeStatuses, status);
    },
    accountListRoute() {
      return this.selectedOrganizationHasOnboarded ? { name: 'aws_accounts' } : { name: 'aws_onboarding' };
    },
  },
  async mounted() {
    try {
      this.iamDetails = await customerService.getIamDetails(this.$route.params.awsAccountId).then(_.property('data'));
    } catch (e) {
      await this.$router.push({ name: 'error' });
      throw e;
    }
  },
  methods: {
    ...mapActions('aws', ['loadAwsAccounts']),
    async validateAccessClicked() {
      try {
        this.validating = true;
        this.errorCode = null;
        this.errorCollection = null;
        const awsAccountId = this.$route.params.awsAccountId;
        await customerService.validateAccess(awsAccountId);
        await this.loadAwsAccounts();
        await this.$router.push(this.accountListRoute);
      } catch (e) {
        this.validating = false;
        this.handleError(e);
      }
    },
    handleError(e) {
      const status = _.get(e, 'response.status', 500);
      const code = _.get(e, 'response.data.code');
      const errors = _.get(e, 'response.data.errors', null);
      if (status >= 500) {
        throw e;
      }
      if (code === 'General') {
        throw e;
      }
      this.errorCode = code;
      this.errorCollection = errors;
    },
  },
};
</script>

<template>
  <Layout :loading="!iamDetails" with-footer>
    <template #default>
      <div class="row pb-3">
        <div class="col">
          <router-link :to="{ name: 'aws_accounts' }">
            <BaseIcon name="arrow-left" class="mr-1" />
            Back to AWS Accounts List
          </router-link>
        </div>
      </div>
      <PageHeader>
        <h1>AWS Account Setup</h1>
        <template v-slot:subheader>
          {{ subheader }}
          <span
            v-if="selectedAwsAccount.aws_partition === 'aws-us-gov'"
            class="badge badge-pill badge-info ml-2 pl-2 pr-2 pt-1 pb-1 align-text-top"
            >GovCloud</span
          >
        </template>
      </PageHeader>
      <div v-if="iamDetails">
        <BoxMessage v-if="selectedAwsAccountIsActive" type="success" class="pt-4 pb-2">
          <strong>This AWS Account is fully configured!</strong>
          <p class="mb-0">
            We have confirmed that the ProsperOps role is configured correctly and no further action is necessary.
          </p>
        </BoxMessage>
        <BoxMessage v-else type="info" class="pt-4 pb-2">
          <p class="mb-0">
            ProsperOps access is granted via an IAM role. To make configuration simple, customized
            scripts/templates/instructions are provided below. Please choose whichever method is best for you. Once the
            IAM role has been configured, click the
            <strong>Validate Access</strong> button to continue.
          </p>
        </BoxMessage>
        <BoxMessage v-if="errorCode" type="error" class="pt-4 pb-2">
          <ErrorMessageByCode :code="errorCode" :errors="errorCollection" />
        </BoxMessage>
        <div class="row pt-2">
          <div class="col">
            <SetupDirections
              :iam-details="iamDetails"
              :aws-account-number="selectedAwsAccount.aws_account_number"
              :disabled="selectedAwsAccountIsActive"
            />
          </div>
        </div>
        <div v-if="!selectedAwsAccountIsActive" class="row pt-2">
          <div class="col pt-3 pb-3 d-flex flex-row-reverse">
            <form-submit-button
              variant="primary"
              type="submit"
              :loading="validating"
              class="rounded-sm"
              @click="validateAccessClicked"
            >
              Validate Access
              <template v-slot:loading> Validating... </template>
            </form-submit-button>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <SecurityFooter />
    </template>
  </Layout>
</template>
