<script>
import SpinnerLogo from '@shared/design/spinners/SpinnerLogo.vue';

export default {
  components: {
    SpinnerLogo,
  },
};
</script>

<template>
  <div class="loadingState">
    <div>
      <SpinnerLogo />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loadingState {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 60px);
}
</style>
