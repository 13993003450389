import moment from 'moment';

export function formatDate(date: moment.MomentInput) {
  return moment(date).format('MMMM D, YYYY h:mm:ss A');
}

export function formatDateDateOnly(date: moment.MomentInput) {
  return moment(date).format('MMMM D, YYYY');
}

export function formatDateNoLocalization(date: moment.MomentInput) {
  return moment.utc(date).format('MMMM D, YYYY h:mm:ss A');
}

export function formatDateDateOnlyNoLocalization(date: moment.MomentInput) {
  return moment.utc(date).format('MMMM D, YYYY');
}

export function getSystemDateOnlyNoLocalization(date: moment.MomentInput) {
  return moment.utc(date).format('YYYY-MM-DD');
}

export function getSystemTimeOnlyNoLocalization(date: moment.MomentInput) {
  return moment.utc(date).format('hh:mm:ss');
}

export function getTimezone(date: moment.MomentInput) {
  return 'UTC' + moment(date).format('Z');
}

export function currentlyActive(startDate: moment.MomentInput, endDate: moment.MomentInput) {
  return moment().isBetween(startDate, endDate, null, '(]');
}

export function formatDateMonthAndYearOnly(date: moment.MomentInput) {
  return moment.utc(date).format('MMMM YYYY');
}

export function getCurrentYear() {
  return moment().format('YYYY');
}

export function formatDateWithRelative(date: moment.MomentInput) {
  // Customizes time thresholds to display hours until over 7 days
  moment.relativeTimeThreshold('m', 60);
  moment.relativeTimeThreshold('h', 24 * 7);

  return moment(date).format('MMMM D, YYYY h:mm:ss A') + ' (' + moment(date).fromNow() + ')';
}

export function toUtc(date: moment.MomentInput) {
  return moment(date).utc().format();
}

export function getCurrentQuarter() {
  const quarter = moment(new Date()).startOf('quarter');

  return [quarter.quarter(), quarter.year()];
}

export function getNextQuarter() {
  const quarter = moment(new Date()).endOf('quarter').add(1, 'days').startOf('quarter');

  return [quarter.quarter(), quarter.year()];
}

export function daysToMonths(days: number) {
  const start = moment();
  const end = moment().add(days, 'days');

  const duration = moment.duration(end.diff(start));
  return duration.asMonths();
}
