<script>
import Layout from '@console/Layout.vue';

export default {
  components: {
    Layout,
  },
  head: {
    title: '404 - Page Not Found',
  },
};
</script>

<template>
  <Layout wide>
    <template #default>
      <div class="announcement pt-4">
        <div class="pt-2 pb-2 text-center">
          <h1 class="display-4">404 - Page Not Found</h1>
        </div>
        <p class="lead text-center mb-0 w-50">
          We're sorry, but the page that you requested could not be found. <br />
          <router-link :to="{ name: 'root' }">Return to the console</router-link>
        </p>
      </div>
    </template>
  </Layout>
</template>

<style scoped>
.announcement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75%;
}
</style>
