import type { AwsAccount, AwsAccountStatus } from "@console/services/api.models";
import type { BootstrapVariant } from "@shared/design/bootstrap";

const statusMappings: Record<AwsAccountStatus, { variant: BootstrapVariant, label: string }> = {
  PendingIamSetup: { variant: 'warning', label: 'Pending Setup' },

  // Show all Provisioning statuses as Provisioning to simplify things for the user
  AwaitingProvisioning: { variant: 'info', label: 'Provisioning' },
  Provisioning: { variant: 'info', label: 'Provisioning' },
  ProvisioningError: { variant: 'info', label: 'Provisioning' },

  AccessError: { variant: 'danger', label: 'Access Error' },
  Active: { variant: 'success', label: 'Active' },
  ResellerEndCustomer: { variant: 'info', label: 'End Customer' },
  NoAccessRequired: { variant: 'info', label: 'No Access Required' },
  Ignored: { variant: 'light', label: 'Ignored' },
} as const;

const statusPriorities: Record<AwsAccountStatus, number> = {
  AccessError: 1,
  PendingIamSetup: 2,

  // The Provisioning statuses are all reported as Provisioning, so group together
  AwaitingProvisioning: 3,
  Provisioning: 3,
  ProvisioningError: 3,

  Active: 5,
  ResellerEndCustomer: 6,
  NoAccessRequired: 7,
  Ignored: 8,
} as const;

const getStatusLabel = (status: AwsAccountStatus) => statusMappings[status].label ?? status;
const getStatusVariant = (status: AwsAccountStatus) => statusMappings[status].variant ?? 'primary';
const getStatusPriority = (status: AwsAccountStatus) => statusPriorities[status] ?? 999;

/**
 * Gets the worst status from the list of statuses, generally statuses requiring User Action > System Action > No
 * Action. This is useful when showing a single status to represent multiple.
 */
const getWorstStatus = (statuses: AwsAccountStatus[]): AwsAccountStatus => {
  return statuses
    .reduce((worstStatus, status) => {
      const isNewWorst = !worstStatus || getStatusPriority(status) < getStatusPriority(worstStatus);
      return isNewWorst ? status : worstStatus;
    });
}

const getWorstStatusForAccount = (account: AwsAccount): AwsAccountStatus =>
  getWorstStatus(Object.values(account.status_by_offering));

export default {
  getStatusLabel,
  getStatusVariant,
  getStatusPriority,
  getWorstStatus,
  getWorstStatusForAccount,
}
