<template>
  <svg
    enable-background="new 0 0 16 16"
    version="1.1"
    viewBox="0 0 16 16"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="currentColor">
      <path
        d="m8 15.95c-4.32 0-7.84-3.52-7.84-7.84s3.52-7.84 7.84-7.84c1.54 0 3.02 0.44 4.3 1.29 0.39 0.26 0.5 0.79 0.24 1.18s-0.79 0.5-1.18 0.24c-0.99-0.67-2.16-1.01-3.36-1.01-3.39 0-6.14 2.76-6.14 6.14 0 3.39 2.76 6.14 6.14 6.14 1.57 0 3.06-0.59 4.2-1.66 1.25-1.17 1.94-2.77 1.94-4.48 0-0.47 0.38-0.85 0.85-0.85s0.85 0.38 0.85 0.85c0 2.16-0.9 4.25-2.48 5.72-1.46 1.37-3.36 2.12-5.36 2.12z"
      />
      <path
        d="m15.15 4.01c0.09 0.34-0.14 0.68-0.49 0.72l-2.11 0.24-1.53 0.17c-0.49 0.06-0.82-0.49-0.54-0.9l1.34-1.97 1.34-1.97c0.28-0.41 0.9-0.3 1.03 0.17l0.4 1.48 0.56 2.06z"
      />
      <path
        d="m6.77 10.45c0.22 0 0.44-0.08 0.6-0.25l1.64-1.64v-3.91c0-0.47-0.38-0.85-0.85-0.85s-0.85 0.38-0.85 0.85v3.22l-1.14 1.13c-0.33 0.33-0.33 0.87 0 1.2 0.16 0.17 0.38 0.25 0.6 0.25z"
      />
      <path
        class="st0"
        d="m61.09 43.04c0 2.01-0.85 3.83-2.21 5.1-1.25 1.17-2.93 1.89-4.78 1.89-3.86 0-6.99-3.13-6.99-6.99s3.13-6.99 6.99-6.99c1.42 0 2.74 0.42 3.84 1.15"
      />
      <path
        d="m61.25 38.94c0.09 0.34-0.14 0.68-0.49 0.72l-2.11 0.24-1.53 0.17c-0.49 0.06-0.82-0.49-0.54-0.9l1.34-1.97 1.34-1.97c0.28-0.41 0.9-0.3 1.03 0.17l0.4 1.48 0.56 2.06z"
      />
      <polyline class="st0" points="54.09 39.5 54.09 43.07 55.48 44.46" />
    </g>
  </svg>
</template>
