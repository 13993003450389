<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    canRemove: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isPendingEmailVerification() {
      return this.user.user_status === 'PendingEmailValidation';
    },
  },
};
</script>

<template>
  <b-dropdown variant="outline-info" toggle-class="rounded-sm btn-no-focus-box-shadow" no-caret right>
    <template #button-content> Actions <BaseIcon name="angle-down" class="ml-1" /> </template>
    <b-dropdown-item v-if="isPendingEmailVerification" @click.prevent="$emit('resend-verification', user)">
      <div class="pt-2 pb-2">
        <BaseIcon name="envelope" class="mr-1 text-secondary menuIcon" />
        Resend Verification Email
      </div>
    </b-dropdown-item>
    <b-dropdown-item @click.prevent="$emit('update-role', user)">
      <div class="pt-2 pb-2">
        <BaseIcon name="user-edit" class="mr-1 text-secondary menuIcon" />
        Update Role
      </div>
    </b-dropdown-item>
    <b-dropdown-item v-if="canRemove" @click.prevent="$emit('remove-user', user)">
      <div class="pt-2 pb-2">
        <BaseIcon name="user-minus" class="mr-1 text-secondary menuIcon" />
        Remove User
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<style lang="scss" scoped>
.menuIcon {
  width: 20px;
  margin-left: -10px;
}
</style>
