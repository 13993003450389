import type {
  AwsOrganizationUsageResponse,
  CustomerDataExportIamValidationResponse,
} from '@console/services/api.models';

import { defineStore } from 'pinia';

import customerService from '@console/services/customerService';
import { store as vuexStore } from '@console/state/vuex/store';

type AwsOrganizationId = string;

type AwsOrganizationState = {
  usage?: AwsOrganizationUsageResponse;
};

export type AwsState = {
  organizations: Map<AwsOrganizationId, AwsOrganizationState>;
  customerDataExportValidations: { [key: AwsOrganizationId]: CustomerDataExportIamValidationResponse };
};

export const useAwsStore = defineStore('aws', {
  state: (): AwsState => ({
    organizations: new Map<AwsOrganizationId, AwsOrganizationState>(),
    customerDataExportValidations: {},
  }),
  getters: {
    selectedOrganizationId(): string | undefined {
      return vuexStore.getters['aws/selectedOrganizationId'];
    },
    organization(): AwsOrganizationState | undefined {
      if (!this.selectedOrganizationId) return;
      return this.organizations.get(this.selectedOrganizationId);
    },
    usage(): AwsOrganizationUsageResponse | undefined {
      return this.organization?.usage;
    },
  },
  actions: {
    async loadOrganization() {
      if (!this.selectedOrganizationId) return;
      if (this.organization) return;

      const usageResponse = await customerService.getAwsServiceUsage(this.selectedOrganizationId);
      this.organizations.set(this.selectedOrganizationId, {
        usage: usageResponse?.data,
      });
    },
    setCustomerDataExportValidation(awsOrgId: string, validation: CustomerDataExportIamValidationResponse) {
      this.customerDataExportValidations[awsOrgId] = validation;
    },
  },
});
