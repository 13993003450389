<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import awsService from '@aws/services/awsService';
import { useAwsStore } from '@aws/stores/aws.store';
import { CustomerDataExportValidationStatus } from '@console/services/api.models';
import { useVuexStore } from '@console/state/vuex/store';

import CustomerDataExportS3BucketName from './CustomerDataExportS3BucketName.vue';
import DataExportStatus from './DataExportStatus.vue';
import DataExportToggle from './DataExportToggle.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const vuexStore = useVuexStore();
const awsStore = useAwsStore();

const isLoading = ref(false);

const isCustomerDataExportEnabled = computed<boolean>(() => {
  const isEnabled = vuexStore.getters['aws/selectedOrganizationSettings']?.is_customer_data_export_enabled ?? false;
  const hasS3BucketNameForExport = !!vuexStore.getters['aws/selectedOrganizationSettings']
    ?.customer_data_export_s3_bucket_name;
  return isEnabled && hasS3BucketNameForExport;
});
const showValidationWarning = computed<boolean>(() => {
  return (
    awsStore.customerDataExportValidations[vuexStore.getters['aws/selectedOrganizationId']]?.status_code !==
      CustomerDataExportValidationStatus.Enabled && isCustomerDataExportEnabled.value
  );
});

onMounted(async () => {
  if (!isCustomerDataExportEnabled.value) {
    return;
  }

  isLoading.value = true;

  const validation = await awsService.getCustomerDataExportIamValidationStatus(
    vuexStore.getters['aws/selectedOrganizationId']
  );
  awsStore.setCustomerDataExportValidation(vuexStore.getters['aws/selectedOrganizationId'], validation);

  isLoading.value = false;
});
</script>

<template>
  <PanelSection header="Data Export" rounded new-header class="settingsConstrainedWidth">
    <BoxMessage type="info" class="pt-2 pb-4" border-variant="outlined">
      <p>ProsperOps data exports require an S3 bucket in the management account of your AWS Organization.</p>
      <p>
        Create an S3 bucket in your management account, specify the bucket name in “S3 Bucket Name” below, then select
        “Setup” to update your ProsperOps IAM role with permissions to write to that bucket.
      </p>
      <p>After access is validated, ProsperOps will export data to the bucket on a daily cadence.</p>
    </BoxMessage>
    <BoxMessage v-if="showValidationWarning" type="warning" class="pt-2 pb-4" border-variant="outlined">
      <p>Your chosen S3 bucket is pending validation. Exports will not process until you validate it below.</p>
    </BoxMessage>

    <DataExportToggle :is-loading="isLoading" @on-toggle-loading="(loading: boolean) => (isLoading = loading)" />

    <CustomerDataExportS3BucketName
      :is-loading="isLoading"
      @on-toggle-loading="(loading: boolean) => (isLoading = loading)"
    />

    <DataExportStatus :is-loading="isLoading" @on-toggle-loading="(loading: boolean) => (isLoading = loading)" />
  </PanelSection>
</template>

<style lang="scss" scoped>
.settingsConstrainedWidth {
  max-width: 850px;

  :deep(section) {
    padding: 0.5rem 1.5rem 2rem;
  }
}
</style>
