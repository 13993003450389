<script>
import BackgroundDonuts from '@shared/design/BackgroundDonuts.vue';
import SpinnerLogo from '@shared/design/spinners/SpinnerLogo.vue';

export default {
  components: {
    BackgroundDonuts,
    SpinnerLogo,
  },
};
</script>

<template>
  <div class="panel">
    <BackgroundDonuts />
    <div class="item">
      <SpinnerLogo />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.panel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.item {
  font-size: 2.5rem;
  font-weight: 600;
}
</style>
