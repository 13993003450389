<script>
import Layout from '@console/Layout.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import Panel from '@shared/design/panels/Panel.vue';

export default {
  components: {
    Layout,
    PageHeader,
    Panel,
  },
  head: {
    title: 'Help Center',
  },
};
</script>

<template>
  <Layout>
    <template #default>
      <PageHeader>
        <h1>Help</h1>
        <template v-slot:subheader>
          <strong>Have questions? Need assistance?</strong> We're here to help! Select from one of the following ways to
          contact us:
        </template>
      </PageHeader>
      <div class="row sectional">
        <div class="col-sm-6">
          <Panel>
            <h1 class="text-center">
              <BaseIcon name="comment" variant="far" />
            </h1>
            <h2 class="text-center">Live Chat</h2>
            <p class="text-center">Click the icon at the bottom right of this window</p>
            <p class="text-center small mb-0">
              <i> We're available 8 AM to 5 PM Central time, Monday through Friday. </i>
            </p>
          </Panel>
        </div>
        <div class="col-sm-6">
          <Panel>
            <h1 class="text-center">
              <BaseIcon name="at" />
            </h1>
            <h2 class="text-center">Email</h2>
            <p class="text-center">
              <a href="mailto:help@prosperops.com">help@prosperops.com</a>
            </p>
            <p class="text-center small mb-0">
              <i> We typically respond in less than 12 hours. </i>
            </p>
          </Panel>
        </div>
      </div>
      <div class="row sectional">
        <div class="col-sm-6">
          <Panel>
            <h1 class="text-center">
              <BaseIcon name="phone" />
            </h1>
            <h2 class="text-center">Phone</h2>
            <p class="text-center">
              <a href="tel:18553600512">855.360.0512 (US toll-free)</a>
            </p>
            <p class="text-center small mb-0">
              <i> We're available 8 AM to 5 PM Central time, Monday through Friday. </i>
            </p>
          </Panel>
        </div>
        <div class="col-sm-6">
          <Panel>
            <h1 class="text-center">
              <BaseIcon name="book" />
            </h1>
            <h2 class="text-center">Help Center</h2>
            <p class="text-center mb-0">
              <a href="https://help.prosperops.com" target="_blank">https://help.prosperops.com</a>
            </p>
          </Panel>
        </div>
      </div>
    </template>
  </Layout>
</template>
