<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: 'include',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: this.value,
      labels: {
        include: 'Include',
        exclude: 'Exclude',
      },
    };
  },
  methods: {
    change(newSelected) {
      this.selected = newSelected;
      const event = { name: this.name, selected: newSelected };
      this.$emit('change', event);
    },
  },
};
</script>

<template>
  <b-dropdown
    variant="transparent"
    menu-class="rounded-0"
    toggle-class="btn-no-focus-box-shadow rounded-0"
    right
    no-caret
    :disabled="disabled"
  >
    <template #button-content>
      <div v-if="selected" class="d-flex align-items-center justify-content-center">
        <div class="text-capitalize">
          {{ labels[selected] }}
        </div>
        <div class="ml-2">
          <BaseIcon name="angle-down" />
        </div>
      </div>
    </template>
    <b-dropdown-item @click.prevent="change('include')">
      <div class="d-flex flex-column align-items-start justify-content-center">Include</div>
    </b-dropdown-item>
    <b-dropdown-item @click.prevent="change('exclude')">
      <div class="d-flex flex-column align-items-start justify-content-center">Exclude</div>
    </b-dropdown-item>
  </b-dropdown>
</template>
