<script>
import _ from 'lodash';

import DonutChart from '@console/components/charts/DonutChart.vue';
import StackedChartLegend from '@console/components/charts/StackedChartLegend.vue';

export default {
  components: {
    StackedChartLegend,
    DonutChart,
  },
  props: {
    arbitrageOpportunity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: [],
    };
  },
  computed: {
    data() {
      const withData = point => point.y > 0;
      const filtered = _.filter(this.chartData, withData);
      return _.reduce(
        filtered,
        (acc, point) => {
          return [...acc, { ...point, ...this.labelOptions(point) }];
        },
        []
      );
    },
  },
  mounted() {
    this.chartData = [
      {
        name: 'End Customer Spend Coverage',
        label: 'End Customer Spend Coverage',
        y: this.arbitrageOpportunity.end_customer_spend_coverage_percentage,
        color: '#5c54ff',
        legendSelected: true,
        legendOrder: 0,
      },
      {
        name: 'Arbitrage Spend Coverage',
        label: 'Arbitrage Spend Coverage',
        y: this.arbitrageOpportunity.arbitrage_spend_coverage_percentage,
        color: '#fcbe2c',
        legendSelected: true,
        legendOrder: 1,
      },
      {
        name: 'Uncovered Usage',
        label: 'Uncovered Usage',
        y: this.arbitrageOpportunity.uncovered_usage_percentage,
        color: '#fc5454',
        legendSelected: true,
        legendOrder: 2,
      },
    ];
  },
  methods: {
    labelOptions(point) {
      if (point.y < 5) {
        return { dataLabels: { enabled: false } };
      }
      return {
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.1f}%',
          distance: '-25%',
          style: {
            color: '#fff',
            textOutline: '0px',
            fontWeight: '400',
          },
        },
      };
    },
  },
};
</script>

<template>
  <div class="arbitrageOpportunity row">
    <div>
      <DonutChart name="Arbitrage Opportunity" :data="data" />
    </div>
    <div class="d-flex align-items-center">
      <StackedChartLegend :value="data" class="chartLegend" />
    </div>
    <div class="d-flex flex-row-reverse footnote">
      <small> (normalized) </small>
    </div>
  </div>
</template>

<style lange="scss" scoped>
.arbitrageOpportunity {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

.chartLegend {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.footnote {
  position: absolute;
  right: 5%;
  bottom: 15px;
}
</style>
