<script>
import NumberHelpers from '@shared/utilities/number_helpers';

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    decimals: {
      type: Number,
      required: false,
      default: 1,
    },
    // Indicates if the number needs to be multiplied by 100 to get a percentage. Most of the time, our percentages are
    // already multiplied by 100 (e.g. 50.0 for 50%), so we don't want to multiply by 100 again
    convertToPercent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    formatted() {
      if (this.convertToPercent) {
        // formatPercent() automatically multiples values by 100 to get a percent
        return NumberHelpers.formatPercent(this.value, this.decimals);
      } else {
        return NumberHelpers.formatNumber(this.value, this.decimals) + '%';
      }
    },
  },
};
</script>

<template>
  <span>{{ formatted }}</span>
</template>
