import { useAzureStore } from '@azure/state';
import { requires } from '@console/router/requirements';

const isAzureEnabled = () => {
  const azureStore = useAzureStore();
  return azureStore.isEnabled;
};

export const routes = [
  {
    path: '/azure/onboarding',
    name: 'azure_onboarding',
    component: () => import('@azure/views/Onboarding.vue'),
    beforeEnter: requires(isAzureEnabled),
    meta: {
      initModules: true,
    },
  },
];
