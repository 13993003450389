<template>
  <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="usage 1" clip-path="url(#clip0_93_1844)">
      <g id="Group">
        <path
          id="Vector"
          d="M19.5 39C30.2696 39 39 30.2696 39 19.5C39 8.73045 30.2696 0 19.5 0C8.73045 0 0 8.73045 0 19.5C0 30.2696 8.73045 39 19.5 39Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M19.5001 37.9737C29.7028 37.9737 37.9737 29.7028 37.9737 19.5001C37.9737 9.29732 29.7028 1.02637 19.5001 1.02637C9.29732 1.02637 1.02637 9.29732 1.02637 19.5001C1.02637 29.7028 9.29732 37.9737 19.5001 37.9737Z"
          stroke="#657286"
          stroke-width="2"
        />
      </g>
      <path
        id="Vector_3"
        d="M20.2185 17.4474H23.9132L20.2185 21.1421L16.1132 17.0369L11.2896 21.8606L12.7264 23.2974L16.1132 19.9106L20.2185 24.0158L25.3501 18.8842V22.579H27.4027V15.3948H20.2185V17.4474Z"
        fill="#657286"
      />
    </g>
    <defs>
      <clipPath id="clip0_93_1844">
        <rect width="39" height="39" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
