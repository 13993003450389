<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { loadScript, unloadScript } from 'vue-plugin-load-script';

import { useAuthStore } from '@shared/state/auth.store';

export type CalendlyWidgetProps = {
  routingFormId: string;
  hide?: boolean;
  loadSilently?: boolean;
};

const props = withDefaults(defineProps<CalendlyWidgetProps>(), {
  loadSilently: false,
  hide: false,
});

const emit = defineEmits<{
  (e: 'loaded'): void;
  (e: 'event-scheduled'): void;
}>();

const calendlyRef = ref<HTMLElement>();

const authStore = useAuthStore();

const profile = computed(() => authStore.profile);

const script = 'https://assets.calendly.com/assets/external/widget.js';
onMounted(async () => {
  await loadScript(script);
  // intentionally not adding to the window object, we unload the script on unmount
  const calendly = window.Calendly;

  if (calendly && calendlyRef.value) {
    window.addEventListener('message', onCalendlyMessage);

    const params = new URLSearchParams({
      a1: profile.value.email,
      a2: profile.value.name,
      autofill: 'true',
      primary_color: '5c54ff',
      text_color: '657286',
      hide_gdpr_banner: '1',
    });

    // Calendly doesn't parse '+' as space even though it is valid so we need to replace it with '%20'
    const paramsString = params.toString().replace('+', '%20');
    const url = `https://calendly.com/d/${props.routingFormId}?${paramsString}`;

    calendly.initInlineWidget({
      url,
      parentElement: calendlyRef.value,
    });
  }
});

onUnmounted(async () => {
  await unloadScript(script);
  window.removeEventListener('message', onCalendlyMessage);
});

type CalendlyPageHeightEvent = {
  event: 'calendly.page_height';
  payload: {
    height: string;
  };
};

type CalendlyEventTypeViewedEvent = {
  event: 'calendly.event_type_viewed';
  payload: object;
};

type CalendlyEventScheduledEvent = {
  event: 'calendly.event_scheduled';
  payload: {
    event: {
      uri: string;
    };
    invitee: {
      uri: string;
    };
  };
};

type CalendlyEvent = CalendlyPageHeightEvent | CalendlyEventTypeViewedEvent | CalendlyEventScheduledEvent;

const frameHeight = ref(0);
const loading = ref(true);

const onCalendlyMessage = (messageEvent: MessageEvent<CalendlyEvent>) => {
  if (messageEvent.origin !== 'https://calendly.com') {
    return;
  }
  const calendlyEvent = messageEvent.data;
  switch (calendlyEvent.event) {
    case 'calendly.event_type_viewed':
      emit('loaded');
      loading.value = false;
      break;
    case 'calendly.page_height':
      var newHeight = parseInt(calendlyEvent.payload.height.replace('px', ''));
      // Ensure we always have enough space, don't shrink as that can cause weird scrolling behavior
      if (newHeight > frameHeight.value) {
        frameHeight.value = newHeight;
      }
      break;
    case 'calendly.event_scheduled':
      emit('event-scheduled');
      break;
  }
};
</script>

<template>
  <div class="wrapper" :class="{ loadSilently }">
    <div ref="calendlyRef" class="calendlyFrame" :class="{ loading, hide }" :style="{ height: frameHeight + 'px' }" />
  </div>
</template>

<style lang="scss" scoped>
.calendlyFrame {
  min-width: 320px;
}

.wrapper.loadSilently {
  .calendlyFrame {
    width: 100%;
    opacity: 1;

    // We get no benefit from the built-in transition component since we don't use v-if or v-show
    transition: opacity 300ms, transform 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(0);
  }

  .calendlyFrame.hide,
  .calendlyFrame.loading {
    // the iframe won't start to load if it isn't scrolled into view
    // use a fixed position so that it's always in view
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    pointer-events: none;

    // use 0 opacity so that the iframe is loaded ahead of time but not visible
    opacity: 0;
    transform: translateY(100px);
  }
}
</style>
