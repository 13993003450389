<script>
import Currency from '@shared/components/Currency.vue';
import Percentage from '@shared/components/Percentage.vue';

export default {
  components: {
    Currency,
    Percentage,
  },
  props: {
    sections: {
      type: Array,
      required: true,
    },
    total: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="tableWrapper">
    <table class="table">
      <tr v-for="section in sections" :key="section.key">
        <th>
          <div class="dot" :style="{ 'background-color': section.color }"></div>
          {{ section.key }}
        </th>
        <td>
          <Currency :value="section.amount" :decimals="0" />
        </td>
        <td>
          <Percentage :value="section.value" />
        </td>
      </tr>
      <tr>
        <th>
          {{ total.key }}
        </th>
        <td>
          <Currency :value="total.amount" :decimals="0" />
        </td>
        <td>
          <Percentage :value="total.value" :decimals="0" />
        </td>
      </tr>
    </table>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.tableWrapper {
  flex-grow: 1;
  padding: 0;
  padding-top: 1.5rem;
  margin: 0;
}

.tableWrapper tr td {
  padding-right: 0;
  text-align: right;
}

.tableWrapper tr th {
  padding-left: 0;
}

.tableWrapper tr:not(:last-child) th,
.tableWrapper tr:not(:last-child) td {
  padding-top: 1rem;
  font-weight: 400;
  border-top: 0;
}

.tableWrapper tr:nth-last-child(2) th,
.tableWrapper tr:nth-last-child(2) td {
  padding-bottom: 2rem;
}

.tableWrapper tr:last-child th,
.tableWrapper tr:last-child td {
  padding-top: 2rem;
  font-weight: 600;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-bottom: 1px;
  border-radius: 50%;
}
</style>
