<script>
import _ from 'lodash';

export default {
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isNumber(n) {
      return _.isNumber(n);
    },
  },
};
</script>

<template>
  <span v-if="isNumber(settings.flex_compute_shrink_allowance_percentage)">
    {{ settings.flex_compute_shrink_allowance_percentage }}%
  </span>
  <span v-else> - </span>
</template>
