<script setup lang="ts">
import { computed, ref } from 'vue';

import awsService, { EditableAwsOrgSettings } from '@aws/services/awsService';
import { useAwsStore } from '@aws/stores/aws.store';
import { useVuexStore } from '@console/state/vuex/store';

import SettingRow from '@console/components/settings/SettingRow.vue';
import BaseIcon from '@shared/design/icons/BaseIcon.vue';

const vuexStore = useVuexStore();
const awsStore = useAwsStore();

interface Props {
  isLoading: boolean;
}

const props = withDefaults(defineProps<Props>(), { isLoading: false });

const isEditing = ref(false);
const s3EditValue = ref(vuexStore.getters['aws/selectedOrganizationSettings']?.customer_data_export_s3_bucket_name);
const s3CurrentValue = computed(
  () => vuexStore.getters['aws/selectedOrganizationSettings']?.customer_data_export_s3_bucket_name
);
const awsAccountFriendlyName = computed(
  () => vuexStore.getters['aws/selectedOrganizationManagementAccount']?.friendly_name
);
const awsAccountNumber = computed(
  () => vuexStore.getters['aws/selectedOrganizationManagementAccount']?.aws_account_number
);

const emits = defineEmits<{ (e: 'onToggleLoading', isLoading: boolean): void }>();

function onCancel() {
  isEditing.value = false;
}
function onEdit() {
  if (props.isLoading) {
    return;
  }

  // reset the edit value back to original state when showing the input
  s3EditValue.value = s3CurrentValue.value;

  isEditing.value = true;
}
async function onSave() {
  const hasValueChanged = s3EditValue.value !== s3CurrentValue.value;

  if (!hasValueChanged) {
    isEditing.value = false;
    return;
  }

  emits('onToggleLoading', true);

  if (s3EditValue.value) {
    await awsService.setSetting(
      vuexStore.getters['aws/selectedOrganizationId'],
      EditableAwsOrgSettings.CustomerDataExportS3BucketName,
      s3EditValue.value
    );
  } else {
    await awsService.disableSetting(
      vuexStore.getters['aws/selectedOrganizationId'],
      EditableAwsOrgSettings.CustomerDataExportS3BucketName
    );
  }

  const validation = await awsService.getCustomerDataExportIamValidationStatus(
    vuexStore.getters['aws/selectedOrganizationId']
  );
  awsStore.setCustomerDataExportValidation(vuexStore.getters['aws/selectedOrganizationId'], validation);

  await vuexStore.dispatch('aws/initializeAwsOrganizationSettings');

  isEditing.value = false;

  emits('onToggleLoading', false);
}
</script>

<template>
  <SettingRow>
    <template #label> S3 Bucket Name </template>
    <template #description>
      Must be in the AWS Management account
      <strong> {{ awsAccountFriendlyName }} ({{ awsAccountNumber }}) </strong>
    </template>
    <template #value>
      <div v-if="isEditing" class="grid">
        <div>
          <input
            v-model="s3EditValue"
            class="form-control form-control-sm s3BucketFormInput"
            placeholder="Enter S3 Bucket Name"
            autocomplete="off"
          />
        </div>
        <div>
          <button :disabled="isLoading" class="btn btn-primary btn-sm s3BucketSaveButton" @click="onSave">
            {{ isLoading ? 'Saving...' : 'Save' }}
          </button>
        </div>
        <div :class="{ 'ml-3': isLoading }">
          <button :disabled="isLoading" class="btn btn-outline-info btn-sm" @click="onCancel">Cancel</button>
        </div>
      </div>
      <div v-else :class="{ disabled: isLoading }" class="test">
        {{ s3CurrentValue }}
        <div class="d-inline-block ml-1">
          <span class="cursorHover s3BucketEditIcon" @click="onEdit">
            <BaseIcon name="pencil-alt" class="icon" />
          </span>
        </div>
      </div>
    </template>
  </SettingRow>
</template>

<style scoped lang="scss">
@import '@shared/scss/colors.scss';
.disabled {
  color: $gray-400;
}
.cursorHover:hover {
  cursor: pointer;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 40px 40px;
  grid-column-gap: 15px;
}
</style>
