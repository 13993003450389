<template>
  <svg
    enable-background="new 0 0 180 180"
    version="1.1"
    viewBox="0 0 96.870003 50.119999"
    xml:space="preserve"
    width="60.870003"
    height="50.119999"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M 71.81,50.12 H 25.06 C 11.22,50.12 0,38.9 0,25.06 0,11.22 11.22,0 25.06,0 h 46.75 c 13.84,0 25.06,11.22 25.06,25.06 0,13.83 -11.22,25.06 -25.06,25.06 z"
    />

    <path
      fill="none"
      stroke="#ffffff"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      d="m 12.73,24.15 c 6.69,0.9 4.46,10.82 12.6,10.82 10.15,0 8.05,-25.24 16.8,-25.24 8.23,0 5.95,30.64 14.7,30.64 9.8,0 8.22,-18.93 14.7,-18.93 8.4,0 4.2,11.72 12.6,11.72"
    />
  </svg>
</template>
