<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';

import Panel from './Panel.vue';

const props = defineProps<{
  header?: string;
  startExpanded?: boolean;
}>();

const expanded = ref(false);

onMounted(() => {
  expanded.value = props.startExpanded ?? false;
});

const iconName = computed<'angle-up' | 'angle-down'>(() => {
  const caretDirection = expanded.value ? 'up' : 'down';
  return `angle-${caretDirection}`;
});

function toggle() {
  expanded.value = !expanded.value;
}
</script>

<template>
  <Panel>
    <header class="header" @click="toggle">
      <div class="flex-grow-1">
        <slot name="header">
          <div>{{ header }}</div>
        </slot>
      </div>
      <BaseIcon class="headerIcon ml-2" :name="iconName" />
    </header>
    <section v-if="expanded" class="content">
      <slot />
    </section>
  </Panel>
</template>

<style lang="scss" scoped>
@use '@shared/scss/colors.scss';

.header {
  display: flex;
  align-items: center;
  cursor: pointer;

  .headerIcon {
    font-size: 1.1rem;
  }
}

.content {
  $margin-offset: 1rem;

  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 0.5rem;
  margin-right: -$margin-offset;
  margin-left: -$margin-offset;
  border-top: 1px solid colors.$table-border;
}
</style>
