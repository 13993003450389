<script setup lang="ts">
import { dateFormat, dateUtc } from '@shared/utilities/filters';

import StatusPill from '@shared/design/StatusPill.vue';

defineProps<{
  dataThroughDate: string;
}>();

const formatDate = (date: string, format = 'MMMM DD, YYYY') => dateFormat(dateUtc(date), format);
</script>

<template>
  <StatusPill>
    <BaseIcon name="calendar-check" class="mr-1" variant="far" />
    As of {{ formatDate(dataThroughDate) }}
  </StatusPill>
</template>
