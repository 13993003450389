<script>
import DownloadCsvIcon from '@shared/design/icons/DownloadCsvIcon.vue';

export default {
  components: {
    DownloadCsvIcon,
  },
  props: {
    anyExpanded: {
      type: Boolean,
      required: true,
    },
    anyCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    expand() {
      this.$emit('expand');
    },
    collapse() {
      this.$emit('collapse');
    },
    csv() {
      this.$emit('csv');
    },
  },
};
</script>

<template>
  <div class="actions">
    <b-button variant="link" class="smaller btn-no-focus-box-shadow" :disabled="!anyCollapsed" @click.prevent="expand">
      Expand All
    </b-button>
    <div>|</div>
    <b-button variant="link" class="smaller btn-no-focus-box-shadow" :disabled="!anyExpanded" @click.prevent="collapse">
      Collapse All
    </b-button>
    <div>|</div>
    <b-button
      title="Download in CSV format"
      variant="transparent"
      class="csvIcon btn-no-focus-box-shadow"
      @click.prevent="csv"
    >
      <DownloadCsvIcon />
    </b-button>
  </div>
</template>

<style lang="scss" scoped>
.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
}

.smaller {
  font-size: 0.8rem;
  font-weight: 400;
}

.csvIcon > svg {
  width: 24px;
  height: 24px;
}
</style>
