<script>
export default {
  props: {
    variant: {
      type: String,
      required: true,
      validator(value) {
        return ['primary', 'secondary', 'success', 'warning'].indexOf(value) !== -1;
      },
    },
  },
};
</script>

<template>
  <div class="panelWrapper rounded-sm" :class="'bg-' + variant">
    <div class="panel">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$accent-width: 8px;

.panel {
  display: flex;
  width: calc(100% - $accent-width);
  height: 100%;
  padding: 1rem;
  background-color: #fff;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.panelWrapper {
  display: flex;
  height: 100%;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}

.panelWrapper::before {
  display: block;
  flex-shrink: 0;
  width: $accent-width;
  content: '';
}
</style>
