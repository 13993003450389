<script>
import _ from 'lodash';

import Currency from '@shared/components/Currency.vue';

export default {
  components: {
    Currency,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    expanded: {
      type: Boolean,
      required: false,
      default: null,
    },
    level: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    netAdjustmentClass() {
      if (this.toNumber(this.row, 'net_adjustment_rounded') < 0) {
        return { 'text-danger': true };
      }
      return {};
    },
    expandible() {
      return _.isBoolean(this.expanded);
    },
    offsetStyle() {
      return { 'padding-left': this.level * 15 + 'px' };
    },
  },
  methods: {
    toNumber(row, key) {
      return _.get(row, key, 0) || 0;
    },
    isSmallVariant() {
      return this.variant === 'small';
    },
  },
};
</script>

<template>
  <div class="showbackRow" :class="{ smallVariant: small }">
    <div>
      <div class="d-flex align-items-center" :style="offsetStyle">
        <div class="expandIcon mr-2" :class="{ hiddenIcon: !expandible }">
          <BaseIcon name="angle-down" class="text-muted" :class="{ expanded }" />
        </div>
        <div class="text-truncate">
          <slot name="label" />
        </div>
      </div>
    </div>
    <div :class="{ 'font-weight-custom-bold': level === 0 }">
      <Currency
        :value="toNumber(row, 'original_cost_rounded')"
        class="d-block w-100 text-right text-nowrap offsetRight"
      />
    </div>
    <div :class="{ 'font-weight-custom-bold': level === 0 }">
      <Currency
        :value="toNumber(row, 'undiscounted_usage_rounded')"
        class="d-block w-100 text-right text-nowrap offsetRight"
      />
    </div>
    <div :class="{ 'font-weight-custom-bold': level === 0 }">
      <Currency
        :value="toNumber(row, 'reallocated_savings_rounded')"
        class="d-block w-100 text-right text-nowrap offsetRight"
      />
    </div>
    <div :class="{ 'font-weight-custom-bold': level === 0 }">
      <Currency
        :value="toNumber(row, 'adjusted_cost_rounded')"
        class="d-block w-100 text-right text-nowrap offsetRight"
      />
    </div>
    <div :class="{ 'font-weight-custom-bold': level === 0 }">
      <Currency
        :value="toNumber(row, 'net_adjustment_rounded')"
        signed
        :class="netAdjustmentClass"
        class="d-block w-100 text-right text-nowrap offsetRight"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.showbackRow {
  display: flex;
  justify-content: space-between;

  > div:nth-child(2),
  > div:nth-child(3),
  > div:nth-child(6) {
    margin-left: 6px;

    @media (min-width: 1660px) {
      margin-left: 12px;
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 30px;
    padding: 0.5rem 0.4rem;
    margin: 0;
    font-size: 0.85rem;

    @media (min-width: 1260px) {
      min-width: 40px;
      font-size: 0.85rem;
    }

    @media (min-width: 1660px) {
      min-width: 160px;
      padding: 1rem 0.8rem;
      font-size: 1rem;
    }
  }

  > div:first-child {
    flex-grow: 1;
    justify-content: flex-start;
    min-width: 240px;
    text-align: left;
    overflow-wrap: break-word;

    > div:first-child {
      min-width: 220px;
    }

    @media (min-width: 1260px) {
      min-width: 280px;
      > div:first-child {
        min-width: 280px;
      }
    }

    @media (min-width: 1660px) {
      min-width: 400px;
      > div:first-child {
        min-width: 370px;
      }
    }
  }
}

.showbackRow.smallVariant > div {
  font-size: 0.85rem;
}

.hiddenIcon {
  visibility: hidden;
}

.expanded {
  transform: scaleY(-1);
}

.offsetRight {
  padding-right: 25px;
}
</style>
