import moment from 'moment';
import Vue from 'vue';

export const dateLocalize = function (value) {
  return moment(value);
};

export const dateUtc = function (value) {
  return moment.utc(String(value));
};

export const dateFormat = function (value, pattern) {
  if (moment.isMoment(value)) {
    return value.format(pattern);
  }
  return moment(value).format(pattern);
};

export const dateFromNow = function (value) {
  if (moment.isMoment(value)) {
    return value.fromNow();
  }
  return moment(value).fromNow();
};

export const jsonparse = function (value) {
  if (value) {
    return JSON.parse(String(value));
  }
};

Vue.filter('dateLocalize', dateLocalize);
Vue.filter('dateUtc', dateUtc);
Vue.filter('dateFormat', dateFormat);
Vue.filter('dateFromNow', dateFromNow);
Vue.filter('jsonparse', jsonparse);
