<script>
import _ from 'lodash';
import moment from 'moment';

import * as chartUtilities from '@console/components/charts/utility';
import NumberHelpers from '@shared/utilities/number_helpers';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

export default {
  components: {
    PanelSection,
    ChartLegend,
    SeriesChart,
  },
  props: {
    dailySavings: {
      type: Array,
      required: true,
    },
    monthStart: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      series: [],
    };
  },
  computed: {
    xAxis() {
      const month = moment.utc(this.monthStart).format('MMM');
      const monthAndDay = ({ day }) => `${month} ${day}`;
      const categories = _.map(this.dailySavings, monthAndDay);
      return { categories };
    },
    yAxis() {
      const maxValue = this.dailySavings?.reduce((prev, current) => {
        var totalSavings =
          current.base_savings +
          current.smart_savings +
          current.inherited_savings +
          current.total_other_savings +
          current.unbilled_savings;
        return prev > totalSavings ? prev : totalSavings;
      }, 0);
      const decimalPlaces = chartUtilities.decimalPlacesNeededForChartMax(maxValue);
      return [
        {
          min: 0,
          title: {
            text: null,
          },
          labels: {
            format: '<span style="font-size:12px">${value:,.' + decimalPlaces + 'f}</span>', // eslint-disable-line
          },
        },
      ];
    },
    filteredSeries() {
      return _.filter(this.series, s => s.legendSelected);
    },
    tooltip() {
      return chartUtilities.tooltipWithTotal({
        totalLabel: 'Total Savings',
        valueFormatter: v => NumberHelpers.formatDollars(v),
      });
    },
  },
  mounted() {
    const safeMap = (arr, key) => _.map(arr, a => _.get(a, key, null));
    // compute
    const base = safeMap(this.dailySavings, 'base_savings');
    const smart = safeMap(this.dailySavings, 'smart_savings');
    const inherited = safeMap(this.dailySavings, 'inherited_savings');
    const other = safeMap(this.dailySavings, 'total_other_savings');
    const unbilled = safeMap(this.dailySavings, 'unbilled_savings');
    const series = [
      {
        label: 'Smart Savings',
        name: 'Smart Savings',
        type: 'column',
        stacking: 'normal',
        data: smart,
        color: '#00c58c',
        legendOrder: 1,
        legendSelected: true,
        tooltip: {
          valuePrefix: '$',
        },
      },
      {
        label: 'Base Savings',
        name: 'Base Savings',
        type: 'column',
        stacking: 'normal',
        data: base,
        color: '#8fffdf',
        legendOrder: 2,
        legendSelected: true,
        tooltip: {
          valuePrefix: '$',
        },
      },
      {
        label: 'Inherited Savings',
        name: 'Inherited Savings',
        type: 'column',
        stacking: 'normal',
        data: inherited,
        color: '#d9fff4',
        legendOrder: 3,
        legendSelected: true,
        tooltip: {
          valuePrefix: '$',
        },
      },
      {
        label: 'Unbilled Savings',
        name: 'Unbilled Savings',
        type: 'column',
        stacking: 'normal',
        data: unbilled,
        color: '#a5a4bf',
        legendOrder: 4,
        legendSelected: true,
        tooltip: {
          valuePrefix: '$',
        },
      },
      {
        label: 'Other Savings',
        name: 'Other Savings',
        type: 'column',
        stacking: 'normal',
        data: other,
        color: '#e6e6e6',
        legendOrder: 5,
        legendSelected: true,
        tooltip: {
          valuePrefix: '$',
        },
      },
    ];

    const isZeroOrNull = d => d === 0 || d === null;
    const containsNoData = el => _.every(el.data, isZeroOrNull);
    this.series = _.reject(series, containsNoData);
  },
};
</script>

<template>
  <PanelSection header="Daily Savings">
    <div>
      <ChartLegend v-model="series" />
      <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" :tooltip="tooltip" />
    </div>
  </PanelSection>
</template>
