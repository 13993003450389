<script>
export default {
  props: {
    secondsInterval: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      handle: null,
      tickCount: 1,
    };
  },
  mounted() {
    this.handle = setInterval(() => this.tick(), this.secondsInterval * 1000);
  },
  unmounted() {
    clearInterval(this.handle);
  },
  methods: {
    tick() {
      this.$emit('tick', this.tickCount);
      this.tickCount = this.tickCount + 1;
    },
  },
};
</script>

<template>
  <div class="d-none">Poller Invoked {{ tickCount }} times</div>
</template>
