<script>
import { mapGetters } from 'vuex';

import Layout from '@console/Layout.vue';

export default {
  components: {
    Layout,
  },
  head: {
    title: 'An unexpected error has occurred',
  },
  computed: {
    ...mapGetters('customer', ['selectedCompany']),
    noNav() {
      return !this.selectedCompany;
    },
  },
};
</script>

<template>
  <Layout :no-nav="noNav" wide :with-announcement-banner="false">
    <template #default>
      <div class="announcement pt-4">
        <div class="pt-2 pb-2 text-center">
          <h1 class="display-4">Something went wrong</h1>
        </div>
        <p class="lead text-center mb-0 w-50">
          We're sorry, but an unexpected error has occurred. Our team has been notified and we are working to fix the
          problem.
        </p>
      </div>
    </template>
  </Layout>
</template>

<style scoped>
.announcement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75%;
}
</style>
