<script>
import NumberHelpers from '@shared/utilities/number_helpers';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          backgroundColor: 'transparent',
          height: '320px',
        },
        title: null,
        plotOptions: {
          pie: {
            center: ['50%'],
            allowPointSelect: false,
          },
          series: {
            states: {
              hover: {
                brightness: 0,
                enabled: false,
              },
              inactive: {
                opacity: 1,
              },
            },
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          borderRadius: 0,
          borderColor: '#adb5bd',
          shared: true,
          shadow: false,
          formatter: function () {
            const name = this.point.name;
            const value = NumberHelpers.formatNumber(this.y, 1);
            return `<strong>${name}</strong> <br /> ${value}%`;
          },
        },
        series: [
          {
            type: 'pie',
            name: this.name,
            size: '280px',
            innerSize: '50%',
            data: this.data,
          },
        ],
      };
    },
  },
};
</script>

<template>
  <div style="width: 320px; height: 320px">
    <chart :options="chartOptions" />
  </div>
</template>
