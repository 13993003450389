<script setup lang="ts">
import type { Options, SeriesGaugeOptions } from 'highcharts';

import highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import highchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { computed } from 'vue';

highchartsMore(highcharts);
highchartsSolidGauge(highcharts);

type Props = {
  value: number;
  title: string;
  width: number;
  height: number;
  min: number;
  max: number;
  unit?: string;
  yAxis?: Options['yAxis'];
};

const props = withDefaults(defineProps<Props>(), {
  width: 320,
  height: 215,
  min: 0,
  max: 100,
  unit: 'Months',
  yAxis: undefined,
});

const chartValue = computed(() => {
  return Math.max(Math.min(props.value, props.max), props.min);
});

const formatted = computed(() => {
  return `${props.value.toFixed(1)} ${props.unit}`;
});

const plotBandSize = computed(() => {
  return (props.max - props.min) / 3;
});

const chartOptions = computed<Options>(() => {
  const options: Options = {
    chart: {
      type: 'gauge',
      spacingBottom: 0,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      height: props.height,
      width: props.width,
      backgroundColor: 'transparent',
    },
    title: undefined,
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: undefined,
      size: props.width,
      center: ['50%', '80%'],
    },
    tooltip: {
      enabled: false,
    },
    yAxis: props.yAxis ?? {
      lineWidth: 0,
      minorTickInterval: 0,
      tickLength: 0,
      labels: {
        enabled: false,
      },
      min: props.min,
      max: props.max,
      plotBands: [
        {
          from: props.min,
          to: props.min + plotBandSize.value,
          color: 'var(--success)',
          thickness: 20,
        },
        {
          from: props.min + plotBandSize.value,
          to: props.max - plotBandSize.value,
          color: 'var(--warning)',
          thickness: 20,
        },
        {
          from: props.max - plotBandSize.value,
          to: props.max,
          color: 'var(--danger)',
          thickness: 20,
        },
      ],
    },
    plotOptions: {
      gauge: {
        dataLabels: {
          enabled: false,
        },
        dial: {
          radius: '75%',
          backgroundColor: '#495057',
          baseWidth: 10,
          baseLength: '0%',
          rearLength: '0%',
        },
        pivot: {
          backgroundColor: '#495057',
        },
      },
    },
    series: [
      {
        name: props.title,
        data: [chartValue.value],
      } as SeriesGaugeOptions,
    ],
  };

  return options;
});
</script>

<template>
  <div class="gauge mb-2">
    <chart :options="chartOptions" />
    <div class="gaugeValue">
      {{ formatted }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.gauge {
  position: relative;

  .gaugeValue {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 20px;
    font-weight: 800;
    text-align: center;
  }
}
</style>
