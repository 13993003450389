<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AccordionList',
});
</script>

<template>
  <div class="box rounded-sm">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.box {
  margin: 0;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}
</style>
