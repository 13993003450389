<script>
import _ from 'lodash';
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import OnboardingProgress from '@console/components/onboarding/OnboardingProgress.vue';
import Subscribe from '@console/components/onboarding/Subscribe.vue';
import Layout from '@console/Layout.vue';
import Done from '@gcp/components/onboarding/Done.vue';
import ManagementPermissions from '@gcp/components/onboarding/ManagementPermissions.vue';
import SavingsAnalysisPermissions from '@gcp/components/onboarding/SavingsAnalysisPermissions.vue';
import ScheduleSavingsAnalysis from '@gcp/components/onboarding/ScheduleSavingsAnalysis.vue';
import Start from '@gcp/components/onboarding/Start.vue';

const preSavingsAnalysisOnboardingSteps = [
  {
    text: 'Google Cloud Details',
    key: 'start',
    number: 1,
  },
  {
    text: 'Initial Google Cloud Access',
    key: 'savings-analysis-permissions',
    number: 2,
  },
  {
    text: 'Free Savings Analysis',
    key: 'schedule-savings-analysis',
    number: 3,
  },
];

const postSavingsAnalysisOnboardingSteps = [
  ...preSavingsAnalysisOnboardingSteps,
  {
    text: 'Additional Google Cloud Access',
    key: 'management-permissions',
    number: 4,
  },
];

const subscribeStep = {
  text: 'Review and Subscribe',
  key: 'subscribe',
  number: 5,
};

export default defineComponent({
  components: {
    Done,
    Layout,
    ManagementPermissions,
    OnboardingProgress,
    SavingsAnalysisPermissions,
    ScheduleSavingsAnalysis,
    Start,
    Subscribe,
  },
  data: function () {
    return { onboardingSteps: [] };
  },
  head: {
    title: 'Google Cloud Onboarding',
  },
  computed: {
    ...mapGetters('customer', ['isSubscribed']),
    ...mapGetters('nav', ['context']),
    ...mapGetters('gcp', ['getBillingAccountById', 'getBillingAccountSettingsById']),
    ...mapState('gcp', ['billingConfiguration']),
    selectedBillingAccount() {
      return this.getBillingAccountById(this.context.id);
    },
    selectedBillingAccountSettings() {
      return this.getBillingAccountSettingsById(this.context.id);
    },
    stepsForProgressTracker() {
      const steps = this.stepsForCurrentState;
      const isActive = step => step.key === this.currentStep;
      const enrich = step => ({ ...step, isActive: isActive(step) });
      return _.map(steps, enrich);
    },
    stepsForCurrentState() {
      if (!this.selectedBillingAccountSettings?.savings_analysis_completed) {
        return preSavingsAnalysisOnboardingSteps;
      } else if (!this.isSubscribed) {
        return [...postSavingsAnalysisOnboardingSteps, subscribeStep];
      }

      return postSavingsAnalysisOnboardingSteps;
    },
    currentStep() {
      if (this.$route.query.step) {
        return this.$route.query.step;
      }

      const isActive = this.selectedBillingAccount?.status === 'Active';
      if (isActive && this.isSubscribed) {
        return 'done';
      } else if (isActive) {
        return 'subscribe';
      } else if (this.selectedBillingAccountSettings?.savings_analysis_completed) {
        return 'management-permissions';
      } else if (this.selectedBillingAccount) {
        return 'schedule-savings-analysis';
      } else if (this.billingConfiguration && !this.billingConfiguration.editing) {
        return 'savings-analysis-permissions';
      }

      return 'start';
    },
  },
  created() {
    if (!this.selectedBillingAccount) {
      this.onboard('gcp');
    }
  },
  methods: {
    ...mapActions('nav', ['onboard']),
  },
});
</script>

<template>
  <Layout :with-announcement-banner="false">
    <template #default>
      <OnboardingProgress v-if="currentStep !== 'done'" class="pb-2" :steps="stepsForProgressTracker" />
      <Start v-if="currentStep === 'start'" />
      <SavingsAnalysisPermissions v-else-if="currentStep === 'savings-analysis-permissions'" />
      <ScheduleSavingsAnalysis v-else-if="currentStep === 'schedule-savings-analysis'" />
      <ManagementPermissions v-else-if="currentStep === 'management-permissions'" />
      <Subscribe v-else-if="currentStep === 'subscribe'" />
      <Done v-else-if="currentStep === 'done'" />
    </template>
  </Layout>
</template>
