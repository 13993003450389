<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169 189.93">
    <path
      d="M117.128,162.817H100.434v-22.97H76.721v22.97H59.486L88.3,189.93l28.829-27.113Zm31.1-110.338H119.184a15.232,15.232,0,0,1-9.321-3.648,13.163,13.163,0,0,1-3.231-8.672V10.743H29.869a.822.822,0,0,0-.572.247.613.613,0,0,0-.278.587V147.189a1.068,1.068,0,0,0,.247.572.7.7,0,0,0,.587.247H49.887A42.54,42.54,0,0,0,46.44,158.69H26.576a8.154,8.154,0,0,1-8.146-8.162V8.224A8.154,8.154,0,0,1,26.576.062h85.5A1.759,1.759,0,0,1,112.645,0,3.809,3.809,0,0,1,115.1.958h.124a1.641,1.641,0,0,1,.325.247L157.7,43.869a3.981,3.981,0,0,1,1.237,2.782,5.108,5.108,0,0,1-.124.927V150.543a8.18,8.18,0,0,1-8.162,8.162H129.061a41.729,41.729,0,0,0-3.447-10.681h21.749c.247,0,.247-.139.386-.247.2-.139.464-.4.464-.572,0-36.249.015-58.492.015-94.725Zm-31.967-13.17V12.552L146.39,43.065H120.019a3.944,3.944,0,0,1-2.659-1.1,3.827,3.827,0,0,1-1.1-2.659Z"
      transform="translate(10.059)"
      fill="#262626"
      fill-rule="evenodd"
    />
    <path d="M0,38.51H137.42v66.02H0Z" transform="translate(0 21.018)" fill="#5c54ff" fill-rule="evenodd" />
    <path
      d="M33.936,69.51,43.52,72.4a17.66,17.66,0,0,1-3.045,6.709,13.112,13.112,0,0,1-5.163,4.065A19.326,19.326,0,0,1,27.49,84.55a22.8,22.8,0,0,1-9.429-1.669,14.987,14.987,0,0,1-6.322-5.874Q9.072,72.81,9.08,66.248c0-5.828,1.561-10.326,4.668-13.448q4.684-4.707,13.216-4.7c4.452,0,7.976.9,10.511,2.69a16.01,16.01,0,0,1,5.688,8.27L33.5,61.209a8.389,8.389,0,0,0-1.067-2.365A6.2,6.2,0,0,0,30.2,56.927a6.5,6.5,0,0,0-2.968-.68,6.4,6.4,0,0,0-5.688,2.983c-1,1.484-1.5,3.787-1.5,6.941,0,3.911.587,6.585,1.793,8.038a6.143,6.143,0,0,0,5.024,2.18A6.1,6.1,0,0,0,31.6,74.626a10.87,10.87,0,0,0,2.334-5.116Zm12.49,2.736,10.4-.649A7.763,7.763,0,0,0,58.2,75.446a5.8,5.8,0,0,0,4.823,2.133,5.327,5.327,0,0,0,3.617-1.1,3.3,3.3,0,0,0,1.268-2.535,3.253,3.253,0,0,0-1.206-2.458Q65.5,70.4,61.1,69.448c-4.792-1.082-8.224-2.489-10.264-4.266a8.606,8.606,0,0,1-3.092-6.8,9.229,9.229,0,0,1,1.577-5.132,10.537,10.537,0,0,1,4.761-3.8,22.507,22.507,0,0,1,8.7-1.376q6.794,0,10.357,2.5c2.381,1.669,3.787,4.344,4.235,7.992l-10.31.6A4.818,4.818,0,0,0,61.7,54.624a4.459,4.459,0,0,0-2.875.8,2.428,2.428,0,0,0-.958,1.963,2,2,0,0,0,.788,1.515,9.041,9.041,0,0,0,3.679,1.3A45.337,45.337,0,0,1,72.6,63.327a10.741,10.741,0,0,1,4.514,3.911,9.824,9.824,0,0,1,1.407,5.209,11.024,11.024,0,0,1-1.886,6.245,11.811,11.811,0,0,1-5.271,4.344,21.25,21.25,0,0,1-8.517,1.484c-6.029,0-10.187-1.159-12.505-3.463a13.833,13.833,0,0,1-3.911-8.811ZM80.062,48.673H91.5l7.976,25.351,7.853-25.351h11.114L105.3,83.9H93.449L80.062,48.673Z"
      transform="translate(4.956 26.236)"
      fill="#fff"
    />
  </svg>
</template>
