<script>
import _ from 'lodash';
import { mapState } from 'pinia';
import { mapState as vuexMapState, mapActions as vuexMapActions } from 'vuex';

import { useAuthStore } from '@shared/state/auth.store';

import ErrorMessageByCode from '@aws/components/accounts/ErrorMessageByCode.vue';
import SetupDirections from '@aws/components/accounts/SetupDirections.vue';
import AwsAccountNumberRoleForm from '@aws/components/forms/AwsAccountNumberRoleForm.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';

export default {
  components: {
    BoxMessage,
    AwsAccountNumberRoleForm,
    ErrorMessageByCode,
    SetupDirections,
  },
  data() {
    return {
      editable: true,
      iamDetails: undefined,
      addingManagementAccount: false,
      errorCode: null,
      errorCollection: null,
      awsOrganizationFriendlyName: null,
      canEnterOrgFriendlyName: true,
    };
  },
  computed: {
    ...vuexMapState('customer', ['selectedCompanyId']),
    ...mapState(useAuthStore, ['role']),
    awsAccountNumber() {
      return this.iamDetails.aws_account_number;
    },
    currentUserIsViewer() {
      return this.role(this.selectedCompanyId) === 'Viewer';
    },
  },
  methods: {
    ...vuexMapActions('aws', ['addManagementAccount']),
    async onFormSuccess(iamDetails) {
      this.iamDetails = iamDetails;
      this.editable = false;
      this.canEnterOrgFriendlyName = false;
    },
    editAwsAccountNumber() {
      this.editable = true;
    },
    onFormCancel() {
      this.editable = false;
    },
    async addManagementAccountClicked() {
      this.addingManagementAccount = true;
      this.errorCode = null;
      this.errorCollection = null;
      try {
        await this.addManagementAccount({
          awsAccountNumber: this.iamDetails.aws_account_number,
          friendlyName: this.awsOrganizationFriendlyName,
        });
      } catch (e) {
        this.addingManagementAccount = false;
        this.handleError(e);
      }
    },
    resolved(evt) {
      this.errorCode = null;
      this.errorCollection = null;
      const code = evt.code;
      if (code === 'AwsAccountNotManagementAccount') {
        const iamDetails = evt.data;
        this.iamDetails = iamDetails;
      } else {
        throw new Error(`Unknown resolution for code: ${code}`);
      }
    },
    handleError(e) {
      const status = _.get(e, 'response.status', 500);
      const code = _.get(e, 'response.data.code');
      const errors = _.get(e, 'response.data.errors', null);
      if (status >= 500) {
        throw e;
      }
      if (code === 'General') {
        throw e;
      }
      this.errorCode = code;
      this.errorCollection = errors;
    },
  },
};
</script>

<template>
  <div>
    <div class="row pt-4">
      <div class="col">
        <h2 v-if="iamDetails">Configure Access</h2>
        <h2 v-else>Initial AWS Account Access</h2>
      </div>
    </div>
    <BoxMessage v-if="currentUserIsViewer" type="error" class="pt-2 pb-2">
      <strong> You do not have the necessary permission to add AWS Organizations</strong>
      <p class="mb-0">
        In order to configure ProsperOps, you must be an
        <strong>Owner</strong> or <strong>Editor</strong> role user. Please contact your account's administrator for
        further assistance.
      </p>
    </BoxMessage>
    <BoxMessage v-if="!currentUserIsViewer" type="info" class="pt-2 pb-2">
      <p v-if="iamDetails" class="mb-0">
        ProsperOps access is granted via an IAM role. To make configuration simple, customized
        scripts/templates/instructions are provided below. Please choose whichever method is best for you. Once the IAM
        role has been configured, click the
        <strong>Validate Access</strong>
        button to continue.
      </p>
      <p v-else class="mb-0">
        To perform our Savings Analysis, we will need very limited
        <a href="https://help.prosperops.com/limited-iam-permissions-for-savings-analysis" target="_blank"
          >IAM permissions</a
        >
        on your management account (the account where you receive your AWS bill). If you need help finding your
        management account number, please see our
        <a href="https://help.prosperops.com/where-do-i-find-my-aws-management-account-number" target="_blank"
          >help article</a
        >.
      </p>
    </BoxMessage>
    <div v-if="!currentUserIsViewer" class="row pt-4">
      <div class="col">
        <AwsAccountNumberRoleForm
          v-if="editable"
          :iam-role="iamDetails"
          :disabled="currentUserIsViewer"
          :button-location="canEnterOrgFriendlyName ? 'left' : 'right'"
          @on-success="onFormSuccess"
          @on-cancel="onFormCancel"
        >
          <div v-if="canEnterOrgFriendlyName" class="col">
            <label for="awsOrgFriendlyName" class="awsOrgFriendlyNameLabel"> AWS Organization Name (optional) </label>
            <div class="inputWrapper">
              <input
                id="awsOrgFriendlyName"
                v-model.trim="awsOrganizationFriendlyName"
                type="text"
                class="input"
                autocomplete="off"
              />
            </div>
          </div>
        </AwsAccountNumberRoleForm>
        <div v-else>
          <div class="pb-1 text-muted">AWS Management Account</div>
          <div>
            <span class="role" @click="editAwsAccountNumber">
              <div class="awsAccountNumber">{{ awsAccountNumber }}</div>
              <BaseIcon name="pencil-alt" class="icon" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <BoxMessage v-if="errorCode" type="error" class="pt-4 pb-2">
      <ErrorMessageByCode :code="errorCode" :errors="errorCollection" @resolved="resolved" />
    </BoxMessage>
    <div v-if="iamDetails">
      <div class="row pt-2">
        <div class="col">
          <SetupDirections :iam-details="iamDetails" :aws-account-number="awsAccountNumber" savings-analysis-role />
        </div>
      </div>
      <div v-if="iamDetails" class="row pt-2 pb-4">
        <div class="col-sm-8 pt-2">
          <small>
            Note: The person configuring access will need AWS permissions to create IAM roles on the target account.
            <span>
              If you need to invite someone else with those permissions, you can do that in
              <router-link :to="{ name: 'user_management' }">User Management</router-link>.
            </span>
          </small>
        </div>
        <div class="col pt-2">
          <div class="d-flex flex-row-reverse">
            <form-submit-button
              variant="primary"
              type="submit"
              :loading="addingManagementAccount"
              class="rounded-sm text-nowrap"
              @click="addManagementAccountClicked"
            >
              Validate Access
              <template v-slot:loading> Validating... </template>
            </form-submit-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
.role {
  font-size: 1.5rem;
}

.role:hover {
  cursor: pointer;
}

.icon {
  margin-bottom: 2px;
  margin-left: 6px;
  font-size: 1rem;
}

.awsAccountNumber {
  display: inline;
}

.inputWrapper {
  display: inline-block;
  margin-bottom: 0.1rem;
}

.inputWrapper::after {
  display: block;
  height: 3px;
  content: '';
}

.inputWrapper:focus-within::after {
  margin-right: 1rem;
  background-color: map-get($theme-colors, 'success');
  transition: background-color 0.2s ease-in-out;
}

.inputWrapper.inputError:focus-within::after {
  margin-right: 1rem;
  background-color: map-get($theme-colors, 'danger');
  transition: background-color 0.2s ease-in-out;
}

.input {
  min-width: 380px;
  height: 40px;
  padding: 0;
  padding-left: 1rem;
  margin: 0;
  margin-right: 1rem;
  font-size: 1rem;
  background-color: #fff;
  border: 0;
  border-radius: 0;
}

.awsOrgFriendlyNameLabel {
  display: block;
}
</style>
