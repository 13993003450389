<template>
  <svg
    enable-background="new 0 0 72 72"
    version="1.1"
    viewBox="0 0 72 72"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m44.6 51.5v3.3h-5.7v-3.4c-6-1-9.4-4.7-9.4-9.9h7c0 2.1 1.4 3.7 5.3 3.7 3 0 4.5-0.9 4.5-2.9 0-1.6-0.8-2.2-3.7-3l-4.7-1.3c-2.9-0.7-7.9-2.9-7.9-9 0-4.7 3.1-8.8 8.9-9.8v-3.4h5.7v3.5c4.5 0.7 8.7 3.5 8.7 9.8h-7c0-2.9-2.1-3.8-4.7-3.8-3.2 0-3.9 1.2-3.9 2.8 0 1.5 0.9 2.5 4 3.3l4.6 1.2c4.5 1.2 7.7 4.2 7.7 9.1 0 5.6-4 9-9.4 9.8z"
      fill="currentColor"
    />
    <path
      d="m16.6 27.6h-10c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5h10.1c1.4 0 2.5 1.1 2.5 2.5-0.1 1.4-1.2 2.5-2.6 2.5z"
      fill="currentColor"
    />
    <path
      d="m23 36.8h-20.5c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5h20.5c1.4 0 2.5 1.1 2.5 2.5 0 1.3-1.1 2.5-2.5 2.5z"
      fill="currentColor"
    />
    <path
      d="m14.7 45.9h-9.9c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.4 2.5-2.4h9.9c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.4-2.5 2.4z"
      fill="currentColor"
    />
    <path
      d="m41.3 66.1c-9.8 0-19-4.7-24.7-12.6-0.8-1.1-0.6-2.7 0.6-3.5 1.1-0.8 2.7-0.6 3.5 0.6 4.8 6.6 12.5 10.6 20.7 10.6 14.1 0 25.5-11.5 25.5-25.5s-11.5-25.7-25.6-25.7c-7.2 0-14 3-18.9 8.3-0.9 1-2.5 1.1-3.5 0.2s-1.1-2.5-0.2-3.5c5.8-6.3 14-10 22.5-10 16.8 0 30.5 13.7 30.5 30.5s-13.5 30.6-30.4 30.6z"
      fill="currentColor"
    />
  </svg>
</template>
