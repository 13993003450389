/**
 * Can be used at compile-time to assert that a switch statement has covered all possible cases (e.g. all enum values)
 * Example:
 * ```typescript
 * type MyEnum = 'A' | 'B' | 'C';
 * const myEnumValue: MyEnum = 'A';
 * switch (myEnumValue) {
 *  case 'A': return 'A';
 *  case 'B': return 'B';
 *  case 'C': return 'C';
 *  default: return neverReached(myEnumValue);
 * }
 * ```
 * If any of the cases are missing, the TypeScript compiler will throw an error on the default case.
 * @param _ the value that should have been checked by all previous switch or if statements
 * @param value an optional return value. This can be useful for scenarios where in the event of a missing case at
 * runtime, a default value can be returned.
 * @returns `value` if provided, otherwise `undefined`
 */
export function neverReached(_: never, value?: undefined): undefined;
// eslint-disable-next-line no-redeclare
export function neverReached<T>(_:never, value: T): T;
// eslint-disable-next-line no-redeclare
export function neverReached(_:never, value: unknown) {
  return value;
}
