import type { BillingScopeResponse, AgreementType } from '@azure/services/billingScope';
import type { AccessValidationResponse, CreateServicePrincipalResponse } from '@azure/services/servicePrincipal';

import { AssertionError } from 'assert';
import { defineStore } from 'pinia';

import * as billingScope from '@azure/services/billingScope';
import * as servicePrincipal from '@azure/services/servicePrincipal';
import { store } from '@console/state/vuex/store';
import { useFeatureStore } from '@shared/state/feature.store';

export type OnboardingForm = {
  agreementType?: AgreementType;
  billingAccountId?: string;
  billingProfileId?: string; // MCA only, not Enterprise Agreement
  applicationId?: string;
  tenantId?: string;
  storageAccountSubscriptionId?: string;
  storageAccountResourceGroupName?: string;
  storageAccountName?: string;
};

export type AzureState = {
  billingScopes: { [id: string]: BillingScopeResponse } | undefined;
  onboarding: {
    form: OnboardingForm;
    servicePrincipal?: CreateServicePrincipalResponse;
    accessValidation?: AccessValidationResponse;
  };
};

function defaultOnboardingState() {
  return {
    form: <OnboardingForm>{},
  };
}

function assertServicePrincipal(
  value: CreateServicePrincipalResponse | undefined
): asserts value is CreateServicePrincipalResponse {
  if (!value) {
    throw new AssertionError({ message: 'value is not defined' });
  }
}

export const useAzureStore = defineStore('azure', {
  state: (): AzureState => ({
    onboarding: defaultOnboardingState(),
    billingScopes: undefined,
  }),
  getters: {
    isEnabled: () => {
      const features = useFeatureStore();
      return store.getters['customer/isSettingEnabled']('EnableAzure') || features.azure;
    },
    getBillingScopeById: (state: AzureState) => (id: string) => {
      if (!state.billingScopes) {
        return undefined;
      }

      return state.billingScopes[id];
    },
  },
  actions: {
    async createServicePrincipal(): Promise<void> {
      const form = this.onboarding.form;
      const spn = await servicePrincipal.create({
        application_id: form.applicationId,
        tenant_id: form.tenantId,
      });
      this.onboarding.servicePrincipal = spn;
    },
    async createBillingScope(): Promise<void> {
      assertServicePrincipal(this.onboarding.servicePrincipal);

      const scope = await billingScope.create({
        agreement_type: this.onboarding.form.agreementType,
        billing_account_id: this.onboarding.form.billingAccountId,
        billing_profile_id: this.onboarding.form.billingProfileId,
        storage_account_subscription_id: this.onboarding.form.storageAccountSubscriptionId,
        storage_account_resource_group_name: this.onboarding.form.storageAccountResourceGroupName,
        storage_account_name: this.onboarding.form.storageAccountName,
        service_principal_id: this.onboarding.servicePrincipal.id,
      });
      this.billingScopes = { ...this.billingScopes, [scope.id]: scope };
      this.onboarding = defaultOnboardingState();

      store.dispatch('nav/switch', { cloud: 'azure', id: scope.id });
    },
    async validateServicePrincipalAccess(): Promise<AccessValidationResponse> {
      assertServicePrincipal(this.onboarding.servicePrincipal);

      const accessValidation = await servicePrincipal.validateAccess(this.onboarding.servicePrincipal.id, {
        agreement_type: this.onboarding.form.agreementType,
        billing_account_id: this.onboarding.form.billingAccountId,
        billing_profile_id: this.onboarding.form.billingProfileId,
        storage_account_subscription_id: this.onboarding.form.storageAccountSubscriptionId,
        storage_account_resource_group_name: this.onboarding.form.storageAccountResourceGroupName,
        storage_account_name: this.onboarding.form.storageAccountName,
      });

      this.onboarding.accessValidation = accessValidation;

      return accessValidation;
    },
    async loadBillingScopes() {
      if (this.billingScopes) {
        return;
      }

      const scopes = await billingScope.getAll();
      const scopesById = scopes.reduce((agg, val) => {
        return { [val.id]: val, ...agg };
      }, {});

      this.billingScopes = scopesById;
    },
  },
});
