<script>
import { mapState } from 'pinia';
import { mapGetters as vuexMapGetters, mapState as vuexMapState } from 'vuex';

import { useAuthStore } from '@shared/state/auth.store';

export default {
  computed: {
    ...vuexMapState('customer', ['selectedCompanyId']),
    ...vuexMapGetters('customer', ['companyName']),
    ...mapState(useAuthStore, ['hubSpotToken', 'loggedIn', 'isEmployee', 'email']),
  },
  mounted() {
    if (this.loggedIn) {
      this.setupHubSpot();
    }
  },
  methods: {
    setupHubSpot() {
      if (!this.isEmployee) {
        const identificationEmail = this.email;
        const identificationToken = this.hubSpotToken;
        const settings = { identificationEmail, identificationToken };
        window.hsConversationsSettings = settings;
        window.hsConversationsOnReady = [() => window.HubSpotConversations.widget.load()];
      }
    },
  },
  render() {
    return this.$slots.default;
  },
};
</script>
