<script>
import _ from 'lodash';
import { mapState } from 'pinia';
import { mapGetters } from 'vuex';

import customerService from '@console/services/customerService';
import { useFeatureStore } from '@shared/state/feature.store';

import CommitmentAsOfPill from '@aws/components/commitments/CommitmentAsOfPill.vue';
import CommitmentBurndown from '@aws/components/commitments/compute/CommitmentBurndown.vue';
import RegionalCommitments from '@aws/components/commitments/compute/RegionalCommitments.vue';
import TotalRemainingCommitment from '@aws/components/commitments/compute/TotalRemainingCommitment.vue';
import WeightedAverageDuration from '@aws/components/commitments/compute/WeightedAverageDuration.vue';
import WeightedAverageDurationTrend from '@aws/components/commitments/compute/WeightedAverageDurationTrend.vue';
import BuildingState from '@console/components/BuildingState.vue';
import Layout from '@console/Layout.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import AggregationDropdown from '@shared/design/panels/AggregationDropdown.vue';
import AmortizationDropdown from '@shared/design/panels/AmortizationDropdown.vue';
import PanelLabel from '@shared/design/panels/PanelLabel.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

export default {
  components: {
    Layout,
    PageHeader,
    BuildingState,
    PanelSection,
    PanelLabel,
    AmortizationDropdown,
    AggregationDropdown,
    CommitmentBurndown,
    CommitmentAsOfPill,
    RegionalCommitments,
    TotalRemainingCommitment,
    WeightedAverageDuration,
    WeightedAverageDurationTrend,
  },
  data() {
    return {
      commitments: null,
      commitmentsNotFound: false,
      facets: {
        commitment_burndown: 'amortized',
        total_remaining_commitment: 'amortized',
      },
      pivots: {
        commitment_burndown: 'daily',
      },
    };
  },
  head: {
    title: 'Compute Commitments',
  },
  computed: {
    ...mapGetters('aws', ['selectedOrganization']),
    ...mapState(useFeatureStore, ['featureFlags']),
    isLoading() {
      return this.commitments === null && !this.commitmentsNotFound;
    },
    emptyCommitments() {
      const timelines = _.get(this.commitments, 'dashboard.convertible_reserved_instance_expiration_timelines', []);
      return !_.some(timelines);
    },
    dashboard() {
      return this.commitments.dashboard;
    },
    burndown() {
      const facet = this.facets.commitment_burndown;
      const pivot = this.pivots.commitment_burndown;
      const prop = `${facet}_${pivot}_commitment_burndown`;
      return _.get(this.dashboard, prop);
    },
    remaining() {
      const facet = this.facets.total_remaining_commitment;
      const prop = `${facet}_total_remaining_commitment`;
      return _.get(this.dashboard, prop);
    },
    criExpirationTimelines() {
      return _.get(this.commitments, ['dashboard', 'convertible_reserved_instance_expiration_timelines']);
    },
  },
  async mounted() {
    try {
      const awsOrganizationId = this.selectedOrganization.id;
      const response = await customerService.getCommitments(awsOrganizationId);
      this.commitments = response.data;
    } catch (e) {
      this.handleError(e);
    }
  },
  methods: {
    facetChange(event) {
      this.facets[event.name] = event.selected;
    },
    pivotChange(event) {
      this.pivots[event.name] = event.selected;
    },
    handleError(e) {
      const status = _.get(e, 'response.status', 500);
      if (status === 404) {
        this.commitmentsNotFound = true;
      } else {
        throw e;
      }
    },
  },
};
</script>

<template>
  <Layout :loading="isLoading">
    <template #default>
      <BuildingState v-if="commitmentsNotFound || emptyCommitments" />
      <div v-else>
        <PageHeader wrap-utility>
          <h1>Compute Commitments</h1>
          <template v-slot:utility>
            <CommitmentAsOfPill :data-through-date="dashboard.data_through_date" />
          </template>
        </PageHeader>
        <h2 class="commitmentsScopeHeader">Global</h2>
        <div class="row sectional">
          <div class="col">
            <PanelSection header="Commitment Burndown">
              <template v-slot:utility>
                <div class="d-flex">
                  <AggregationDropdown name="commitment_burndown" class="mr-2" @change="pivotChange" />
                  <AmortizationDropdown class="fixedWidthToggle" name="commitment_burndown" @change="facetChange" />
                </div>
              </template>
              <CommitmentBurndown
                :key="'commitment_burndown_' + facets.commitment_burndown + pivots.commitment_burndown"
                :commitment-burndown="burndown"
                :data-through-date="dashboard.data_through_date"
                class="fadeIn"
              />
            </PanelSection>
          </div>
        </div>
        <div class="row sectional stack-sectional">
          <div class="col">
            <PanelSection header="Current Weighted Average Duration">
              <template v-slot:utility>
                <PanelLabel>Amortized</PanelLabel>
              </template>
              <WeightedAverageDuration :weighted-average-duration="dashboard.current_weighted_average_duration" />
            </PanelSection>
          </div>
          <div class="col">
            <PanelSection header="Total Remaining Commitment">
              <template v-slot:utility>
                <AmortizationDropdown
                  class="fixedWidthToggle"
                  name="total_remaining_commitment"
                  @change="facetChange"
                />
              </template>
              <TotalRemainingCommitment
                :key="'tot_rem_commitment_' + facets.total_remaining_commitment"
                :total-remaining-commitment="remaining"
                class="fadeIn"
              />
            </PanelSection>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <PanelSection header="Weighted Average Duration Trend">
              <template v-slot:utility>
                <PanelLabel>Amortized</PanelLabel>
              </template>
              <WeightedAverageDurationTrend
                :trend="dashboard.weighted_average_duration_trend"
                :data-through-date="dashboard.data_through_date"
              />
            </PanelSection>
          </div>
        </div>
        <h2 class="commitmentsScopeHeader">Regional</h2>
        <RegionalCommitments
          :cri-expiration-timelines="criExpirationTimelines"
          :data-through-date="dashboard.data_through_date"
        />
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.commitmentsScopeHeader {
  margin: 0.5rem 0 0;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}

.row.sectional + .commitmentsScopeHeader {
  margin-top: 2rem;
}

.fadeIn {
  opacity: 1;
  animation: fade 1s forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.fixedWidthToggle {
  width: 6rem;
}
</style>
