<script setup lang="ts">
import { computed } from 'vue';

import PanelAccent from '@shared/design/panels/PanelAccent.vue';
import Tooltip from '@shared/design/Tooltip.vue';

export type MetricPanelProps = {
  name: string;
  description?: string;
  icon: object;
  color: string;
};

const props = defineProps<MetricPanelProps>();

var tooltipTarget = computed(() => `metricPanel-${props.name}`);
</script>

<template>
  <PanelAccent :variant="props.color">
    <div class="d-flex align-items-center w-100">
      <div :class="['mr-3', `text-${props.color}`]">
        <Component :is="props.icon" />
      </div>
      <div class="overflow-hidden">
        <div :class="['metricValue', `text-${props.color}`]"><slot /></div>
        <div :id="tooltipTarget" class="metricName text-muted" :class="{ 'tooltip-target': !!props.description }">
          {{ props.name }}
        </div>
        <Tooltip v-if="props.description" placement="bottom" :target="tooltipTarget">{{ props.description }}</Tooltip>
      </div>
    </div>
  </PanelAccent>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
.metricValue {
  font-size: 2.5rem;
  line-height: 1.2;
}

.metricName {
  font-size: 0.9rem;
}
</style>
