<!-- eslint-disable vue/no-deprecated-dollar-listeners-api -->
<script>
export default {};
</script>

<template>
  <b-dropdown-item v-bind="$attrs" class="p-0" link-class="profile-dropdown" v-on="$listeners">
    <div class="profileDropdownItem d-flex pt-2 pb-2 align-items-center">
      <div class="ml-2 mr-2 d-flex justify-content-center">
        <slot name="left" />
      </div>
      <div class="pl-1 text-truncate text-nowrap">
        <slot name="right" />
      </div>
    </div>
  </b-dropdown-item>
</template>

<style lang="scss" scoped>
.profileDropdownItem {
  > div:first-child {
    width: 28px;
  }

  > div:last-child {
    width: 172px;
  }
}
</style>
