<script>
import AwsLogoDark from '@shared/design/icons/AwsLogoDark.vue';
import AzureLogo from '@shared/design/icons/AzureLogo.vue';
import GcpLogo from '@shared/design/icons/GcpLogo.vue';

export default {
  components: {
    AwsLogoDark,
    AzureLogo,
    GcpLogo,
  },
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    cloud: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <div class="header">
    <div class="icon" :class="{ [cloud]: true }">
      <slot name="icon">
        <AwsLogoDark v-if="cloud === 'aws'" />
        <AzureLogo v-if="cloud === 'azure'" />
        <GcpLogo v-if="cloud === 'gcp'" />
      </slot>
    </div>
    <div class="content">
      <slot>{{ text }}</slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import '@shared/scss/colors.scss';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 20rem;
  padding: 0.5rem;
  color: $prosperops-purple;
  text-transform: uppercase;
  background-color: $gray-200;
  border-bottom: 1px solid #fff;
}

.icon {
  flex-shrink: 0;
  margin-right: 1rem;

  > svg {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

.icon.aws {
  flex-basis: 2rem;
  min-width: 2rem;
  margin-top: 4px;
}

.icon.azure {
  flex-basis: 1.4rem;
  min-width: 1.4rem;
}

.icon.gcp {
  flex-basis: 1.5rem;
  min-width: 1.5rem;
  margin-top: 2px;
}

.content {
  font-weight: 400;
}
</style>
