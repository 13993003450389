<script>
export default {
  props: {
    xAxis: {
      type: Object,
      required: true,
    },
    yAxis: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    zoomable: {
      type: Boolean,
      required: false,
      default: false,
    },
    height: {
      type: Number,
      required: false,
      default: 400,
    },
    tooltip: {
      type: Object,
      required: false,
      default: () => ({
        enabled: true,
        borderRadius: 0,
        borderColor: '#adb5bd',
        shared: true,
        shadow: false,
        headerFormat: '<strong style="font-size: 12px">{point.key}</strong> <br />',
      }),
    },
    plotOptions: {
      type: Object,
      required: false,
      default: () => ({
        column: {
          states: {
            hover: {
              brightness: 0,
              enabled: false,
            },
          },
        },
      }),
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: this.height,
          backgroundColor: 'transparent',
          zoomType: this.zoomable ? 'x' : undefined,
        },
        title: null,
        plotOptions: this.plotOptions,
        legend: {
          enabled: false,
        },
        tooltip: this.tooltip,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        series: this.series,
      };
    },
  },
};
</script>

<template>
  <div>
    <chart :options="chartOptions" />
  </div>
</template>
