<script>
import _ from 'lodash';

import BarChart from '@console/components/charts/BarChart.vue';

const sortOrder = [
  'convertible_reserved_instances_days',
  'compute_savings_plans_days',
  'standard_reserved_instances_days',
  'ec2_instance_savings_plans_days',
  'overall_days',
];

const orderedKeyValuePairs = weightedAverageDuration => {
  const toKeyValuePair = (value, key) => ({ key, value });
  const pairs = _.map(weightedAverageDuration, toKeyValuePair);
  const indexOfKey = pair => _.indexOf(sortOrder, pair.key);
  return _.orderBy(pairs, indexOfKey);
};

export default {
  components: {
    BarChart,
  },
  props: {
    weightedAverageDuration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [],
    };
  },
  computed: {
    xAxis() {
      const durations = orderedKeyValuePairs(this.weightedAverageDuration);
      const friendlyNames = {
        convertible_reserved_instances_days: 'Convertible RIs',
        compute_savings_plans_days: 'Compute SPs',
        standard_reserved_instances_days: 'Standard RIs',
        ec2_instance_savings_plans_days: 'EC2 Instance SPs',
        overall_days: 'Overall',
      };
      const categories = _.map(durations, pair => friendlyNames[pair.key]);
      return { categories };
    },
    yAxis() {
      return [
        {
          min: 0,
          title: {
            text: null,
          },
          labels: {
            format: '<span style="font-size:12px">{value:,.0f}</span>', // eslint-disable-line
            overflow: 'allow',
          },
        },
      ];
    },
  },
  mounted() {
    const durations = orderedKeyValuePairs(this.weightedAverageDuration);
    const colors = {
      convertible_reserved_instances_days: '#00c58c',
      compute_savings_plans_days: '#5c54ff',
      standard_reserved_instances_days: '#fcbe2c',
      ec2_instance_savings_plans_days: '#8fffdf',
      overall_days: '#adb5bd',
    };
    const toData = pair => ({ y: pair.value, color: colors[pair.key] });
    const data = _.map(durations, toData);
    this.series = [
      {
        name: '',
        data,
      },
    ];
  },
};
</script>

<template>
  <div>
    <BarChart :x-axis="xAxis" :y-axis="yAxis" :series="series" data-label-format="{y} days" />
  </div>
</template>
