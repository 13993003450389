<script>
import Toggle from '@shared/components/Toggle.vue';
import InfoModal from '@shared/design/InfoModal.vue';

let counter = 0;

export default {
  components: {
    InfoModal,
    Toggle,
  },
  props: {
    showNormalized: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    instanceId: null,
  }),
  created() {
    // This component is rendered multiple times on the multi-org page, so make sure each instance has a unique ID for the modal
    // checkbox IDs
    this.instanceId = ++counter;
  },
  methods: {
    getButtonClasses(isNormalization) {
      const variant = isNormalization === this.showNormalized ? 'btn-primary' : 'btn-link';
      return `btn btn-sm ${variant}`;
    },
    setTo(checked) {
      if (checked !== this.showNormalized) {
        this.$emit('change', checked);
      }
    },
  },
};
</script>

<template>
  <!-- Use text-nowrap to prevent the icon from wrapping without the toggle -->
  <div class="text-nowrap">
    <Toggle :enabled="!showNormalized" off="Normalized" on="Actual" @change="value => setTo(!value)" />
    <InfoModal title="Actual vs. Normalized Values">
      <p>
        <strong>Actual</strong> values match your Google Cloud bill. This provides an accurate view of monthly cost and
        savings but is not useful when comparing across months since the number of days in each month can vary. As such,
        month over month comparison values are not shown.
      </p>
      <p>
        <strong>Normalized</strong> values have been adjusted to a 30-day month to remove number of day variance and
        provide a useful representation that can be compared across months. In this view, month over month dollar and
        percent change figures are also shown.
      </p>
    </InfoModal>
  </div>
</template>
