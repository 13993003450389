<script>
import axios from 'axios';

import env from '@shared/utilities/environment';

export default {
  data() {
    return {
      console_version: null,
      api_version: null,
    };
  },
  head: {
    title: 'Version Details',
  },
  async mounted() {
    const res = await axios.get(env.get('VITE_API') + '/version');
    this.api_version = res.data;
    this.console_version = env.get('VITE_CONSOLE_VERSION');
  },
};
</script>

<template>
  <div class="m-2">
    <p>
      Console Version: {{ console_version }}
      <br />
      API Version: {{ api_version }}
    </p>
  </div>
</template>
