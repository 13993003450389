<script>
export default {};
</script>

<template>
  <div class="box rounded-sm">
    <div class="p-3">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.box {
  height: 100%;
  margin: 0;
  background-color: #fff;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}

.box::before {
  display: block;
  width: 100%;
  height: 4px;
  content: '';
  background-image: linear-gradient(to right, map-get($theme-colors, 'primary'), map-get($theme-colors, 'success'));
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}
</style>
