<script setup lang="ts">
import type { Options, XAxisOptions, YAxisOptions, SeriesOptionsType } from 'highcharts';

import { computed } from 'vue';

const props = defineProps<{
  xAxis: XAxisOptions;
  yAxis: YAxisOptions[];
  series: SeriesOptionsType[];
  dataLabelFormat?: string;
}>();

const chartOptions = computed<Options>(() => {
  return {
    chart: {
      backgroundColor: 'transparent',
      type: 'bar',
      marginRight: 40,
    },
    title: { text: undefined },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          format: props.dataLabelFormat,
          crop: false,
          overflow: 'allow',
        },
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: props.xAxis,
    yAxis: props.yAxis,
    series: props.series,
  };
});
</script>

<template>
  <div>
    <chart :options="chartOptions" />
  </div>
</template>
