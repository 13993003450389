<template>
  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="mag with graph">
      <path
        id="Vector"
        d="M12.8002 25.1649C19.3351 25.1649 24.6327 19.8673 24.6327 13.3325C24.6327 6.79757 19.3351 1.5 12.8002 1.5C6.26535 1.5 0.967773 6.79757 0.967773 13.3325C0.967773 19.8673 6.26535 25.1649 12.8002 25.1649Z"
        stroke="#43425D"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_2"
        d="M21.3657 22.8334L28.0324 29.5"
        stroke="#43425D"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        id="Vector_3"
        d="M8.78747 12.4445H6.89136V18.6852H8.78747V12.4445Z"
        fill="#43425D"
        stroke="#43425D"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_4"
        d="M13.2523 9.68066H11.3562V18.6854H13.2523V9.68066Z"
        fill="#43425D"
        stroke="#43425D"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_5"
        d="M17.7174 7.58936H15.8213V18.6852H17.7174V7.58936Z"
        fill="#43425D"
        stroke="#43425D"
        stroke-miterlimit="10"
      />
    </g>
  </svg>
</template>
