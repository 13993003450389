<script setup lang="ts">
import type { FeatureFlag } from '@shared/state/feature.store';

import { useFloating } from '@floating-ui/vue';
import { onClickOutside } from '@vueuse/core';
import { computed, ref } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';
import { useAuthStore } from '@shared/state/auth.store';
import { useFeatureStore } from '@shared/state/feature.store';
import env from '@shared/utilities/environment';

const vuexStore = useVuexStore();
const authStore = useAuthStore();
const isDemo = computed(() => vuexStore.getters['customer/isDemo']);

const isLocal = env.get('VITE_ENV') === 'local';
const isVisible = computed(() => {
  return flags.value.length && (isLocal || (authStore.isEmployee && !isDemo.value));
});

const featureStore = useFeatureStore();
const flags = computed(() =>
  Object.entries(featureStore.featureFlags).map(([key, value]) => ({
    name: key as FeatureFlag,
    value: value as boolean,
  }))
);

const activeFlagCount = computed(() => flags.value.filter(flag => flag.value).length);
const showActiveIndicator = computed(() => activeFlagCount.value > 0);
const activeIndicator = computed(() => (activeFlagCount.value > 9 ? '+' : activeFlagCount.value));

const onChange = (name: FeatureFlag) => {
  featureStore.toggleFeatureFlag(name);
};

const areAllFlagsEnabled = computed(() => flags.value.every(flag => flag.value));
const toggleAll = () => {
  featureStore.toggleAllFeatureFlags();
};

const button = ref();
const container = ref();
const isOpen = ref(false);
const toggleOpen = () => (isOpen.value = !isOpen.value);

const { floatingStyles } = useFloating(button, container, {
  placement: 'bottom-end',
});

onClickOutside(container, toggleOpen, {
  ignore: [button],
});
</script>

<template>
  <div v-if="isVisible && !isDemo" class="position-relative" data-testid="feature-flag-container">
    <div ref="button" class="target" data-testid="feature-flag-button" @click="toggleOpen">
      <BaseIcon name="cog" class="icon text-muted" fixed-width width="25" height="25" />
      <div v-if="showActiveIndicator" class="activeFlagCount">{{ activeIndicator }}</div>
    </div>
    <div v-if="isOpen" ref="container" class="popover b-popover bs-popover-bottom rounded-0" :style="floatingStyles">
      <div class="popover-body">
        <h3>Feature Flags:</h3>
        <div v-if="flags.length > 1" class="form-group form-check mb-1 toggleAll">
          <div class="custom-control custom-switch form-check-input">
            <input
              id="feature-flag-all"
              :checked="areAllFlagsEnabled"
              class="custom-control-input"
              type="checkbox"
              @change="toggleAll"
            />
            <label class="custom-control-label" for="feature-flag-all"> All </label>
          </div>
        </div>
        <div v-for="flag in flags" :key="flag.name" class="form-group form-check mb-1">
          <div class="custom-control custom-switch form-check-input">
            <input
              :id="`feature-flag-${flag.name}`"
              :checked="flag.value"
              class="custom-control-input"
              type="checkbox"
              @change="onChange(flag.name)"
            />
            <label class="custom-control-label" :for="`feature-flag-${flag.name}`">
              {{ flag.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@shared/scss/colors.scss';

.popover {
  min-width: 160px;
  max-width: initial;
}

.target {
  height: 1.25rem;
  cursor: pointer;
  user-select: none;

  .icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.toggleAll {
  padding-bottom: 4px;
  border-bottom: 1px solid $gray-200;
}

.activeFlagCount {
  position: absolute;
  right: -6px;
  bottom: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  font-size: 10px;
  font-weight: 600;
  color: white;
  background-color: var(--danger);
  border-radius: 50%;
}
</style>
