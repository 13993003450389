<script>
import _ from 'lodash';

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    dateTime: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    items() {
      const isArrayOrObject = x => _.isArray(x) || _.isObject(x);
      const filter = this.dateTime ? isArrayOrObject : _.isNumber;
      const items = _.filter(this.value, item => _.some(item.data, filter));
      return _.sortBy(items, 'legendOrder');
    },
    selected() {
      return _.filter(this.items, i => i.legendSelected);
    },
  },
  methods: {
    click(item) {
      if (this.selected.length === 1 && item.legendSelected) {
        return;
      }
      item.legendSelected = !item.legendSelected;
      this.$emit('input', this.value);
    },
    classes(item) {
      const base = ['legendItem'];
      if (!item.legendSelected) {
        return [...base, 'clickable', 'notSelected'];
      } else if (this.selected.length === 1) {
        return [...base, 'notClickable'];
      } else {
        return [...base, 'clickable'];
      }
    },
  },
};
</script>

<template>
  <div class="legend">
    <div v-for="item in items" :key="item.label" :class="classes(item)" @click="click(item)">
      <div :style="{ 'background-color': item.color }"></div>
      <div class="chartLegendText">{{ item.label }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.legend {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.legendItem {
  display: flex;
  align-items: center;
  margin-right: 1.2rem;
}

.legendItem div:first-child {
  width: 20px;
  height: 10px;
  margin-right: 0.5rem;
  border-radius: 8px;
}

.legendItem div:last-child {
  font-size: 0.8rem;
}

.clickable {
  cursor: pointer;
}

.notClickable {
  cursor: not-allowed;
}

.notSelected {
  opacity: 0.5;
}

.chartLegendText {
  max-width: 275px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include media-breakpoint-up(md) {
    max-width: 100%;
  }
}
</style>
