<script>
import Panel from '@shared/design/panels/Panel.vue';

export default {
  components: {
    Panel,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    centerContent: {
      type: Boolean,
      required: false,
      default: false,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    newHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    underlined: {
      type: Boolean,
      required: false,
      default: true,
    },
    headerItemSpacing: {
      type: String,
      required: false,
      default: '0.5rem',
    },
  },
};
</script>

<template>
  <Panel class="panelSection" :class="{ 'rounded-sm': rounded }">
    <header
      :class="{ newHeader: newHeader, underlined: newHeader && underlined }"
      :style="{ columnGap: headerItemSpacing }"
    >
      <slot name="header">
        <div>{{ header }}</div>
      </slot>
      <slot name="utility" />
    </header>
    <section :class="{ centerContent: centerContent }">
      <slot />
    </section>
  </Panel>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.panelSection {
  display: flex;
  flex-direction: column;

  // Move the padding from the panel to the header and section so that the scroll bar is at the edge of the panel
  padding: 0;

  > header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 0.5rem;
    min-height: 25px;
    padding: 1rem 1rem 0 1rem;
    font-size: 1rem;

    > div:first-child {
      flex: 1 0 auto;
      margin-right: 0.5rem;
      font-weight: 500;
      vertical-align: top;
    }
  }

  > section {
    padding: 1rem;
    overflow-x: auto;
  }
}

.centerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: -25px;
}

header.newHeader {
  padding: 0;
  margin: 1rem 1.5rem 0 1.5rem;

  &.underlined {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $gray-200;
  }
}
</style>
