<script>
import _ from 'lodash';

import authService from '@console/services/authService';

import BackgroundDonuts from '@shared/design/BackgroundDonuts.vue';

const waitSeconds = async seconds => {
  return new Promise(resolve => {
    setTimeout(resolve, seconds * 1000);
  });
};

export default {
  components: {
    BackgroundDonuts,
  },
  head: {
    title: 'Email Verification',
  },
  computed: {
    emailConfirmed() {
      return _.get(this.$route, 'query.status') === 'confirmed';
    },
  },
  async mounted() {
    if (this.emailConfirmed) {
      await waitSeconds(5);
      await authService.login({ to: '/' });
    }
  },
};
</script>

<template>
  <div class="box">
    <BackgroundDonuts />
    <div class="topsection">
      <img src="@console/assets/images/logo.svg" class="logo" />
    </div>
    <div class="wrapper">
      <div v-if="emailConfirmed">
        <h2 class="display-4 title confirmed">
          <BaseIcon name="check-circle" class="icon" />
          Email Address Confirmed!
        </h2>
        <p class="lead">
          Your email address has been successfully confirmed. You can now log in to your account. You will be
          automatically redirected to the login page in a few seconds. Or, simply click
          <a href="/" title="ProsperOps Login">here</a>.
        </p>
        <p class="lead">
          If you have any questions at any time, please do not hesitate to
          <a href="mailto:help@prosperops.com">email us</a>.
        </p>
      </div>
      <div v-else>
        <h2 class="display-4 title pending">
          <BaseIcon name="envelope" class="icon" />
          Thanks for signing up!
        </h2>
        <p class="lead">
          You should receive an email to confirm your account. You'll then be able to learn how much we can save you.
        </p>
        <p class="lead">
          If you have any questions at any time, please do not hesitate to
          <a href="mailto:help@prosperops.com">email us</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 2rem 5rem 2rem;
}

.topsection {
  margin-bottom: 0.75rem;
  text-align: center;
}

.wrapper {
  padding: 2rem;
  margin-top: 0.75rem;
  background-color: #fff;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);

  @media (min-width: 930px) {
    width: 900px;
  }
}

.logo {
  height: 2.4rem;
}

.title {
  padding-bottom: 1rem;
  font-size: 1.6rem;
}

.pending {
  color: map-get($theme-colors, 'primary');
}

.confirmed {
  color: map-get($theme-colors, 'success');
}

.icon {
  margin-right: 0.5rem;
}

.backgroundLogo {
  position: fixed;
  z-index: -5;
  display: block;
  overflow: hidden;
}

.backgroundLogo.medium {
  width: 380px;
  height: 380px;
}

.backgroundLogo.large {
  width: 860px;
  height: 860px;
}

.backgroundLogo.top {
  top: -150px;
  left: -150px;
}

.backgroundLogo.bottom {
  right: -400px;
  bottom: -400px;
}
</style>
