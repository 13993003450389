<script>
import Layout from '@console/Layout.vue';

export default {
  components: {
    Layout,
  },
  head: {
    title: 'Google Cloud Permissions',
  },
};
</script>

<template>
  <Layout>
    <template #default> Permissions </template>
  </Layout>
</template>
