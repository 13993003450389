<script>
import _ from 'lodash';
import { mapState } from 'pinia';
import { mapState as vuexMapState, mapGetters as vuexMapGetters } from 'vuex';

import { useAuthStore } from '@shared/state/auth.store';

import SecurityFooter from '@aws/components/accounts/SecurityFooter.vue';
import AddManagementAccount from '@aws/components/onboarding/AddManagementAccount.vue';
import AwsAccounts from '@aws/components/onboarding/AwsAccounts.vue';
import CheckOrganizationStatus from '@aws/components/onboarding/CheckOrganizationStatus.vue';
import Done from '@aws/components/onboarding/Done.vue';
import ScheduleAnalysis from '@aws/components/onboarding/ScheduleAnalysis.vue';
import UpdateManagementAccount from '@aws/components/onboarding/UpdateManagementAccount.vue';
import OnboardingProgress from '@console/components/onboarding/OnboardingProgress.vue';
import Subscribe from '@console/components/onboarding/Subscribe.vue';
import Layout from '@console/Layout.vue';

export default {
  components: {
    Layout,
    OnboardingProgress,
    SecurityFooter,
    AddManagementAccount,
    ScheduleAnalysis,
    UpdateManagementAccount,
    AwsAccounts,
    CheckOrganizationStatus,
    Subscribe,
    Done,
  },
  data() {
    return {
      allSteps: [
        {
          text: 'Initial AWS Account Access',
          key: 'add-management-account',
          visibleInProgressTracker: true,
          showHeader: true,
          showSecurityFooter: true,
        },
        {
          text: 'Free Savings Analysis',
          key: 'schedule-analysis',
          visibleInProgressTracker: true,
          showHeader: true,
          showSecurityFooter: false,
        },
        {
          text: 'Update Management Account Permissions',
          key: 'update-management-account',
          visibleInProgressTracker: true,
          showHeader: true,
          showSecurityFooter: true,
        },
        {
          text: 'Additional AWS Account Access',
          key: 'aws-accounts',
          visibleInProgressTracker: true,
          showHeader: true,
          showSecurityFooter: true,
        },
        {
          key: 'check-organization-status',
          visibleInProgressTracker: false,
          showHeader: false,
          showSecurityFooter: false,
        },
        {
          text: 'Review and Subscribe',
          key: 'subscribe',
          visibleInProgressTracker: true,
          showHeader: true,
          showSecurityFooter: false,
        },
        {
          key: 'done',
          visibleInProgressTracker: false,
          showHeader: false,
          showSecurityFooter: false,
        },
      ],
    };
  },
  head: {
    title: 'Onboarding',
  },
  computed: {
    ...vuexMapState('customer', ['selectedCompanyId']),
    ...mapState(useAuthStore, ['role']),
    ...vuexMapGetters('customer', ['isSubscribed', 'isReseller']),
    ...vuexMapGetters('aws', [
      'selectedOrganization',
      'selectedOrganizationCompletedSavingsAnalysis',
      'selectedOrganizationManagementAccountIsConfigured',
      'selectedOrganizationChildAccounts',
      'selectedOrganizationHasChildAccounts',
      'selectedOrganizationChildAccountsAreConfigured',
      'selectedOrganizationIsConfigured',
      'selectedOrganizationIsCurDataSource',
    ]),
    stepsForProgressTracker() {
      const isVisible = step => step.visibleInProgressTracker;
      const visibleSteps = _.filter(this.stepsForOrganization, isVisible);
      const isActive = step => step.key === this.currentStepKey;
      const enrich = (step, i) => ({
        ...step,
        number: i + 1,
        isActive: isActive(step),
      });
      return _.map(visibleSteps, enrich);
    },
    canSeeAwsAccountStep() {
      if (this.isReseller) {
        const isEndCustomer = a => a.status === 'ResellerEndCustomer';
        return (
          this.selectedOrganizationHasChildAccounts && !_.every(this.selectedOrganizationChildAccounts, isEndCustomer)
        );
      } else if (this.selectedOrganizationIsCurDataSource) {
        const isNoAccessRequired = a => a.status === 'NoAccessRequired';
        return (
          this.selectedOrganizationHasChildAccounts &&
          !_.every(this.selectedOrganizationChildAccounts, isNoAccessRequired)
        );
      }
      return this.selectedOrganizationHasChildAccounts;
    },
    stepsForOrganization() {
      let steps = this.allSteps;
      if (!this.selectedOrganization || !this.selectedOrganizationCompletedSavingsAnalysis) {
        return _.take(steps, 2);
      }
      if (this.isSubscribed && this.$route.query.step !== 'subscribe') {
        steps = _.reject(steps, { key: 'subscribe' });
      }
      if (!this.canSeeAwsAccountStep) {
        steps = _.reject(steps, { key: 'aws-accounts' });
      }
      return steps;
    },
    currentStep() {
      return _.find(this.allSteps, { key: this.currentStepKey });
    },
    currentStepKey() {
      if (this.$route.query.step) {
        return this.$route.query.step;
      }
      if (!this.selectedOrganization || this.currentUserIsViewer) {
        return 'add-management-account';
      }
      if (!this.selectedOrganizationCompletedSavingsAnalysis) {
        return 'schedule-analysis';
      }
      if (!this.selectedOrganizationManagementAccountIsConfigured) {
        return 'update-management-account';
      }
      if (!this.selectedOrganizationChildAccountsAreConfigured) {
        return 'aws-accounts';
      }
      if (!this.selectedOrganizationIsConfigured) {
        return 'check-organization-status';
      }
      if (!this.isSubscribed) {
        return 'subscribe';
      }
      return 'done';
    },
    isConstrained() {
      const fullScreenSteps = ['check-organization-status', 'done'];
      return !_.includes(fullScreenSteps, this.currentStepKey);
    },
    isPreSavingsAnalysis() {
      const preSavingsAnalysisSteps = ['add-management-account', 'schedule-analysis'];
      return _.includes(preSavingsAnalysisSteps, this.currentStepKey);
    },
    currentUserIsViewer() {
      return this.role(this.selectedCompanyId) === 'Viewer';
    },
  },
};
</script>

<template>
  <Layout :constrained="isConstrained" :with-footer="currentStep.showSecurityFooter" :with-announcement-banner="false">
    <template #default>
      <OnboardingProgress
        v-if="currentStep.visibleInProgressTracker && !currentUserIsViewer"
        class="pb-2"
        :steps="stepsForProgressTracker"
      />
      <component :is="currentStepKey" />
    </template>
    <template v-slot:footer>
      <SecurityFooter :initial-access="isPreSavingsAnalysis" />
    </template>
  </Layout>
</template>
