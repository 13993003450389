<script>
import { mapState } from 'pinia';

import { useAuthStore } from '@shared/state/auth.store';

export default {
  computed: {
    ...mapState(useAuthStore, ['isEmployee']),
  },
  created() {
    if (this.isEmployee) {
      window.removeEventListener('storage', this.storageChange, false);
      window.addEventListener('storage', this.storageChange, false);
    }
  },
  methods: {
    storageChange(event) {
      if (event.key === 'customers.federationContext' && this.$route.name !== 'session_ended') {
        this.$router.push({ name: 'session_ended' });
      }
    },
  },
  render() {
    return this.$slots.default;
  },
};
</script>
