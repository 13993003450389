<script setup>
import useUniqueId from '@shared/composables/useUniqueId';

import Tooltip from './Tooltip.vue';

defineProps({
  title: {
    type: String,
    required: true,
  },
});

const id = `info-tooltip-${useUniqueId()}`;
</script>

<template>
  <span class="wrapper text-primary">
    <div class="icon">
      <BaseIcon :id="id" name="info-circle" />
    </div>
    <Tooltip :target="id" placement="top">
      <slot />
    </Tooltip>
  </span>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.wrapper {
  display: inline-flex;
  align-items: center;
  margin-left: 0.5rem;
}
</style>
