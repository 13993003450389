<script>
import ConsoleNavigationMobileMenu from '@console/components/navigation/ConsoleNavigationMobileMenu.vue';
import HelpButton from '@console/components/navigation/HelpButton.vue';
import ProsperOpsLogo from '@console/components/navigation/ProsperOpsLogo.vue';
import TopBarMobileNavButton from '@console/components/navigation/TopBarMobileNavButton.vue';

export default {
  components: {
    HelpButton,
    ConsoleNavigationMobileMenu,
    ProsperOpsLogo,
    TopBarMobileNavButton,
  },
  data() {
    return {
      showNav: false,
    };
  },
  methods: {
    toggleNav() {
      this.showNav = !this.showNav;
    },
  },
};
</script>

<template>
  <div class="topBar">
    <div class="topBarContent">
      <ProsperOpsLogo class="logoSection" as-link />
      <div class="middleSection"></div>
      <div class="d-flex justify-content-center align-items-center">
        <div class="pr-1">
          <HelpButton />
        </div>
        <div>
          <TopBarMobileNavButton :open="showNav" @click="toggleNav" />
        </div>
      </div>
      <div></div>
    </div>
    <ConsoleNavigationMobileMenu v-if="showNav" class="menuMobile" />
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.topBar {
  z-index: 10;
  width: 100%;
  background: $gray-100;
}

.topBarContent {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.25rem 1rem;
  margin: 0;
}

.logoSection {
  width: calc(250px - 1rem); /* subtract 1rem to account for topBar padding */
}
.middleSection {
  display: block;
  flex-grow: 1;
  padding-left: 4rem;
  text-align: left;
}

.profileDropdown {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.menuOptions {
  height: 52px;
  font-size: 1.8rem;
  background-color: transparent;
  border: 0;
  outline: 0;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.menuOptions:hover {
  cursor: pointer;
}

.menuMobile {
  display: block;

  @include media-breakpoint-up(md) {
    display: none;
  }
}
</style>
