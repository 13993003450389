<script setup>
import StatusIndicator from '@shared/design/icons/StatusIndicator.vue';

const props = defineProps({
  text: {
    type: String,
    required: false,
    default: '',
  },
  subtext: {
    type: String,
    required: false,
    default: '',
  },
  status: {
    type: String,
    required: false,
    default: '',
  },
  disabled: {
    type: Boolean,
    required: false,
  },
  cloud: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: false,
    default: null,
  },
});

const route = {
  name: 'root',
  query: {
    contextCloud: props.cloud,
    contextId: props.id,
  },
};
</script>

<template>
  <RouterLink :to="route" class="link" :class="{ disabled: props.disabled }">
    <div class="icon">
      <slot name="icon">
        <StatusIndicator :status="props.status" />
      </slot>
    </div>
    <div class="content">
      <slot>
        <span class="text" :title="props.text">{{ props.text }}</span>
        <br />
        <small class="subtext" :title="props.subtext">{{ props.subtext }}</small>
      </slot>
    </div>
  </RouterLink>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20rem;
  min-height: 3rem;
  padding: 0.25rem 0.5rem;
  color: $body-color;
  text-decoration: none;

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.link:active,
.link:focus,
.link:hover {
  background-color: $gray-200;
  outline: 0;
}

.icon {
  flex-basis: 1.5rem;
  min-width: 1.5rem;
  margin-right: 0.5rem;

  > svg {
    display: block;
    margin: 0 auto;
  }
}

.content {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text {
  font-weight: 400;
}

.subtext {
  font-weight: 300;
}
</style>
