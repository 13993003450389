/* eslint-disable camelcase */

export type NonComputeService = 'elasti_cache' | 'memory_db' | 'open_search' | 'rds' | 'redshift';
export type AwsService = 'compute' | NonComputeService;

const awsBillingOfferingShortNames = ['Compute', 'ElastiCache', 'MemoryDb', 'OpenSearch', 'Rds', 'Redshift'] as const;
export const awsBillingOfferings = awsBillingOfferingShortNames.map(
  name => `AutonomousDiscountManagementForAws${name}` as const
);
export type AwsBillingOffering = typeof awsBillingOfferings[number];

export type BillingOfferingVariant = 'AwsComputeBaseFlex' | 'AwsComputeBaseFlexSmart' | 'AwsComputeInheritedBaseSmart';

export type AwsAccountStatus =
  | 'PendingIamSetup'
  | 'AwaitingProvisioning'
  | 'Provisioning'
  | 'ProvisioningError'
  | 'AccessError'
  | 'Active'
  | 'Ignored'
  | 'ResellerEndCustomer'
  | 'NoAccessRequired';

export interface AwsAccount {
  id: string;
  aws_organization_id: string;
  aws_account_number: string;
  friendly_name: string;
  aws_partition: string;
  status: AwsAccountStatus;
  status_by_offering: Partial<Record<AwsBillingOffering, AwsAccountStatus>>;
  is_reseller_base_shard?: boolean;
  reseller_flex_shard_region?: string;
  is_savings_plan_action_aws_account: boolean;
  action_account_regions: string[];
  elasti_cache_action_account_regions: string[];
  memory_db_action_account_regions: string[];
  open_search_action_account_regions: string[];
  rds_action_account_regions: string[];
  redshift_action_account_regions: string[];

  // The following fields are not part of the API response, but are populated by the store
  is_management_account: boolean;
}

export interface AccountValidationActionResponse {
  Action: string;
  Region: string;
  ActionAllowed: boolean;
  Error: string;
  Reason: string;
}

export interface AccountValidationError {
  code: string;
  errors: {
    context: {
      AccessFailures: string;
    };
  }[];
}

export interface ComputeBaseFlexPortfolioAllocation {
  unbilled_one_year_percentage?: number;
  unbilled_one_year_discount_percentage?: number;
  unbilled_three_year_percentage?: number;
  unbilled_three_year_discount_percentage?: number;
  base_one_year_percentage?: number;
  base_one_year_discount_percentage?: number;
  base_three_year_percentage?: number;
  base_three_year_discount_percentage?: number;
  flex_one_year_percentage?: number;
  flex_one_year_discount_percentage?: number;
  flex_three_year_percentage?: number;
  flex_three_year_discount_percentage?: number;
  flex_boost_one_year_percentage?: number;
  flex_boost_one_year_discount_percentage?: number;
  flex_boost_three_year_percentage?: number;
  flex_boost_three_year_discount_percentage?: number;
  on_demand_percentage?: number;
  effective_discount_percentage?: number;
}

export interface ComputeInheritedBaseSmartPortfolioAllocation {
  unbilled_one_year_percentage?: number;
  unbilled_one_year_discount_percentage?: number;
  unbilled_three_year_percentage?: number;
  unbilled_three_year_discount_percentage?: number;
  inherited_one_year_percentage?: number;
  inherited_one_year_discount_percentage?: number;
  inherited_three_year_percentage?: number;
  inherited_three_year_discount_percentage?: number;
  base_one_year_percentage?: number;
  base_one_year_discount_percentage?: number;
  base_three_year_percentage?: number;
  base_three_year_discount_percentage?: number;
  smart_one_year_percentage?: number;
  smart_one_year_discount_percentage?: number;
  smart_three_year_percentage?: number;
  smart_three_year_discount_percentage?: number;
  on_demand_percentage?: number;
  effective_discount_percentage?: number;
}

export interface NonComputePortfolioAllocation {
  inherited_one_year_percentage?: number;
  inherited_one_year_discount_percentage?: number;
  inherited_three_year_percentage?: number;
  inherited_three_year_discount_percentage?: number;
  smart_one_year_percentage?: number;
  smart_one_year_discount_percentage?: number;
  smart_three_year_percentage?: number;
  smart_three_year_discount_percentage?: number;
  on_demand_percentage?: number;
  effective_discount_percentage?: number;
}

export type ComputePortfolioAllocation = ComputeBaseFlexPortfolioAllocation &
  ComputeInheritedBaseSmartPortfolioAllocation;
export type PortfolioAllocation = ComputePortfolioAllocation & NonComputePortfolioAllocation;

export interface SavingsSpendCoverageBreakdown {
  base_target?: number;

  unbilled_spend_coverage?: number;
  unbilled_spend_coverage_percentage?: number;
  inherited_spend_coverage?: number;
  inherited_spend_coverage_percentage?: number;
  base_spend_coverage?: number;
  base_spend_coverage_percentage?: number;
  flex_spend_coverage?: number;
  flex_spend_coverage_percentage?: number;
  flex_boost_spend_coverage?: number;
  flex_boost_spend_coverage_percentage?: number;
  smart_spend_coverage?: number;
  smart_spend_coverage_percentage?: number;

  overall_spend_coverage?: number;
  overall_spend_coverage_percentage?: number;
}

export interface ComputeBaseFlexSpendCoverageTrend {
  month_start: string;
  on_demand?: number;
  flex?: number;
  flex_boost?: number;
  base_compute_savings_plans?: number;
  base_ec2_instance_savings_plans?: number;
  base_convertible_reserved_instances?: number;
  base_standard_reserved_instances?: number;
  unbilled?: number;
  base_spend_coverage_target?: number;
}

export interface ComputeInheritedBaseSmartSpendCoverageTrend {
  month_start: string;
  on_demand?: number;
  inherited_compute_savings_plans?: number;
  inherited_ec2_instance_savings_plans?: number;
  inherited_convertible_reserved_instances?: number;
  inherited_standard_reserved_instances?: number;
  base_compute_savings_plans?: number;
  smart_compute_savings_plans?: number;
  smart_ec2_instance_savings_plans?: number;
  smart_convertible_reserved_instances?: number;
  unbilled?: number;
  base_spend_coverage_target?: number;
}

export interface NonComputeSpendCoverageTrend {
  month_start: string;
  on_demand?: number;
  smart_reserved_coverage?: number;
  inherited_reserved_coverage?: number;
}

export type ComputeSpendCoverageTrend = ComputeBaseFlexSpendCoverageTrend & ComputeInheritedBaseSmartSpendCoverageTrend;
export type SpendCoverageTrend = ComputeSpendCoverageTrend & NonComputeSpendCoverageTrend;

/* ================================ shared commitments dashboard ================================ */
export interface AwsOrganizationCommitmentLockInRiskDetail {
  label: string;
  days: number;
}

export interface AwsOrganizationCurrentCommitmentLockInRisk {
  details: AwsOrganizationCommitmentLockInRiskDetail[];
  overall_days: number;
}

export interface AwsOrganizationTotalRemainingCommitmentLineItem {
  label: string;
  dollars: number;
  percentage: number;
}

export interface AwsOrganizationTotalRemainingCommitment {
  line_items: AwsOrganizationTotalRemainingCommitmentLineItem[];
  overall_dollars: number;
}

export interface AwsOrganizationCommitmentLockInRiskTrendSeries {
  label: string;
  days: (number | null)[];
}

export interface AwsOrganizationCommitmentLockInRiskTrend {
  series_data: AwsOrganizationCommitmentLockInRiskTrendSeries[];
  overall_days: (number | null)[];
}

export interface CommitmentLockInRiskRangeStats {
  min: number;
  max: number;
  average: number;
}

export interface AwsOrganizationCommitmentLockInRiskTrailingTrend {
  overall: (CommitmentLockInRiskRangeStats | null)[];
}

/* ================================ non-compute commitments dashboard ================================ */
export interface AwsOrganizationNonComputeCommitmentBurndownSeries {
  label: string;
  data: (number | null)[];
}

export interface AwsOrganizationNonComputeCommitmentBurndown {
  series_data: AwsOrganizationNonComputeCommitmentBurndownSeries[];
  enterprise_discount_program_expiration_date: string | null;
}

export interface FilteredNonComputeCommitmentsDashboard {
  amortized_daily_commitment_burndown: AwsOrganizationNonComputeCommitmentBurndown;
  actual_daily_commitment_burndown: AwsOrganizationNonComputeCommitmentBurndown;
  amortized_cumulative_commitment_burndown: AwsOrganizationNonComputeCommitmentBurndown;
  actual_cumulative_commitment_burndown: AwsOrganizationNonComputeCommitmentBurndown;
  amortized_total_remaining_commitment: AwsOrganizationTotalRemainingCommitment;
  actual_total_remaining_commitment: AwsOrganizationTotalRemainingCommitment;
  current_commitment_lock_in_risk: AwsOrganizationCurrentCommitmentLockInRisk;
  commitment_lock_in_risk_trailing_trend: AwsOrganizationCommitmentLockInRiskTrailingTrend;
  weighted_average_duration_trend: AwsOrganizationCommitmentLockInRiskTrend;
}

export interface RegionResponse {
  system_name: string;
  display_name: string;
}

export interface RegionFilter {
  region: RegionResponse;
  match_keys: string[];
}

export interface AwsOrganizationNonComputeCommitmentsDashboard {
  version: number | null;
  data_through_date: string;
  region_filters: RegionFilter[];
  filtered_dashboards: { [key: string]: FilteredNonComputeCommitmentsDashboard };
}

export interface NonComputeCommitmentsDashboardResponse {
  id: string;
  aws_organization_id: string;
  dashboard: AwsOrganizationNonComputeCommitmentsDashboard;
}

type AwsAccountId = string;

/* ================================ compute commitments dashboard ================================ */
export interface AwsOrganizationWeightedAverageDurationTrend {
  convertible_reserved_instances_days: (number | null)[];
  compute_savings_plans_days: (number | null)[];
  standard_reserved_instances_days: (number | null)[];
  ec2_instance_savings_plans_days: (number | null)[];
  overall_days: (number | null)[];
}

export interface AwsOrganizationTotalRemainingComputeCommitment {
  convertible_reserved_instances_dollars: number | null;
  compute_savings_plans_dollars: number | null;
  standard_reserved_instances_dollars: number | null;
  ec2_instance_savings_plans_dollars: number | null;
  overall_dollars: number;
  convertible_reserved_instances_percentage: number | null;
  compute_savings_plans_percentage: number | null;
  standard_reserved_instances_percentage: number | null;
  ec2_instance_savings_plans_percentage: number | null;
}

export interface AwsOrganizationCurrentWeightedAverageDuration {
  convertible_reserved_instances_days: number | null;
  compute_savings_plans_days: number | null;
  standard_reserved_instances_days: number | null;
  ec2_instance_savings_plans_days: number | null;
  overall_days: number | null;
}

export interface AwsOrganizationComputeCommitmentBurndown {
  convertible_reserved_instances: (number | null)[];
  compute_savings_plans: (number | null)[];
  standard_reserved_instances: (number | null)[];
  ec2_instance_savings_plans: (number | null)[];
  enterprise_discount_program_expiration_date: string | null;
}

export interface AwsOrganizationConvertibleReservedInstanceExpirationTimelineExpirationDateDetail {
  expiration_date: string;
  amortized_hourly_commitment: number;
  percentage_of_regional_convertible_reserved_instance_commitment: number;
  is_seed: boolean;
}

export interface AwsOrganizationConvertibleReservedInstanceExpirationTimelineDetail {
  years: number;
  offering_type: string;
  expiration_date_details: AwsOrganizationConvertibleReservedInstanceExpirationTimelineExpirationDateDetail[];
}

export interface AwsOrganizationConvertibleReservedInstanceExpirationTimeline {
  region: string;
  region_display_name: string;
  aws_account_id: AwsAccountId;
  aws_account_number: string;
  friendly_name: string;
  is_ri_action_account: boolean;
  details: AwsOrganizationConvertibleReservedInstanceExpirationTimelineDetail[];
}

export interface AwsOrganizationComputeCommitmentsDashboard {
  version: number | null;
  data_through_date: string;
  amortized_daily_commitment_burndown: AwsOrganizationComputeCommitmentBurndown;
  actual_daily_commitment_burndown: AwsOrganizationComputeCommitmentBurndown;
  amortized_cumulative_commitment_burndown: AwsOrganizationComputeCommitmentBurndown;
  actual_cumulative_commitment_burndown: AwsOrganizationComputeCommitmentBurndown;
  current_commitment_lock_in_risk: AwsOrganizationCurrentWeightedAverageDuration;
  current_commitment_lock_in_risk_trailing: AwsOrganizationCurrentWeightedAverageDuration;
  amortized_total_remaining_commitment: AwsOrganizationTotalRemainingComputeCommitment;
  actual_total_remaining_commitment: AwsOrganizationTotalRemainingComputeCommitment;
  commitment_lock_in_risk_trend: AwsOrganizationWeightedAverageDurationTrend;
  convertible_reserved_instance_expiration_timelines: AwsOrganizationConvertibleReservedInstanceExpirationTimeline[];
  commitment_lock_in_risk_trailing_trend: AwsOrganizationCommitmentLockInRiskTrailingTrend;
}

export interface ComputeCommitmentsDashboardResponse {
  id: string;
  aws_organization_id: string;
  dashboard: AwsOrganizationComputeCommitmentsDashboard;
}

export interface MonthStart {
  key: string;
  friendly_name: string;
}

export interface AwsOrganizationUsageResponse {
  updated_date: string;
  usage_period_start: string;
  usage_period_end: string;
  usage_days: number;

  ec2_normalized_usage: number;
  fargate_normalized_usage: number;
  lambda_normalized_usage: number;
  compute_normalized_usage: number;

  elasti_cache_normalized_usage: number;
  elasti_cache_normalized_gross_savings: number;
  elasti_cache_normalized_waste: number;
  elasti_cache_normalized_spend: number;
  elasti_cache_normalized_effective_savings_rate: number;

  memory_db_normalized_usage: number;
  memory_db_normalized_gross_savings: number;
  memory_db_normalized_waste: number;
  memory_db_normalized_spend: number;
  memory_db_normalized_effective_savings_rate: number;

  open_search_normalized_usage: number;
  open_search_normalized_gross_savings: number;
  open_search_normalized_waste: number;
  open_search_normalized_spend: number;
  open_search_normalized_effective_savings_rate: number;

  rds_normalized_usage: number;
  rds_normalized_gross_savings: number;
  rds_normalized_waste: number;
  rds_normalized_spend: number;
  rds_normalized_effective_savings_rate: number;

  redshift_normalized_usage: number;
  redshift_normalized_gross_savings: number;
  redshift_normalized_waste: number;
  redshift_normalized_spend: number;
  redshift_normalized_effective_savings_rate: number;
}

/* ================================ non-compute savings dashboard ================================ */
export interface NonComputeNetSavingsTrendMonth {
  month_start: string;
  service_usage: number | null;
  net_savings: number | null;
  effective_savings_rate_percentage: number | null;
  baseline_effective_savings_rate_percentage: number | null;
}

export interface NonComputePortfolioDistribution {
  on_demand_percentage: number;
  inherited_one_year_percentage: number;
  inherited_three_year_percentage: number;
  smart_one_year_percentage: number;
  smart_three_year_percentage: number;
  inherited_one_year_discount_percentage: number;
  inherited_three_year_discount_percentage: number;
  smart_one_year_discount_percentage: number;
  smart_three_year_discount_percentage: number;
  effective_discount_percentage: number;
}

export interface NonComputeSpendCoverageSummary {
  inherited_spend_coverage: number;
  inherited_spend_coverage_percentage: number;
  smart_spend_coverage: number;
  smart_spend_coverage_percentage: number;
  overall_spend_coverage: number;
  overall_spend_coverage_percentage: number;
}

export interface NonComputeDailySpendCoverageDay {
  day: number;
  service_usage: number | null;
  inherited_spend_coverage: number | null;
  smart_spend_coverage: number | null;
  overall_spend_coverage_percentage: number | null;
}

export interface NonComputeDailySavingsDay {
  day: number;
  inherited_savings: number | null;
  smart_savings: number | null;
}

export interface NonComputeKeyMetrics {
  portfolio_actions: number;
  reserved_instance_utilization_percentage: number;
}

export interface AwsOrganizationNonComputeSavingsBreakdownPeriod {
  month_start: string;
  period_title: string;
  sort_order: number;
  is_finalized: boolean;
  service_usage: number;
  service_usage_normalized: number;
  service_usage_delta: number | null;
  service_usage_delta_percentage: number | null;
  service_spend: number;
  service_spend_normalized: number;
  gross_savings: number;
  gross_savings_normalized: number;
  inherited_savings: number | null;
  inherited_savings_normalized: number | null;
  smart_savings: number | null;
  smart_savings_normalized: number | null;
  savings_share: number | null;
  savings_share_normalized: number | null;
  net_savings: number | null;
  net_savings_normalized: number | null;
  net_savings_delta: number | null;
  net_savings_delta_percentage: number | null;
  incremental_savings: number | null;
  incremental_savings_normalized: number | null;
}

export interface EffectiveSavingsRate {
  current: number;
  prior_to_subscription: number | null;
  is_prior_to_subscription_overridden: boolean;
  actual_management_fees: number | null;
  estimated_management_fees: number | null;
}

export interface NonComputeSavingsSummary {
  reservation_savings: number;
  total_savings: number;
}

export interface AwsOrganizationNonComputeSavingsDashboard {
  version: number | null;
  is_finalized: boolean;
  data_through_date: string;
  subscription_start_date: string | null;
  savings_summary: NonComputeSavingsSummary;
  effective_savings_rate: EffectiveSavingsRate;
  savings_breakdown_periods: AwsOrganizationNonComputeSavingsBreakdownPeriod[];
  key_metrics: NonComputeKeyMetrics;
  daily_savings: NonComputeDailySavingsDay[];
  daily_spend_coverage: NonComputeDailySpendCoverageDay[];
  daily_spend_coverage_by_region: { [key: string]: NonComputeDailySpendCoverageDay[] };
  daily_spend_coverage_by_match_key: { [key: string]: NonComputeDailySpendCoverageDay[] };
  spend_coverage_summary: NonComputeSpendCoverageSummary;
  portfolio_distribution: NonComputePortfolioDistribution;
  net_savings_trend: NonComputeNetSavingsTrendMonth[];
  spend_coverage_trend: NonComputeSpendCoverageTrend[];
  spend_coverage_trend_by_region: { [key: string]: NonComputeSpendCoverageTrend[] };
  spend_coverage_trend_by_match_key: { [key: string]: NonComputeSpendCoverageTrend[] };
  savings_impacted_by_inherited: boolean;
  pre_subscription_effective_savings_rate_days?: number;
}

export interface NonComputeSavingsDashboardResponse {
  id: string;
  aws_organization_id: string;
  month_start: string;
  dashboard: AwsOrganizationNonComputeSavingsDashboard;
  selected_timeframe: MonthStart;
  available_timeframes: MonthStart[];
}

export type NonComputeSettingName =
  | 'AnchorDayAndHour'
  | 'CommitDropDampening'
  | 'LookbackDays'
  | 'MaximumCoverageTarget'
  | 'MinimumCoverage'
  | 'PaymentTermPreference'
  | 'Mode'
  | 'VacateByDate';

export interface NonComputeMatchKeySettingsSummary {
  match_key: string;
  region: RegionResponse;
  settings: Record<NonComputeSettingName, string>;
}

export interface NonComputeSettingsSummary {
  updated_date: string | null;
  global_settings: Record<NonComputeSettingName, string>;
  match_key_settings: NonComputeMatchKeySettingsSummary[];
}

export interface CustomerSettingResponse {
  id: string;
  aws_organization_id: string;
  setting_name: string;
  setting_value: string;
}

export enum CustomerDataExportValidationStatus {
  Enabled = 'Enabled',
  Error = 'Error',
  PendingIamValidation = 'PendingIamValidation',
}

export interface CustomerDataExportIamValidationResponse {
  id: string;
  aws_organization_id: string;
  status_code: CustomerDataExportValidationStatus;
  error_message: string;
  created_date: string;
  updated_date: string;
}
export interface SavingsSummary {
  ec2_reserved_instance_savings: number;
  savings_plan_savings: number | null;
  total_savings: number;
}

export interface AwsOrganizationSavingsBreakdownPeriod {
  month_start: string;
  period_title: string;
  sort_order: number;
  is_finalized: boolean;
  compute_usage: number;
  compute_usage_normalized: number;
  compute_usage_delta: number | null;
  compute_usage_delta_percentage: number | null;
  compute_spend: number;
  compute_spend_normalized: number;
  gross_savings: number;
  gross_savings_normalized: number;
  unbilled_savings: number | null;
  unbilled_savings_normalized: number | null;
  inherited_savings: number | null;
  inherited_savings_normalized: number | null;
  base_savings: number | null;
  base_savings_normalized: number | null;
  flex_savings: number | null;
  flex_savings_normalized: number | null;
  flex_boost_savings: number | null;
  flex_boost_savings_normalized: number | null;
  smart_savings: number | null;
  smart_savings_normalized: number | null;
  savings_share: number | null;
  savings_share_normalized: number | null;
  net_savings: number | null;
  net_savings_normalized: number | null;
  net_savings_delta: number | null;
  net_savings_delta_percentage: number | null;
  incremental_savings: number | null;
  incremental_savings_normalized: number | null;
  reseller_total_compute_usage: number | null;
  reseller_total_compute_usage_normalized: number | null;
  reseller_total_compute_usage_delta: number | null;
  reseller_total_compute_usage_delta_percentage: number | null;
  reseller_end_customer_spend_coverage: number | null;
  reseller_end_customer_spend_coverage_normalized: number | null;
  reseller_end_customer_spend_coverage_delta: number | null;
  reseller_end_customer_spend_coverage_delta_percentage: number | null;
}

export interface KeyMetrics {
  portfolio_actions: number;
  ec2_instance_changes: number;
  reserved_instance_utilization_percentage: number;
  savings_plan_utilization_percentage: number | null;
}

export interface DailySavingsDay {
  day: number;
  unbilled_savings: number | null;
  inherited_savings: number | null;
  base_savings: number | null;
  flex_savings: number | null;
  flex_boost_savings: number | null;
  smart_savings: number | null;
}

export interface DailySpendCoverageDay {
  day: number;
  compute_usage: number | null;
  unbilled_spend_coverage: number | null;
  inherited_spend_coverage: number | null;
  base_spend_coverage: number | null;
  flex_spend_coverage: number | null;
  flex_boost_spend_coverage: number | null;
  smart_spend_coverage: number | null;
  overall_spend_coverage_percentage: number | null;
}

export interface SpendCoverageSummary {
  base_target: number | null;
  unbilled_spend_coverage: number;
  unbilled_spend_coverage_percentage: number;
  inherited_spend_coverage: number;
  inherited_spend_coverage_percentage: number;
  base_spend_coverage: number;
  base_spend_coverage_percentage: number;
  flex_spend_coverage: number;
  flex_spend_coverage_percentage: number;
  flex_boost_spend_coverage: number;
  flex_boost_spend_coverage_percentage: number;
  smart_spend_coverage: number;
  smart_spend_coverage_percentage: number;
  overall_spend_coverage: number;
  overall_spend_coverage_percentage: number;
}

export interface PortfolioDistribution {
  unbilled_one_year_percentage: number;
  unbilled_three_year_percentage: number;
  inherited_one_year_percentage: number;
  inherited_three_year_percentage: number;
  base_one_year_percentage: number;
  base_three_year_percentage: number;
  flex_one_year_percentage: number;
  flex_three_year_percentage: number;
  flex_boost_one_year_percentage: number;
  flex_boost_three_year_percentage: number;
  smart_one_year_percentage: number;
  smart_three_year_percentage: number;
  unbilled_one_year_discount_percentage: number;
  unbilled_three_year_discount_percentage: number;
  inherited_one_year_discount_percentage: number;
  inherited_three_year_discount_percentage: number;
  base_one_year_discount_percentage: number;
  base_three_year_discount_percentage: number;
  flex_one_year_discount_percentage: number;
  flex_three_year_discount_percentage: number;
  flex_boost_one_year_discount_percentage: number;
  flex_boost_three_year_discount_percentage: number;
  smart_one_year_discount_percentage: number;
  smart_three_year_discount_percentage: number;
  on_demand_percentage: number;
  effective_discount_percentage: number;
}

export interface NetSavingsTrendMonth {
  month_start: string;
  compute_usage: number | null;
  net_savings: number | null;
  effective_savings_rate_percentage: number | null;
  baseline_effective_savings_rate_percentage: number | null;
}

export interface SpendCoverageTrendMonth {
  month_start: string;
  on_demand: number | null;
  smart_compute_savings_plans: number | null;
  smart_ec2_instance_savings_plans: number | null;
  smart_convertible_reserved_instances: number | null;
  flex: number | null;
  flex_boost: number | null;
  base_compute_savings_plans: number | null;
  base_ec2_instance_savings_plans: number | null;
  base_convertible_reserved_instances: number | null;
  base_standard_reserved_instances: number | null;
  inherited_compute_savings_plans: number | null;
  inherited_ec2_instance_savings_plans: number | null;
  inherited_convertible_reserved_instances: number | null;
  inherited_standard_reserved_instances: number | null;
  unbilled: number | null;
  base_spend_coverage_target: number | null;
}

export interface ArbitrageOpportunity {
  end_customer_spend_coverage_percentage: number;
  arbitrage_spend_coverage_percentage: number;
  uncovered_usage_percentage: number;
}

export interface ArbitrageOpportunityTrendMonth {
  month_start: string;
  end_customer_spend_coverage: number;
  arbitrage_spend_coverage: number;
  uncovered_usage: number;
}

export interface AwsOrganizationComputeSavingsDashboard {
  version: number | null;
  is_finalized: boolean;
  data_through_date: string;
  subscription_start_date: string | null;
  variant: BillingOfferingVariant;
  savings_summary: SavingsSummary;
  effective_savings_rate: EffectiveSavingsRate;
  savings_breakdown_periods: AwsOrganizationSavingsBreakdownPeriod[];
  key_metrics: KeyMetrics;
  daily_savings: DailySavingsDay[];
  daily_spend_coverage: DailySpendCoverageDay[];
  spend_coverage_summary: SpendCoverageSummary;
  portfolio_distribution: PortfolioDistribution;
  net_savings_trend: NetSavingsTrendMonth[];
  spend_coverage_trend: SpendCoverageTrendMonth[];
  savings_impacted_by_inherited: boolean;
  pre_subscription_effective_savings_rate_days: number | null;
  arbitrage_opportunity: ArbitrageOpportunity;
  arbitrage_opportunity_trend: ArbitrageOpportunityTrendMonth[];
  ec2_cyclicality_detected: boolean;
  global_cyclicality_detected: boolean;
}

export interface ComputeSavingsDashboardResponse {
  id: string;
  aws_organization_id: string;
  month_start: string;
  dashboard: AwsOrganizationComputeSavingsDashboard;
  selected_timeframe: MonthStart;
  available_timeframes: MonthStart[];
}
