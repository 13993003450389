<script>
export default {
  props: {
    borderVariant: {
      type: String,
      default: 'none',
      validator(value) {
        return ['none', 'outlined'].indexOf(value) !== -1;
      },
    },
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['info', 'success', 'warning', 'error'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    icon() {
      const icons = {
        info: { name: 'lightbulb', variant: 'far' },
        success: { name: 'check' },
        warning: { name: 'exclamation-triangle' },
        error: { name: 'exclamation-triangle' },
      };
      return icons[this.type];
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <div class="box rounded-sm">
        <div class="left h-100" :class="type">
          <slot name="icon">
            <BaseIcon v-bind="icon" />
          </slot>
        </div>
        <div :class="{ content: true, border: borderVariant === 'outlined' }">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.box {
  display: flex;
  height: 100%;
  margin: 0;
  background-color: #fff;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}

.content {
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 75px;
  max-width: 75px;
  height: 100%;
  font-size: 1.2rem;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.info {
  color: white;
  background: map-get($theme-colors, 'primary');
}

.success {
  color: white;
  background: map-get($theme-colors, 'success');
}

.warning {
  color: #212529;
  background: map-get($theme-colors, 'warning');
}

.error {
  color: white;
  background: map-get($theme-colors, 'danger');
}
</style>
