<script>
import FeatureFlagsButton from './FeatureFlagsButton.vue';
import HelpButton from '@console/components/navigation/HelpButton.vue';
import IncrementalLifetimeSavings from '@console/components/navigation/IncrementalLifetimeSavings.vue';
import LifetimeSavings from '@console/components/navigation/LifetimeSavings.vue';
import ProfileButton from '@console/components/navigation/ProfileButton.vue';
import ProsperOpsLogo from '@console/components/navigation/ProsperOpsLogo.vue';

export default {
  components: {
    HelpButton,
    FeatureFlagsButton,
    ProfileButton,
    LifetimeSavings,
    IncrementalLifetimeSavings,
    ProsperOpsLogo,
  },
};
</script>

<template>
  <div class="topBar">
    <div class="topBarContent">
      <ProsperOpsLogo class="logoSection" as-link />
      <div class="middleSection d-flex align-items-center">
        <LifetimeSavings class="lifetimeSavings" />
        <IncrementalLifetimeSavings class="lifetimeSavings incremental" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div class="pr-1">
          <FeatureFlagsButton />
        </div>
        <div class="pr-1">
          <HelpButton />
        </div>
        <div>
          <ProfileButton />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.topBar {
  z-index: 10;
  width: 100%;
  background: $gray-100;
}

.topBarContent {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.25rem 1rem;
  margin: 0;
}

.logoSection {
  width: calc(265px - 1rem); /* subtract 1rem to account for topBar padding */
}

.middleSection {
  flex-grow: 1;
  padding-left: 4rem;
  text-align: left;
}

.lifetimeSavings {
  font-size: 1.1rem;
  color: $gray-700;
}

.incremental {
  min-width: 170px;
  margin-left: 5%;
}
</style>
