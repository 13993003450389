<script setup lang="ts">
import { computed } from 'vue';

import CalendlyWidget from '@console/components/widgets/CalendlyWidget.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';

const calendlyRoutingFormId = computed(() => {
  // Use "early" route when day of month is <= 3
  return new Date().getUTCDate() > 3 ? 'cp6f-2w3-2nf' : 'cpg8-nk3-td6';
});
</script>

<template>
  <div>
    <div class="row pt-4">
      <div class="col">
        <h2>Free Savings Analysis</h2>
      </div>
    </div>
    <BoxMessage type="success" class="pt-4">
      <strong> We've verified our access and your savings opportunities are now being analyzed! </strong>
      <p class="mb-0">
        Our savings analysis is comprehensive and to ensure you understand the findings, an expert from our team
        personally reviews them with you. It takes about an hour, sometimes less. After the review, you'll get a copy of
        our analysis to keep. Our goal is to arm you with data to make an informed decision. Let's go ahead and get that
        scheduled.
      </p>
    </BoxMessage>
    <div class="row pt-4">
      <div class="col">
        <CalendlyWidget :routing-form-id="calendlyRoutingFormId" />
      </div>
    </div>
  </div>
</template>
