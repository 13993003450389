<script lang="ts" setup>
import type { SettingsSummary } from '@console/services/gcp/api.models';

import { useHead } from '@unhead/vue';
import { computed, ref, onMounted } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';

import RequiresRole from '@console/components/RequiresRole.vue';
import SettingRow from '@console/components/settings/SettingRow.vue';
import StatusIndicator from '@console/components/settings/StatusIndicator.vue';
import CalendlyButton from '@console/components/widgets/CalendlyButton.vue';
import Layout from '@console/Layout.vue';
import BaseTarget from '@gcp/components/settings/BaseTarget.vue';
import FeatureFlagSwitch from '@shared/components/FeatureFlagSwitch.vue';
import BadgeSwitch from '@shared/design/BadgeSwitch.vue';
import InfoModal from '@shared/design/InfoModal.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import LastUpdated from '@shared/design/panels/LastUpdated.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const store = useVuexStore();
const settings = ref<SettingsSummary>();
const loading = ref(true);
const context = computed(() => store.getters['nav/context']);
const getBillingAccountSettingsById = computed(() => store.getters['gcp/getBillingAccountSettingsById']);

onMounted(async () => {
  settings.value = await getBillingAccountSettingsById.value(context.value.id);
  loading.value = false;
});
useHead({
  title: 'Google Cloud Compute Settings',
});

const getRegionFromMatchKey = (matchKey: string): string => {
  return matchKey.split(':')[0];
};

const getMachineSeriesFromMatchKey = (matchKey: string): string => {
  return matchKey.split(':').slice(1).join('');
};
</script>

<template>
  <FeatureFlagSwitch feature-flag="gcpSettings">
    <Layout :loading="loading">
      <template #default>
        <div v-if="settings">
          <PageHeader>
            <h1>Compute Settings</h1>
          </PageHeader>
          <div class="row sectional">
            <div class="col">
              <PanelSection header="Status" rounded new-header class="settingsConstrainedWidth">
                <SettingRow class="settingRow">
                  <template v-slot:label> Managing Commitment</template>
                  <template v-slot:description>
                    Deploying a new commitment and optimizing existing commitment using a Base layer and Smart layer.
                    The Smart layer utilizes Adaptive Laddering strategy which dynamically adjusts coverage with usage
                    changes.
                  </template>
                  <template v-slot:value>
                    <StatusIndicator :enabled="settings.commitment_management_enabled" />
                  </template>
                </SettingRow>
              </PanelSection>
            </div>
          </div>
          <div class="row sectional">
            <div class="col">
              <PanelSection header="Base" rounded new-header class="settingsConstrainedWidth">
                <template v-slot:utility>
                  <LastUpdated :value="settings.base_last_updated" />
                </template>
                <SettingRow class="settingRow">
                  <template v-slot:label> Base Target </template>
                  <template v-slot:description>
                    Desired amount of Compute Flex CUD spend coverage to maintain (in on demand equivalent dollars for a
                    30 day month)
                  </template>
                  <template v-slot:value>
                    <BaseTarget :settings="settings" />
                  </template>
                </SettingRow>
              </PanelSection>
            </div>
          </div>
          <div class="row sectional">
            <div class="col">
              <PanelSection header="Smart: Global" rounded new-header class="settingsConstrainedWidth">
                <template v-slot:utility>
                  <LastUpdated :value="settings.smart_last_updated" />
                </template>
                <SettingRow class="regionSettingRow">
                  <template v-slot:label>
                    Mode
                    <InfoModal title="Global Modes" class="text-dark">
                      <p>
                        <strong>Smart:</strong> Usage is continually analyzed by ProsperOps and autonomous discount
                        management of current and new match keys automatically starts/stops based on changing usage
                        patterns, scale, stability, etc. PropserOps algorithms automatically determine and adapt the
                        size and spread of commitments coverage. To opt-out a specific match key, configure a Match Key
                        Setting with a mode of <strong>Disabled</strong>. To always have ProsperOps manage a specific
                        match key, configure a Match Key Setting with a mode of <strong>Seeding and Rolling</strong> or
                        <strong>Enabled</strong>.
                      </p>
                      <p>
                        <strong>Seeding and Rolling:</strong>
                        ProsperOps periodically deploys microcommitments in match keys with reasource-based coverage and
                        term-optimized commitments reaching expiration.
                      </p>
                      <p>
                        <strong>Disabled:</strong> By default, ProsperOps manages no match keys. To opt-in a specific
                        match key, configure a Match Key Setting with a mode of <strong>Smart</strong>,
                        <strong>Seeding and Rolling</strong>, or <strong>Enabled</strong>.
                      </p>
                    </InfoModal>
                  </template>
                  <template v-slot:value>
                    <span class="font-weight-bold">{{ settings.mode }}</span>
                  </template>
                </SettingRow>
                <SettingRow class="settingRow">
                  <template v-slot:label>Discount Shift</template>
                  <template v-slot:description>
                    Enables progressive upgrades of 1-year CUDs to 3-year CUDs while there is rolling, term-optimized
                    commitment.
                  </template>
                  <template v-slot:value>
                    <BadgeSwitch :enabled="settings.discount_shift" primary />
                  </template>
                </SettingRow>
              </PanelSection>
            </div>
          </div>
          <div class="row sectional">
            <div class="col">
              <PanelSection header="Smart: Match Key" rounded new-header class="settingsConstrainedWidth">
                <template v-slot:utility>
                  <LastUpdated :value="settings.match_key_last_updated" />
                </template>
                <div v-if="!settings.match_keys?.length" class="noData text-muted">
                  No match key settings configured.
                </div>
                <div v-else class="tableWrapper">
                  <table class="table table-striped matchKeyTable">
                    <thead>
                      <tr class="matchKeyHeader">
                        <th>Region</th>
                        <th>Machine Series</th>
                        <th>
                          Mode
                          <InfoModal title="Match Key Modes" class="text-dark">
                            <p>
                              <strong>Smart:</strong> Usage for a specific match key is continually analyzed by
                              ProsperOps and autonomous discount management automatically starts/stops based on changing
                              usage patterns, scale, stability, etc. ProsperOps algorithms automatically determine and
                              adapt the size and spread of commitments coverage.
                            </p>
                            <p>
                              <strong>Seeding and Rolling:</strong>
                              ProsperOps periodically deploys microcommitments in match keys with reasource-based
                              coverage and term-optimized commitments reaching expiration.
                            </p>
                            <p>
                              <strong>Enabled:</strong> Usage for a specific match key is continually analyzed by
                              ProsperOps, but unlike Smart mode, ProsperOps will not automatically stop managing
                              commitments for match keys with small amounts of usage. Use this mode when you are certain
                              small amounts of usage will persist.
                            </p>
                            <p>
                              <strong>Disabled:</strong> ProsperOps will not manage commitments regardless of match key
                              usage.
                            </p>
                          </InfoModal>
                        </th>
                        <th class="text-center">Discount Shift</th>
                        <th class="text-center" style="width: 97px">3 Year Min vCPU</th>
                        <th class="text-center" style="width: 97px">3 Year Min GB RAM</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="setting in settings.match_keys" :key="`${setting.match_key}_row`">
                        <tr>
                          <td>{{ getRegionFromMatchKey(setting.match_key) }}</td>
                          <td>{{ getMachineSeriesFromMatchKey(setting.match_key) }}</td>
                          <td>{{ setting.mode }}</td>
                          <template v-if="setting.mode !== 'Disabled'">
                            <td class="text-center" :class="{ 'text-muted': !setting.discount_shift }">
                              {{ setting.discount_shift ? 'On' : 'Off' }}
                            </td>
                            <td class="text-center" :class="{ global: !setting.three_year_minimum_v_cpu }">
                              {{ setting.three_year_minimum_v_cpu ? 0 : '-' }}
                            </td>
                            <td class="text-center" :class="{ global: !setting.three_year_minimum_gb_ram }">
                              {{ setting.three_year_minimum_gb_ram ? 0 : '-' }}
                            </td>
                          </template>
                          <template v-else>
                            <td></td>
                            <td></td>
                            <td></td>
                          </template>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </PanelSection>
            </div>
          </div>
          <RequiresRole atleast="Editor">
            <div class="row pt-3">
              <div class="col">
                <div class="updateSettingsConstrainedWidth">
                  <p>
                    <strong>Want to update your settings?</strong> We personally review setting adjustments with you to
                    ensure you are safely saving the most possible. Please click the button below to schedule a time to
                    discuss. For urgent questions or updates, please chat with us.
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="updateSettingsConstrainedWidth updateButton">
                  <CalendlyButton routing-form-id="cpmm-d98-7t3">
                    <span>Update Settings</span>
                  </CalendlyButton>
                </div>
              </div>
            </div>
          </RequiresRole>
        </div>
      </template>
    </Layout>
  </FeatureFlagSwitch>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.settingsConstrainedWidth {
  max-width: 850px;

  :deep(section) {
    padding: 0.5rem 1.5rem 2rem;
  }
}

.updateSettingsConstrainedWidth {
  max-width: 850px;
}

.updateButton {
  min-height: 500px;
}

.calendly {
  margin-top: -32px;
}

.subheader {
  position: relative;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 500;
}

.subheader::after {
  display: block;
  height: 3px;
  margin-top: 4px;
  content: '';
  background-color: #5c54ff;
  border-radius: 2px;
}

.description {
  font-size: 0.85rem;
}

.regionSettingRow {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.regionSettingRow:first-child {
  padding-top: 0.75rem;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
}

.tableWrapper {
  margin-top: 0.75em;
  overflow: auto;
  border-right: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
}

.matchKeyTable {
  width: 100%;
  min-width: max-content;
  max-height: 300px;
  margin-bottom: 0;

  td,
  th {
    align-content: center;
  }
}

.matchKeyHeader {
  font-size: 15px;
}
</style>
