<script>
import _ from 'lodash';
import moment from 'moment';

import { getCurrencyFormat, percentFormat } from '@console/components/charts/utility';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

export default {
  components: {
    PanelSection,
    ChartLegend,
    SeriesChart,
  },
  props: {
    netSavingsTrend: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      series: [],
    };
  },
  computed: {
    xAxis() {
      const formatCategory = item => moment.utc(item.month_start).format('MMM YYYY');
      const categories = _.map(this.netSavingsTrend, formatCategory);
      return {
        categories,
      };
    },
    yAxis() {
      const data = this.netSavingsTrend;
      const esr = _.compact(_.map(data, 'effective_savings_rate_percentage'));
      const base = _.compact(_.map(data, 'baseline_effective_savings_rate_percentage'));
      const smallestValue = _.min([...esr, ...base]);
      const min = smallestValue >= 0 ? 0 : _.floor(smallestValue, -1);
      const rightTickPositions = _.range(min, 61, 10);
      return [
        {
          min: 0,
          tickAmount: rightTickPositions.length,
          title: {
            text: null,
          },
          labels: {
            format: getCurrencyFormat(),
          },
        },
        {
          tickPositions: rightTickPositions,
          title: {
            text: null,
          },
          labels: {
            format: percentFormat,
          },
          opposite: true,
        },
      ];
    },
    filteredSeries() {
      return _.filter(this.series, s => s.legendSelected);
    },
  },
  mounted() {
    const safeMap = (arr, key) => _.map(arr, a => _.get(a, key, null));
    const hasAnyProps = (o, props) => _.some(props, prop => _.has(o, prop));
    const firstMonths = _.initial(this.netSavingsTrend);
    const lastMonth = _.last(this.netSavingsTrend);
    const includeLastMonth = hasAnyProps(lastMonth, [
      'compute_engine_usage',
      'net_savings',
      'effective_savings_rate_percentage',
      'baseline_effective_savings_rate_percentage',
    ]);
    const data = includeLastMonth ? [...firstMonths, lastMonth] : firstMonths;
    const computeUsage = safeMap(data, 'compute_engine_usage');
    const netSavings = safeMap(data, 'net_savings');
    const esr = safeMap(data, 'effective_savings_rate_percentage');
    const base = safeMap(data, 'baseline_effective_savings_rate_percentage');

    this.series = [
      {
        label: 'Compute Usage',
        name: 'Compute Usage',
        data: computeUsage,
        color: '#a7a3ff',
        dashStyle: 'solid',
        type: 'column',
        yAxis: 0,
        tooltip: {
          valuePrefix: '$',
          valueSuffix: '',
          valueDecimals: 2,
        },
        legendOrder: 0,
        legendSelected: true,
      },
      {
        label: 'Net Savings',
        name: 'Net Savings',
        data: netSavings,
        color: '#00c58c',
        dashStyle: 'solid',
        type: 'column',
        yAxis: 0,
        tooltip: {
          valuePrefix: '$',
          valueSuffix: '',
          valueDecimals: 2,
        },
        legendOrder: 1,
        legendSelected: true,
      },
      {
        label: 'Effective Savings Rate with ProsperOps',
        name: 'Effective Savings Rate with ProsperOps',
        data: esr,
        type: 'line',
        color: '#fcbe2c',
        dashStyle: 'solid',
        yAxis: 1,
        marker: {
          enabled: false,
        },
        tooltip: {
          valuePrefix: '',
          valueSuffix: '%',
          valueDecimals: 1,
        },
        legendOrder: 2,
        legendSelected: true,
      },
      {
        label: 'Effective Savings Rate before ProsperOps (baseline)',
        name: 'Effective Savings Rate before ProsperOps (baseline)',
        data: base,
        type: 'line',
        color: '#495057',
        dashStyle: 'dash',
        yAxis: 1,
        marker: {
          enabled: false,
        },
        tooltip: {
          valuePrefix: '',
          valueSuffix: '%',
          valueDecimals: 1,
        },
        legendOrder: 3,
        legendSelected: true,
      },
    ].filter(Boolean);
  },
};
</script>

<template>
  <PanelSection header="Net Savings Trend">
    <div>
      <ChartLegend v-model="series" />
      <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" />
      <div class="d-flex flex-row-reverse">
        <small>(normalized)</small>
      </div>
    </div>
  </PanelSection>
</template>
