<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';

import AzureNavigation from '@azure/components/Navigation.vue';
import AwsNavigation from '@console/components/navigation/AwsNavigation.vue';
import ContextSelector from '@console/components/navigation/ContextSelector.vue';
import GcpNavigation from '@console/components/navigation/GcpNavigation.vue';

export default {
  components: {
    AwsNavigation,
    AzureNavigation,
    GcpNavigation,
    ContextSelector,
  },
  props: {
    mobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState('aws', ['awsOrganizations']),
    ...mapGetters('aws', [
      'selectedOrganizationHasOnboarded',
      'selectedOrganization',
      'selectedOrganizationSettings',
      'isMultiOrgSummarySelected',
    ]),
    ...mapGetters('customer', ['isSettingEnabled', 'isSubscribed', 'isReseller', 'isDemo']),
    ...mapGetters('gcp', ['billingAccounts']),
    ...mapGetters('nav', ['isAwsContext', 'isGcpContext', 'context']),
    hasNoOrganizations() {
      return _.isEmpty(this.awsOrganizations);
    },
    hasNoGcpBillingAccounts() {
      return _.isEmpty(this.billingAccounts);
    },
  },
};
</script>

<template>
  <div class="navigation" :class="{ mobile }">
    <div v-if="isDemo" id="demoModeBanner" class="demoModeBanner">Anonymous Mode</div>
    <b-tooltip target="demoModeBanner">
      All data shown is live and from a real ProsperOps customer. However, all references to customer identifying
      information including names, account numbers, etc. have been masked.
    </b-tooltip>
    <header class="header">
      <ContextSelector :mobile="mobile" />
    </header>
    <AwsNavigation v-if="context?.cloud === 'aws'" data-testid="aws-nav" />
    <AzureNavigation v-if="context?.cloud === 'azure'" data-testid="azure-nav" />
    <GcpNavigation v-if="context?.cloud === 'gcp'" data-testid="gcp-nav" />
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.mobile {
  height: calc(100vh - 65px - 65px) !important;
  overflow: auto;
}

.navigation {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 62px);
  padding-bottom: 10px;
  color: #fff;
  color: rgba(255, 255, 255, 0.85);
}

.demoModeBanner {
  padding: 0.5rem;
  margin: 1rem 1rem 0;
  font-weight: 500;
  color: $gray-900;
  text-align: center;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  background-color: map-get($theme-colors, 'warning');
  border-radius: 0.2rem;
}
</style>
