<script>
import { defineComponent } from 'vue';

import storage from '@shared/lib/local_storage';

import BaseIcon from '@shared/design/icons/BaseIcon.vue';

const localStorageKey = 'dismissAnnouncementBanner';

export default defineComponent({
  name: 'AnnouncementBanner',
  components: {
    BaseIcon,
  },
  data() {
    return {
      dismissed: storage.get(localStorageKey) || false,
    };
  },
  computed: {
    showBanner() {
      return !this.dismissed;
    },
  },
  methods: {
    dismiss() {
      this.dismissed = true;
      storage.set(localStorageKey, true);
    },
  },
});
</script>

<template>
  <div v-if="showBanner" class="banner">
    <button class="btn bannerDismiss" data-test-id="dismiss" @click="dismiss">
      <BaseIcon name="times" />
    </button>
    <div class="bannerContent">
      Autonomous Discount Management is now Generally Available for AWS RDS, ElastiCache, MemoryDB, Redshift, and
      OpenSearch.
      <a
        href="https://www.prosperops.com/blog/now-generally-available-autonomous-discount-management-for-aws-rds-elasticache-memorydb-redshift-and-opensearch/"
        target="_blank"
        class="ml-2"
        >Learn more<BaseIcon name="external-link-alt" class="ml-2"
      /></a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.banner {
  background-color: map-get($theme-colors, 'success');
}

.bannerContent {
  padding: 0.5rem 1rem;
  font-weight: 500;
  color: $white;
  text-align: center;
  text-shadow: 0 1px rgba(0, 0, 0, 0.1);

  a {
    color: #5b54f6;
    text-shadow: none;
  }
}

.bannerDismiss {
  float: right;
  padding: 0.5rem 1rem;
  color: $white;
  border: 0;
}
</style>

<style lang="scss">
@import '@shared/scss/colors.scss';
</style>
