<script>
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

import BoxMessage from '@shared/design/BoxMessage.vue';

const mustContainSingleDot = value => (value?.match(/\./g) || []).length === 1;

export default defineComponent({
  name: 'Start',
  components: {
    BoxMessage,
  },
  setup() {
    return {
      v$: useVuelidate({ $scope: false }),
    };
  },
  data: function () {
    return {
      loading: false,
      billingAccountId: null,
      detailedUsageExportDatasetId: null,
      pricingExportDatasetId: null,
      organizationId: null,
    };
  },
  computed: {
    ...mapState('gcp', ['billingConfiguration']),
    disabled() {
      if (this.billingConfiguration) {
        return this.v$.$errors.length > 0;
      }
      return !this.v$.$dirty || this.v$.$error;
    },
    showSpecificExportInstructions() {
      return this.v$.billingAccountId.$dirty && !this.v$.billingAccountId.$error;
    },
  },
  mounted() {
    this.$refs.billingAccountId.focus();
    this.billingAccountId = this.billingConfiguration?.billingAccountId;
    this.detailedUsageExportDatasetId = this.billingConfiguration?.detailedUsageExportDatasetId;
    this.pricingExportDatasetId = this.billingConfiguration?.pricingExportDatasetId;
    this.organizationId = this.billingConfiguration?.organizationId;
  },
  validations: {
    billingAccountId: {
      regex: helpers.regex(/^[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}$/),
      required,
    },
    detailedUsageExportDatasetId: {
      mustContainSingleDot,
      required,
    },
    pricingExportDatasetId: {
      mustContainSingleDot,
      required,
    },
    organizationId: {
      regex: helpers.regex(/^[0-9]+$/),
      required,
    },
  },
  methods: {
    ...mapActions('gcp', ['startOnboarding']),
    async submit() {
      this.loading = true;
      await this.startOnboarding({
        billingAccountId: this.billingAccountId,
        detailedUsageExportDatasetId: this.detailedUsageExportDatasetId,
        pricingExportDatasetId: this.pricingExportDatasetId,
        organizationId: this.organizationId,
        editing: false,
      });
      this.loading = false;
    },
  },
});
</script>

<template>
  <div>
    <div class="row">
      <div class="col">
        <h2>Google Cloud Details</h2>
        <div class="pt-2">
          <BoxMessage type="info">
            <p>
              To perform our Savings Analysis, we require limited read-only
              <a href="https://help.prosperops.com/gcp-limited-permissions-for-savings-analysis" target="_blank"
                >IAM permissions</a
              >
              on your Google Cloud billing account, billing export, and organization. If you need help finding the
              requested information, please see our
              <a href="https://help.prosperops.com/gcp-onboarding-guide-gcp-details" target="_blank">help article</a>.
            </p>

            Important: Google Cloud billing data exports to BigQuery must be enabled for <b>both</b> the Detailed Usage
            Cost and Pricing data tables. This is how Google Cloud provides the data necessary for our analysis. If you
            don’t have the proper exports enabled or are unsure, please see our
            <a href="https://help.prosperops.com/gcp-onboarding-guide-prerequisites" target="_blank">help article</a>.
          </BoxMessage>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="row pt-4">
        <div class="form-group col-lg-4">
          <label for="billingAccountId">Billing Account ID</label>
          <div class="d-flex align-items-center">
            <input
              id="billingAccountId"
              ref="billingAccountId"
              v-model.trim="v$.billingAccountId.$model"
              type="text"
              :class="{ 'form-control': true, 'is-invalid': v$.billingAccountId.$error }"
              placeholder="Your Billing Account ID"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-4">
          <label for="detailedUsageExportDatasetId">Detailed Usage Cost Dataset ID</label>
          <div class="d-flex align-items-center">
            <input
              id="detailedUsageExportDatasetId"
              v-model.trim="v$.detailedUsageExportDatasetId.$model"
              type="text"
              :class="{ 'form-control': true, 'is-invalid': v$.detailedUsageExportDatasetId.$error }"
              placeholder="Your Detailed Usage Cost Dataset ID"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-4">
          <label for="pricingExportDatasetId">Pricing Dataset ID</label>
          <div class="d-flex align-items-center">
            <input
              id="pricingExportDatasetId"
              v-model.trim="v$.pricingExportDatasetId.$model"
              type="text"
              :class="{ 'form-control': true, 'is-invalid': v$.pricingExportDatasetId.$error }"
              placeholder="Your Pricing Dataset ID"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-4">
          <label for="organizationId">Organization ID</label>
          <div class="d-flex align-items-center">
            <input
              id="organizationId"
              v-model.trim="v$.organizationId.$model"
              type="text"
              :class="{ 'form-control': true, 'is-invalid': v$.organizationId.$error }"
              placeholder="Your Organization ID"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
      <div class="row sectional">
        <div class="col">
          <form-submit-button variant="primary" type="submit" :loading="loading" :disabled="disabled">
            Next
            <template #loading>Next...</template>
          </form-submit-button>
        </div>
      </div>
    </form>
  </div>
</template>
