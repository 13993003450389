<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import customerService from '@console/services/customerService';

import ErrorMessageByCode from '@aws/components/accounts/ErrorMessageByCode.vue';
import SetupDirections from '@aws/components/accounts/SetupDirections.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';

export default {
  components: {
    BoxMessage,
    SetupDirections,
    ErrorMessageByCode,
  },
  data() {
    return {
      submitting: false,
      iamDetails: null,
      errorCode: null,
      errorCollection: null,
    };
  },
  computed: {
    ...mapGetters('customer', ['isSubscribed', 'isReseller']),
    ...mapGetters('aws', [
      'selectedOrganizationManagementAccount',
      'selectedOrganizationChildAccounts',
      'selectedOrganizationHasChildAccounts',
      'selectedOrganizationIsCurDataSource',
    ]),
    showActiveManagementMessage() {
      if (!this.isSubscribed) {
        return false;
      }
      if (this.isReseller) {
        const isEndCustomer = a => a.status === 'ResellerEndCustomer';
        return (
          !this.selectedOrganizationHasChildAccounts || _.every(this.selectedOrganizationChildAccounts, isEndCustomer)
        );
      } else if (this.selectedOrganizationIsCurDataSource) {
        const isNoAccessRequired = a => a.status === 'NoAccessRequired';
        return (
          !this.selectedOrganizationHasChildAccounts ||
          _.every(this.selectedOrganizationChildAccounts, isNoAccessRequired)
        );
      }
      return !this.selectedOrganizationHasChildAccounts;
    },
  },
  async mounted() {
    this.iamDetails = await customerService
      .getIamDetails(this.selectedOrganizationManagementAccount.id)
      .then(_.property('data'));
  },
  methods: {
    ...mapActions('aws', ['validateManagementAccountAccess']),
    async validateAccessClicked() {
      try {
        this.submitting = true;
        this.errorCode = null;
        this.errorCollection = null;
        await this.validateManagementAccountAccess();
      } catch (e) {
        this.submitting = false;
        this.handleError(e);
      }
    },
    handleError(e) {
      const status = _.get(e, 'response.status', 500);
      const code = _.get(e, 'response.data.code');
      const errors = _.get(e, 'response.data.errors', null);
      if (status >= 500) {
        throw e;
      }
      if (code === 'General') {
        throw e;
      }
      this.errorCode = code;
      this.errorCollection = errors;
    },
  },
};
</script>

<template>
  <div v-if="iamDetails">
    <div class="row pt-4">
      <div class="col">
        <h2>Update Management Account Permissions</h2>
      </div>
    </div>
    <BoxMessage type="info" class="pt-2 pb-2">
      <p v-if="isReseller" class="mb-0">
        To deliver active discount management, ProsperOps needs additional IAM permissions on the AWS management
        account. This gives us deeper visibility into usage, allows us understand end-customer commitments, and enables
        us to manage your arbitrage commit portfolio. Use the customized scripts/templates/instructions below to update
        the management account permissions, then click the
        <strong>Validate Access</strong>
        button.
      </p>
      <p v-else-if="selectedOrganizationIsCurDataSource" class="mb-0">
        To deliver active discount management, ProsperOps needs additional IAM permissions on the AWS management
        account. This gives us deeper visibility into usage and allows us to manage your commitments across your
        Organization. Use the customized scripts/templates/instructions below to update the management account
        permissions, then click the
        <strong>Validate Access</strong>
        button.
      </p>
      <p v-else class="mb-0">
        To actively manage your commitment portfolio, ProsperOps needs additional IAM permissions on all AWS accounts in
        the Organization, including the management account. This gives us real-time visibility into EC2 state changes
        and allows us to manage your commitments across your Organization. Use the customized
        scripts/templates/instructions below to update the management account permissions, then click the
        <strong>Validate Access</strong>
        button.
      </p>
    </BoxMessage>
    <BoxMessage v-if="showActiveManagementMessage" type="success" class="pt-2 pb-2">
      <p class="mb-0">
        Once ProsperOps access to your management account has been confirmed, we will begin to actively manage your
        savings.
      </p>
    </BoxMessage>
    <BoxMessage v-if="errorCode" type="error" class="pt-4 pb-2">
      <ErrorMessageByCode :code="errorCode" :errors="errorCollection" />
    </BoxMessage>
    <div class="row pt-2">
      <div class="col">
        <SetupDirections
          :iam-details="iamDetails"
          :aws-account-number="selectedOrganizationManagementAccount.aws_account_number"
          update-role
        />
      </div>
    </div>
    <div class="row pt-4">
      <div class="col d-flex flex-row-reverse">
        <div>
          <form-submit-button
            variant="primary"
            type="submit"
            :loading="submitting"
            class="rounded-sm"
            @click="validateAccessClicked"
          >
            Validate Access
            <template v-slot:loading> Validating... </template>
          </form-submit-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.check {
  color: map-get($theme-colors, 'success');
}
</style>
