<script>
export default {
  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
    onZero: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    show() {
      if (this.onZero && this.value === 0) {
        return true;
      }
      return this.value === undefined || this.value === null;
    },
  },
};
</script>

<template>
  <span v-if="show">-</span>
  <span v-else>
    <slot />
  </span>
</template>
