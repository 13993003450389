<script>
import SavingsMetric from '@aws/components/savings/SavingsMetric.vue';
import CyclicalWaveIcon from '@shared/design/icons/CyclicalWaveIcon.vue';
import Modal from '@shared/design/Modal.vue';

export default {
  components: {
    CyclicalWaveIcon,
    SavingsMetric,
    Modal,
  },
  props: {
    savings: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
    useSmallerText: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    utilizationPercentage() {
      return this.savings?.dashboard?.key_metrics?.savings_plan_utilization_percentage ?? 0;
    },
    showCyclicalityDetected() {
      return this.savings?.dashboard?.global_cyclicality_detected ?? false;
    },
  },
};
</script>

<template>
  <SavingsMetric
    name="savings_plan_utilization_percentage"
    variant="secondary"
    :use-smaller-text="useSmallerText"
    :value="utilizationPercentage"
    :company="company"
  >
    <template v-if="showCyclicalityDetected" v-slot:value-icon>
      <a v-b-modal.savings-cyclicality-detected-modal href="#" class="wave" @click.prevent><CyclicalWaveIcon /></a>
      <Modal id="savings-cyclicality-detected-modal" size="md" title="Global Cyclical Usage Detected">
        <div class="pb-3">
          <span>
            Our platform has detected global cyclical usage patterns and is using advanced strategies to maximize your
            savings. These techniques may lower your Savings Plan utilization rate but improve your Effective Savings
            Rate.
          </span>
          <a
            href="https://www.prosperops.com/blog/how-to-maximize-cloud-savings-on-cyclical-workloads-using-commitment-based-discounts/"
            target="_blank"
            rel="noopener noreferrer"
            class="text-nowrap"
          >
            Learn more
          </a>
        </div>
      </Modal>
    </template>
  </SavingsMetric>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';

.wave {
  padding-left: 2px;
  line-height: 1;
  > svg {
    height: 18px;
    color: map-get($theme-colors, 'secondary');
  }
}
</style>
