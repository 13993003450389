<script>
export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <div class="onboardingProgressTracker">
        <template v-for="step in steps" :key="step.key">
          <div v-if="step.number > 1" :key="step.number + 'sep'" class="sep"></div>
          <div class="step">
            <div class="node" :class="{ active: step.isActive }">
              {{ step.number }}
            </div>
            <div class="stepText">
              {{ step.text }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.onboardingProgressTracker {
  display: flex;
  justify-content: center;
  padding: 0;
}

.node {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  font-size: 0.8rem;
  color: white;
  background-color: map-get($theme-colors, 'secondary');
  border-radius: 50%;
}

.node.active {
  background-color: map-get($theme-colors, 'primary');
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepText {
  max-width: 100px;
  padding-top: 4px;
  font-size: 0.8rem;
  text-align: center;
}

.sep {
  display: block;
  width: 10%;
  min-width: 20px;
  max-width: 200px;
  height: 2px;
  margin-top: 1.4rem;
  background-color: $gray-500;

  @include media-breakpoint-up(md) {
    min-width: 50px;
    max-width: 400px;
  }
}
</style>
