<template>
  <div class="focusCenter">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.focusCenter {
  max-width: 100%;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  text-align: left;

  @include media-breakpoint-up(md) {
    max-width: 650px;
    margin-top: 100px;
    margin-top: 150px;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
