<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="text-truncate">
    <span style="font-weight: 500">{{ user.first_name }} {{ user.last_name }}</span>
    <br />
    <span class="text-muted">{{ user.email_address }}</span>
  </div>
</template>
