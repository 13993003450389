<script>
export default {
  props: {
    enabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    classes() {
      return this.enabled ? 'active' : 'disabled';
    },
    statusValue() {
      return this.enabled ? 'Active' : 'Disabled';
    },
  },
};
</script>

<template>
  <div class="d-flex align-items-center">
    <div class="statusIndicator mr-4" :class="classes"></div>
    <div class="statusValue">
      {{ statusValue }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.statusIndicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.active {
  background-color: map-get($theme-colors, 'success');
}

.disabled {
  background-color: $gray-400;
}

.statusValue {
  font-weight: 400;
}
</style>
