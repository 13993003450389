<script setup lang="ts">
import type { AggregationChangeEvent } from './types';

import PanelDropdown from '@shared/design/panels/PanelDropdown.vue';

defineProps<{
  name: string;
}>();

const emit = defineEmits<{
  (event: 'change', payload: AggregationChangeEvent): void;
}>();

const change = (event: AggregationChangeEvent) => {
  emit('change', event);
};
</script>

<template>
  <PanelDropdown :name="name" :options="['daily', 'cumulative']" @change="change" />
</template>
