<script>
export default {
  props: {
    series: {
      type: Array,
      required: true,
    },
    xAxis: {
      type: Object,
      required: false,
      default: () => ({
        type: 'category',
      }),
    },
    yAxis: {
      type: Object,
      required: false,
      default: () => ({
        title: 'USD',
      }),
    },
    tooltip: {
      type: Object,
      required: false,
      default: () => ({
        enabled: true,
        borderRadius: 0,
        borderColor: '#adb5bd',
        shared: true,
        shadow: false,
        headerFormat: '<strong style="font-size: 12px">{point.key}</strong> <br />',
      }),
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'waterfall',
          backgroundColor: 'transparent',
        },
        title: null,
        legend: {
          enabled: false,
        },
        plotOptions: {
          waterfall: {
            lineWidth: 2,
            dashStyle: 'ShortDot',
          },
        },
        tooltip: this.tooltip,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        series: this.series,
      };
    },
  },
};
</script>

<template>
  <div>
    <chart :options="chartOptions" />
  </div>
</template>
