<script>
export default {
  props: {
    status: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    statusClass() {
      return this.status === 'Active' ? 'bg-success' : 'bg-warning';
    },
  },
};
</script>

<template>
  <div class="dot" :class="{ [statusClass]: true }" :title="status" />
</template>

<style lang="scss" scoped>
.dot {
  width: 0.75rem;
  height: 0.75rem;
  margin: 0 auto;
  background-color: none;
  border-radius: 50%;
}
</style>
