<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import {
  PermissionSet,
  validateBillingAccountAccess,
  validateBillingExportAccess,
  validatePricingExportAccess,
  validateOrganizationAccess,
} from '@gcp/services/accessValidation';

import CreateIamRolePanel from '@gcp/components/onboarding/CreateIamRolePanel.vue';
import ValidateBillingAccountAccess from '@gcp/components/onboarding/ValidateBillingAccountAccess.vue';
import ValidateOrganizationAccess from '@gcp/components/onboarding/ValidateOrganizationAccess.vue';
import ValidationStepperItem from '@gcp/components/onboarding/ValidationStepperItem.vue';
import AccordionList from '@shared/design/AccordionList.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import CopyButton from '@shared/design/CopyButtonV2.vue';
import Tabs from '@shared/design/Tabs.vue';

const GcpConsoleKey = 'gcp_console';
const GcpTerraformKey = 'terraform';

const GcpBillingAccountKey = 'gcp_billing_account';
const GcpDetailedUsageExportKey = 'gcp_detailed_usage_export';
const GcpPricingExportKey = 'gcp_pricing_export';
const GcpProjectsKey = 'gcp_organization';

export default defineComponent({
  name: 'SavingsAnalysisPermissions',
  components: {
    AccordionList,
    BoxMessage,
    CreateIamRolePanel,
    CopyButton,
    Tabs,
    ValidateBillingAccountAccess,
    ValidateOrganizationAccess,
    ValidationStepperItem,
  },
  data() {
    return {
      tabItems: [
        { key: GcpConsoleKey, label: 'Google Cloud Console' },
        { key: GcpTerraformKey, label: 'Terraform' },
      ],
      activeTabItem: GcpConsoleKey,
      activeAccordionKey: null,
      showRoleCreated: true,
      savingsAnalysisPermissionSet: PermissionSet.SavingsAnalysis,
    };
  },
  computed: {
    ...mapState('gcp', ['serviceAccount', 'savingsAnalysisRole']),
    ...mapGetters('gcp', [
      'billingAccountId',
      'detailedUsageExportProjectId',
      'detailedUsageExportDatasetId',
      'pricingExportProjectId',
      'pricingExportDatasetId',
      'organizationId',
    ]),
    sortedPermissions() {
      return [...this.savingsAnalysisRole.permissions].sort();
    },
    billingAccountUrl() {
      return `https://console.cloud.google.com/billing/${this.billingAccountId}/manage`;
    },
    detailedUsageBigQueryUrl() {
      return `https://console.cloud.google.com/bigquery?project=${this.detailedUsageExportProjectId}`;
    },
    pricingBigQueryUrl() {
      return `https://console.cloud.google.com/bigquery?project=${this.pricingExportProjectId}`;
    },
    organizationIamUrl() {
      return `https://console.cloud.google.com/iam-admin/iam?organizationId=${this.organizationId}`;
    },
    roleIamUrl() {
      return `https://console.cloud.google.com/iam-admin/roles?organizationId=${this.organizationId}`;
    },
    isValidatingBillingAccount() {
      return this.isValidating(GcpBillingAccountKey);
    },
    isValidatingDetailedUsageExport() {
      return this.isValidating(GcpDetailedUsageExportKey);
    },
    isValidatingPricingExport() {
      return this.isValidating(GcpPricingExportKey);
    },
    isValidatingOrganization() {
      return this.isValidating(GcpProjectsKey);
    },
  },
  async mounted() {
    await this.loadSavingsAnalysisRole();
  },
  methods: {
    ...mapActions('gcp', ['addBillingAccount', 'editBillingConfiguration', 'loadSavingsAnalysisRole']),
    onTabChange(nextTab) {
      this.activeTabItem = nextTab;
    },
    roleCreatedClick() {
      this.activeAccordionKey = GcpBillingAccountKey;
      this.showRoleCreated = false;
    },
    isValidating(key) {
      return this.activeAccordionKey === key;
    },
    async onBillingAccountAccessValidated() {
      this.activeAccordionKey = GcpDetailedUsageExportKey;
    },
    async onOrganizationAccessValidated() {
      await this.addBillingAccount();
      this.activeAccordionKey = 'done';
    },
    async validateBillingAccountAccess() {
      await validateBillingAccountAccess(this.billingAccountId);
      this.activeAccordionKey = GcpDetailedUsageExportKey;
    },
    async validateDetailedUsageExportAccess() {
      await validateBillingExportAccess(
        this.billingAccountId,
        this.detailedUsageExportProjectId,
        this.detailedUsageExportDatasetId
      );
      this.activeAccordionKey = GcpPricingExportKey;
    },
    async validatePricingExportAccess() {
      await validatePricingExportAccess(
        this.billingAccountId,
        this.pricingExportProjectId,
        this.pricingExportDatasetId
      );
      this.activeAccordionKey = GcpProjectsKey;
    },
    async validateOrganizationAccess() {
      await validateOrganizationAccess(this.organizationId);
      await this.addBillingAccount();

      this.activeAccordionKey = 'done';
    },
  },
});
</script>

<template>
  <div v-if="serviceAccount && savingsAnalysisRole" class="savingsAnalysisPermissions">
    <div class="row sectional">
      <div class="col">
        <h2>Initial Google Cloud Access</h2>
        <div class="pt-2">
          <BoxMessage type="info">
            ProsperOps access is granted via IAM permissions. Follow the steps below to configure access using the
            Google Cloud Console. First create a least-privilege <b>ProsperOps</b> role, then follow the guide to apply
            permissions, clicking <b>Validate</b> after each step. If you need additional help, see
            <a href="https://help.prosperops.com/gcp-onboarding-guide-initial-gcp-access" target="_blank"
              >this article</a
            >.
          </BoxMessage>
        </div>
      </div>
    </div>
    <div class="row sectional">
      <div class="col">
        <Tabs :items="tabItems" :active-key="activeTabItem" @changed="onTabChange" />
      </div>
    </div>
    <div v-if="activeTabItem === 'gcp_console'" class="row">
      <div class="col">
        <div>
          <CreateIamRolePanel
            :iam-role="savingsAnalysisRole"
            :organization-id="organizationId"
            :permissions="savingsAnalysisRole.permissions"
            :on-created="roleCreatedClick"
          />
        </div>
        <AccordionList class="bg-white">
          <ValidateBillingAccountAccess
            :billing-account-id="billingAccountId"
            :role="savingsAnalysisRole"
            :service-account="serviceAccount"
            :open="isValidatingBillingAccount"
            :on-validated="onBillingAccountAccessValidated"
            :permissions="savingsAnalysisPermissionSet"
          />
          <ValidationStepperItem
            title="Detailed Usage Cost Export Access"
            :open="isValidatingDetailedUsageExport"
            :validate="validateDetailedUsageExportAccess"
          >
            <template #default>
              <ul class="pl-3">
                <li>
                  Navigate to <a target="_blank" rel="noopener noreferrer" :href="detailedUsageBigQueryUrl">BigQuery</a>
                </li>
                <li>
                  Expand the
                  <span class="text-monospace font-weight-bold">{{ detailedUsageExportProjectId }}</span> project and
                  the <span class="text-monospace font-weight-bold">{{ detailedUsageExportDatasetId }}</span> dataset
                  and select the
                  <span class="text-monospace font-weight-bold"
                    >gcp_billing_export_resource_v1_{{ billingAccountId.replaceAll('-', '_') }}</span
                  >
                  table
                </li>
                <li>
                  Click <b>Share</b>, then <b>Add Principal</b> and add the following new principal:<br />
                  <span class="text-monospace bg-light p-1">{{ serviceAccount?.email }}</span>
                  <CopyButton :key="serviceAccount?.email" class="ml-2" :text="serviceAccount?.email" />
                </li>
                <li>Assign the <b>BigQuery Data Viewer</b> role</li>
              </ul>
            </template>
            <template #error="{ error }">
              <span v-if="error.code === 'BigQueryResourceNotFound'">
                Hmm, we weren't able to find the detailed usage table in the given project and dataset. Please confirm
                that the export is configured.
              </span>
              <span v-else>
                Hmm, we weren't able to validate access. Please ensure the principal name is correct and is assigned the
                <b>BigQuery Data Viewer</b> role. If the issue persists, please chat with us for help.
              </span>
            </template>
          </ValidationStepperItem>
          <ValidationStepperItem
            title="Pricing Export Access"
            :open="isValidatingPricingExport"
            :validate="validatePricingExportAccess"
          >
            <template #default>
              <ul class="pl-3">
                <li>
                  Navigate to <a target="_blank" rel="noopener noreferrer" :href="pricingBigQueryUrl">BigQuery</a>
                </li>
                <li>
                  Expand the <span class="text-monospace font-weight-bold">{{ pricingExportProjectId }}</span> project
                  and the <span class="text-monospace font-weight-bold">{{ pricingExportDatasetId }}</span> dataset and
                  select the <span class="text-monospace font-weight-bold">cloud_pricing_export</span> table
                </li>
                <li>
                  Click <b>Share</b>, then <b>Add Principal</b> and add the following new principal:<br />
                  <span class="text-monospace bg-light p-1">{{ serviceAccount?.email }}</span>
                  <CopyButton :key="serviceAccount?.email" class="ml-2" :text="serviceAccount?.email" />
                </li>
                <li>Assign the <b>BigQuery Data Viewer</b> role</li>
              </ul>
            </template>
            <template #error="{ error }">
              <span v-if="error.code === 'BigQueryResourceNotFound'">
                Hmm, we weren't able to find the pricing table in the given project and dataset. Please confirm that the
                export is configured.
              </span>
              <span v-else>
                Hmm, we weren't able to validate access. Please ensure the principal name is correct and is assigned the
                <b>BigQuery Data Viewer</b> role. If the issue persists, please chat with us for help.
              </span>
            </template>
          </ValidationStepperItem>
          <ValidateOrganizationAccess
            :organization-id="organizationId"
            :role="savingsAnalysisRole"
            :service-account="serviceAccount"
            :open="isValidatingOrganization"
            :on-validated="onOrganizationAccessValidated"
            :permissions="savingsAnalysisPermissionSet"
          />
        </AccordionList>
        <button type="button" class="btn btn-link p-0 mt-4" @click="editBillingConfiguration">
          <BaseIcon name="arrow-left" class="mr-1" />
          Back to Google Cloud Details
        </button>
      </div>
    </div>
    <div v-if="activeTabItem === 'terraform'" class="row">
      <div class="col">
        <div class="d-flex d-justify-content-center align-items-center mt-5">
          <p class="lead flex-grow-1 text-center">Terraform support coming soon!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.savingsAnalysisPermissions li + li {
  margin-top: 0.25rem;
}
</style>
