<script setup lang="ts">
import type { OptionsArray } from '@console/components/filters/types';

import { computed } from 'vue';

import useSearch from '@console/components/filters/useSearch';

import Container from '@console/components/filters/FilterContainer.vue';

const props = defineProps<{
  modelValue?: string | null;
  matchKeys: OptionsArray<string>;
}>();

const emit = defineEmits(['update:modelValue']);

const value = computed(() => {
  return props.modelValue ?? 'All Match Keys';
});

const onClick = (matchKey?: string) => {
  emit('update:modelValue', matchKey);
};

const options = computed(() => {
  return [...props.matchKeys].sort();
});

const { filteredOptions, searchRef, searchText, reset: resetSearch } = useSearch(options);
</script>

<template>
  <Container label="Match Key" :value="value" @shown="resetSearch">
    <div class="filterSearch">
      <b-form-input ref="searchRef" v-model="searchText" placeholder="Search Match Keys" />
      <BaseIcon name="search" />
    </div>
    <b-dropdown-item v-if="!searchText" @click="onClick()">All Match Keys</b-dropdown-item>
    <b-dropdown-divider />
    <template v-for="matchKey in filteredOptions" :key="matchKey">
      <!-- if it's a string -->
      <b-dropdown-item v-if="typeof matchKey === 'string'" :key="matchKey" @click="onClick(matchKey)">
        {{ matchKey }}
      </b-dropdown-item>
      <!-- if it's a group -->
      <template v-else>
        <b-dropdown-group v-if="matchKey.values.length" :id="matchKey.label" :header="matchKey.label">
          <b-dropdown-item
            v-for="matchKeyValue in matchKey.values"
            :key="matchKeyValue"
            class="grouped"
            @click="onClick(matchKeyValue)"
          >
            {{ matchKeyValue }}
          </b-dropdown-item>
        </b-dropdown-group>
      </template>
    </template>
  </Container>
</template>

<style lang="scss" scoped>
.grouped {
  padding-left: 0.7rem;
}
</style>
