<script>
export default {
  props: {
    hollow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    fill() {
      return this.hollow ? 'none' : 'currentColor';
    },
  },
};
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 100 100">
    <circle cx="50" cy="50" r="40" stroke-width="20" :fill="fill" stroke="currentColor" />
  </svg>
</template>
