/* eslint-disable camelcase */

export enum RoleTypes {
  Viewer = 'Viewer',
  Editor = 'Editor',
  Owner = 'Owner',
  Granular = 'Granular',
}

export interface GetUserResponse {
  first_name: string;
  last_name: string;
  email_address: string;
  last_login: string;
  picture_url: string;
  user_role: RoleTypes;
  granular_roles: string[];
  is_owner: boolean;
  user_status: string;
  created_date: string;
  updated_date: string;
}

export enum AuthTypes {
  Default = 'Default',
  Federated = 'Federated',
}

export interface GetAuthConfigResponse {
  auth_type: AuthTypes;
  issuer: string;
  sign_in_endpoint: string;
  strategy: string;
  domain_aliases: string[];
  distribution_list: string;
}
