<script setup lang="ts">
import type { MetricPanelProps } from '@shared/design/panels/MetricPanel.vue';

import { useResizeObserver } from '@vueuse/core';
import { computed, ref } from 'vue';

import NumberHelpers from '@shared/utilities/number_helpers';

import MetricPanel from '@shared/design/panels/MetricPanel.vue';

export type NumericMetricPanelProps = MetricPanelProps & {
  value: number;
  zeroValue?: string;
  decimals?: number;
  numberStyle?: 'currency' | 'percent';
  valueColor?: string;
};

const props = defineProps<NumericMetricPanelProps>();

// In order to decide if we should format using units i.e. 200K instead of 200,000
// we render a hidden div with the long value and check if it overflows
const panelRef = ref<HTMLElement>();
const sizerRef = ref<HTMLElement>();
const isOverflowing = ref(false);
useResizeObserver(panelRef, () => {
  isOverflowing.value = sizerRef.value != null && sizerRef.value.scrollWidth > sizerRef.value.clientWidth;
});

const sizerValue = computed(() => {
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: props.numberStyle,
    currency: 'USD',
    minimumFractionDigits: props.decimals,
    maximumFractionDigits: props.decimals,
  });

  return formatter.format(props.value);
});

const value = computed(() => {
  if (isOverflowing.value) {
    return NumberHelpers.formatUnits(props.value, props.numberStyle);
  }
  return sizerValue.value;
});

const showZeroValue = computed(() => props.value === 0 && !!props.zeroValue);
const formattedValue = computed(() => {
  return showZeroValue.value ? props.zeroValue : value.value;
});
</script>

<template>
  <MetricPanel ref="panelRef" class="metricPanel" v-bind="props">
    <div class="overflow-hidden w-100">
      <div v-if="!showZeroValue" ref="sizerRef" class="sizer">{{ sizerValue }}</div>
      <div :class="`text-${valueColor ?? color}`">{{ formattedValue }}</div>
    </div>
  </MetricPanel>
</template>

<style lang="scss" scoped>
.sizer {
  height: 0;
  opacity: 0;
}
</style>
