<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';

import { getCurrencyFormat, percentFormat } from '@console/components/charts/utility';
import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';

const seriesColors = {
  compute: {
    AwsComputeBaseFlex: {
      compute_usage: '#a7a3ff',
      unbilled_spend_coverage: '#05004d',
      flex_boost_spend_coverage: '#8fffdf',
      flex_spend_coverage: '#00c58c',
      base_spend_coverage: '#495057',
      overall_spend_coverage_percentage: '#fcbe2c',
    },
    AwsComputeInheritedBaseSmart: {
      compute_usage: '#a7a3ff',
      unbilled_spend_coverage: '#05004d',
      smart_spend_coverage: '#00c58c',
      base_spend_coverage: '#495057',
      inherited_spend_coverage: '#adb5bd',
      overall_spend_coverage_percentage: '#fcbe2c',
    },
  },
  nonCompute: {
    service_usage: '#a7a3ff',
    smart_spend_coverage: '#00c58c',
    inherited_spend_coverage: '#495057',
    overall_spend_coverage_percentage: '#fcbe2c',
  },
};

export default {
  components: {
    ChartLegend,
    SeriesChart,
  },
  props: {
    dailySpendCoverage: {
      type: Array,
      required: true,
    },
    dashboardVariant: {
      type: String,
      required: false,
      default: undefined,
    },
    monthStart: {
      type: String,
      required: true,
    },
    service: {
      type: String,
      required: true,
      default: 'compute',
    },
  },
  data() {
    return {
      series: [],
    };
  },
  computed: {
    ...mapGetters('customer', ['isReseller']),
    usageHeading() {
      return this.prefixForReseller(`${this.serviceDisplayName} Usage`);
    },
    overallCoverageHeading() {
      return this.isReseller ? 'Arbitrage Spend Coverage' : 'Overall Spend Coverage';
    },
    xAxis() {
      const month = moment.utc(this.monthStart).format('MMM');
      const monthAndDay = ({ day }) => `${month} ${day}`;
      const categories = _.map(this.dailySpendCoverage, monthAndDay);
      return { categories };
    },
    yAxis() {
      const dollarValues = this.series.filter(s => s.yAxis === 0).flatMap(s => s.data);
      const maxValue = Math.max(...dollarValues);

      const rightTickPositions = _.range(0, 120, 20);
      return [
        {
          min: 0,
          tickAmount: rightTickPositions.length,
          title: {
            text: null,
          },
          labels: {
            format: getCurrencyFormat(maxValue),
          },
        },
        {
          tickPositions: rightTickPositions,
          title: {
            text: null,
          },
          labels: {
            format: percentFormat,
          },
          opposite: true,
        },
      ];
    },
    filteredSeries() {
      return _.filter(this.series, s => s.legendSelected);
    },
    serviceDisplayName() {
      return AwsServiceHelpers.getDisplayName(this.service);
    },
    isProductCompute() {
      return this.service === 'compute';
    },
  },
  mounted() {
    const usageField = this.isProductCompute ? 'compute_usage' : 'service_usage';

    // This is the order the items will be stacked in the chart (the first will be on the top)
    const seriesDefs = [
      // Force this series to be the first in the legend (normally it would be last)
      { name: this.usageHeading, field: usageField, type: 'column', legendOrder: 0 },

      { name: 'Unbilled Spend Coverage', field: 'unbilled_spend_coverage', type: 'area' },
      { name: 'Smart Spend Coverage', field: 'smart_spend_coverage', type: 'area' },
      { name: 'Flex Spend Coverage', field: 'flex_spend_coverage', type: 'area' },
      { name: 'Flex Boost Spend Coverage', field: 'flex_boost_spend_coverage', type: 'area' },
      { name: 'Base Spend Coverage', field: 'base_spend_coverage', type: 'area' },
      { name: 'Inherited Spend Coverage', field: 'inherited_spend_coverage', type: 'area' },

      // Force this series to be the last in the legend (normally it would be first)
      {
        name: this.overallCoverageHeading,
        field: 'overall_spend_coverage_percentage',
        type: 'line',
        yAxis: 1,
        legendOrder: 100,
      },
    ];

    const columnOptions = s => ({
      // For columnar series, use the fill color as the border color (the default is white)
      borderColor: this.getSeriesColor(s.field),
    });
    const areaOrLineOptions = {
      stacking: 'normal',
      fillOpacity: '0.5',
      marker: {
        enabled: false,
      },
    };
    const dollarTooltip = { valuePrefix: '$', valueSuffix: '', valueDecimals: 2 };
    const percentTooltip = { valuePrefix: '', valueSuffix: '%', valueDecimals: 1 };

    this.series = seriesDefs
      .map((s, index) => ({
        label: s.name,
        color: this.getSeriesColor(s.field),
        tooltip: s.yAxis === 1 ? percentTooltip : dollarTooltip,
        // Use null as the default vaule to ensure that days without values are still shown on the x axis
        data: this.dailySpendCoverage.map(d => d[s.field] ?? null),
        // Reverse the order of the legend so that the last item (bottom) is the leftmost
        legendOrder: seriesDefs.length - index,
        legendSelected: true,
        dashStyle: 'solid',
        yAxis: 0,
        // Apply the options specific to the series type
        ...(s.type === 'column' ? columnOptions(s) : areaOrLineOptions),
        // Allow the series to override any of the above properties
        ...s,
      }))
      // Don't include any series without a color (i.e. series belongs to another service or variant)
      .filter(s => !!s.color)
      // Remove any series without any data points
      .filter(s => s.data.some(d => !!d));
  },
  methods: {
    prefixForReseller(label) {
      if (this.isReseller) {
        return `Arbitrageable ${label}`;
      }
      return label;
    },
    getSeriesColor(field) {
      const colors = this.isProductCompute
        ? seriesColors.compute[this.dashboardVariant] ?? {}
        : seriesColors.nonCompute;

      return colors[field];
    },
  },
};
</script>

<template>
  <div>
    <ChartLegend v-model="series" />
    <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" />
  </div>
</template>
