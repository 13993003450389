<!-- eslint-disable vue/no-deprecated-dollar-listeners-api -->
<script>
import { BButton } from 'bootstrap-vue';

export default {
  components: {
    BButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonIsDisabled() {
      return this.disabled || this.loading;
    },
  },
};
</script>

<template>
  <BButton v-bind="$attrs" :disabled="buttonIsDisabled" v-on="$listeners">
    <slot v-if="loading" name="loading"> <slot /><span>...</span> </slot>
    <slot v-else />
  </BButton>
</template>

<style scoped>
.disabled {
  pointer-events: all !important;
  cursor: default;
}
</style>
