<template>
  <ul class="navigationList">
    <slot />
  </ul>
</template>

<style lang="scss" scoped>
.navigationList {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
</style>
