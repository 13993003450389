<script>
export default {
  props: {
    target: {
      type: [String, Function],
      required: true,
    },
    placement: {
      type: String,
      required: false,
      default: 'top',
    },
  },
};
</script>

<template>
  <b-popover :target="target" :placement="placement" custom-class="rounded-0" triggers="hover" boundary="viewport">
    <slot></slot>
  </b-popover>
</template>
