<script>
import _ from 'lodash';

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    items() {
      return _.sortBy(this.value, 'legendOrder');
    },
  },
};
</script>

<template>
  <div class="legend">
    <div v-for="item in items" :key="item.label" class="legendItem">
      <div :style="{ 'background-color': item.color }"></div>
      <div class="text-nowrap">{{ item.label }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
.legend {
  margin-bottom: 2rem;

  > div {
    padding-bottom: 1.2rem;
  }
}

.legendItem {
  display: flex;
  align-items: center;
  margin-right: 1.2rem;
}

.legendItem > div:first-child {
  width: 17px;
  height: 17px;
  margin-right: 0.5rem;
  border-radius: 50%;
}

.legendItem > div:last-child {
  font-size: 0.8rem;
}
</style>
