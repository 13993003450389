<script>
import ProsperOpsLogo from '@console/components/navigation/ProsperOpsLogo.vue';

export default {
  components: {
    ProsperOpsLogo,
  },
};
</script>

<template>
  <div class="topBar">
    <div class="topBarContent">
      <ProsperOpsLogo class="logoSection" :as-link="false" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.topBar {
  z-index: 10;
  width: 100%;
  background: $gray-100;
}

.topBarContent {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.25rem 1rem;
  margin: 0;
}

.logoSection {
  width: calc(250px - 1rem); /* subtract 1rem to account for topBar padding */
}
</style>
