<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';

import { dateFormat, dateUtc } from '@shared/utilities/filters';

import ArbitrageOpportunityChart from '@aws/components/savings/ArbitrageOpportunityChart.vue';
import ArbitrageOpportunityTrendChart from '@aws/components/savings/ArbitrageOpportunityTrendChart.vue';
import DailySavingsChart from '@aws/components/savings/DailySavingsChart.vue';
import DailySpendCoverageChart from '@aws/components/savings/DailySpendCoverageChart.vue';
import EffectiveSavingsRate from '@aws/components/savings/EffectiveSavingsRate.vue';
import NetSavingsTrendChart from '@aws/components/savings/NetSavingsTrendChart.vue';
import OrganizationCount from '@aws/components/savings/OrganizationCount.vue';
import OrganizationSummary from '@aws/components/savings/OrganizationSummary.vue';
import PortfolioAllocation from '@aws/components/savings/PortfolioAllocation.vue';
import RiUtilizationMetric from '@aws/components/savings/RiUtilizationMetric.vue';
import SavingsBreakdown from '@aws/components/savings/SavingsBreakdown.vue';
import SavingsCallout from '@aws/components/savings/SavingsCallout.vue';
import SavingsMetric from '@aws/components/savings/SavingsMetric.vue';
import SavingsPlanUtilizationMetric from '@aws/components/savings/SavingsPlanUtilizationMetric.vue';
import SpendCoverageBreakdown from '@aws/components/savings/SpendCoverageBreakdown.vue';
import SpendCoverageTrendChart from '@aws/components/savings/SpendCoverageTrendChart.vue';
import BuildingState from '@console/components/BuildingState.vue';
import TimeframeSelector from '@console/components/savings/TimeframeSelector.vue';
import Layout from '@console/Layout.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import Panel from '@shared/design/panels/Panel.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

export default {
  components: {
    Layout,
    PageHeader,
    BuildingState,
    BoxMessage,
    Panel,
    PanelSection,
    EffectiveSavingsRate,
    SavingsCallout,
    SavingsBreakdown,
    SpendCoverageBreakdown,
    SavingsMetric,
    PortfolioAllocation,
    DailySavingsChart,
    NetSavingsTrendChart,
    DailySpendCoverageChart,
    SpendCoverageTrendChart,
    TimeframeSelector,
    ArbitrageOpportunityTrendChart,
    ArbitrageOpportunityChart,
    OrganizationSummary,
    OrganizationCount,
    RiUtilizationMetric,
    SavingsPlanUtilizationMetric,
  },
  async beforeRouteUpdate(to, from, next) {
    await this.load(to.params.timeframe);
    next();
  },
  data() {
    return {
      selectedTimeframe: null,
      savingsDashboardNotFound: false,
      loadingSavings: false,
    };
  },
  head: {
    title: 'Compute Savings',
  },
  computed: {
    ...mapState('aws', ['savings']),
    ...mapGetters('customer', ['selectedCompany', 'isReseller', 'isDemo']),
    ...mapGetters('aws', [
      'selectedOrganization',
      'selectedOrganizationActiveAccounts',
      'savingsTimeframes',
      'selectedOrganizationIsCurDataSource',
      'isMultiOrgSummarySelected',
    ]),
    isLoading() {
      return !this.selectedTimeframe && !this.savingsDashboardNotFound;
    },
    hasSavingsPlan() {
      return _.has(this.savings, ['dashboard', 'key_metrics', 'savings_plan_utilization_percentage']);
    },
    hasDailySavings() {
      const ds = this.savings.dashboard.daily_savings;
      return ds.some(d => Object.entries(d).some(([field, value]) => field !== 'day' && !!value));
    },
    metricColGridClasses() {
      if (this.metricColumnCount === 3) {
        return 'col-lg-12 col-xl-4';
      }

      if (this.metricColumnCount === 4) {
        return this.containsLargeMetricValues ? 'col-lg-6' : 'col-lg-6 col-xl-3';
      }

      return 'col-xl-6';
    },
    containsLargeMetricValues() {
      const thresholdForLargeDigits = 5;
      const ec2InstanceChanges = _.get(this.savings, 'dashboard.key_metrics.ec2_instance_changes', 0);
      const portfolioActions = _.get(this.savings, 'dashboard.key_metrics.portfolio_actions', 0);
      const containsLargePortfolioActions = portfolioActions.toString().length > thresholdForLargeDigits;
      const containsLargeEc2InstanceChanges =
        !this.selectedOrganizationIsCurDataSource && ec2InstanceChanges.toString().length > thresholdForLargeDigits;

      return containsLargeEc2InstanceChanges || containsLargePortfolioActions;
    },
    useSmallerMetricText() {
      const hasFourColumnsWithSmallNumbers = this.metricColumnCount === 4 && !this.containsLargeMetricValues;
      const hasThreeColumnsWithLargeNumbers = this.metricColumnCount === 3 && this.containsLargeMetricValues;

      return hasFourColumnsWithSmallNumbers || hasThreeColumnsWithLargeNumbers;
    },
    metricColumnCount() {
      if (!this.selectedOrganizationIsCurDataSource && this.hasSavingsPlan) {
        return 4;
      }

      if (
        (this.selectedOrganizationIsCurDataSource && this.hasSavingsPlan) ||
        (!this.selectedOrganizationIsCurDataSource && !this.hasSavingsPlan)
      ) {
        return 3;
      }

      return 2;
    },
    metricPanelClasses() {
      if (this.metricColumnCount === 4) {
        return `metricPanel ${
          this.containsLargeMetricValues ? ' metricPanelLargeNumbers' : ' metricPanelSmallNumbers'
        }`;
      }

      return 'metricPanel';
    },
    hasArbitrageData() {
      return this.savings.dashboard.arbitrage_opportunity_trend && this.savings.dashboard.arbitrage_opportunity;
    },
  },
  async mounted() {
    await this.load(this.$route.params.timeframe);
  },
  methods: {
    ...mapActions('aws', ['loadSavings']),
    async onChange(nextTimeframe) {
      const timeframe = nextTimeframe.key;
      await this.$router.push({ name: 'aws_compute_savings', params: { timeframe }, query: this.$route.query });
    },
    async load(timeframe) {
      try {
        if (timeframe) {
          await this.loadSavings(timeframe);
          const filter = { key: timeframe };
          this.selectedTimeframe = _.find(this.savingsTimeframes, filter);
        } else {
          await this.loadSavings();
          this.selectedTimeframe = _.head(this.savingsTimeframes);
        }
      } catch (e) {
        await this.handleError(e, timeframe);
      }
    },
    async handleError(e, timeframe) {
      const status = _.get(e, 'response.status', 500);
      if (status === 404) {
        if (!timeframe) {
          this.savingsDashboardNotFound = true;
        } else {
          await this.$router.push({ name: '404' });
        }
      } else {
        throw e;
      }
    },
    resellerPrefix(text) {
      if (this.isReseller) {
        return `Arbitrage ${text}`;
      }
      return text;
    },
    formatDate(date, format = 'MMMM D, YYYY') {
      return dateFormat(dateUtc(date), format);
    },
  },
};
</script>

<template>
  <Layout :loading="isLoading">
    <template #default>
      <BuildingState v-if="savingsDashboardNotFound" />
      <div v-else>
        <PageHeader wrap-utility>
          <h1>Compute Savings</h1>
          <template v-slot:utility>
            <TimeframeSelector
              :selected="selectedTimeframe"
              :timeframes="savingsTimeframes"
              :finalized="savings.dashboard.is_finalized"
              :data-through-date="savings.dashboard.data_through_date"
              @change="onChange"
            />
          </template>
        </PageHeader>
        <div v-if="savings.dashboard.subscription_start_date" class="row sectional">
          <div class="col">
            <BoxMessage type="info">
              <strong
                >{{ formatDate(savings.dashboard.subscription_start_date) }} was your first full day with the ProsperOps
                service enabled.</strong
              >
              This month is considered a transition month as the savings results reflect a blend of before and after
              ProsperOps management.
            </BoxMessage>
          </div>
        </div>
        <div v-if="savings.dashboard.savings_impacted_by_inherited" class="row sectional">
          <div class="col">
            <BoxMessage type="warning">
              <div v-if="savings.dashboard.is_finalized">
                <strong
                  >Savings performance was impacted by unutilized Standard RIs and/or EC2 Instance Savings Plans which
                  were inherited.</strong
                >
                This will continue until these discount instruments expire or are otherwise disposed of.
              </div>
              <div v-else>
                <strong
                  >Savings performance is impacted by unutilized Standard RIs and/or EC2 Instance Savings Plans which
                  were inherited.</strong
                >
                This will continue until these discount instruments expire or are otherwise disposed of. Please
                <router-link :to="{ name: 'help' }">contact us</router-link>
                for further guidance.
              </div>
            </BoxMessage>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <Panel v-if="savings">
              <SavingsCallout
                :savings="savings"
                :organization="selectedOrganization"
                :aws-accounts="selectedOrganizationActiveAccounts"
                :demo="isDemo"
              />
            </Panel>
          </div>
        </div>
        <div class="row sectional summary">
          <div v-if="isMultiOrgSummarySelected" class="col-xl-auto">
            <div>
              <PanelSection v-if="savings" header="Effective Savings Rate" class="effectiveSavingsRate">
                <EffectiveSavingsRate
                  :data="savings.dashboard.effective_savings_rate"
                  :pre-subscription-esr-days="savings.dashboard.pre_subscription_effective_savings_rate_days"
                  :default-max="50"
                />
              </PanelSection>
            </div>
            <div class="organizationCountWidget">
              <OrganizationCount :organization-summary="savings.dashboard.organization_summary" />
            </div>
          </div>
          <div v-else class="col-xl-auto effectiveSavingsRate">
            <PanelSection v-if="savings" header="Effective Savings Rate">
              <EffectiveSavingsRate
                :data="savings.dashboard.effective_savings_rate"
                :pre-subscription-esr-days="savings.dashboard.pre_subscription_effective_savings_rate_days"
                :default-max="50"
              />
            </PanelSection>
          </div>
          <div class="col">
            <SavingsBreakdown :savings="savings" :demo="isDemo" service="compute" />
          </div>
        </div>
        <div v-if="isMultiOrgSummarySelected" class="row sectional">
          <div class="col">
            <OrganizationSummary :savings="savings" :demo="isDemo" :selected-timeframe="selectedTimeframe" />
          </div>
        </div>
        <div v-if="isReseller && hasArbitrageData" class="row sectional stack-sectional">
          <div class="col">
            <PanelSection header="Arbitrage Opportunity Trend">
              <ArbitrageOpportunityTrendChart
                v-if="savings.dashboard.arbitrage_opportunity_trend"
                :arbitrage-opportunity-trend="savings.dashboard.arbitrage_opportunity_trend"
              />
            </PanelSection>
          </div>
          <div class="col">
            <PanelSection header="Arbitrage Opportunity">
              <ArbitrageOpportunityChart
                v-if="savings.dashboard.arbitrage_opportunity"
                :arbitrage-opportunity="savings.dashboard.arbitrage_opportunity"
              />
            </PanelSection>
          </div>
        </div>
        <div class="row sectional">
          <div v-if="!selectedOrganizationIsCurDataSource" :class="metricColGridClasses">
            <SavingsMetric
              name="ec2_instance_changes"
              variant="warning"
              :value="savings.dashboard.key_metrics.ec2_instance_changes"
              :use-smaller-text="useSmallerMetricText"
              :company="selectedCompany"
            />
          </div>
          <div :class="metricColGridClasses">
            <SavingsMetric
              name="portfolio_actions"
              variant="success"
              :value="savings.dashboard.key_metrics.portfolio_actions"
              :use-smaller-text="useSmallerMetricText"
              :company="selectedCompany"
            />
          </div>
          <div :class="metricColGridClasses">
            <RiUtilizationMetric
              :savings="savings"
              :company="selectedCompany"
              :use-smaller-text="useSmallerMetricText"
            />
          </div>
          <div v-if="hasSavingsPlan" :class="metricColGridClasses">
            <SavingsPlanUtilizationMetric
              name="savings_plan_utilization_percentage"
              variant="secondary"
              :company="selectedCompany"
              :savings="savings"
              :use-smaller-text="useSmallerMetricText"
            />
          </div>
        </div>
        <div v-if="hasDailySavings" class="row sectional">
          <div class="col">
            <PanelSection :header="resellerPrefix('Daily Savings')">
              <DailySavingsChart
                :daily-savings="savings.dashboard.daily_savings"
                :dashboard-variant="savings.dashboard.variant"
                :month-start="savings.month_start"
                service="compute"
              />
            </PanelSection>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <PanelSection :header="resellerPrefix('Net Savings Trend')">
              <NetSavingsTrendChart :net-savings-trend="savings.dashboard.net_savings_trend" service="compute" />
            </PanelSection>
          </div>
        </div>
        <div class="row sectional stack-sectional">
          <div class="col">
            <PanelSection :header="resellerPrefix('Portfolio Allocation')">
              <PortfolioAllocation
                v-if="savings.dashboard.portfolio_distribution"
                :portfolio-allocation="savings.dashboard.portfolio_distribution"
                :dashboard-variant="savings.dashboard.variant"
                service="compute"
              />
            </PanelSection>
          </div>
          <div class="col">
            <PanelSection :header="resellerPrefix('Spend Coverage')" class="spendCoverageBreakdown">
              <SpendCoverageBreakdown
                v-if="savings.dashboard.spend_coverage_summary"
                :spend-coverage="savings.dashboard.spend_coverage_summary"
                :dashboard-variant="savings.dashboard.variant"
                :finalized="savings.dashboard.is_finalized"
                service="compute"
              />
            </PanelSection>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <PanelSection :header="resellerPrefix('Daily Spend Coverage')">
              <DailySpendCoverageChart
                :month-start="savings.month_start"
                :daily-spend-coverage="savings.dashboard.daily_spend_coverage"
                :dashboard-variant="savings.dashboard.variant"
                service="compute"
              />
            </PanelSection>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <PanelSection :header="resellerPrefix('Spend Coverage Trend')">
              <SpendCoverageTrendChart
                :spend-coverage-trend="savings.dashboard.spend_coverage_trend"
                :dashboard-variant="savings.dashboard.variant"
                service="compute"
              />
            </PanelSection>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.row.summary {
  display: flex;

  > div:first-child {
    flex-basis: auto;

    @include media-breakpoint-up(xl) {
      flex-basis: 380px;
    }
  }
}

.metricPanel {
  min-width: 160px;
}

.organizationCountWidget {
  margin-top: 30px;
}

.effectiveSavingsRate > :deep(section) {
  padding-top: 0;
}

.effectiveSavingsRate {
  max-height: 560px;
}

.spendCoverageBreakdown {
  height: auto; // Don't expand the panel to match the Portfolio Allocation panel's height
}

@include media-breakpoint-only(xl) {
  .metricPanelLargeNumbers {
    padding: 0;
    margin: 0;
  }

  .metricPanelSmallNumbers {
    padding: 0;
    margin-right: 4%;
    margin-left: 4%;
  }

  .colPadding {
    padding: 0;
  }
}
</style>
