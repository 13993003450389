<script setup lang="ts">
import type { AwsOrganizationCommitmentLockInRiskTrailingTrend } from '@console/services/api.models';
import type {
  GradientColorStopObject,
  PlotOptions,
  SeriesArearangeOptions,
  SeriesLineOptions,
  TooltipOptions,
  XAxisOptions,
  YAxisOptions,
} from 'highcharts';

import { computed } from 'vue';

import { getGradientBreakpoints } from './commitment_utility';
import * as chartUtilities from '@console/components/charts/utility';
import { useFeatureStore } from '@shared/state/feature.store';
import { daysToMonths } from '@shared/utilities/date_helpers';
import { isDefined } from '@shared/utilities/is_defined';

import SeriesChart from '@console/components/charts/SeriesChart.vue';

const props = defineProps<{
  trend: AwsOrganizationCommitmentLockInRiskTrailingTrend;
  dataThroughDate: string;
  variant: 'laddered' | 'term-optimized';
}>();

const mappedData = computed(() => {
  return props.trend.overall.map(d =>
    d == null
      ? null
      : {
          max: daysToMonths(d.max),
          average: daysToMonths(d.average),
          min: daysToMonths(d.min),
        }
  );
});

const MS_IN_DAY = 86400000;
const msStart = computed(() => {
  const dayOffset = mappedData.value.length - 1;
  const dataThroughDate = props.dataThroughDate;
  return +new Date(dataThroughDate) - MS_IN_DAY * dayOffset;
});

type SeriesOptions = Omit<SeriesLineOptions | SeriesArearangeOptions, 'name'> & {
  name: string;
};

const gradientStops = computed<Array<GradientColorStopObject>>(() => {
  const green = '#00c58cb0';
  const yellow = '#fcbe2cb0';
  const red = '#fc5454b0';

  const gaugeMin = props.variant === 'term-optimized' ? 0 : 3;
  const gaugeMax = props.variant === 'term-optimized' ? 15 : 12;
  const { yellow: yellowMonthStart, red: redMonthStart } = getGradientBreakpoints(gaugeMin, gaugeMax);

  var values = mappedData.value.filter(isDefined);
  var min = Math.min(...values.map(d => d.min));
  var max = Math.max(...values.map(d => d.max));
  const range = max - min;

  var stops: GradientColorStopObject[] = [];
  if (min < yellowMonthStart) {
    stops.push([0, green]);
  } else if (min < redMonthStart) {
    stops.push([0, yellow]);
  } else {
    stops.push([0, red]);
  }

  if (min < yellowMonthStart && max > yellowMonthStart) {
    const yellowMonths = yellowMonthStart - min;
    const yellowPercentage = yellowMonths / range;
    stops.push([yellowPercentage - 0.1, green]);
    stops.push([yellowPercentage + 0.1, yellow]);
  }

  if (min < redMonthStart && max > redMonthStart) {
    const redMonths = max - redMonthStart;
    const redPercentage = redMonths / range;
    const redPercentageStart = 1 - redPercentage;
    stops.push([redPercentageStart - 0.1, yellow]);
    stops.push([redPercentageStart + 0.1, red]);
  }

  if (max > 10) {
    stops.push([1, red]);
  } else if (max > 8) {
    stops.push([1, yellow]);
  } else {
    stops.push([1, green]);
  }

  return stops;
});

const store = useFeatureStore();
const series = computed<Array<SeriesOptions>>(() => {
  return store.featureFlags.clrTrendV2
    ? [
        {
          type: 'line',
          name: 'Commitment Lock-In Risk',
          data: mappedData.value.map(d => d?.average ?? null),
          zIndex: 1,
          color: '#5c54ff',
          marker: {
            enabled: false,
          },
        },
        {
          name: 'Range',
          data: mappedData.value.map(d => (d == null ? null : [d.min, d.max])),
          type: 'arearange',
          lineWidth: 1,
          linkedTo: ':previous',
          fillOpacity: 0.2,
          color: '#a7a3ff',
          zIndex: 0,
          marker: {
            enabled: false,
          },
          tooltip: {
            pointFormat: `
              <div style="border-top: 1px solid #ced4da; width:100%; margin: 4px 0;"></div>
              <span>Weighted Average Duration</span>
              <br>
              <span style="color:#a7a3ff">●</span> Max: <b>{point.high}</b>
              <br>
              <span style="color:#a7a3ff">●</span> Min: <b>{point.low}</b>
              `,
          },
        },
      ]
    : [
        {
          type: 'line',
          name: 'Average',
          data: mappedData.value.map(d => d?.average ?? null),
          zIndex: 1,
          color: '#6C757D',
          marker: {
            enabled: false,
          },
        },
        {
          name: 'Range',
          data: mappedData.value.map(d => (d == null ? null : [d.min, d.max])),
          type: 'arearange',
          lineWidth: 0,
          linkedTo: ':previous',
          fillOpacity: 1,
          color: {
            linearGradient: {
              x1: 0,
              y1: 1,
              x2: 0,
              y2: 0,
            },
            stops: gradientStops.value,
          },
          zIndex: 0,
          marker: {
            enabled: false,
          },
          tooltip: {
            pointFormat: '<span style="color:#6C757D">●</span> Range: <b>{point.low}</b> - <b>{point.high}</b>',
          },
        },
      ];
});

const tooltip: TooltipOptions = {
  enabled: true,
  borderRadius: 0,
  borderColor: '#adb5bd',
  shared: true,
  shadow: false,
  xDateFormat: '<span style="font-size: 12px">%B %d, %Y</span>',
  useHTML: true,
  dateTimeLabelFormats: {
    millisecond: '%b %e, %Y',
  },
  valueDecimals: 1,
  valueSuffix: ' months',
};

const plotOptions: PlotOptions = {
  series: {
    pointStart: msStart.value,
    pointIntervalUnit: 'day',
  },
  column: {
    states: {
      hover: {
        brightness: 0,
        enabled: false,
      },
    },
  },
};

const xAxis: XAxisOptions = {
  type: 'datetime',
  tickLength: 0,
  labels: {
    formatter: chartUtilities.datetimeZoomLabelFormatter,
  },
  units: chartUtilities.units,
};

const yAxis = computed<YAxisOptions[]>(() => {
  const allValues = mappedData.value.filter(isDefined).map(d => d?.max);
  const maxMonths = Math.max(...allValues);
  return [chartUtilities.getMonthYAxis(maxMonths)];
});
</script>

<template>
  <div>
    <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :tooltip="tooltip" :series="series" :plot-options="plotOptions" />
  </div>
</template>
